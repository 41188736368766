import { Cog } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminOnly } from '@/AdminOnly';
import { StandoutBorder } from '@/Borders';
import { Button } from '@/Button';
import { ButtonIcon } from '@/Icon';
import { getStatusFromMap, StatusBadge } from '@/Labels/Status';
import { LayoutHeader } from '@/Layout';
import { NumericIndicator } from '@/NumericIndicator';
import { Tabby, Tabs } from '@/Tabbar';
import { IdentifierText, PageDescription, PageTitle } from '@/Text';
import { AnalyzeOrder } from '~/Actions/Orders/AnalyzeOrder';
import { SummarizeOrder } from '~/Actions/Orders/SummarizeOrder';
import { CommandActionDropdown } from '~/Components/Common/Command/CommandActionDropdown';
import { orderPredictionStatusMap } from '~/Components/Orders/Representations/OrderPredictionStatusMap';
import { GroupedCommentsPane } from '~/Components/Panes/GroupedCommentsPane';
import { usePageProps } from '~/hooks/usePageProps';
import { useScrollspy } from '~/hooks/useScrollSpy';
import {
  Comment,
  Integration,
  Order,
  OrderDataStream,
  OrderDataStreamType,
} from '~/types/types';
import { filterActiveIssues } from '~/utils/issues';

import { useOrder } from './OrderProvider';

interface OrderHeaderProps {
  order: Order;
  integration: Integration;
  dataStreams: OrderDataStream<any>[];
  groupedComments?: Record<string, Comment[]>;
}

export const OrderHeader = ({
  order,
  dataStreams,
  groupedComments,
}: OrderHeaderProps) => {
  const { t } = useTranslation();
  const { organization } = usePageProps();

  const { orderProduces, inspectionIssues } = useOrder();

  const firstOrderProduce = orderProduces?.[0];

  const manualDataStreamSortOrder = [
    OrderDataStreamType.TRANSPORT_TEMPERATURE,
    OrderDataStreamType.LOCATION,
    OrderDataStreamType.WEATHER,
  ];

  const sortedDataStreams = dataStreams.sort((a, b) => {
    return (
      manualDataStreamSortOrder.indexOf(a.type as OrderDataStreamType) -
      manualDataStreamSortOrder.indexOf(b.type as OrderDataStreamType)
    );
  });

  // Ensure that only unique datastreams exist (based on their type).
  const uniqueDataStreamTypes: string[] = [];
  const uniqueDataStreamsMap: Record<string, OrderDataStream<any>> = {};

  sortedDataStreams.forEach((stream) => {
    if (!uniqueDataStreamsMap[stream.type]) {
      uniqueDataStreamsMap[stream.type] = { ...stream, issues_count: filterActiveIssues(stream.issues).length ?? 0 };
      uniqueDataStreamTypes.push(stream.type);
    } else {
      if (uniqueDataStreamsMap?.[stream.type] && uniqueDataStreamsMap[stream.type].issues_count !== undefined && uniqueDataStreamsMap[stream.type].issues_count !== null) {
        (uniqueDataStreamsMap[stream.type].issues_count as number) += filterActiveIssues(stream.issues).length ?? 0;
      }
    }
  });

  const uniqueDataStreams = uniqueDataStreamTypes.map((type) => uniqueDataStreamsMap[type]) ?? [];

  const ids = [
    '#order',
    '#inspection',
    '#attachments',
    ...uniqueDataStreams.map((stream) => `#${t(`data_streams.id_${stream.type}`)}`),
  ];

  const activeId = useScrollspy(ids, 400);

  return (
    <LayoutHeader isSticky>
      <div className="flex flex-wrap justify-between">
        {/* Left section */}
        <div className="flex">
          {firstOrderProduce?.produce?.image_url && (
            <StandoutBorder className="mr-4">
              <img
                src={firstOrderProduce.produce.image_url}
                alt={firstOrderProduce.produce.image_url}
                className="object-cover w-12 h-12 rounded-full"
              />
            </StandoutBorder>
          )}
          <div>
            <div className="flex flex-wrap gap-4">
              <div>
                <PageTitle>{t('order')}</PageTitle>
                <PageDescription>
                  <IdentifierText>{order.ref ?? order.id}</IdentifierText>
                </PageDescription>
              </div>

              <div>
                {order.predicted_score && (
                  <StatusBadge config={getStatusFromMap(order.predicted_score, orderPredictionStatusMap)}/>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Right section: button */}
        <div className="flex space-x-2">
          <div>
            <GroupedCommentsPane
              commentable={{ id: order.id, type: 'Order' }}
              groupedComments={groupedComments}
              path={`/b/${organization.id}/comments/add`}
              Trigger={
                <Button size="sm" variant="white" className="flex items-center">
                  <div className="inline-block mr-1">
                    <NumericIndicator brand="gray" size="sm">
                      {Object.values(groupedComments ?? {}).reduce(
                        (prev, curr) => (prev += curr.length),
                        0
                      ) ?? 0}
                    </NumericIndicator>
                  </div>

                  {t('view_all_comments')}
                </Button>
              }
            />
            <div className="mt-2">
              <AdminOnly>
                <CommandActionDropdown actionItems={[
                  { action: SummarizeOrder, target: order, group: 'admin' },
                  { action: AnalyzeOrder, target: order, group: 'admin' },
                ]}>
                  <Button className="w-full" size="sm" variant="gray">
                    <ButtonIcon icon={Cog}/>
                    {t('configuration')}
                  </Button>
                </CommandActionDropdown>
              </AdminOnly>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <Tabs inCardHeader>
          <Tabby
            as="a"
            theme="red"
            tab={{
              name: t('order'),
              href: '#order',
              current: activeId === 'order',
            }}
          />
          <Tabby
            as="a"
            theme="red"
            tab={{
              name: t('inspection'),
              href: '#inspection',
              current: activeId === 'inspection',
              count: inspectionIssues.filter((issue) => issue.status !== 'read').length,
            }}
          />
          <Tabby
            as="a"
            theme="red"
            tab={{
              name: t('attachments'),
              href: '#attachments',
              current: activeId === 'attachments',
            }}
          />

          {uniqueDataStreams.map((stream, index) => (
            <Tabby
              as="a"
              theme="red"
              key={index}
              tab={{
                name: t(`data_streams.type_${stream.type}`),
                href: `#${t(`data_streams.id_${stream.type}`)}`,
                current: activeId === t(`data_streams.id_${stream.type}`),
                count: stream.issues_count,
              }}
            />
          ))}
        </Tabs>
      </div>
    </LayoutHeader>
  );
};
