import { ReactNode, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, SimpleCardHeader } from '@/Card';
import { Checkbox } from '@/Checkbox';
import { PlaceholderBox } from '@/Fallback';
import { RadioGroup } from '@/Form/RadioGroup';
import {
  MeasurementFieldIcon,
  PercentageIcon, QualityIcon,
  SimpleCardHeaderIcon,
} from '@/Icon';
import { LabeledField } from '@/LabeledField';
import { getStatusFromMap, StatusBadge } from '@/Labels/Status';
import { Breadcrumbs, fromBreadcrumb, LayoutBody, LayoutHeader } from '@/Layout';
import { RadioCard } from '@/RadioButtonCard';
import { TabOptionGroup, TabOptionItem } from '@/TabOption';
import { CardTitle, DescriptionText, PageDescription, PageTitle, SimpleCardTitle } from '@/Text';
import { EditQuality } from '~/Actions/Common/EditQuality';
import { UploadQuality } from '~/Actions/Common/UploadQuality';
import { CommandActionButton } from '~/Components/Common/Command/CommandActionButton';
import { Grouper } from '~/Components/Common/Grouper';
import { useConfirmationModal } from '~/Components/Common/Modals/useConfirmationModal';
import { OrderProduceCard } from '~/Components/Orders/OrderProduceCard';
import { FieldEntry as FieldEntry, QualityInputMapper } from '~/Components/Orders/QualityInput/formatter';
import {
  QualityInputProvider,
  useQualityInput,
} from '~/Components/Orders/QualityInput/QualityInputProvider';
import { ProduceVarietyCard } from '~/Components/Produce/ProduceVarietyCard';
import { qualityInspectionsStatusMap } from '~/Components/Quality/QualityEntries/Inspections/QualityInspectionsStatusMap';
import { usePageProps } from '~/hooks/usePageProps';
import { Breadcrumb, Inspection, Order, PageProps, Produce, ProduceVariety } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';
import { FieldInputCard } from './Quality/FieldInputCard';
import { QualitySection } from './Quality/QualitySection';
import { QualityInputActionbar } from './QualityInputActionbar';

export interface AddQualityPage extends PageProps {
  order: Order;
  isEdit: boolean;
  inspection?: Inspection;
  breadcrumb?: Breadcrumb;
}

const QualityReviewCard = () => {
  const { organization } = usePageProps();
  const {
    form,
    getFieldsResults,
    isInitialized,
    orderProduce,
    order,
    isEditing,
    initialInspection,
    otherUnfilledProduces,
    willContinueSubmission,
    setWillContinueSubmission,
  } = useQualityInput();
  const { confirm, ConfirmationModal } = useConfirmationModal({
    title: 'Submit Quality Review',
    description: 'Are you sure you want to submit this quality review?',
    confirmText: 'Submit',
    cancelText: 'Cancel',
  });

  const fieldEntries: FieldEntry[] = useWatch({ control: form.control, name: 'fields' });

  if (!isInitialized || !orderProduce) return null;
  const fieldResults = getFieldsResults(fieldEntries);

  // Check if there are any defects detected
  const hasDefects = fieldResults.some(result => result.has_risk);

  const submit = () => {
    const formEntries = form.getValues();

    if (isEditing && initialInspection) {
      return QualityInputMapper.createEditAPI(formEntries, organization, order, orderProduce, initialInspection);
    }

    return QualityInputMapper.createAPI(formEntries, organization, orderProduce, order, willContinueSubmission);
  };

  return (
    <Card>
      <CardBody>
        <div className="py-2">
          <LabeledField label="Produce" renderValue={(
            <div className="inline-block mb-2">
              <OrderProduceCard orderProduce={orderProduce}/>
            </div>
          )}/>

          <LabeledField
            label="Inspection defects"
            renderValue={(
              <Card>
                <CardBody variant="muted">
                  <div className="flex flex-wrap gap-1">
                    {fieldResults.map((fieldResult, index) => (
                      <div key={index}>
                        <StatusBadge
                          config={getStatusFromMap(fieldResult.result, qualityInspectionsStatusMap) ?? null}
                        >
                          {fieldResult?.definition?.name}
                        </StatusBadge>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            )}
          />

          <div className="my-4">

          </div>

          <hr/>
          <div className="mt-4">
            {otherUnfilledProduces.length > 0 && (
              <div className="flex gap-2 mb-2">
                <Checkbox
                  id="willContinueSubmission"
                  checked={willContinueSubmission}
                  onCheckedChange={(val) => setWillContinueSubmission(val)}
                />
                <DescriptionText
                  as="label"
                  htmlFor="willContinueSubmission"
                >
                  You still have other varieties left to inspect. Check this to continue the inspection after
                  submission.
                </DescriptionText>
              </div>
            )}

            <div className="flex gap-4">
              {isEditing ? (
                <>
                  {hasDefects && <ConfirmationModal />}
                  <CommandActionButton
                    type="button"
                    getParams={submit}
                    action={EditQuality}
                    target={order}
                    confirmation={hasDefects ? {
                      title: 'Edit Quality Review with Defects',
                      description: 'This quality review contains defects. Are you sure you want to save these changes?',
                      confirmText: 'Save Changes',
                      cancelText: 'Cancel',
                    } : undefined}
                  />
                </>
              ) : (
                <>
                  {hasDefects && <ConfirmationModal />}
                  <CommandActionButton
                    type="button"
                    variant="primary"
                    getParams={submit}
                    action={UploadQuality}
                    target={order}
                    confirmation={hasDefects ? {
                      title: 'Submit Quality Review with Defects',
                      description: 'Please note this value is outside of your buyers spec, has this been pre-agreed upon?',
                      confirmText: 'Submit',
                      cancelText: 'Cancel',
                    } : undefined}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const QualityPageForm = () => {
  const { fields, isInitialized } = useQualityInput();

  if (!isInitialized) return null;

  const [selected, setSelected] = useState('form');

  return (
    <Card>
      <SimpleCardHeader>
        <SimpleCardHeaderIcon icon={QualityIcon}/>
        <SimpleCardTitle>Inspection</SimpleCardTitle>
      </SimpleCardHeader>
      <TabOptionGroup value={selected} onChange={setSelected}>
        <TabOptionItem
          value="form"
          title="Using form"
          description="Fill the required spec fields."
        />
        <TabOptionItem
          value="voice"
          title="Using text or voice"
          disabled
          status="coming-soon"
          description="Write or voice-record your inspection."
        />
        <TabOptionItem
          value="file"
          title="Using file upload"
          disabled
          description="Upload a file / image of your inspection."
          status="unavailable"
        />
      </TabOptionGroup>
      <Grouper
        sticky
        spacing={false}
        data={fields}
        groupBy={(field) => {
          if (field.definition.type === 'measurement') return 'required';

          return 'check_defect';
        }}
        config={{
          required: {
            labelKey: 'Measurements',
            icon: MeasurementFieldIcon,
            theme: 'red',
            priority: 2,
          },
          check_defect: {
            labelKey: 'Check for defects',
            icon: PercentageIcon,
            theme: 'orange',
            priority: 1,
          },
        }}
      >
        {(field, originalIndex) => (
          <div key={field?.definition?.id} className="rounded xs:m-4 lg:m-0 xs:border lg:border-none">
            <FieldInputCard
              defaultFormEntry={field.formEntry}
              fieldDefinition={field.definition}
              index={originalIndex} // Use the original index here
            />
          </div>
        )}
      </Grouper>
    </Card>
  );
};

export const QualityGeneralInfo = () => {
  const { handleSetOrderProduce, orderProduceOptions, orderProduce } = useQualityInput();

  return (
    <Card>
      <>
        <CardTitle>
          <RadioGroup
            onValueChange={handleSetOrderProduce}
            defaultValue={orderProduce?.produce_variety_id}
          >
            {orderProduceOptions.map((orderProduce) => (
              <div key={orderProduce.produce_variety_id}>
                <RadioCard value={orderProduce.produce_variety_id} id={orderProduce.id}>
                  <ProduceVarietyCard
                    produce={orderProduce.produce as Produce}
                    produceVariety={orderProduce.produce_variety as ProduceVariety}
                    inspectionResult={orderProduce.inspectionResult}
                  />
                </RadioCard>
              </div>
            ))}
          </RadioGroup>
        </CardTitle>
      </>
    </Card>
  );
};

const AddQualityPage = ({ order, inspection, isEdit, breadcrumb }: AddQualityPage) => {
  const { t } = useTranslation();

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  if (!order) return (
    <div>
      <LayoutBody>
        <PlaceholderBox title="No order selected"
          description="Please select an order to start the quality inspection."/>
      </LayoutBody>
    </div>
  );

  return (
    <QualityInputProvider order={order} isEditing={isEdit} initialInspection={inspection}>
      {({ isInitialized }) => (
        <div>
          <LayoutHeader>
            <div>
              {breadcrumb && (
                <Breadcrumbs
                  backLink={fromBreadcrumb(breadcrumb)}
                />
              )}
              <PageTitle>{t('quality_inspection')}</PageTitle>
              <PageDescription>
                {t('quality_inspection_description')}
              </PageDescription>
            </div>
          </LayoutHeader>
          <LayoutBody>
            <div className="space-y-18">
              <QualitySection
                step={1}
                titleKey="Basic inspection information"
                descriptionKey="Tell us about your inspection"
              >
                <QualityGeneralInfo/>
              </QualitySection>
              {isInitialized && (
                <>
                  <QualitySection
                    titleKey="Quality inspection"
                    step={2}
                    descriptionKey="Describe the quality of your produce here."
                  >
                    <QualityPageForm/>
                  </QualitySection>
                  <QualitySection
                    titleKey="Confirm submission"
                    step={3}
                    descriptionKey="Review your quality submission."
                  >
                    <Card>
                      <QualityReviewCard/>
                    </Card>
                  </QualitySection>
                  <QualityInputActionbar/>
                </>
              )}

              {!isInitialized && (
                <PlaceholderBox title="Select the produce"
                  description="Please select the produce variety to start the quality inspection."/>
              )}
            </div>
          </LayoutBody>
        </div>
      )}
    </QualityInputProvider>
  );
};

AddQualityPage.layout = (page: ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default AddQualityPage;
