import { useQuery } from './useQuery';

/**
 * A hook that builds on top of useQuery to simplify working with individual query parameters.
 * It provides a simplified interface for components that only need to interact with a single parameter.
 *
 * @template T - The type of the query parameters object
 * @template K - The key of the parameter in the query parameters object
 *
 * @param paramName - The name of the parameter to manage
 * @param defaultValue - Optional default value to use if the parameter is not present in the URL
 * @param options - Options to pass to useQuery
 *
 * @returns An object with:
 *   - value: The current value of the parameter
 *   - onChange: A function to update the parameter value
 *   - loading: Whether the query is currently loading
 *
 * @example
 * // In a component:
 * const { value, onChange, loading } = useQueryParam<AnalyticsConfigRequest, 'reporting_seller_id'>('reporting_seller_id');
 *
 * // With default value:
 * const { value, onChange, loading } = useQueryParam<AnalyticsConfigRequest>('qualityEntityType', 'produce_variety');
 *
 * // Then use with a picker:
 * <GenericPicker
 *   value={value}
 *   onChange={onChange}
 *   loading={loading}
 *   // ... other props
 * />
 */
export function useQueryParam<T extends Record<string, any>, K extends keyof T = keyof T>(
  paramName: K,
  defaultValue?: T[K] | null,
  options?: Parameters<typeof useQuery>[1]
) {
  const { parameters, setParameter, isLoading } = useQuery<T>(undefined, options);

  // Get the current value of the parameter, or use the default value if not present
  const value = parameters[paramName] !== undefined ? parameters[paramName] : defaultValue;

  // Create a function to update the parameter
  const onChange = (newValue: T[K] | null | unknown) => {
    setParameter(paramName, newValue as T[K]);
  };

  return {
    value,
    onChange,
    loading: isLoading,
  };
}

/**
 * A specialized version of useQueryParam specifically for use with GenericPicker
 * This handles the type conversions needed for GenericPicker's onChange prop
 *
 * @template T - The type of the query parameters object
 * @template K - The key of the parameter in the query parameters object
 *
 * @param paramName - The name of the parameter to manage
 * @param defaultValue - Optional default value to use if the parameter is not present in the URL
 * @param options - Options to pass to useQuery
 *
 * @returns An object with props ready to be spread into a GenericPicker component
 *
 * @example
 * // In a component:
 * const pickerProps = useQueryParamPicker<AnalyticsConfigRequest>('reporting_seller_id');
 *
 * // With default value:
 * const pickerProps = useQueryParamPicker<AnalyticsConfigRequest>('qualityEntityType', 'produce_variety');
 *
 * // Then use with a picker:
 * <GenericPicker
 *   {...pickerProps}
 *   title="Seller"
 *   options={options}
 *   // ... other props
 * />
 */
export function useQueryParamPicker<T extends Record<string, any>, K extends keyof T = keyof T>(
  paramName: K,
  defaultValue?: T[K] | null,
  options?: Parameters<typeof useQuery>[1]
) {
  const { value, onChange, loading } = useQueryParam<T, K>(paramName, defaultValue, options);

  // Create a wrapper function that handles the type conversion for GenericPicker
  const handleChange = (newValue: unknown) => {
    onChange(newValue);
  };

  return {
    value,
    onChange: handleChange,
    loading,
  };
}
