import { LightbulbIcon, ThermometerIcon } from 'lucide-react';
import { useMemo } from 'react';
import {
  ComposedChart,
  Line, ReferenceLine,
  XAxis,
  YAxis,
} from 'recharts';

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/Chart';
import { DateFormat, useDate } from '~/hooks/useDate';
import { OrderDataStream } from '~/types/types';

import {
  enrichDataPointsWithIssueInfo,
  extractIssueRegions,
} from './ColdChainCharts.helpers';

interface ColdChainChartProps {
  dataStream: OrderDataStream<any>;
  data: any;
  type: 'temperature' | 'light';
  lowThresholdY?: number | null;
  upperThresholdY?: number | null;
  openIssues: any[];
}

interface AnomalyDotProps {
  cx: number;
  cy: number;
  stroke: string;
  payload: any;
  value: number;
  thresholdY?: number;
  lowerThresholdY?: number;
}

const CustomizedDot = ({ cx, cy, payload }: AnomalyDotProps) => {
  if (payload.status === 'open') {
    // Check that the point is related to an open issue...
    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        className="stroke-2 fill-yellow-500 stroke-white drop-shadow-md"
      />
    );
  }

  if (payload.status === 'accepted') {
    // Check that the point is related to an open issue...
    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        className="stroke-2 fill-orange-500 stroke-white drop-shadow-md"
      />
    );
  }

  if (payload.status === 'escalated') {
    // Check that the point is related to an open issue...
    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        className="stroke-2 fill-rose-500 stroke-white drop-shadow-md"
      />
    );
  }

  if (payload.status === 'read') {
    // Check that the point is related to an open issue...
    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        className="stroke-2 fill-emerald-500 stroke-white drop-shadow-md"
      />
    );
  }

  return null;
};

export const ColdChainChart = ({ dataStream, data, lowThresholdY, upperThresholdY, type, openIssues }: ColdChainChartProps) => {
  const { parseOrFormat } = useDate();

  // Generate issue regions using our helper function
  const issueRegions = useMemo(() => {
    return extractIssueRegions(openIssues, data);
  }, [openIssues, data]);

  // Enrich chart data with issue region information
  const chartData = useMemo(() => {
    return enrichDataPointsWithIssueInfo(data, issueRegions);
  }, [data, issueRegions]);

  // Custom tooltip component that shows issue region information
  const CustomTooltipContent = (props: any) => {
    if (!props.active || !props.payload || !props.payload.length) {
      return null;
    }

    const dataPoint = props.payload[0].payload;

    // If the data point is in an issue region, show enhanced tooltip with region info
    if (dataPoint && dataPoint.inIssueRegion && dataPoint.issueRegionInfo) {
      const { durationHours, durationDays, pointCount } = dataPoint.issueRegionInfo;
      const issueStatus = dataPoint.status || 'unknown';

      // Get the issue start and end dates
      const issueRegion = dataPoint.issueId && issueRegions.find(r => r.issue.id === dataPoint.issueId);
      const formattedStartDate = issueRegion
        ? parseOrFormat(issueRegion.issue.issueable.data.startDate, DateFormat.HumanDateTime)
        : 'Unknown';
      const formattedEndDate = issueRegion?.issue.issueable.data.endDate
        ? parseOrFormat(issueRegion.issue.issueable.data.endDate, DateFormat.HumanDateTime)
        : 'Ongoing';

      return (
        <ChartTooltipContent
          {...props}
          labelFormatter={(val) => parseOrFormat(val, DateFormat.HumanDateTime)}
          indicator="line"
          renderFooter={
            <div className="grid gap-1 pt-1">
              <div className="flex items-center justify-between">
                <span className="text-xs font-medium">Issue Status:</span>
                <span className={`ml-4 px-2 py-0.5 rounded-full text-xs ${
                  issueStatus === 'open' ? 'bg-yellow-100 text-yellow-800' :
                    issueStatus === 'escalated' ? 'bg-rose-100 text-rose-800' :
                      issueStatus === 'accepted' ? 'bg-orange-100 text-orange-800' :
                        issueStatus === 'read' ? 'bg-emerald-100 text-emerald-800' :
                          'bg-gray-100 text-gray-800'
                }`}>
                  {issueStatus.toUpperCase()}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-xs">From:</span>
                <span className="ml-4 font-mono text-xs">{formattedStartDate}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-xs">To:</span>
                <span className="ml-4 font-mono text-xs">{formattedEndDate}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-xs">Duration:</span>
                <span className="ml-4 font-mono text-xs">
                  {durationDays > 0 ? `${durationDays}d ${durationHours % 24}h` : `${durationHours}h`}
                </span>
              </div>
            </div>
          }
        />
      );
    }

    // Standard tooltip for non-issue points
    return (
      <ChartTooltipContent
        {...props}
        labelFormatter={(val) => parseOrFormat(val, DateFormat.HumanDateTime)}
        indicator="line"
      />
    );
  };

  const ticks = useMemo(() => {
    const ticks = [0];

    if (lowThresholdY !== undefined && lowThresholdY !== null) {
      ticks.push(lowThresholdY);
    }

    if (upperThresholdY !== undefined && upperThresholdY !== null) {
      ticks.push(upperThresholdY);
    }

    const max = Math.max(...data.map((d: any) => d.value));
    if (max > 1) {
      ticks.push(5);
    }

    return ticks;
  }, [data, lowThresholdY, upperThresholdY]);

  const chartConfig = {
    value: {
      icon: type === 'temperature' ? ThermometerIcon : LightbulbIcon,
      label: type === 'temperature' ? 'Temperature' : 'Light',
      color: 'var(red-500)',
    },
    anomalyValue: {
      hide: true,
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer config={chartConfig} className="aspect-auto h-[300px] w-full">
      <ComposedChart
        className="text-sm"
        data={chartData}
      >
        <XAxis
          tickFormatter={(val) => parseOrFormat(val, DateFormat.HumanDateTimeNoYear)}
          className="fill-gray-700"
          stroke="#D1D5DB"
          dataKey="date"
          tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
          tickMargin={14}
        />
        <YAxis
          className="fill-gray-700"
          stroke="#D1D5DB"
          tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
          tickFormatter={(val) => `${val}${type === 'temperature' ? '°C' : '%'}`}
          domain={[Math.min(...ticks, 100) - 3, Math.max(...ticks, -100) + 3]}
          ticks={ticks}
        />

        <ChartTooltip
          cursor={false}
          content={<CustomTooltipContent />}
        />

        {lowThresholdY !== undefined && lowThresholdY !== null && (
          <ReferenceLine
            type="monotone"
            y={lowThresholdY}
            label="Min"
            stroke='red'
            strokeWidth={2}
            strokeDasharray="3 3"
          />
        )}

        {upperThresholdY !== undefined && upperThresholdY !== null && (
          <ReferenceLine
            type="monotone"
            y={upperThresholdY}
            label="Max"
            stroke='orange'
            strokeWidth={2}
            strokeDasharray="3 3"
          />
        )}

        <Line
          type="monotone"
          dataKey="value"
          className="stroke-2 stroke-blue-200"
          strokeWidth={1}
        />

        {/* Scatter-plot with anomaly dots */}
        <Line
          type="monotone"
          dataKey="anomalyValue"
          strokeWidth={0} // No line connecting the dots
          dot={(props) => <CustomizedDot {...props} />}
          connectNulls={false} // Don't connect dots when there are null values
        />
      </ComposedChart>
    </ChartContainer>
  );
};
