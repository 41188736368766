import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { EllipsisHorizontalIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardHeaderContainer, PromptCard } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import { Floater } from '@/Floater';
import { WithHoverCard } from '@/HoverCard';
import { Icon, IconBox } from '@/Icon';
import { Label } from '@/Label';
import { CardSubtitle, CardTitle, Helper, MutedText, Strong, Text, TextLabel } from '@/Text';
import { FormatDistribution } from '~/Components/Analytics/FormatDistribution';
import { FieldIcon } from '~/Components/Fields/FieldIcon';
import { AccuracyIndicator } from '~/Components/Indicators/AccuracyIndicator';
import { useAction } from '~/hooks/useAction';
import { useFormat } from '~/hooks/useFormat';
import { usePageProps } from '~/hooks/usePageProps';
import { Organization } from '~/types/types';
import { cn } from '~/utils/cn';
import { findIndex } from '~/utils/findIndex';

import { ForecastAccuracyPageProps } from '../helpers';
import ForecastSellerFieldSummary = App.Domain.Quality.ForecastSellerFieldSummary;
import { AcademicCapIcon, LightBulbIcon } from '@heroicons/react/24/outline';

function FieldSummary({ summary }: any) {
  const { t } = useTranslation();
  const { formatPercentage } = useFormat();

  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    middleware: [offset(12)],
    whileElementsMounted: autoUpdate,
  });

  return (
    <CardBody className="py-2! px-4! grid items-center grid-cols-[2fr_300px] justify-between"
      size="sm"
      ref={refs.setReference}
    >
      <div>
        <div className="flex gap-1 items-center">
          <Icon default={false} className="w-4 h-4">
            <FieldIcon field={summary.field}/>
          </Icon>
          <Strong className="text-sm!">
            {summary.field.normalized_name ?? summary.field.name}
          </Strong>
        </div>
        <div>
          <div className="gap-1 flex items-center">
            <span className="w-3 h-3 inline-block">
              <AccuracyIndicator score={Number(summary.accuracy)}/>
            </span>
            <MutedText>
              {formatPercentage(summary.accuracy)}
            </MutedText>
          </div>
        </div>

        {summary.isDropAnomaly && (
          <Floater refs={refs} floatingStyles={floatingStyles}>
            <Card className="max-w-sm">
              <CardHeaderContainer className='bg-gray-50 py-2! flex items-center gap-2'>
                <IconBox>
                  <ExclamationCircleIcon className='w-4 h-4 text-red-500'/>
                </IconBox>
                <Strong>
                  {summary.isUpperAnomaly ? (
                    'Accuracy drop detected for ' + summary.field.name
                  ) : (
                    'Accuracy drop detected for ' + summary.field.name
                  )}
                </Strong>
              </CardHeaderContainer>
              <CardBody>
                <div className="bg-gray-50 border-2 px-2 py-2 my-2 rounded">
                  <MutedText>
                    <Strong className="text-xs flex text-blue-800!">
                      <Icon>
                        <LightBulbIcon className="w-4 h-4 text-blue-800"/>
                      </Icon>
                      Forecasted impact
                    </Strong>
                    <div>
                      The producer is projected to lose <strong>2.5%</strong> of their overall trust score in the coming
                      weeks due to this field.
                    </div>
                  </MutedText>
                </div>

                <div>
                  <MutedText>
                    Over the past <i>14</i> days, the accuracy of the {summary.field.name} field has declined <strong
                      className="text-gray-800 font-bold">substantially</strong> by 11.74%, which is forecasted to
                    significantly impact the overall trust score of the seller.
                  </MutedText>
                </div>
              </CardBody>

              <CardFooter>
                <div className="flex justify-between gap-2">
                  <div className="flex space-2">
                    <div>
                      {/*@ts-ignore*/}
                      <Button variant="white" size="sm">
                        Mark as issue
                      </Button>
                    </div>
                    <div>
                      {/*@ts-ignore*/}
                      <Button variant="ghost" size="sm" disabled>
                        <Icon className="w-4 h-4">
                          <EllipsisHorizontalIcon className="w-4 h-4"/>
                        </Icon>
                        Ignore
                      </Button>
                    </div>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Floater>
        )}

        {summary.isMissingAnomaly && (
          <Floater refs={refs} floatingStyles={floatingStyles}>
            <Card className="max-w-sm">
              <CardHeaderContainer className='bg-gray-50 py-2! flex items-center gap-2'>
                <IconBox>
                  <AcademicCapIcon className='w-4 h-4 text-blue-500'/>
                </IconBox>
                <Strong>
                  Missing buyer inspection for {summary.field.name}
                </Strong>
              </CardHeaderContainer>
              <CardBody>
                <div>
                  <MutedText>
                    The seller has reported <i>79</i> instances of {summary.field.name} defects this week that are not
                    being
                    captured by the buyer.
                  </MutedText>
                </div>
              </CardBody>

              <CardFooter>
                <div className="flex space-2">
                  <div>
                    {/*@ts-ignore*/}
                    <Button variant="white" size="sm">
                      Resolve
                    </Button>
                  </div>
                  <div>
                    {/*@ts-ignore*/}
                    <Button variant="ghost" size="sm" disabled>
                      <Icon className="w-4 h-4">
                        <EllipsisHorizontalIcon className="w-4 h-4"/>
                      </Icon>
                      Ignore
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Floater>
        )}

      </div>
      <div className="grow flex justify-end relative">
        <WithHoverCard
          renderContent={(
            <div>
              <div>
                <Helper>
                  {t('buyer_statistics')}
                </Helper>

                {summary.buyerDistribution ? (
                  <FormatDistribution distribution={summary.buyerDistribution} isCompact={false}/>
                ) : (
                  <div className="w-full items-center text-center justify-center">
                    <Label>
                      N/A
                    </Label>
                  </div>
                )}
              </div>

              <div className="py-2">
                <hr/>
              </div>

              <div>
                <Helper>
                  {t('seller_statistics')}
                </Helper>

                {summary.sellerDistribution ? (
                  <FormatDistribution distribution={summary.sellerDistribution} isCompact={false}/>
                ) : (
                  <div className="w-full items-center text-center justify-center">
                    <Label>
                      N/A
                    </Label>
                  </div>
                )}
              </div>
            </div>
          )}>
          <Card>
            <CardBody size="sm" className="py-2! bg-gray-50 rounded">
              <div className="flex items-center gap-2">
                <div className="w-[50px]">
                  <Helper>
                    {t('buyer')}
                  </Helper>
                </div>
                <div className="max-w-[150px] min-w-[150px]">
                  {summary.buyerDistribution ? (
                    <FormatDistribution distribution={summary.buyerDistribution} isCompact/>
                  ) : (
                    <div className="w-full items-center text-center justify-center">
                      <Label>
                        N/A
                      </Label>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-[50px]">
                  <Helper>
                    {t('seller')}
                  </Helper>
                </div>
                <div className="max-w-[150px] min-w-[150px]">
                  {summary.sellerDistribution ? (
                    <FormatDistribution distribution={summary.sellerDistribution} isCompact/>
                  ) : (
                    <div className="w-full items-center text-center justify-center">
                      <Label>
                        N/A
                      </Label>
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </WithHoverCard>
      </div>
    </CardBody>
  );
}

interface ActiveSellerPaneProps {
  seller: Organization;
  summaries?: ForecastSellerFieldSummary[];
}

export const ActiveSellerPane = ({ seller, summaries }: ActiveSellerPaneProps) => {
  const { t } = useTranslation();
  const { formatPercentage } = useFormat();

  if (!summaries) {
    return (
      <div className="rounded-tr">
        <PlaceholderBox isFlat title="Select a seller" description="Sellect a seller"/>
      </div>
    );
  }
  const groupBy = (arr, key) => {
    return arr.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };

  let summaryGroups = groupBy(summaries.map((summary) => {
    let urgency = 'not enough data';

    if (summary.accuracy >= 85) {
      urgency = 'high accuracy (>85%)';
    } else if (summary.accuracy < 85 && summary.accuracy >= 70) {
      urgency = 'medium accuracy (70%-85%)';
    } else if (summary.accuracy < 70 && summary.accuracy >= 0.01) {
      urgency = 'low accuracy (<70%)';
    }

    return {
      ...summary,
      urgency,
    };
  }), 'urgency');

  const order = ['low accuracy (<70%)', 'medium accuracy (70%-85%)', 'high accuracy (>85%)', 'not enough data'];
  summaryGroups = Object.keys(summaryGroups)
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    .reduce((obj, key) => {
      obj[key] = summaryGroups[key];
      return obj;
    }, {} as Record<string, any>);

  const categories = Object.keys(summaryGroups);

  return (
    <div className="min-h-[350px] flex flex-col rounded-tr">
      <CardBody className="border-b bg-gray-50">
        <TextLabel className="text-lg! font-medium!">
          {seller.title}
        </TextLabel>
        <MutedText className="items-center">
          {seller.accuracy && (
            <span className="space-x-2 flex items-center">
              <span className="w-3 h-3 flex">
                <AccuracyIndicator
                  score={seller.accuracy as number}
                />
              </span>
              <Text>
                {formatPercentage(seller.accuracy as number)}
              </Text>
            </span>
          )}
        </MutedText>
      </CardBody>

      <div>
        <div className="px-4 py-2 bg-gray-100 border-y">
          <div className="mb-2">
            <div>
              <Strong>
                {t('measurement_comparison')}
              </Strong>
            </div>
            <div>
              <MutedText>
                {t('measurement_comparison_description')}
              </MutedText>
            </div>
          </div>
          <hr/>
          <div className="grid grid-cols-[2fr_1fr] mt-2">
            <div>
              <Helper>
                {t('field')}
              </Helper>
            </div>

            <div className="text-right">
              <Helper>
                {t('distribution')}
              </Helper>
            </div>
          </div>
        </div>

        {categories.map((category) => (
          <div key={category}>
            <div className="bg-gray-200 px-4 py-1 border-b">
              <Helper>
                {category}
              </Helper>
            </div>
            <div>
              <div className="divide-y">
                {summaryGroups[category].filter(summary => !summary.field?.meta_type_data?.ignore).map((summary) => (
                  <FieldSummary
                    key={summary.field.id}
                    summary={summary}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const FieldForecastComparer = () => {
  const { reload } = useAction();
  const { formatPercentage } = useFormat();
  const { t } = useTranslation();

  const {
    data: { sellers, bySeller },
    parameters,
  } = usePageProps<ForecastAccuracyPageProps>();

  const [activeSupplierIndex, setActiveSupplierIndex] = useState(() => {
    if (parameters.bySeller?.id) {
      return findIndex(sellers.map((supplier) => supplier.id), parameters.bySeller.id) ?? 0;
    }

    return null;
  });
  const activeSupplier = activeSupplierIndex !== null ? sellers[activeSupplierIndex] : null;

  const handleSupplierChange = (index: number) => {
    const supplier = sellers[index];
    const supplierId = supplier.id;

    setActiveSupplierIndex(index);

    if (supplierId) {
      reload({ bySeller: { id: supplierId } });
    }
  };

  const anomalies = bySeller?.forecastFieldSummaries?.filter((summary) => summary.isAnomaly) ?? [];

  return (
    <div>
      {anomalies.length > 0 && (
        <div className="mb-4">
          <PromptCard variant="red" renderIcon={<ExclamationCircleIcon className="fill-red-500 w-5 mb-"/>}>
            <div>
              <CardTitle>
                {t('trend_detected')}
              </CardTitle>
              <CardSubtitle>
                {t('trend_detected_description', { count: anomalies.length })}
              </CardSubtitle>
            </div>
          </PromptCard>
        </div>
      )}

      <Card>
        <div className="grid md:grid-cols-[350px_1fr] divide-x">
          <div>
            <div className="bg-gray-100 px-6 py-2 rounded-tl border-b">
              <Helper>
                {t('seller')}
              </Helper>
            </div>
            <div>
              <div className="divide-y rounded-tr">
                {sellers.map((seller, index) => (
                  <CardBody
                    onClick={() => handleSupplierChange(index)}
                    key={seller.id}
                    className={cn(
                      'cursor-pointer hover:bg-gray-100 transition-colors duration-200',
                      activeSupplier?.id === seller.id && 'bg-gray-50'
                    )}
                  >
                    <div className="flex justify-between items-center space-x-2">
                      <div className="flex items-center gap-2">
                        <div
                          className="w-2 h-2 rounded"
                          style={{ backgroundColor: seller.color }}
                        />
                        <MutedText className="text-xs!">{seller.title}</MutedText>
                      </div>

                      <div className="flex gap-1 items-center">
                        <div className="shrink-0 w-3 h-3">
                          <AccuracyIndicator
                            score={seller.accuracy as number}
                          />
                        </div>
                        <MutedText className="text-xs">
                          {formatPercentage(seller.accuracy as number)}
                        </MutedText>
                      </div>
                    </div>
                  </CardBody>
                ))}
              </div>
            </div>
          </div>

          {(activeSupplier && bySeller?.forecastFieldSummaries?.length) ? (
            <ActiveSellerPane
              seller={activeSupplier}
              summaries={bySeller.forecastFieldSummaries}
            />
          ) : (
            <div className="rounded-tr">
              <PlaceholderBox isFlat title="Select a seller" description="Sellect a seller"/>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
