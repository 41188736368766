import { router } from '@inertiajs/react';

import { EditIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { SellerRoutes } from '~/hooks/useRoutes';

interface Params {
  orderId: string;
  inspectionId: string;
}

export class NavEditInspection extends BaseAction {
  public static icon = EditIcon;
  public static analyticsName = 'edit_inspection';
  public static type: ActionAppType = 'seller';
  public static toast = {
    titleKey: 'edit_inspection',
  };

  constructor(public context: ActionContext<any>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'edit_inspection';
  }

  public disabled() {
    return false;
  }

  public async execute({ orderId, inspectionId }: Params) {
    const routes = this.context.routes as SellerRoutes;
    const route = routes.qualityInput();

    router.get(`${route}?orderId=${orderId}&inspectionId=${inspectionId}`, {}, {
      preserveScroll: false,
    });
  }
}
