import Avatar from "@/Avatar";
import { ColorDot } from "@/ColorDot";
import { useDate } from "~/hooks/useDate";
import { usePageProps } from "~/hooks/usePageProps";
import { InspectionComment } from "~/types/types";
import { Text } from "@/Text";
import { useScrollToElement } from "~/hooks/useScrollToElement";
import { useTranslation } from "react-i18next";

interface InspectionCommentEventProps {
  isRootReply: boolean;
  comment: InspectionComment;
  onClose?: () => void;
}

export const InspectionCommentEvent = ({
  comment,
  onClose,
}: InspectionCommentEventProps) => {
  const { t } = useTranslation();
  const { order } = usePageProps();
  const scrollToElement = useScrollToElement();

  const { differenceToNow } = useDate();

  return (
    <div className="flex flex-col gap-2 last-of-type:border-b-0 border-b border-slate-200">
      <div className="flex items-center gap-3">
        <div className="w-full">
          <div className="flex px-3 py-3 items-center gap-3">
            <div className="self-start">
              <Avatar imageSrc="" initials={comment.user?.name?.[0] || "A"} />
            </div>
            <div className="flex flex-col justify-start w-full">
              <div className="text-sm text-token-text font-medium">
                <span className="font-bold text-token-text">
                  {comment.user?.name}
                </span>
                <Text className="inline">
                  {" "}
                  {t("has_commented_on")}{" "}
                  <Text
                    onClick={() => {
                      scrollToElement("inspection");
                      onClose && onClose();
                    }}
                    className="cursor-pointer font-semibold inline text-indigo-500"
                  >
                    {t("inspection")}
                  </Text>{" "}
                </Text>
              </div>
              <div className="flex gap-2 items-center mt-1">
                <span className="text-xs text-token-muted shrink-0">
                  {differenceToNow(new Date(comment.created_at as string))}
                </span>
                <ColorDot brand="indigo" size="xs" />
                <Text className="text-xs text-indigo-500">
                  {
                    comment.user?.organizations?.find(
                      (org) =>
                        order?.buyer_id === org.id ||
                        order?.seller_id === org.id
                    )?.title
                  }
                </Text>
              </div>
              <div className="flex gap-2 mt-2">
                <div className="rounded bg-slate-50 px-3 py-3 w-full">
                  <Text className=" text-token-text">{comment.text}</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
