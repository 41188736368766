import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form';

import { Form } from '@/Form';
import {
  AddQualityInput,
  FieldEntry,
  FormQualityInput,
  QualityFormFactory,
} from '~/Components/Orders/QualityInput/formatter';
import { Field, Inspection, Order, OrderProduce, Range, Spec } from '~/types/types';

interface QualityInputProviderProps {
  children: (props: QualityInputContextValue) => ReactNode;
  order: Order;
  spec?: Spec;
  initialInspection?: Inspection;
  isEditing?: boolean;
}

interface DefinedField {
  definition?: Field;
  formEntry?: FieldEntry;
}

interface FieldResult {
  result: 'risk' | 'na';
  touched: boolean;
  has_risk: boolean;
  definition?: Field;
  formEntry?: FieldEntry;
}

interface QualityInputContextValue {
  order: Order;
  initialInspection?: Inspection;
  form: UseFormReturn<FormQualityInput>;
  fields: DefinedField[];
  isInitialized: boolean;
  orderProduceOptions: OrderProduce[];
  orderProduce: OrderProduce | null;
  otherUnfilledProduces: OrderProduce[];
  handleSetOrderProduce: (orderProduceId: string) => void;
  willContinueSubmission: boolean;
  setWillContinueSubmission: (value: boolean) => void;
  handleSubmit: (data: FormQualityInput) => void;
  getFieldsResults: (fieldEntries: FieldEntry[]) => FieldResult[];
  isEditing: boolean;
}

const QualityInputContext = createContext<QualityInputContextValue | undefined>(undefined);

export const QualityInputProvider = ({
  children,
  order,
  initialInspection,
  isEditing = false,
}: QualityInputProviderProps) => {
  const [willContinueSubmission, setWillContinueSubmission] = useState(false);
  const orderProduces = order?.order_produces;

  const unfilledOrderProduces = useMemo(() => {
    return orderProduces?.filter((orderProduceItem: OrderProduce) => {
      return !orderProduceItem.inspectionResult?.hasSellerInspection;
    }) ?? [];
  }, [orderProduces]);

  /**
   * This is the `orderProduce` currently selected for the form. We initialize empty, but the selection will have a strong impact.
   *
   * The `orderProduce` might have a spec associated.
   */
  const [orderProduce, setOrderProduce] = useState<OrderProduce | null>(() => {
    const sampleQualityEntry = initialInspection?.quality_entries?.[0];

    if (sampleQualityEntry && sampleQualityEntry.produce_variety_id && orderProduces && orderProduces.length > 0) {
      const selectedOrderProduce = orderProduces.find(orderProduceItem => orderProduceItem.produce_variety_id === sampleQualityEntry.produce_variety_id);

      return selectedOrderProduce ?? null;
    }

    if (unfilledOrderProduces && unfilledOrderProduces.length === 1) {
      return unfilledOrderProduces[0];
    }

    return null;
  });

  const otherUnfilledProduces = useMemo(() => {
    return unfilledOrderProduces.filter((orderProduceItem: OrderProduce) => {
      return orderProduceItem.produce_variety_id !== orderProduce?.produce_variety_id;
    }) ?? [];
  }, [unfilledOrderProduces, orderProduce]);

  const spec = orderProduce?.spec;

  /**
   * Pre-initialize the form.
   *
   * Note: this will be populated based on the selected order-produce later.
   */
  const form = useForm<FormQualityInput>({
    // resolver: zodResolver(qualityInputSchema),
    defaultValues: orderProduce ? QualityFormFactory.makeDefaultValues.new(orderProduce, initialInspection ?? null, isEditing) : {},
  });

  /**
   * These are all the available `orderProduces` in the form.
   */

  const handleSetOrderProduce = (orderProduceId: string) => {
    const filteredOrderProduces = orderProduces?.filter((orderProduceItem: OrderProduce) => {
      return orderProduceItem.produce_variety_id === orderProduceId;
    }) ?? [];

    const nestedOrderProduce = filteredOrderProduces[0] ?? null;

    setOrderProduce(nestedOrderProduce);

    const defaultValues = QualityFormFactory.makeDefaultValues.new(nestedOrderProduce, initialInspection ?? null, isEditing);
    if (!defaultValues) return;

    form.reset(defaultValues);
  };

  const formFields = useFieldArray({
    control: form.control,
    name: 'fields',
  }) ?? [];

  /**
   * Final resolution. Here, we import the form data and submit it.
   * @param data
   */
  const handleSubmit = (data: AddQualityInput) => {
    showDebug(data);
  };

  /**
   * Loop through fieldEntries, get fieldId, and merge it with the spec fields.
   * @param fieldEntries
   */
  const getFieldsResults = (fieldEntries: FieldEntry[]): FieldResult[] => {
    return (fieldEntries || []).map(fieldEntry => {
      const specField = spec?.linked_fields?.find(linkedField => fieldEntry.fieldId === linkedField.id);

      if (!specField) return null;

      const touched =
        fieldEntry.has_defect !== false ||
        fieldEntry.measurement !== undefined ||
        fieldEntry.percentage !== undefined ||
        fieldEntry.samples !== undefined ||
        fieldEntry.samples_with_defect !== undefined;
      const result = getStatusFromField(fieldEntry, specField);

      return {
        has_risk: result === 'risk',
        touched,
        result,
        definition: specField,
        formEntry: fieldEntry,
      };
    }).filter(val => !!val) ?? [];
  };

  /**
   * Zip the form fields with the spec fields.
   * - Nest the spec fields under the `definition` key (to avoid confusion; these are the field definitions as stored in the spec).
   * - Nest the form fields under the `formEntry` key (to avoid confusion; these are the form entries as stored in the form).
   */
  const fields: DefinedField[] = useMemo(() => {
    return formFields.fields.map((formEntry => {
      const specField = spec?.linked_fields?.find(field => formEntry.fieldId === field.id);

      return {
        definition: specField,
        formEntry,
      };
    })).filter(val => !!val) ?? [];
  }, [formFields.fields, spec?.linked_fields]);

  const isInitialized = !!orderProduce;

  const value = {
    order,
    handleSubmit,
    initialInspection,
    form,
    isInitialized,
    fields,
    orderProduce,
    otherUnfilledProduces,
    orderProduceOptions: orderProduces ?? [],
    willContinueSubmission,
    setWillContinueSubmission,
    handleSetOrderProduce,
    getFieldsResults,
    isEditing,
  };

  return (
    <QualityInputContext.Provider value={value}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          {children(value)}
        </form>
      </Form>
    </QualityInputContext.Provider>
  );
};

export const testMeasurementInRange = (value: number, range: Range): boolean => {
  if (range.min && range.max) {
    return value >= range.min && value <= range.max;
  }

  if (range.min) {
    return value >= range.min;
  }

  if (range.max) {
    return value <= range.max;
  }

  return false;
};

export const getStatusFromField = (fieldEntry: FieldEntry, fieldDefinition: Field) => {
  if (fieldEntry.has_defect) return 'risk';

  const constraint = fieldDefinition.constraints?.[0];
  if (!constraint) return 'na';

  if (fieldDefinition.type === 'measurement' && constraint) {
    const range = constraint.ranges?.[0];
    const number = fieldEntry.measurement ? parseInt(fieldEntry.measurement) : null;

    if (range && number) {
      return testMeasurementInRange(number, range) ? 'green' : 'risk';
    }
  }

  return 'na';
};

// Custom hook to use the context
export const useQualityInput = () => {
  const context = useContext(QualityInputContext);
  if (context === undefined) {
    throw new Error('useQualityInput must be used within a QualityInputProvider');
  }
  return context;
};
