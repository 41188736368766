import { XMarkIcon as X } from '@heroicons/react/20/solid';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from 'react';
import { w } from 'windstitch';

import { withDefaultProps } from '~/utils/UIPrimitives';

import { cn } from '../utils/cn';

/**
 * Modal UI Component.
 * Based on shadcn-ui (Radix UI and Tailwind)
 *
 * Notes:
 * - If you want to change the width of the modal, change the `max-w-lg` class in the `ModalContent` component.
 * - If you want to control the modal, set open and onOpenChange props to the `Modal` component.
 *
 * Example:
 * ```tsx
 * <Modal>
 *   <ModalTrigger asChild>
 *     <Button variant="white" size="sm">
 *       Open
 *     </Button>
 *   </ModalTrigger>
 *   <ModalContent>
 *     <ModalHeader>
 *       <ModalTitle>
 *         Modal header
 *       </ModalTitle>
 *       <ModalDescription>
 *         Modal description
 *       </ModalDescription>
 *     </ModalHeader>
 *     <ModalBody>
 *       The body of this component
 *     </ModalBody>
 *     <ModalFooter>
 *       <Button variant="primary">
 *         Save changes
 *       </Button>
 *     </ModalFooter>
 *   </ModalContent>
 * </Modal>
 * ```
 */
export const Modal = DialogPrimitive.Root;
export const ModalTrigger = DialogPrimitive.Trigger;
export const ModalPortal = DialogPrimitive.Portal;

export const ModalOverlay = withDefaultProps(DialogPrimitive.Overlay, {
  className: 'place-items-center overflow-y-auto py-16 px-2 grid fixed inset-0 bg-background/80 backdrop-blur-xs data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
});

export const ModalContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, container, ...props }, ref) => (
  <ModalPortal container={container ?? undefined}>
    <ModalOverlay>
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'grid w-full max-w-lg bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 sm:rounded-lg md:w-full relative border border-gray-300',
          className
        )}
        {...props}
      >
        {children}
        <DialogPrimitive.Close
          className="absolute right-4 top-2.5 md:top-3 rounded-xs opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-hidden focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <X className="w-8 h-8 md:w-4 md:h-4"/>
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </ModalOverlay>
  </ModalPortal>
));
ModalContent.displayName = DialogPrimitive.Content.displayName;

export const ModalHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'h-fit md:h-initial',
      'flex flex-col space-y-1 border-b border-muted px-6 py-4 text-center rounded-t-lg sm:text-left bg-gray-50',
      className
    )}
    {...props}
  />
);
ModalHeader.displayName = 'DialogHeader';

export const ModalFooter = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse gap-y-2 px-6 py-4 border-t border-t-muted sm:flex-row sm:justify-end sm:space-x-2',
      className
    )}
    {...props}
  />
);
ModalFooter.displayName = 'ModalFooter';

export const ModalTitle = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      'text-lg text-slate-600 font-semibold leading-none tracking-tight',
      className
    )}
    {...props}
  />
));

ModalTitle.displayName = DialogPrimitive.Title.displayName;

export const ModalDescription = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
ModalDescription.displayName = DialogPrimitive.Description.displayName;

export const ModalIcon = ({ icon }: { icon: ReactNode }) => {
  return (
    <div className="p-1 bg-white border-gray-100 rounded shadow-xs ring-1 ring-gray-200 text-main-500">
      {icon}
    </div>
  );
};

export const ModalBody = w.div('px-2 py-2 md:px-6 md:py-4 flex-1');
