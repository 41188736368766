/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PlusIcon } from '@heroicons/react/20/solid';
import { TagIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Clock1, ContainerIcon, HandshakeIcon, StoreIcon } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody } from '@/Card';
import { ListDetail } from '@/Details';
import { WithGenericFallback } from '@/Fallback';
import {
  BuyerQualityIcon,
  IntegrationsIcon,
  MiniLabelIcon,
  ProduceIcon,
  SellerIcon,
  TransportLocationIcon,
} from '@/Icon';
import { IconBox, LabelIcon, SimpleIcon } from '@/Icon';
import { MiniLabel } from '@/Label';
import { Label } from '@/Label';
import { SideLabeledField } from '@/SideLabeledField';
import { StatusDot } from '@/StatusDot';
import { Text } from '@/Text';
import { IdentifierText, SectionTitle } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { AddCSVDataModal } from '~/Components/Orders/AddCSVDataModal';
import { OrderProduceCard } from '~/Components/Orders/OrderProduceCard';
import { DateFormat, useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { Integration, Order, OrderStep } from '~/types/types';
import { FormatStringList } from '~/utils/FormatStringList';

import { useOrder } from '../OrderProvider';

interface OrderDetailsSectionProps {
  order: Order;
  activeStep: OrderStep;
  integration: Integration;
  isSeller?: boolean;
}

export const OrderDetailsSection = ({
  order,
  integration,
  isSeller,
}: OrderDetailsSectionProps) => {
  const { t } = useTranslation();

  const { user } = usePageProps();
  const { orderProduces } = useOrder();

  const { format, parseOrFormat } = useDate();

  const uniqueContainerExtRefs = Array.from(new Set(order.loads?.filter(
    (load) => load.container_ext_ref)
    .map((load) => load.container_ext_ref) ?? [])) as string[];

  const orderUnits = orderProduces?.flatMap((orderProduce) => orderProduce.units) ?? [];

  const availableIntentions = orderUnits
    .map(unit => unit?.custom_properties?.intention)
    .filter((intention, index, self) =>
      intention && self.indexOf(intention) === index
    ) ?? [];

  return (
    <div className="space-y-4 pb-8 mt-4" id="order">
      <SectionTitle>{t('order')}</SectionTitle>

      <div>
        <Card>
          <CardBody>
            <div className="space-y-4">
              <SideLabeledField
                label={t('order_po') as string}
                icon={TagIcon}
                renderValue={
                  <IdentifierText aria-label={t('order_po')}>{order.public_ref}</IdentifierText>
                }
              />

              {order.inco && (
                <SideLabeledField
                  label={t('incoterm')}
                  icon={HandshakeIcon}
                  renderValue={
                    <span>
                      <Tooltipped label={`${order.inco.title} - ${order.inco.description}`}>
                        <span className="inline-block">
                          <MiniLabel className="inline-block" variant="muted">
                            {order.inco.type}
                          </MiniLabel>
                        </span>
                      </Tooltipped>
                    </span>
                  }
                />
              )}

              <SideLabeledField
                label={t('supplier')}
                id={`seller::${order.seller_id}`}
                testId="sellerId"
                icon={SellerIcon}
                value={order.seller?.title}
              />
              <SideLabeledField
                label={t('buyer')}
                value={order.buyer?.title}
                icon={BuyerQualityIcon}
              />

              <hr/>

              {uniqueContainerExtRefs.length > 0 && (
                <SideLabeledField
                  label={t('container_ids')}
                  icon={ContainerIcon}
                  renderValue={<Text className="text-sm"><FormatStringList strings={uniqueContainerExtRefs}/></Text>}/>
              )}

              {order.inco_term && (
                <SideLabeledField
                  label={t('delivery_destination')}
                  icon={TransportLocationIcon}
                  value={order.delivery_location}
                />
              )}

              {order.expected_arrival_at && (
                <WithGenericFallback>
                  <SideLabeledField
                    label={t('expected_arrival')}
                    icon={Clock1}
                    value={(
                      <div className="flex items-center">
                        {!order.delay && (
                          <Text>{parseOrFormat(order.expected_arrival_at, DateFormat.HumanDateTime)}</Text>
                        )}

                        {order.delay && (
                          <div className="flex items-center gap-2">
                            <div className="opacity-30 line-through">
                              <Text>{parseOrFormat(order.delay.originalArrivalDate, DateFormat.HumanDateTime)}</Text>
                            </div>
                            <SimpleIcon icon={ArrowRight}/>

                            <div>
                              <Text>{parseOrFormat(order.delay.updatedArrivalDate, DateFormat.HumanDateTime)}</Text>
                            </div>

                            <div>
                              <Label theme="red">
                                <LabelIcon icon={ExclamationTriangleIcon}/>
                                {order.delay.totalDelayInHours}
                                {t(' hours delayed')}
                              </Label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </WithGenericFallback>
              )}

              <hr/>

              <SideLabeledField
                label={t('produce')}
                icon={ProduceIcon}
                renderValue={
                  <div className="flex flex-wrap gap-2">
                    {orderProduces?.map((orderProduce, index) => (
                      <div className="inline-block" key={index}>
                        <OrderProduceCard
                          renderSpec
                          key={index}
                          orderProduce={orderProduce}
                        />
                      </div>
                    ))}
                  </div>
                }
              />

              {availableIntentions.length > 0 && (
                <SideLabeledField
                  label={t('intention')}
                  icon={StoreIcon}
                  renderValue={
                    <div>
                      {availableIntentions.map((intention, index) => (
                        <MiniLabel variant="muted" key={index}>
                          <MiniLabelIcon icon={TagIcon} className="w-3 h-3 mr-2 stroke-gray-100"/>
                          {intention}
                        </MiniLabel>
                      ))}
                    </div>
                  }
                />
              )}

              {integration && !isSeller && (
                <>
                  <hr/>

                  <SideLabeledField
                    label="Integration"
                    icon={IntegrationsIcon}
                    renderValue={
                      <>
                        <div className="flex text-sm items-left">
                          <div className="hidden mt-1 mr-2 sm:block">
                            <StatusDot
                              success={integration.status === 'active'}
                            />
                          </div>

                          <div className="space-y-1">
                            <div className="flex items-center">
                              <span className="text-slate-700">
                                {integration.type}
                              </span>
                              <div className="ml-2 sm:hidden">
                                <StatusDot
                                  success={integration.status === 'active'}
                                />
                              </div>
                            </div>

                            <span>
                              {integration.last_synced_at && (
                                <div className="text-sm font-light text-slate-400">
                                  Last updated:{' '}
                                  {format(
                                    new Date(integration.last_synced_at),
                                    DateFormat.HumanMonthDateTime
                                  )}
                                </div>
                              )}
                            </span>
                            {user.superadmin && (
                              <div className="space-x-2">
                                <AddCSVDataModal
                                  integration={integration}
                                  order={order}
                                >
                                  {/* @ts-ignore */}
                                  <Button variant="white" size="sm" disabled>
                                    Sync
                                  </Button>
                                </AddCSVDataModal>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    }
                  />
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
