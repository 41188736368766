import { motion } from 'framer-motion';
import { InfoIcon } from 'lucide-react';

import { Checkbox } from '@/Checkbox';
import { IconBox, IconBoxIcon, SimpleIcon } from '@/Icon';
import { DescriptionText, Strong } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';

const cardTransition = {
  type: 'spring',
  bounce: 0.1,
  duration: 0.3,
};

const borderTransition = {
  type: 'spring',
  bounce: 0.2,
  duration: 0.01,
};
export const IconCheckboxCard = ({
  checked,
  onCheckedChange,
  icon,
  title,
  description,
  className,
  iconClassName,
  disabled = false,
  id = `icon-checkbox-card-${Math.random().toString(36).substring(2, 9)}`,
  renderChecked,
  size = 'md',
  showCheckbox = true,
  compact = false,
}: {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  icon?: React.ComponentType<any>;
  title: string;
  description?: string;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
  id?: string;
  renderChecked?: React.ReactNode;
  showCheckbox?: boolean;
  size?: 'sm' | 'md' | 'lg';
  compact?: boolean;
}) => {
  return (
    <motion.div
      whileTap={{ scale: disabled ? 1 : 0.996 }}
      transition={cardTransition}
      className='flex flex-col stretch'
    >
      <label
        htmlFor={id}
        className={cn(
          'block w-full h-full',
          !disabled && 'cursor-pointer'
        )}
      >
        <motion.div
          animate={{
            borderColor: checked ? 'rgb(79, 70, 229)' : 'rgba(0, 0, 0, 0)',
            borderWidth: 1,
          }}
          transition={borderTransition}
          className={cn(
            'bg-white shadow border rounded-lg',
            'transition-all',
            'h-full',
            size === 'sm' && 'p-4',
            size === 'md' && 'p-6',
            size === 'lg' && 'p-12',
            !disabled && 'hover:border-indigo-300',
            disabled && 'opacity-60 cursor-not-allowed',
            className
          )}
        >
          <div className={cn(
            'flex',
            'h-full',
            compact ? 'flex-row items-center gap-3' : 'flex-col'
          )}>
            {icon && (
              <div className={cn(
                compact ? 'mb-0 flex-shrink-0' : 'mb-2',
                'max-w-8',
                iconClassName
              )}>
                <IconBox className={cn(
                  'flex items-center justify-center border-0!',
                  size === 'sm' ? 'w-6! h-6!' :
                    size === 'lg' ? 'w-12! h-12!' : 'w-10! h-10!'
                )}>
                  <IconBoxIcon
                    icon={icon}
                    className={cn(
                      size === 'sm' ? 'w-4 h-4 !max-w-4' :
                        size === 'lg' ? 'w-10 h-10 !max-w-7' : 'w-8 h-8 !max-w-6'
                    )}
                  />
                </IconBox>
              </div>
            )}
            <div className={cn(
              'flex items-start justify-between',
              compact ? 'flex-1' : 'mt-auto'
            )}>
              <div className='grow'>
                <Strong>{title}</Strong>
                {description && !compact && (
                  <DescriptionText>
                    {description}
                  </DescriptionText>
                )}
                {description && compact && (
                  <Tooltipped label={description}>
                    <SimpleIcon icon={InfoIcon} className="inline-block ml-1" />
                  </Tooltipped>
                )}
              </div>

              <motion.div
                whileHover={{ scale: disabled ? 1 : 1.05 }}
                whileTap={{ scale: disabled ? 1 : 0.95 }}
                transition={{ type: 'spring', bounce: 0.3, duration: 0.2 }}
                className={cn(
                  'ml-4',
                  compact ? 'relative' : 'absolute top-12 right-12'
                )}
              >
                <Checkbox
                  id={id}
                  checked={checked}
                  onCheckedChange={onCheckedChange}
                  disabled={disabled}
                  className={cn(
                    'w-5 h-5 bg-white border-gray-300',
                    showCheckbox ? 'opacity-100' : 'opacity-0'
                  )}
                />
              </motion.div>
            </div>
          </div>

          {renderChecked && checked && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ type: 'spring', bounce: 0.2, duration: 0.4 }}
              className="mt-4"
            >
              {renderChecked}
            </motion.div>
          )}
        </motion.div>
      </label>
    </motion.div>
  );
};

export const CheckboxList = ({
  value,
  options,
  onChange,
  className,
}: {
  value: string[];
  options: { label: string; value: string, icon?: React.ComponentType<any>, subLabel?: string }[];
  onChange: (value: string[]) => void;
  className?: string;
}) => {
  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {options.map((option) => (
        <IconCheckboxCard
          size="sm"
          compact={true}
          showCheckbox={false}
          key={option.value}
          checked={value.includes(option.value)}
          onCheckedChange={(checked) => {
            if (checked) {
              onChange([...value, option.value]);
            } else {
              onChange(value.filter(v => v !== option.value));
            }
          }}
          icon={option.icon}
          title={option.label}
          description={option.subLabel}
        />
      ))}
    </div>
  );
};
