import { BarChartIcon } from 'lucide-react';
import React, { useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/Chart';
import { DateFormat, useDate } from '~/hooks/useDate';

import { TimeSeriesGroup } from '../../../../../types/timeseries';

interface StackedAreaChartProps {
  /**
   * The time series group containing multiple series to stack
   */
  timeSeriesGroup: TimeSeriesGroup;
  /**
   * Height of the chart
   */
  height?: number;
  /**
   * Width of the chart
   */
  width?: number | string;
  /**
   * Unit to display in tooltip and axis
   */
  unit?: string;
  /**
   * Whether to show the legend
   */
  showLegend?: boolean;
  /**
   * Whether to show tooltips
   */
  showTooltip?: boolean;
  /**
   * Whether to show dots on the lines
   */
  showDots?: boolean;
}

/**
 * A stacked area chart component that visualizes multiple time series as stacked areas.
 * Uses Recharts for rendering and supports responsive sizing.
 */
export const StackedAreaChart = ({
  timeSeriesGroup,
  height = 300,
  width = '100%',
  unit = '',
  showLegend = true,
  showTooltip = true,
  showDots = false,
}: StackedAreaChartProps) => {
  const { parseOrFormat } = useDate();
  const [hoveredSeries, setHoveredSeries] = useState<string | null>(null);

  // Get unique dates across all series
  const allDates = new Set<string>();
  timeSeriesGroup.timeSeries.forEach(series => {
    series.events.forEach(event => {
      allDates.add(event.date);
    });
  });

  // Create data points for each date
  const data = Array.from(allDates).sort().map(date => {
    const point: any = { date };
    timeSeriesGroup.timeSeries.forEach(series => {
      const event = series.events.find(e => e.date === date);
      point[series.label] = event?.value ?? 0;
    });
    return point;
  });

  // Custom tooltip component that matches ColdChainChart styling
  const CustomTooltipContent = (props: any) => {
    if (!props.active || !props.payload || !props.payload.length) {
      return null;
    }

    // Calculate total for this data point
    const total = props.payload.reduce((sum: number, entry: any) => sum + (entry.value || 0), 0);

    return (
      <ChartTooltipContent
        {...props}
        labelFormatter={(val) => parseOrFormat(val, DateFormat.HumanDateTime)}
        valueFormatter={(val: number) => `${val.toFixed(1)}${unit}`}
        indicator="line"
        renderFooter={
          <div className="mt-1 border-t border-gray-200 pt-1">
            <div className="flex items-center justify-between text-xs">
              <span className="font-medium">Total:</span>
              <span>{total.toFixed(1)}{unit}</span>
            </div>
          </div>
        }
      />
    );
  };

  const chartConfig = {
    value: {
      icon: BarChartIcon,
      label: timeSeriesGroup.timeSeries[0]?.label || 'Values',
      color: 'var(--blue-500)',
    },
  } satisfies ChartConfig;

  // Calculate y-axis domain with extra padding
  const allValues = data.flatMap(point =>
    Object.entries(point)
      .filter(([key]) => key !== 'date')
      .map(([, value]) => value as number)
  );
  const maxValue = Math.max(...allValues);
  const padding = maxValue * 0.2; // 20% padding

  return (
    <ChartContainer config={chartConfig} className="aspect-auto h-[300px] w-full">
      <AreaChart data={data} className="text-sm">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(val) => parseOrFormat(val, DateFormat.HumanDateTimeNoYear)}
          className="fill-gray-700"
          stroke="#D1D5DB"
          tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
          tickMargin={14}
        />
        <YAxis
          unit={unit}
          domain={[0, maxValue + padding]}
          className="fill-gray-700"
          stroke="#D1D5DB"
          tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
        />
        {showTooltip && (
          <ChartTooltip
            cursor={false}
            content={<CustomTooltipContent />}
          />
        )}
        {showLegend && (
          <Legend
            onMouseEnter={(e) => setHoveredSeries(e.value)}
            onMouseLeave={() => setHoveredSeries(null)}
          />
        )}
        {timeSeriesGroup.timeSeries.map((series) => (
          <Area
            key={series.label}
            type="monotone"
            dataKey={series.label}
            stackId="1"
            stroke={series.color}
            fill={series.color}
            dot={showDots}
            style={{
              opacity: hoveredSeries === null || hoveredSeries === series.label ? 1 : 0.3,
              transition: 'opacity 0.2s ease-in-out',
            }}
          />
        ))}
      </AreaChart>
    </ChartContainer>
  );
};
