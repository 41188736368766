/* eslint-disable react/prop-types */
import { ErrorBoundary } from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { CSSProperties, Fragment, ReactNode, useEffect, useMemo } from 'react';

import { LinkContext, makeTopLinks, NavLinkType } from '@/Nav/nav-items';
import { MainNavItem, Navbar, SubNavItem } from '@/Nav/Navbar';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarInset,
  SidebarProvider,
  SidebarRail,
} from '@/Nav/Sidebar';
import { UserMenu, UserSelectable } from '@/Nav/UserMenu';
import { DebugProvider, LocalDebugModal } from '~/Components/Common/LocalDebugModal';
import BreakpointIndicator from '~/Components/Common/Tools/BreakpointIndicator';
import { FAB } from '~/Components/FAB';
import { ServerErrorProvider } from '~/hooks/ServerErrorProvider';
import { useAppTitle } from '~/hooks/useAppTitle.js';
import { usePageProps } from '~/hooks/usePageProps';
import { usePageTracking } from '~/hooks/usePageTracking.js';
import { Toaster } from '~/hooks/useToast.js';
import { AppProvider } from '~/Providers/AppProvider';
import { OrganizationStatus } from '~/types/types';

import { GeneratingModal } from './GeneratingModal.tsx';
import { OnboardingModal } from './OnboardingModal';

interface DashboardLayoutProps {
  children: ReactNode;
  sideBar?: ReactNode;
  sidebarAlwaysOpen?: boolean;
  subLinksBuilder?: (context: LinkContext) => NavLinkType[];
}

const sidebarStyles: CSSProperties = {
  '--sidebar-width': '23rem',
  '--sidebar-width-mobile': '18rem',
} as any;

export const DashboardLayout = ({
  children,
  sideBar,
  sidebarAlwaysOpen = false,
  subLinksBuilder,
}: DashboardLayoutProps) => {
  const posthog = usePostHog();
  const props = usePageProps();
  const {
    user,
    organization,
    show_onboard_modal,
    features,
    app: { path },
  } = props;

  const context: LinkContext = useMemo(() => {
    return {
      user,
      organization,
      features,
    };
  }, [user, organization, features]);

  const subLinks = useMemo(() => {
    if (subLinksBuilder) {
      return subLinksBuilder(context);
    }

    return null;
  }, [subLinksBuilder, context]);

  const topLinks = makeTopLinks(organization.id);

  useAppTitle();
  usePageTracking(path);
  useEffect(() => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }
    posthog.identify(user.id);
  }, [posthog, user.id]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 *:w-full pb-12">
      <SidebarProvider defaultOpen={sidebarAlwaysOpen} style={sidebarStyles}>
        <DebugProvider pageProps={props}>
          <ServerErrorProvider>
            <AppProvider>
              <SidebarInset>
                <LocalDebugModal/>
                <Navbar
                  user={user}
                  organization={organization}
                  renderNavLinks={(
                    <div className="flex gap-3">
                      {topLinks.map((link) => (
                        <Fragment key={link.name}>
                          {link.requiresFeature && features && link.requiresFeature in features && features[link.requiresFeature][0] && (
                            <MainNavItem key={link.name} link={link}/>
                          )}

                          {!link.requiresFeature && (
                            <MainNavItem key={link.name} link={link}/>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  )}
                  renderSubNavLinks={!!subLinks && (
                    <div className="flex items-center justify-center space-x-4">
                      {subLinks.map((link) => (
                        <SubNavItem key={link.name} link={link}/>
                      ))}
                    </div>
                  )}
                />
                <FAB/>
                <BreakpointIndicator/>
                <OnboardingModal
                  user={user}
                  organization={organization}
                  show={show_onboard_modal ?? false}
                />

                {organization.status === OrganizationStatus.GENERATING && (
                  <GeneratingModal/>
                )}
                {organization.status === OrganizationStatus.GENERATING ? (
                  <ErrorBoundary
                    onError={() => {
                      console.log('ignore');
                    }}
                    fallback={<div>Generating new data</div>}
                  >
                    {children}
                  </ErrorBoundary>
                ) : (
                  <>{children}</>
                )}
              </SidebarInset>

              {!!sideBar && (
                <Sidebar
                  side='right'
                  // collapsible={sidebarAlwaysOpen ? 'none' : 'offcanvas'}
                >
                  {sideBar}
                </Sidebar>
              )}
            </AppProvider>
          </ServerErrorProvider>
        </DebugProvider>
      </SidebarProvider>
    </div>
  );
};
