import { LabelProps } from 'recharts';

import { Card, CardBody, CardHeaderContainer } from '@/Card';
import { MutedText } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';
import { TooltipProps } from '~/types/global-types';
import { WeatherDataDayPayload } from '~/types/types';

export const RainTick = ({ x, y, payload }: any) => {
  const { format, parse } = useDate();

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
      >
        {format(parse(payload.value, DateFormat.ISO), DateFormat.HumanDateTimeNoYear)}
      </text>
    </g>
  );
};

export const RainLabel = ({ x, y, stroke, value }: LabelProps) => {
  return (
    <text className="stroke-slate-500" x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};

type Payload = WeatherDataDayPayload;

type RainTooltipProps = TooltipProps<Payload>

export const RainTooltip = ({ payload, label }: RainTooltipProps) => {
  const { format, parse } = useDate();

  const data = payload?.[0]?.payload;

  const hasRain = data?.rainfall && data.rainfall > 0;

  return (
    <Card className="min-w-[150px]">
      <CardHeaderContainer className="py-4!" size="sm">
        <MutedText>
          {
            format(
              parse(label, DateFormat.ISO),
              DateFormat.HumanMonthDateTime
            )
          }
        </MutedText>
      </CardHeaderContainer>
      <CardBody size="sm">
        {!hasRain && <MutedText>No rainfall</MutedText>}
        {!!hasRain && <MutedText>{data?.rainfall?.toFixed(2)} mm rainfall</MutedText>}
      </CardBody>
    </Card>
  );
};
