import { MutedText } from '@/Text';
import { Spec } from '~/types/types';
import { cn } from '~/utils/cn';
import { DefaultTheme } from '~/utils/colors';

import { SpecIcon } from './SpecIcon';

export interface SpecLabelProps {
  onOpen?: (specId: string) => void;
  spec: Spec;
  theme?: DefaultTheme;
  disabled?: boolean;
}

/**
 * SpecLabel
 */
export const SpecLabel = ({ spec, theme = 'gray', onOpen, disabled }: SpecLabelProps) => {
  return (
    <div
      className="flex items-center space-x-1"
    >
      <SpecIcon theme={theme} />

      {onOpen ? (
        <div className="text-xs cursor-pointer" onClick={() => !disabled && onOpen(spec.id)}>
          <MutedText className={cn(`text-xs  ${!disabled && 'text-blue-500!'}`)}>
            {spec.name}
          </MutedText>
        </div>
      ) : (
        <div className="text-xs cursor-pointer">
          <MutedText className="text-xs">
            {spec.name}
          </MutedText>
        </div>
      )}
    </div>
  );
};
