import { motion } from 'framer-motion';

import { SimpleIcon } from '@/Icon';
import { Label } from '@/Label';
import { StatusBadge, StatusMap } from '@/Labels/Status';
import { Helper, Strong } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';

type DetailDataType = 'text' | 'count' | 'entries' | 'status' | 'render';

interface ListDetailProps {
  icon?: any;
  label: string | undefined | null;
  value: any;
  className?: string;
  description?: string | undefined | null;
  dtype?: DetailDataType;
  layout?: 'normal' | 'space-between';
  statusMap?: StatusMap;
  renderFn?: (value: any) => React.ReactNode;
}

const DetailValue = ({ value, dtype, statusMap, renderFn }: { value: any; dtype: DetailDataType, statusMap?: StatusMap; renderFn?: (value: any) => React.ReactNode; }) => {
  switch (dtype) {
  case 'text':
    return (
      <Strong className="text-xs! text-gray-500! flex items-center!">
        {value}
      </Strong>
    );

  case 'count':
    return (
      <div className="flex items-center text-xs! text-gray-400! gap-2">
          #
        <Strong className="text-xs! text-gray-500! flex items-center!">
          {value}
        </Strong>
      </div>
    );
  case 'render':
    if (!renderFn) {
      console.warn('renderFn is required when dtype is "render"');
      return <span>{String(value)}</span>;
    }
    return renderFn(value);

  case 'entries':
    return (
      <div className="space-y-2">
        <div className="flex items-center gap-4">
          <Strong className="text-xs!">
            {Number(value).toString()}
          </Strong>
        </div>
      </div>
    );

  case 'status':
    if (!statusMap || !statusMap[value]) {
      return (
        <Strong className="text-xs! text-gray-500! flex items-center!">
          {value}
        </Strong>
      );
    }
    return <StatusBadge status={value} config={statusMap[value]}/>;

  default:
    return <span>{String(value)}</span>;
  }
};

export const ListDetail = ({
  icon,
  label,
  value,
  className,
  description,
  dtype = 'text',
  renderFn,
  layout = 'normal',
  statusMap,
}: ListDetailProps) => {
  if (value === undefined || value === null) {
    return null;
  }

  return (
    <motion.div
      aria-label={`detail ${label}`}
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      className={cn('flex items-center gap-', className, layout === 'space-between' && 'justify-between')}
    >
      <div>
        <Tooltipped label={description}>
          <Label className="flex! items-center" theme="tooltip">
            <div className="flex items-center">
              {icon && (
                <SimpleIcon className="mr-1.5 !h-4 !w-4 !max-w-4 flex items-center" icon={icon}/>
              )}

              <Helper aria-label={label ? `${label} label` : undefined}
                className="text-xs! text-gray-500! flex! items-center!">
                {label}
              </Helper>
            </div>
          </Label>
        </Tooltipped>
      </div>

      <div
        aria-label={'value'}
      >
        <DetailValue statusMap={statusMap} value={value} dtype={dtype} renderFn={renderFn} />
      </div>
    </motion.div>
  );
};

interface InlineDetailsProps {
  children: React.ReactNode;
  separator?: boolean;
}

export const InlineDetails = ({
  children,
  separator,
}: InlineDetailsProps) => {
  return (
    <div className={cn('flex flex-wrap gap-12', separator && 'divide-x divide-gray-200')}>
      {children}
    </div>
  );
};
