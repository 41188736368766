import {
  ExclamationCircleIcon,
  HandThumbUpIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AcceptIssueIcon, EscalateIcon, MarkAsReadIcon } from '@/Icon';
import { StatusLabel } from '@/StatusLabel';
import { MutedText } from '@/Text';
import { Issue, IssueStatus } from '~/types/types';
import { valueCounts } from '~/utils/valueCounts';

type QualityLabelProps = {
  issues: Issue[];
  pending?: boolean;
  label?: string;
} | {
  issuesCount: number;
}

const valueCountIssues = (issues: Issue[]) => valueCounts(issues, 'status');

/**
 * The QualityLabel shows issues status based on the given issues.
 *
 * - If there are no issues, it shows a success label with the text "No Issues".
 *
 * @constructor
 * @param props
 */
export const QualityLabel = (props: QualityLabelProps) => {
  const { t } = useTranslation();

  if ('issuesCount' in props) {
    const { issuesCount } = props;
    if (issuesCount === 0) {
      return (
        <StatusLabel status="success" ariaLabel="No Issues">
          {t('no_issues')}
        </StatusLabel>
      );
    }

    return (
      <StatusLabel status="error" ariaLabel="Issues">
        {t('n_issues', { count: issuesCount })}
      </StatusLabel>
    );
  }

  if (!('issues' in props)) {
    return null;
  }

  const { issues, label } = props;

  if (!issues.length) {
    return (
      <StatusLabel status="success" ariaLabel={`No Issues: ${label}`}>
        {t('no_issues')}
      </StatusLabel>
    );
  }

  const issueByType = valueCountIssues(issues);

  /**
   * Check if there are more than one type of issues; if so, we show a breakdown of the issues.
   */
  if (Object.keys(issueByType).length > 1) {
    return (
      <div className="flex bg-white divide-x rounded-xs shadow-xs divide-gray-50">
        {issueByType[IssueStatus.Open] && (
          <div>
            <div className="flex items-center gap-1 px-2">
              <QuestionMarkCircleIcon className="w-3 h-4 text-yellow-500"/>
              <MutedText className="text-xs">
                {issueByType[IssueStatus.Open]}
              </MutedText>
            </div>
          </div>
        )}
        {issueByType[IssueStatus.Ignored] && (
          <div>
            <div className="flex items-center gap-1 px-2">
              <ExclamationCircleIcon className="w-3 h-4 text-gray-500"/>
              <MutedText className="text-xs">
                {issueByType[IssueStatus.Ignored]}
              </MutedText>
            </div>
          </div>
        )}

        {issueByType[IssueStatus.Read] && (
          <div>
            <div className="flex items-center gap-1 px-2">
              <MarkAsReadIcon className="w-3 h-4 text-gray-500"/>
              <MutedText className="text-xs">
                {issueByType[IssueStatus.Read]}
              </MutedText>
            </div>
          </div>
        )}

        {issueByType[IssueStatus.Accepted] && (
          <div>
            <div className="flex items-center gap-1 px-2">
              <AcceptIssueIcon className="w-3 h-4 text-gray-500"/>
              <MutedText className="text-xs">
                {issueByType[IssueStatus.Accepted]}
              </MutedText>
            </div>
          </div>
        )}

        {issueByType[IssueStatus.Escalated] && (
          <div>
            <div className="flex items-center gap-1 px-2">
              <EscalateIcon className="w-3 h-4 text-red-500"/>
              <MutedText className="text-xs">
                {issueByType[IssueStatus.Escalated]}
              </MutedText>
            </div>
          </div>
        )}

        {issueByType[IssueStatus.Resolved] && (
          <div>
            <div className="flex items-center gap-1 px-2">
              <HandThumbUpIcon className="w-3 h-4 text-blue-500"/>
              <MutedText className="text-xs">
                {issueByType[IssueStatus.Resolved]}
              </MutedText>
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * Otherwise, grab the type, and render the relevant status label.
   */
  const issueType = Object.keys(issueByType)[0];

  /**
   * If there are only open issues, we show the error label with the number of issues.
   */
  if (issueType === IssueStatus.Open) {
    return (
      <StatusLabel status="error" ariaLabel={`Issues: ${label}`}>
        {t('n_issues_unresolved', { count: issues.length })}
      </StatusLabel>
    );
  }

  /**
   * If there are only resolved issues, we show the success label with the number of issues.
   */
  if (issueType === IssueStatus.Resolved) {
    return (
      <StatusLabel status="info" ariaLabel={`Resolved: ${label}`}>
        {t('n_resolved', { count: issues.length })}
      </StatusLabel>
    );
  }

  if (issueType === IssueStatus.Escalated) {
    return (
      <StatusLabel status="error" ariaLabel={`Escalated: ${label}`}>
        {t('n_escalated', { count: issues.length })}
      </StatusLabel>
    );
  }

  if (issueType === IssueStatus.Accepted) {
    return (
      <StatusLabel status="warning" ariaLabel={`Accepted: ${label}`}>
        {t('n_accepted', { count: issues.length })}
      </StatusLabel>
    );
  }

  /**
   * If there are only ignored issues, we show the gray label with the number of issues.
   */
  if (issueType === IssueStatus.Ignored) {
    return (
      <StatusLabel status="default" ariaLabel={`Ignored: ${label}`}>
        {t('n_ignored', { count: issues.length })}
      </StatusLabel>
    );
  }

  return (
    <StatusLabel status="success" ariaLabel={`No Issues: ${label}`}>
      {t('no_issues')}
    </StatusLabel>
  );
};
