import { StandoutBorder } from '@/Borders';
import { CheckIcon, MiniLabelIcon } from '@/Icon';
import { MiniLabel } from '@/Label';
import { MutedText, Strong, TextLabel } from '@/Text';
import { useProductName } from '~/hooks/useProductName';
import { Produce, ProduceInspectionResult, ProduceVariety } from '~/types/types';

export interface ProduceVarietyCardProps {
  produce: Produce;
  produceVariety: ProduceVariety;
  inspectionResult?: ProduceInspectionResult;
}

/**
 *
 */
export const ProduceVarietyCard = ({
  produce,
  produceVariety,
  inspectionResult,
}: ProduceVarietyCardProps) => {
  const getProduceName = useProductName();
  const produceName = getProduceName(produce);
  return (
    <div className="flex items-center px-4 border border-gray-100 rounded shadow-xs">
      {produce.image_url && (
        <div className="inline mr-2">
          <StandoutBorder>
            <img
              className="object-cover rounded-full w-7 h-7"
              src={produce.image_url}
              alt={produceName}
            />
          </StandoutBorder>
        </div>
      )}
      <div className="flex flex-col divide-y">
        <div className="py-2">
          <div className="flex gap-4">
            <Strong
              id={`produce::${produce.id}`}
              data-testid="orderProduce"
            >
              {produceName}
            </Strong>

            {inspectionResult && inspectionResult.hasSellerInspection && (
              <div>
                <MiniLabel>
                  <MiniLabelIcon icon={CheckIcon}/>
                  Inspected
                </MiniLabel>
              </div>
            )}
          </div>
          {produceVariety.id && (
            <div>
              <MutedText
                id={`produceVariety::${produceVariety.id}`}
                data-testid="orderProduceVariety"
              >
                {produceVariety.name}
              </MutedText>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
