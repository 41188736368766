import { MarkAsReadIcon } from '@/Icon';
import { ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import ResolveIssueInput = App.Domain.Issues.Inputs.ResolveIssueInput;
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Issue, IssueType } from '~/types/types';

interface MarkIssueTypeAsAcceptedContext {
  id: string;
  type: IssueType;
}

/**
 * Mark issue as read
 */
export class MarkIssueTypeAsRead extends BaseAction {
  public static icon = MarkAsReadIcon;
  public static analyticsName = 'Mark all as read';
  public static toast = {
    titleKey: 'marked_all_as_read',
    descriptionKey: 'marked_all_as_read_description',
  };

  constructor(public context: ActionContext<MarkIssueTypeAsAcceptedContext>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'Mark all as read';
  }

  public disabled() {
    return (this.context.target as Issue).status === 'accepted';
  }

  public async execute(data: ResolveIssueInput) {
    console.log({
      id: this.context.target.id,
      type: this.context.target.type,
      data,
    });
    const routes = this.context.routes as BuyerRoutes;
    await this.post(routes.resolveOrderIssues(this.context.target.id, this.context.target.type, data.resolveInputType), data);
  }
}
