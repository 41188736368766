import React, { ComponentType, ReactNode } from 'react';

import { CardGutter } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import { GrouperIcon } from '@/Icon';
import { MutedStrong, MutedText } from '@/Text';
import { cn } from '~/utils/cn';
import { textVariants, Theme } from '~/utils/colors';

interface GroupConfig {
  /** Icon component to display next to the group label */
  icon?: React.ComponentType;
  /** Translation key or display text for the group */
  labelKey?: string;
  /** Priority for sorting groups (higher numbers appear first) */
  priority?: number;
  /** Color theme for the group header */
  theme?: Theme;
}

interface EmptyPlaceholder {
  text: string;
  description?: string;
  icon?: ComponentType;
}

interface GrouperProps<T> {
  /** Array of items to be grouped */
  data: T[];
  /** Function or key to group items by. If function, should return a string identifier for the group */
  groupBy: keyof T | ((item: T) => string);
  /** Configuration object for each group type */
  config?: Record<string, GroupConfig>;
  /** Render function for individual items, receives item and its original index */
  children: (item: T, originalIndex: number) => ReactNode;
  /** Additional className for the root element */
  className?: string;
  /** Whether to add spacing between groups and items */
  spacing?: boolean;
  /** Whether to make group headers sticky while scrolling */
  sticky?: boolean;
  /** Offset from top for sticky headers (in pixels) */
  stickyOffset?: number;
  /** Single placeholder for empty state */
  emptyPlaceholder?: EmptyPlaceholder;
  /** Multiple placeholders for different empty states */
  emptyPlaceholders?: Record<string, EmptyPlaceholder>;
  /** Key to match against emptyPlaceholders */
  emptyKey?: string;
  /** Indentation level for nested groups (0 means no indentation) */
  indentLevel?: number;
  /** Layout mode - rows or grid */
  layout?: 'rows' | 'grid';
  /** Number of grid columns (when layout is grid) */
  gridCols?: number;
}

const defaultConfig: GroupConfig = {
  priority: 0,
};

/**
 * Grouper component for organizing and displaying grouped content with configurable headers.
 *
 * Features:
 * - Groups items by a key or custom function
 * - Configurable group headers with icons and themes
 * - Maintains original item order within groups
 * - Supports sticky headers and custom spacing
 * - Handles empty states with customizable placeholders
 * - Type-safe with TypeScript generics
 *
 * @example
 * Basic usage with groups:
 * ```tsx
 * <Grouper
 *   data={fields}
 *   groupBy={(field) => field.type}
 *   config={{
 *     required: {
 *       labelKey: 'Required Fields',
 *       icon: MeasurementFieldIcon,
 *       theme: 'red',
 *       priority: 2,
 *     },
 *     optional: {
 *       labelKey: 'Optional Fields',
 *       icon: PercentageIcon,
 *       theme: 'gray',
 *       priority: 1,
 *     },
 *   }}
 * >
 *   {(field, index) => (
 *     <FieldCard key={field.id} field={field} />
 *   )}
 * </Grouper>
 * ```
 *
 * With empty state handling:
 * ```tsx
 * <Grouper
 *   data={[]}
 *   groupBy="type"
 *   emptyKey="filtered"
 *   emptyPlaceholders={{
 *     filtered: {
 *       text: "No matches found",
 *       description: "Try adjusting your filters",
 *       icon: FilterIcon
 *     },
 *     empty: {
 *       text: "No items available",
 *       description: "Add some items to get started",
 *       icon: PlusIcon
 *     }
 *   }}
 *   emptyPlaceholder={{
 *     text: "No items found",
 *     description: "Default empty state",
 *     icon: SearchIcon
 *   }}
 * >
 *   {(item) => <ItemComponent item={item} />}
 * </Grouper>
 * ```
 *
 * @param props.data - Array of items to be grouped
 * @param props.groupBy - Function or key to group items by
 * @param props.config - Configuration object for each group type
 * @param props.children - Render function for individual items
 * @param props.className - Additional className for the root element
 * @param props.spacing - Whether to add spacing between groups and items
 * @param props.sticky - Whether to make group headers sticky while scrolling
 * @param props.stickyOffset - Offset from top for sticky headers (in pixels)
 * @param props.emptyPlaceholder - Default placeholder for empty state
 * @param props.emptyPlaceholders - Multiple placeholders for different empty states
 * @param props.emptyKey - Key to match against emptyPlaceholders
 */
export function Grouper<T>({
  data,
  groupBy,
  config = {},
  children,
  className,
  spacing = true, // Default to true to maintain existing behavior
  sticky = false,
  stickyOffset = 0,
  emptyPlaceholder,
  emptyPlaceholders,
  emptyKey,
  indentLevel,
  layout = 'rows',
  gridCols = 1,
}: GrouperProps<T>) {
  // Early return with placeholder if data is empty
  if (data.length === 0) {
    // Determine which placeholder to show
    let placeholderToShow: EmptyPlaceholder | undefined;

    if (emptyKey && emptyPlaceholders) {
      // Try to find a matching placeholder for the emptyKey
      placeholderToShow = emptyPlaceholders[emptyKey] || emptyPlaceholder;
    } else {
      // Fall back to the default emptyPlaceholder
      placeholderToShow = emptyPlaceholder;
    }

    // If we have a placeholder to show, render it
    if (placeholderToShow) {
      return (
        <PlaceholderBox
          title={placeholderToShow.text}
          description={placeholderToShow.description}
          icon={<placeholderToShow.icon /> as any}
        />
      );
    }
  }

  // Modified grouping logic to handle nested groups
  const createGroups = (items: T[], level = 0, path: string[] = []) => {
    const groupedData = items.reduce((acc, item, index) => {
      const groupValue = typeof groupBy === 'function'
        ? groupBy(item)
        : String(item[groupBy]);

      const groupKeys = Array.isArray(groupValue) ? groupValue : [groupValue];
      const currentKey = groupKeys[level];

      if (!acc[currentKey]) {
        acc[currentKey] = [];
      }

      acc[currentKey].push({
        item,
        originalIndex: index,
        hasNestedGroups: level < groupKeys.length - 1,
      });

      return acc;
    }, {} as Record<string, Array<{
      item: T;
      originalIndex: number;
      hasNestedGroups: boolean;
    }>>);

    // Sort the groups by priority before returning
    return Object.entries(groupedData)
      .map(([key, items]) => ({
        key,
        items,
        level,
        path: [...path, key],
        priority: config[key]?.priority ?? defaultConfig.priority,
      }))
      .sort((a, b) => (b.priority - a.priority));
  };

  const renderGroup = ({ key, items, level, path, priority }: {
    key: string;
    items: Array<{
      item: T;
      originalIndex: number;
      hasNestedGroups: boolean;
    }>;
    level: number;
    path: string[];
    priority: number;
  }) => {
    const groupConfig = config[key] ?? defaultConfig;
    const theme = groupConfig.theme ?? 'gray';

    if (items.length === 0) return null;

    // Check if we need to create nested groups
    const hasNestedGroups = items.some(({ hasNestedGroups }) => hasNestedGroups);

    return (
      <div key={path.join('.')} className={spacing ? 'space-y-2' : ''}>
        <CardGutter
          style={sticky ? { top: `${stickyOffset}px` } : undefined}
          className={cn(sticky && 'sticky z-10')}
        >
          <div className="flex items-center gap-4">
            {level > 0 && (
              <div className="flex items-stretch">
                {[...Array(level)].map((_, index) => (
                  <div
                    key={index}
                    className="w-4 border-l border-gray-200 dark:border-gray-700"
                  />
                ))}
              </div>
            )}
            <div className="flex items-center flex-1 gap-4 py-1">
              <MutedStrong className="leading-none">
                {groupConfig.icon && (
                  <GrouperIcon
                    icon={groupConfig.icon}
                    className={cn(textVariants[theme])}
                  />
                )}
                {groupConfig.labelKey ?? key}
              </MutedStrong>

              <MutedText className="leading-none">{items.length}</MutedText>
            </div>
          </div>
        </CardGutter>

        <div className={cn(
          spacing && 'space-y-2',
          level > 0 && 'border-l-gray-100 border-l-8',
          layout === 'grid' && `grid grid-cols-1 md:grid-cols-${gridCols} gap-4`
        )}>
          {hasNestedGroups ? (
            createGroups(
              items.map(({ item }) => item),
              level + 1,
              path
            ).map((group) => renderGroup(group))
          ) : (
          // Render items
            items.map(({ item, originalIndex }) =>
              children(item, originalIndex)
            )
          )}

        </div>
      </div>
    );
  };

  const topLevelGroups = createGroups(data);

  return (
    <div className={className}>
      {topLevelGroups.map((group) => renderGroup(group))}
    </div>
  );
}
