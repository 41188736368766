import React, { useMemo } from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { DateFormat, useDate } from '~/hooks/useDate';
import { TimeSeries } from '~/types/types';

interface DensityAreaChartProps {
  /**
   * Single time series to display as density area
   */
  timeSeries: TimeSeries;
  /**
   * Height of the chart container
   */
  height?: number | string;
  /**
   * Width of the chart container
   */
  width?: number | string;
  /**
   * Unit to display on the Y-axis
   */
  unit?: string;
  /**
   * Whether to show the Y-axis
   */
  showYAxis?: boolean;
  /**
   * Whether to show the X-axis
   */
  showXAxis?: boolean;
  /**
   * Custom class name for the container
   */
  className?: string;
  /**
   * Color gradient for the area
   */
  gradientColors?: {
    start: string;
    end: string;
  };
  /**
   * ID for gradient definition
   */
  gradientId?: string;
  /**
   * Optional callback when area is clicked
   */
  onAreaClick?: (event: any) => void;
  /**
   * Sync ID for synchronizing with other charts
   */
  syncId?: string;
  /**
   * Whether to show tooltip
   */
  showTooltip?: boolean;
  /**
   * Fraction digits for values on tooltip
   */
  tooltipValueFractionDigits?: number;
}

/**
 * Simple tooltip for the density chart
 */
const DensityTooltip = ({ active, payload, label, tooltipValueFractionDigits }: any) => {
  const { parseOrFormat } = useDate();

  if (!active || !payload || !payload.length) return null;

  return (
    <div className="p-2 text-xs bg-white border rounded shadow-sm">
      <p className="font-medium">{parseOrFormat(label, DateFormat.HumanDateNoTime)}</p>
      <p className="text-gray-700">
        {payload[0].name}: {payload[0].value.toFixed(tooltipValueFractionDigits)}
      </p>
    </div>
  );
};

/**
 * A compact area chart that displays a single time series as a density area
 */
export const DensityAreaChart = ({
  timeSeries,
  height = 100,
  width = '100%',
  unit = '',
  showYAxis = false,
  showXAxis = true,
  className = '',
  gradientColors = { start: '#8884d8', end: '#8884d811' },
  gradientId = 'densityGradient',
  onAreaClick,
  syncId,
  showTooltip = true,
  tooltipValueFractionDigits = 1,
}: DensityAreaChartProps) => {
  const { parseOrFormat } = useDate();

  // Process the data for Recharts
  const chartData = useMemo(() => {
    if (!timeSeries || !timeSeries.events || !timeSeries.events.length) return [];

    // Convert the events to the format needed for the area chart
    return timeSeries.events
      .map(event => ({
        date: event.date,
        value: event.value,
      }))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [timeSeries]);

  // Calculate the domain for Y-axis
  const yDomain = useMemo(() => {
    if (!chartData.length) return [0, 1];

    const max = Math.max(...chartData.map(d => d.value));
    return [0, max * 1.1]; // Add 10% padding at the top
  }, [chartData]);

  // Get the series name for display
  const seriesName = timeSeries?.label || 'Density';

  if (!timeSeries || !chartData.length) {
    return <div className="p-2 text-xs text-center text-gray-500">No density data available</div>;
  }

  return (
    <div className={`${className}`} style={{ width }}>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={chartData}
          margin={{ top: 5, right: 20, left: showYAxis ? 20 : 80, bottom: showXAxis ? 20 : 5 }}
          onClick={onAreaClick}
          syncId={syncId}
        >
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={gradientColors.start} stopOpacity={0.8} />
              <stop offset="95%" stopColor={gradientColors.end} stopOpacity={0.2} />
            </linearGradient>
          </defs>

          {showXAxis && (
            <XAxis
              dataKey="date"
              tickFormatter={(date) => parseOrFormat(date, DateFormat.DayFormat)}
              tick={{ fontSize: 10 }}
              type="category"
              height={20}
              tickSize={3}
            />
          )}

          {showYAxis && (
            <YAxis
              domain={yDomain}
              tickFormatter={(value) => `${value.toFixed(1)}${unit}`}
              tick={{ fontSize: 10 }}
              width={30}
            />
          )}

          {showTooltip && <Tooltip content={<DensityTooltip tooltipValueFractionDigits={tooltipValueFractionDigits} />} />}

          <Area
            type="monotone"
            dataKey="value"
            name={seriesName}
            stroke={gradientColors.start}
            fillOpacity={1}
            fill={`url(#${gradientId})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
