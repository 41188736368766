import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { ReactNode } from 'react';

import { cn } from '~/utils/cn';

import { MutedText, Strong } from './Text';

interface RadioButtonCardProps {
  label?: string;
  subLabel?: string;
  id: string;
  value: string;
  disabled?: boolean;
}

export const RadioGroup = RadioGroupPrimitive.Root;

export const RadioGroupItem = RadioGroupPrimitive.Item;

export const RadioGroupItemIndicator = RadioGroupPrimitive.Indicator;

// TODO: Extract only necessities out of RadioGroupPrimitive
export const RadioButtonCard = ({
  label,
  subLabel,
  id,
  value,
  disabled,
}: RadioButtonCardProps) => {
  return (
    <div className={'relative flex items select-none'}>
      <RadioGroupPrimitive.Item
        className={cn(
          'cursor-pointer disabled:cursor-not-allowed mt-5 peer ml-4 absolute bg-white w-[20px] h-[20px] border rounded-full outline-none'
        )}
        value={value}
        id={id}
        disabled={disabled}
      >
        <RadioGroupPrimitive.Indicator
          className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-full after:bg-blue-700"/>
      </RadioGroupPrimitive.Item>
      <label
        htmlFor={id}
        aria-disabled={disabled}
        className={cn(
          'cursor-pointer flex flex-col w-full p-4 border rounded pl-14 peer-aria-checked:text-blue-700 peer-aria-checked:ring-blue-400 peer-focus:ring-blue-200 peer-focus:border-transparent peer-focus:ring-1 peer-focus:ring-offset-0 peer-focus:ring-offset-white',
          'aria-disabled:cursor-not-allowed aria-disabled:opacity-50'
        )}
      >
        <Strong>{label}</Strong>
        {subLabel && <MutedText>{subLabel}</MutedText>}
      </label>
    </div>
  );
};

interface RadioCardProps {
  // Core functionality
  value: string;
  id: string;
  onChange?: (value: string) => void;
  checked?: boolean;
  disabled?: boolean;
  name?: string;

  // Styling and layout
  className?: string;
  radioClassName?: string;
  indicatorClassName?: string;

  // Content
  children?: ReactNode;

  // Optional features
  required?: boolean;
  description?: string;
  ariaLabel?: string;
}

export const RadioCard = ({
  value,
  id,
  onChange,
  checked,
  disabled,
  name,
  className,
  radioClassName,
  indicatorClassName,
  children,
  required,
  description,
  ariaLabel,
}: RadioCardProps) => {
  return (
    <div className={cn(
      'relative flex items-start select-none group',
      disabled && 'opacity-50 cursor-not-allowed',
      className
    )}>
      <RadioGroupPrimitive.Item
        value={value}
        id={id}
        disabled={disabled}
        required={required}
        name={name}
        aria-label={ariaLabel}
        aria-describedby={description ? `${id}-description` : undefined}
        className={cn(
          'peer absolute top-4 left-4',
          'h-5 w-5 rounded-full border',
          'border-zinc-300 text-blue-600',
          'focus:ring-2 focus:ring-blue-200 focus:ring-offset-2',
          'disabled:cursor-not-allowed disabled:opacity-50',
          'transition-all duration-200',
          radioClassName
        )}
        onClick={() => onChange?.(value)}
      >
        <RadioGroupPrimitive.Indicator
          className={cn(
            'flex items-center justify-center w-full h-full relative',
            'after:content-[""] after:block after:w-[11px] after:h-[11px]',
            'after:rounded-full after:bg-blue-700',
            'transition-transform duration-200',
            'data-[state=checked]:scale-100 data-[state=unchecked]:scale-0',
            indicatorClassName
          )}
        />
      </RadioGroupPrimitive.Item>

      <label
        htmlFor={id}
        className={cn(
          'w-full rounded-lg border p-4 pl-14',
          'cursor-pointer transition-all duration-200',
          'hover:border-blue-200',
          'peer-data-[state=checked]:border-blue-600',
          'peer-data-[state=checked]:ring-2 peer-data-[state=checked]:ring-blue-200',
          'peer-focus:ring-2 peer-focus:ring-blue-200',
          'peer-disabled:cursor-not-allowed peer-disabled:opacity-50'
        )}
      >
        {children}

        {description && (
          <p
            id={`${id}-description`}
            className="mt-2 text-sm text-zinc-500"
          >
            {description}
          </p>
        )}
      </label>
    </div>
  );
};
