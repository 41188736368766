import React from 'react';

import { MutedText, Strong } from '@/Text';
import { Order } from '~/types/types';
import { FormatStringList } from '~/utils/FormatStringList';

/**
 * Represents the order title
 *
 * @preconditions - Ensure that the order has preloaded order_produces.produce
 *
 */
export const OrderTitle = ({ order }: { order: Order }) => {
  const orderProduces = order.order_produce ?? order.order_produces;

  if (!orderProduces) {
    return (
      <Strong className="mb-2 leading-none capitalize">
        {order.public_ref}
      </Strong>
    );
  }

  const uniqueProduces = orderProduces
    ?.map((orderProduce) => orderProduce.produce?.name.toLocaleLowerCase())
    .filter((v, i, a) => a.indexOf(v) === i) as string[];
  const uniqueProduceVarieties = orderProduces
    ?.map((orderProduce) => orderProduce.produce_variety?.name)
    .filter((v, i, a) => a.indexOf(v) === i) as string[];

  return (
    <div className="flex flex-col justify-start">
      <div className="flex">
        <Strong className="mb-2 leading-none capitalize ">
          <FormatStringList strings={uniqueProduces}/>
        </Strong>
      </div>
      <div className="flex">
        <MutedText className="text-xxs! leading-none text-gray-500!">
          <FormatStringList strings={uniqueProduceVarieties}/>
        </MutedText>
      </div>
    </div>
  );
};
