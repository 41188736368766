import { useTranslation } from 'react-i18next';
import { PhotoProvider } from 'react-photo-view';

import { Button } from '@/Button';
import { Card, CardBody, CardHeader } from '@/Card';
import { AttachmentsIcon } from '@/Icon';
import { AttachmentsGallery } from '~/Components/Attachments/AttachmentsGallery';
import { UploadAttachmentsModal } from '~/Components/Attachments/UploadAttachmentsModal';
import { usePageProps } from '~/hooks/usePageProps';
import { Attachment, Order } from '~/types/types';

interface AttachmentsCardProps {
  attachments: Attachment[];
}

export const AttachmentsCard = ({ attachments }: AttachmentsCardProps) => {
  const { t } = useTranslation();
  const { organization, order } = usePageProps();

  return (
    <div id="attachments">
      <PhotoProvider>
        <Card>
          <CardHeader
            title={t('attachments')}
            description={t('attachments_description')}
            renderIcon={<AttachmentsIcon className="w-6 h-6"/>}
          >
            <div className="mt-4 space-x-2">
              <UploadAttachmentsModal
                organization={organization}
                order={order as Order}
              >
                <Button className="ml-0 sm:ml-4" variant="white">
                  Upload attachments
                </Button>
              </UploadAttachmentsModal>
            </div>
          </CardHeader>

          {attachments.length > 0 ? (
            <AttachmentsGallery
              attachments={attachments}
              title={t('attachments') as string}
              layout="grid"
              multi
              gridCols={3}
              showComments={true}
              itemSize="md"
            />
          ) : (
            <CardBody>
              <div className="flex flex-col items-center justify-center py-8 text-center">
                <AttachmentsIcon className="w-12 h-12 mb-4 text-gray-300" />
                <p className="mb-2 text-gray-500">{t('no_attachments')}</p>
                <p className="max-w-md text-sm text-gray-400">{t('attachments_description')}</p>
              </div>
            </CardBody>
          )}
        </Card>
      </PhotoProvider>
    </div>
  );
};
