import { MeasurementFieldIcon, SimpleCardHeaderIcon } from '@/Icon';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { AnalyticsConfigRequest, QualityComparisonMetricType, QualityOverTimeParameters } from '~/types/types';

import { QualityIssuesResults } from '../../../../../../types/quality';

import QualityIssuesModule = App.Domain.Reports.Modules.QualityIssues.QualityIssuesModule;

import { DraftingCompass, HandshakeIcon, TimerIcon } from 'lucide-react';
import { useMemo } from 'react';

import { ButtonGutter } from '@/Button';
import { Card, CardGutter, CardHeader, SimpleCardHeader } from '@/Card';
import { WithGenericFallback } from '@/Fallback';
import { CardTitle, MutedStrong, SimpleCardTitle } from '@/Text';
import { QualityOverTimeChart } from '~/Components/Analytics/Charts/Timeseries';
import { QualityBreakdownOverTime } from '~/Components/Analytics/Charts/Timeseries/QualityBreakdownOverTime';
import { useQuery } from '~/hooks/useQuery';
import { formatPicker, getGroups } from '~/utils/formatPicker';

import { useAnalytics } from '../../AnalyticsProvider';
import { comparisonMetrics, qualityEntityTypes } from './QualityOverTime.helpers';

interface QualityOverTimeProps {
  module: QualityIssuesModule;
}

export const QualityOverTimeGutter = () => {
  const { filterOptions } = useAnalytics();
  const { parameters, setParameter } = useQuery<QualityOverTimeParameters>();

  const usesFieldComparsion = parameters.unit_type === 'field_scores';

  return (
    <div>
      <div className="flex gap-4">
        <MutedStrong className="mt-2">
          Filters
        </MutedStrong>
        <ButtonGutter>
          <GenericPicker
            icon={HandshakeIcon}
            showClear={false}
            value={parameters.unit_type}
            title="Quality metric"
            onChange={(unitType) => setParameter('unit_type', unitType as QualityComparisonMetricType)}
            options={comparisonMetrics}
            placeholder="Select a quality metric"
            showTitleOnSelected
            emptyMessage="No quality metrics found"
          />

          {usesFieldComparsion && (
            <GenericPicker
              emptyMessage="No fields found"
              placeholder="Select a field"
              icon={MeasurementFieldIcon}
              title="Focus on field"
              showClear={false}
              value={parameters.field_id}
              options={formatPicker(filterOptions.fieldOptions || [])}
              showTitleOnSelected
              groups={filterOptions.fieldOptions ? getGroups(filterOptions.fieldOptions) : undefined}
              onChange={(fieldId) => setParameter('field_id', fieldId as string)}
            />
          )}

          <GenericPicker
            icon={DraftingCompass}
            title="Compare between"
            showClear={false}
            showTitleOnSelected
            options={qualityEntityTypes}
            value={parameters.quality_entity_type}
            placeholder="Select comparison type"
            emptyMessage="No comparison types found"
            onChange={(qualityEntityType) => setParameter('quality_entity_type', qualityEntityType as string)}
          />
        </ButtonGutter>
      </div>
    </div>
  );
};

// Export the component for testing
export const QualityOverTime = ({ module }: QualityOverTimeProps) => {
  const { parameters: analyticsParameters, isLoading } = useQuery<AnalyticsConfigRequest>();

  // Track whether the chart should be displayed
  const renderChart = useMemo(() => {
    return !!analyticsParameters.quality_entity_type;
  }, [analyticsParameters.quality_entity_type]);

  // Cast to unknown first to avoid type mismatch
  const qualityData = module.results as unknown as QualityIssuesResults;

  return (
    <Card isLoading={isLoading}>
      <SimpleCardHeader>
        <SimpleCardHeaderIcon icon={TimerIcon} />
        <SimpleCardTitle>
          Quality over time
        </SimpleCardTitle>
      </SimpleCardHeader>
      <CardHeader>
        <CardTitle>
          Quality over time
        </CardTitle>
      </CardHeader>
      {!!renderChart && (
        <CardGutter>
          <QualityOverTimeGutter />
        </CardGutter>
      )}
      {/* Chart Display - Only shown when query has been run */}
      {renderChart && (
        <div className="space-y-6">
          {qualityData?.qualityOverTime && (
            <WithGenericFallback>
              <QualityOverTimeChart qualityOverTime={qualityData.qualityOverTime} />
              <QualityBreakdownOverTime qualityOverTime={qualityData.qualityOverTime} />
            </WithGenericFallback>
          )}
        </div>
      )}
    </Card>
  );
};
