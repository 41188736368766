import { PlaceholderBox } from '@/Fallback';
import { MiniLabelIcon } from '@/Icon';
import { MiniLabel } from '@/Label';
import { Strong } from '@/Text';
import { MeasurementDistribution, PercentageDistribution } from '~/types/types';

import { FormatDistribution } from './FormatDistribution';
import { SummarizeDistribution } from './SummarizeDistribution';

interface DistributionDisplayProps {
  distribution: MeasurementDistribution | PercentageDistribution | null;
  title: string;
  icon: React.ComponentType;
  label: string;
}

export const DistributionDisplay = ({ distribution, title, icon, label }: DistributionDisplayProps) => {
  return (
    <div className="flex items-center justify-between py-4">
      <div aria-label="entity">
        <div>
          <Strong className="leading-none!">
            {title}
          </Strong>
        </div>
        <div className="flex items-center gap-2">
          <MiniLabel>
            <MiniLabelIcon
              className="stroke-gray-400!"
              icon={icon}
            />
            {label}
          </MiniLabel>
        </div>
      </div>

      <div className="min-w-[240px]" aria-label="measurements">
        {distribution?.isValid ? (
          <div>
            <div>
              <FormatDistribution
                distribution={distribution}
                isCompact
              />
            </div>
            <div className="mt-1">
              <SummarizeDistribution
                distribution={distribution}
              />
            </div>
          </div>
        ) : (
          <PlaceholderBox
            title='No data available'
            compact
          />
        )}
      </div>
    </div>
  );
};
