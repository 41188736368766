import { useTranslation } from 'react-i18next';

import { AdminOnly } from '@/AdminOnly';
import {
  Card,
  CardBody,
  CardFooter,
  CardGutter,
  SimpleCardHeader,
} from '@/Card';
import { InlineDetails, ListDetail } from '@/Details';
import { MeasurementFieldIcon, ProduceIcon, QualityCostsIcon, QualityIssuesIcon, RegionIcon, SimpleCardHeaderIcon, UserIcon } from '@/Icon';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { CardDescription, CardTitle, SimpleCardTitle } from '@/Text';
import { ScatterCorrelations } from '~/Components/Analytics/Charts/Correlations/ScatterCorrelations';
import Section from '~/Components/Common/Section';
import { useAction } from '~/hooks/useAction';
import { AnalyticsConfigRequest, EntityScopeType } from '~/types/types';
import { DeepPartial } from '~/utils/utils';

import QualityIssuesModule = App.Domain.Reports.Modules.QualityIssues.QualityIssuesModule;
import ReportsPageOutput = App.Http.Controllers.Reports.ReportsPageOutput;

import { HandshakeIcon, Ruler } from 'lucide-react';
import { useMemo } from 'react';

import { useQuery } from '~/hooks/useQuery';

import { useAnalytics } from '../../AnalyticsProvider';
import { ReportsFilter } from '../../types';
import { QualityOverTime } from './QualityOverTime';

interface QualityIssuesSectionProps {
  module: QualityIssuesModule;
}

const noop = () => undefined;

/**
 * QualityIssuesSection displays quality issues analytics with various visualizations
 *
 * The component uses several card layout patterns:
 *
 * 1. CardPanels: A container component that arranges multiple panels in a grid layout
 *    Usage: <CardPanels columns="2_1"> creates a 2/3 + 1/3 column layout
 *    Options: columns can be "1_1" (50-50), "2_1" (66-33), "1_2" (33-66), "1_3" (25-75)
 *
 * 2. CardPanel: A standard content panel that fills available space
 *    Usage: <CardPanel>content</CardPanel>
 *
 * 3. CardSidePanel: A panel specifically designed for sidebar content with different styling
 *    Usage: <CardSidePanel flat>content</CardSidePanel>
 *    Props:
 *    - flat: boolean - Removes panel background/border
 *    - variant: "muted" | "primary" - Changes background color
 */
export const QualityIssuesSection = ({ module }: QualityIssuesSectionProps) => {
  const { t } = useTranslation();

  const scope = module.config.entityScopeType;

  const { reload, loading } = useAction<DeepPartial<ReportsFilter>, ReportsPageOutput>('', {
    withParams: true,
    only: ['issuesModule'],
  });

  const { filterOptions } = useAnalytics();

  const handleScopeChange = (value: EntityScopeType) => {
    if (typeof value === 'string' && (value === 'seller' || value === 'region')) {
      reload({
        overrideIssuesConfig: {
          entityScopeType: value,
        },
      });
    }
  };

  const handleZoomInRegion = (regionId: string) => {
    reload({
      overrideIssuesConfig: {
        entityScopeType: 'seller',
        scope: {
          zoomRegionId: regionId,
        },
      },
    });
  };

  const handleZoomOutRegion = () => {
    reload({
      overrideIssuesConfig: {
        entityScopeType: 'region',
        scope: {
          zoomRegionId: null,
        },
      },
    });
  };

  const { parameters, setParameter, isLoading: queryLoading } = useQuery<AnalyticsConfigRequest>();

  const filterPickerOptions = useMemo(() => {
    return [
      {
        name: 'Compare between',
        labelKey: 'compare_between',
        type: 'single',
        icon: HandshakeIcon,
        options: [
          {
            label: 'Produce varieties',
            value: 'produce_variety',
            icon: ProduceIcon,
          },
          {
            label: 'Regions',
            value: 'region',
            icon: RegionIcon,
          },
        ],
      },
      {
        name: 'Unit',
        labelKey: 'unit',
        type: 'single',
        icon: MeasurementFieldIcon,
        options: [
          {
            label: 'Kg',
            value: 'kg',
            icon: Ruler,
          },
        ],
      },
    ];
  }, []);

  return (
    <Section
      icon={QualityIssuesIcon}
      defaultOpen={true}
      title={t('quality_issues')}
      description={t('reports.quality_issues_description')}
      showCollapsed
      enableToggle
    >
      <div className="grid gap-4">
        <QualityOverTime
          module={module}
        />
        {/* Main Quality Issues Card */}
        {/* <Card isLoading={queryLoading}>
          <CardHeaderContainer size="sm">
            <div className="flex items-center justify-between gap-2">
              <div>
                <SimpleCardHeaderIcon icon={QualityIssuesIcon}/>
                <MutedText>
                  {t('reports.quality_issues')}
                </MutedText>
              </div>
            </div>
          </CardHeaderContainer>
          <CardBody bordered="y">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle>
                  {t('reports.quality_per_supplier')}
                </CardTitle>
                <CardDescription>
                  {t('reports.quality_issues_description')}
                </CardDescription>
              </div>
            </div>
          </CardBody>
          <CardGutter className="flex justify-end">
            <div className="flex space-x-2">
              {!!module.config.scope?.zoomRegion && (
                <div className="flex max-w-120">
                  <GenericPicker
                    showClear={true}
                    loading={loading}
                    icon={UserIcon}
                    value={module.config.scope.zoomRegion.index_code}
                    onChange={() => {
                      handleZoomOutRegion();
                    }}
                    showTitleOnSelected
                    title={t('region')}
                    options={[
                      {
                        label: module.config.scope.zoomRegion.label,
                        value: module.config.scope.zoomRegion.index_code,
                        icon: RegionIcon,
                      },
                    ]}
                    emptyMessage=""
                    placeholder=""
                  />
                </div>
              )}

              <div className="flex">
                <GenericPicker
                  showClear={false}
                  loading={loading}
                  icon={UserIcon}
                  value={scope}
                  onChange={handleScopeChange}
                  showTitleOnSelected
                  title={t('compare')}
                  options={[
                    {
                      label: 'By seller',
                      value: 'seller',
                      icon: SellerIcon,
                      subLabel: 'Compare between sellers',
                    },
                    {
                      label: 'By region',
                      value: 'region',
                      icon: RegionIcon,
                      subLabel: 'Compare between regions',
                    },
                  ]}
                  emptyMessage=""
                  placeholder=""
                />
              </div>
            </div>
          </CardGutter>
          <CardPanels columns="1_1">
            <CardPanel>
              <QualityOverTime module={module} />
            </CardPanel>
          </CardPanels>
        </Card> */}

        {/* Scatter Correlations Card */}
        <div className="grid grid-cols-2">
          {module.results.scatterCorrelations && (
            <AdminOnly>
              <Card isLoading={queryLoading}>
                <SimpleCardHeader>
                  <SimpleCardHeaderIcon icon={QualityIssuesIcon}/>
                  <SimpleCardTitle>
                    {t('quality_issues')}
                  </SimpleCardTitle>
                </SimpleCardHeader>
                <CardBody bordered="y">
                  <div className="flex items-center justify-between">
                    <div>
                      <CardTitle>
                        {t('reports.quality_correlations')}
                      </CardTitle>
                      <CardDescription>
                        {t('reports.quality_correlations_description')}
                      </CardDescription>
                    </div>
                  </div>
                </CardBody>
                <CardGutter>
                  <GenericPicker
                    showClear={false}
                    disabled
                    loading={loading}
                    icon={UserIcon}
                    value={'issue_to_rag'}
                    onChange={noop}
                    showTitleOnSelected
                    title={t('compare')}
                    options={[
                      {
                        label: 'Issue to cost',
                        value: 'issue_to_issue',
                        icon: QualityCostsIcon,
                        subLabel: 'Compare issues to RAG',
                      },
                      {
                        label: 'Issue to RAG',
                        value: 'issue_to_rag',
                        icon: QualityIssuesIcon,
                        subLabel: 'Compare between regions',
                      },
                    ]}
                    emptyMessage=""
                    placeholder=""
                  />
                </CardGutter>
                <CardBody>
                  <ScatterCorrelations
                    data={module.results.scatterCorrelations}
                    color="var(--color-value)"
                  />
                </CardBody>
                <CardFooter>
                  <InlineDetails separator>
                    <ListDetail
                      layout="normal"
                      description={t('count_orders_description')}
                      label={t('count_orders')}
                      value={module.results.scatterCorrelations.items.length}
                      dtype="count"
                    />
                  </InlineDetails>
                </CardFooter>
              </Card>
            </AdminOnly>
          )}
        </div>
      </div>
    </Section>
  );
};
