import { router } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@/Button';
import { Card, CardContent, CardHeaderContainer } from '@/Card';
import { CommentIcon, EditIcon, IconButtonIcon } from '@/Icon';
import { NumericIndicator } from '@/NumericIndicator';
import { EmptyTableContainer } from '@/Table';
import { ReponsiveTableContainer } from '@/Table/TableContainer';
import { Helper, MutedText, Strong, Text } from '@/Text';
import { DeleteQuality } from '~/Actions/Common/DeleteQuality';
import { AnalyzeOrder } from '~/Actions/Orders/AnalyzeOrder';
import { SummarizeOrder } from '~/Actions/Orders/SummarizeOrder';
import { NavEditInspection } from '~/Actions/Quality/NavEditInspection';
import { CommandActionDropdown } from '~/Components/Common/Command/CommandActionDropdown';
import { FormatProduceVariety } from '~/Components/Produce/FormatProduce';
import { QualityEntryUnit } from '~/Components/Quality/QualityEntries/FormatQualityEntry';
import { useComments } from '~/hooks/useComments';
import { DateFormat, useDate } from '~/hooks/useDate';
import { useFormat } from '~/hooks/useFormat';
import { useOrganizationName } from '~/hooks/useOrganizationName';
import { usePageProps } from '~/hooks/usePageProps';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { OrderStatus, ProduceVariety, QualityEntry, Spec } from '~/types/types';

import { useOrder } from '../../../pages/Dashboard/Order/OrderProvider';
import { CommentsPane } from '../Panes/CommentsPane';

interface QualityEntryRowProps {
  qualityEntry: QualityEntry;
  unit?: string | null;
  activeSpec: Spec | null;
}

export const QualityEntryRow = ({
  qualityEntry,
  activeSpec,
}: QualityEntryRowProps) => {
  const { t } = useTranslation();
  const { formatProduceName } = useFormat();
  const { parseOrFormat } = useDate();
  const { order, findProduce, findProduceVariety } = useOrder();
  const { organization } = usePageProps();
  const { comments } = useComments(qualityEntry.id);

  const routes = useRoutes<BuyerRoutes>();

  const goToEdit = () => {
    router.get(`${routes.qualityInput()}?orderId=${order.id}&inspectionId=${qualityEntry.inspection_id}`, {}, {
      preserveScroll: false,
    });
  };

  const { organizationName } = useOrganizationName(
    qualityEntry.organization_id
  );

  const canEditEntry = activeSpec && qualityEntry.organization_id === organization.id;

  return (
    <div
      role="row"
      className="grid grid-cols-[1fr_1fr_1fr_50px] gap-8 items-center justify-between py-4"
    >
      <div>
        <div>
          <Strong>
            {organizationName}
          </Strong>
        </div>
        <MutedText>
          {parseOrFormat(qualityEntry.created_at, DateFormat.HumanDateTimeNoYear)}
        </MutedText>
      </div>

      <div>
        <div>
          <Text>
            <QualityEntryUnit qualityEntry={qualityEntry}/>
          </Text>
        </div>

        {(qualityEntry?.samples_qty && qualityEntry.samples_qty > 1) ? (
          <MutedText>
            out of {' '}
            {qualityEntry.samples_qty} {t('units')}
          </MutedText>
        ) : (
          <MutedText>
            {t('no_units_reported')}
          </MutedText>
        )}
      </div>

      <div>
        <div>
          <Text>
            {formatProduceName(findProduce(qualityEntry.produce_id)?.name)}
          </Text>
        </div>
        <MutedText>
          <FormatProduceVariety produceVariety={findProduceVariety(qualityEntry.produce_variety_id) as ProduceVariety}/>
        </MutedText>
      </div>
      <div className="flex items-center justify-end gap-2">
        <CommandActionDropdown
          actionItems={[
            {
              action: DeleteQuality,
              target: order,
              params: { inspectionId: qualityEntry.inspection_id },
              disabled: !canEditEntry,
              group: 'inspection',
            },
            {
              action: NavEditInspection,
              target: order,
              params: { orderId: order.id, inspectionId: qualityEntry.inspection_id },
              group: 'inspection',
              disabled: !canEditEntry,
            },
          ]}
        />
        <CommentsPane
          path={`/b/${organization.id}/comments/add`}
          comments={comments}
          commentable={{ id: qualityEntry.id, type: 'QualityEntry' }}
          Trigger={
            <IconButton className="relative" disabled={order.status === OrderStatus.GENERATING}
              aria-label="comment-quality-entry-button"
              label="Comment">
              <IconButtonIcon icon={CommentIcon}/>
              <div className="absolute -bottom-3.5 -right-2">
                <NumericIndicator brand="blue" size="xs">
                  {comments.length}
                </NumericIndicator>
              </div>
            </IconButton>
          }
        />
      </div>
    </div>
  );
};

interface QualityEntriesTableProps {
  qualityEntries: QualityEntry[];
  unit?: string | null;
  activeSpec: Spec | null;
}

export const QualityEntriesTable = ({
  qualityEntries,
  activeSpec,
}: QualityEntriesTableProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <ReponsiveTableContainer>
        <CardHeaderContainer className="py-4!">
          <div className="grid grid-cols-[1fr_1fr_1fr_50px]  gap-8">
            <div>
              <Helper>{t('entry')}</Helper>
            </div>
            <div>
              <Helper>{t('input')}</Helper>
            </div>
            <div>
              <Helper>{t('produce')}</Helper>
            </div>
          </div>
        </CardHeaderContainer>

        <div>
          <div className="divide-y divide-gray-200">
            <CardContent>
              {qualityEntries.length > 0 ? (
                qualityEntries.map((qualityEntry) => (
                  <QualityEntryRow
                    activeSpec={activeSpec}
                    qualityEntry={qualityEntry}
                    key={qualityEntry.id}
                  />
                ))
              ) : (
                <EmptyTableContainer placeholder={t('no_users_found')}/>
              )}
            </CardContent>
          </div>
        </div>
      </ReponsiveTableContainer>
    </Card>
  );
};
