import { BuildingStorefrontIcon } from '@heroicons/react/20/solid';
import { Link } from '@inertiajs/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  Card,
  CardBody,
  CardContent,
  CardFooter,
  CardHeaderContainer,
} from '@/Card';
import { LabeledField } from '@/LabeledField';
import { OrderStepLabel } from '@/Labels/OrderStepLabel';
import { Breadcrumbs, LayoutBody, LayoutHeader } from '@/Layout';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { Spinner } from '@/Spinner';
import { TableFooter } from '@/Table';
import { ReponsiveTableContainer } from '@/Table/TableContainer';
import TabFilter from '@/Tabs/TabFilter';
import {
  CardSubtitle,
  CardTitle,
  Helper,
  IdentifierText,
  MutedText,
  PageDescription,
  PageTitle,
  Text,
  TextLabel,
} from '@/Text';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import {
  Order,
  Organization,
  PaginatedItems,
  SellerAnalyticsType,
} from '~/types/types';
import { findIndex } from '~/utils/findIndex';
import { formatPicker } from '~/utils/formatPicker';

import { DashboardLayout } from '../DashboardLayout';
import { PerformanceScoreBar } from './Components/PerformanceScoreBar';

interface TimeSeriesEntry {
  [key: string]: number;
}

interface SuppliersPagePayload {
  supplier: Organization;
  comparedSuppliers: Organization[];
  /**
   * Time series data for the line chart, in the form of:
   * [
   *  {
   *   month: 'Jan',
   *   'supplier_1': 100,
   *  'supplier_2': 200,
   *  },
   * ]
   */
  timeSeriesData: TimeSeriesEntry[];
  ordersConnection: PaginatedItems<Order>;
  filterOptions: {
    suppliers: Organization[];
    dataTypes: SellerAnalyticsType[];
  };
}

export interface SuppliersPageProps {
  data: SuppliersPagePayload;
  parameters: {
    dataType: SellerAnalyticsType;
    suppliers: string[];
  };
}

const TooltipBody = ({
  payload,
  organizations,
}: {
  payload?: any;
  organizations: Organization[];
}) => {
  if (!payload.length || !(payload.length > 0)) {
    return null;
  }

  const contentPayload = payload[0].payload;
  const month = contentPayload?.month;
  // Convert Jan, Feb, Mar to January, February, March
  let monthName: string | undefined;
  try {
    monthName = new Date(month + ',' + ' 1, 2023').toLocaleString('default', {
      month: 'long',
    });
  } catch (e) {
    console.warn('Something went wrong trying to grab long month name: ', e);
    monthName = month;
  }

  return (
    <Card>
      <CardHeaderContainer>
        <TextLabel>{monthName}</TextLabel>
      </CardHeaderContainer>
      <CardBody>
        {payload.map((item: any) => {
          const organizationName =
            organizations.find((org) => org.id === item?.dataKey)?.title ||
            item?.dataKey;
          return (
            <div
              key={item.dataKey}
              className="flex items-center justify-between gap-2"
            >
              <div className="flex items-center">
                <div
                  className="w-2 h-2 mr-2 rounded-full"
                  style={{ backgroundColor: item.color }}
                />
                <MutedText>{organizationName}</MutedText>
              </div>
              <div className="flex items-center">
                <MutedText>{item.value.toFixed(2)}%</MutedText>
              </div>
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};

const LegendBody = ({
  payload,
  organizations,
}: {
  payload?: any;
  organizations: Organization[];
}) => {
  if (!payload.length || !(payload.length > 0)) {
    return null;
  }

  return (
    <div className="flex items-center justify-end gap-4">
      {payload.map((item: any) => {
        const organizationName =
          organizations.find((org) => org.id === item?.dataKey)?.title ||
          item?.dataKey;
        return (
          <div key={item.dataKey} className="flex items-center gap-1">
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: item.color }}
            ></div>
            <MutedText>{organizationName}</MutedText>
          </div>
        );
      })}
    </div>
  );
};

type ColorExtendedSupplier = Organization & { color: string };

const SuppliersPage = ({ data, parameters }: SuppliersPageProps) => {
  const { t } = useTranslation();
  const { reload, loading } = useAction();
  const { organization } = usePageProps();

  const dataTypes = data.filterOptions.dataTypes ?? [];

  /**
   * The index of the active tab
   */
  const [activeIdx, setActiveIdx] = useState(() => {
    return findIndex(dataTypes, parameters.dataType) ?? 0;
  });

  const ordersConnection = data.ordersConnection;
  const orders = data.ordersConnection.data;

  const comparedSuppliers = data.comparedSuppliers;
  const supplierOptions = data.filterOptions.suppliers;

  const timeSeriesData = data.timeSeriesData ?? [];

  const hasNoData =
    timeSeriesData.filter((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { month, ...rest } = item;
      return Object.values(rest).some((value) => value > 0);
    }).length === 0;

  /**
   * Set the active tab and reload the data
   */
  const reloadData = (idx: number) => {
    setActiveIdx(idx);

    reload({
      dataType: dataTypes[idx],
    });
  };

  const formatter = (number: number) => `${number.toFixed(2)}%`;

  return (
    <>
      <LayoutHeader>
        <Breadcrumbs
          backLink={{
            type: 'link',
            name: 'Suppliers',
            href: `/b/${organization.id}/suppliers`,
          }}
        />

        <div className="flex items-center justify-between">
          <div>
            <PageTitle>{data.supplier.title}</PageTitle>
            <PageDescription>
              <IdentifierText>{data.supplier.public_ref}</IdentifierText>
            </PageDescription>
          </div>

          {data.supplier.latest_sell_summary && (
            <div className="w-[150px]">
              <Helper>{t('performance_score')}</Helper>
              <PerformanceScoreBar
                sellerSummary={data.supplier.latest_sell_summary}
              />
            </div>
          )}
        </div>
      </LayoutHeader>

      <LayoutBody>
        <div className="space-y-12">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle>Quality</CardTitle>
                <CardSubtitle>
                  Explore the quality of this supplier
                </CardSubtitle>
              </div>
            </div>

            <Card>
              <CardHeaderContainer>
                <div className="flex items-center justify-between">
                  <LabeledField
                    label="Select data type"
                    renderValue={
                      <TabFilter
                        initialIndex={activeIdx}
                        items={dataTypes.map((type) => t(type as string)) as any}
                        onChange={(index) => {
                          reloadData(index);
                        }}
                      />
                    }
                  />
                </div>
              </CardHeaderContainer>
              <CardBody>
                <div className="h-[350px]">
                  {loading ? (
                    <div className="flex items-center justify-center h-full">
                      <Spinner className="w-12 h-12"/>
                    </div>
                  ) : (
                    <>
                      {hasNoData ? (
                        <div className="flex items-center justify-center h-full">
                          <Text>
                            No{' '}
                            {dataTypes?.[activeIdx]?.toLowerCase() || 'chart'}{' '}
                            data available
                          </Text>
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                            width: '100%',
                            paddingBottom: '350px',
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              bottom: 0,
                              top: 0,
                            }}
                          >
                            <ResponsiveContainer width="100%" height="100%">
                              <LineChart
                                height={300}
                                data={timeSeriesData}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 30,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis
                                  dataKey="month"
                                  tick={{ fontFamily: 'Inter', fontSize: 13 }}
                                  tickMargin={10}
                                />
                                <YAxis
                                  domain={[0, 100]}
                                  tick={{ fontFamily: 'Inter', fontSize: 13 }}
                                  tickFormatter={(value) => formatter(value)}
                                  tickMargin={10}
                                />
                                <Legend
                                  content={(props) => (
                                    <LegendBody
                                      organizations={comparedSuppliers}
                                      {...props}
                                    />
                                  )}
                                  verticalAlign="top"
                                  height={36}
                                />

                                <Tooltip
                                  content={(props) => (
                                    <TooltipBody
                                      organizations={comparedSuppliers}
                                      {...props}
                                    />
                                  )}
                                />

                                {comparedSuppliers.map((sup) => {
                                  return (
                                    <Line
                                      key={sup.id}
                                      type="monotone"
                                      dataKey={sup.id}
                                      stroke={
                                        (sup as ColorExtendedSupplier)?.color
                                      }
                                      activeDot={{ r: 8 }}
                                      dot={(props: any) => {
                                        return (
                                          <circle
                                            {...props}
                                            r={!props.value ? 0 : 5}
                                            aria-label={`Dot with x=${props.payload?.month}`}
                                          />
                                        );
                                      }}
                                    />
                                  );
                                })}
                              </LineChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
              <CardFooter>
                <div className="w-fit">
                  <GenericPicker
                    icon={BuildingStorefrontIcon}
                    options={formatPicker(supplierOptions)}
                    emptyMessage="No suppliers found"
                    isMulti
                    selected={data.comparedSuppliers.map(
                      (supplier) => supplier.id
                    )}
                    onChange={(supplierIds) => {
                      reload({
                        suppliers: supplierIds,
                      });
                    }}
                    placeholder="Compare suppliers"
                    title="Compare suppliers"
                  />
                </div>
              </CardFooter>
            </Card>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle>Order history</CardTitle>

                <CardSubtitle>
                  Explore the order history of this supplier
                </CardSubtitle>
              </div>
            </div>

            <Card>
              <ReponsiveTableContainer>
                <CardHeaderContainer className="py-4!">
                  <div className="grid grid-cols-4 gap-4">
                    <div>
                      <MutedText>Order PO</MutedText>
                    </div>
                    <div>
                      <MutedText>Produce</MutedText>
                    </div>
                    <div>
                      <MutedText>Status</MutedText>
                    </div>
                    <div>
                      <MutedText>Issues</MutedText>
                    </div>
                  </div>
                </CardHeaderContainer>
                <CardContent>
                  <div className="divide-y divide-gray-100">
                    {orders.map((order) => (
                      <div
                        key={order.id}
                        className="grid items-center grid-cols-4 gap-4 py-4"
                      >
                        <div>
                          <IdentifierText>
                            <span className="text-blue-500">
                              <Link
                                href={`/b/${organization.id}/orders/${order.id}`}
                              >
                                {order.public_ref}
                              </Link>
                            </span>
                          </IdentifierText>
                        </div>
                        <div>
                          <TextLabel>
                            {order.order_produce?.[0]?.produce?.name}
                          </TextLabel>
                          <MutedText className="block">
                            {order.order_produce?.[0]?.produce_variety?.name}
                          </MutedText>
                        </div>
                        <div>
                          <OrderStepLabel step={order.active_step}/>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </ReponsiveTableContainer>
              <CardFooter>
                <TableFooter paginatedItems={ordersConnection}/>
              </CardFooter>
            </Card>
          </div>
        </div>
      </LayoutBody>
    </>
  );
};

SuppliersPage.layout = (page: ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default SuppliersPage;
