import {
  CheckIcon,
  ErrorIcon,
  WarningIcon,
} from '@/Icon';
import { StatusMap } from '@/Labels/Status';
import DefectLevel = App.Domain.Quality.DefectLevel;

export const qualityInspectionsStatusMap: StatusMap<DefectLevel> = {
  'green': {
    icon: CheckIcon,
    textKey: 'fine',
    theme: 'green',
  },
  'risk': {
    icon: WarningIcon,
    textKey: 'risk',
    theme: 'yellow',
  },
  'na': {
    icon: CheckIcon,
    textKey: 'fine',
    theme: 'green',
  },
  'critical': {
    icon: ErrorIcon,
    textKey: 'critical',
    theme: 'red',
  },
};
