/* eslint-disable @typescript-eslint/no-explicit-any */
import { Errors } from '@inertiajs/inertia';
import { router } from '@inertiajs/react';
import { parse } from 'qs';
import { useCallback } from 'react';

import { deepMerge, formatData } from '~/hooks/Api/apiFormatter';
import { LocalStorageOptions } from '~/hooks/Api/localStorage';
import { usePageProps } from '~/hooks/usePageProps';
import { useApp } from '~/Providers/AppProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UseActionOptionsProps<D = any, Out = string> {
  onSuccess?: (data: any) => void;
  onFinish?: (data: D) => void;
  onError?: (errors?: Errors) => void;
  withParams?: boolean;
  storeLocalStorage?: boolean;
  preserveState?: boolean;
  preserveScroll?: boolean;
  only?: (keyof Out | string)[];
}

export const useAction = <D = any, Out = string>(
  url = '',
  {
    onSuccess,
    onError,
    withParams = true,
    preserveState = true,
    preserveScroll = true,
    only = [],
  }: UseActionOptionsProps<D, Out> = {}
) => {
  const {
    app: { path },
  } = usePageProps();

  const { loading, setLoading } = useApp();

  const manualPost = async (url: string, data: Partial<D>) => {
    const formattedData = formatData(data);
    return router.post(url, formattedData as any, {
      onStart: () => {
        setLoading(true);
      },
      onFinish: () => {
        setLoading(false);
      },
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
        setLoading(false);
      },
      onError: (error) => {
        onError && onError(error);
        setLoading(false);
      },
      preserveState,
      preserveScroll,
      only: only as any,
    });
  };

  const post = async (data: Partial<D>) => {
    const formattedData = formatData(data);
    return router.post(url, formattedData as any, {
      onStart: () => {
        setLoading(true);
      },
      onFinish: () => {
        setLoading(false);
      },
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
        setLoading(false);
      },
      onError: (error) => {
        onError && onError(error);
        setLoading(false);
      },
      preserveState,
      preserveScroll,
      only: only as any,
    });
  };

  const onlyKeys = only.join(',');

  const resetCurrentPage = useCallback((data = null) => {
    const realOnly = onlyKeys ? onlyKeys.split(',') : [];

    return router.reload({
      data: {
        ...data ?? {},
      },
      replace: true,
      preserveScroll: true,
      preserveState: true,
      only: realOnly,
      onStart: () => {
        setLoading(true);
      },
      onFinish: () => {
        setLoading(false);
      },
      onSuccess: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }, [onlyKeys, setLoading]);

  /**
   * Reloads the current page with new data.
   *
   * If `withParams` is true, it will also ensure that current search params stay in the URL.
   *
   * Note: This is a workaround for a bug in Inertia.js.
   * Link: https://github.com/inertiajs/inertia/issues/65#issuecomment-1682155744
   * The router. reload function is bugged (it only appends to arrays), so we need to `.get` the original path with new data.
   */
  const reload = (
    data: Partial<D>,
    localStorageOptions?: LocalStorageOptions,
    withParamsOverride?: boolean
  ) => {
    let paramsData = data;

    if (withParams) {
      if (
        typeof withParamsOverride === 'undefined' ||
        withParamsOverride?.toString() === 'true'
      ) {
        const params = parse(window.location.search, {
          ignoreQueryPrefix: true,
        });

        paramsData = deepMerge(params, paramsData);
      }
    }

    return router.get(
      `/${path}`,
      {
        ...paramsData as any,
      },
      {
        preserveScroll: true,
        onStart: () => {
          setLoading(true);
        },
        onFinish: () => {
          setLoading(false);
        },
        preserveState,
        only: only as any,
      },
    );
  };

  return {
    manualPost,
    post,
    reload,
    loading,
    resetCurrentPage,
  };
};
