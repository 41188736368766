import { ChevronDownIcon, HomeIcon } from '@heroicons/react/24/solid';
import { Link } from '@inertiajs/react';
import { DotFilledIcon } from '@radix-ui/react-icons';
import * as Menubar from '@radix-ui/react-menubar';

import { usePageProps } from '~/hooks/usePageProps';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';

import { Card } from './Card';

const ROOT_SECTIONS = [
  'Organization',
  'User',
] as const;

type RootSection = typeof ROOT_SECTIONS[number];

const ORGANIZATION_SUB_SECTIONS = [
  'About',
  'Users',
  'Produce',
] as const;

const USER_SUB_SECTIONS = [
  'About',
] as const;

export type NavItem<K> = {
  label: K;
  url: () => string;
}

type SettingsNavItem = NavItem<RootSection | SubSection>;

type SubSection = typeof ORGANIZATION_SUB_SECTIONS[number] | typeof USER_SUB_SECTIONS[number];

const handleRootItemSelect = (item: string) => {
  return item.includes('settings/organization/') ? ROOT_SECTIONS[0] : ROOT_SECTIONS[1];
};

const handleSubItemSelect = (url: string, items: NavItem<SubSection | RootSection>[]) => {
  const urls = items.map((item) => item.url());
  const index = urls.findIndex((route) => url.includes(route));
  return items[index]?.label ?? items[0].label;
};

export const BreadcrumbsSettings = () => {
  const { url } = usePageProps();
  const routes = useRoutes<BuyerRoutes>();
  const radioSelection = handleRootItemSelect(url);
  const rootNavItems: SettingsNavItem[] = [
    {
      label: 'Organization',
      url: () => routes.generalOrganizationSettings(),
    },
    {
      label: 'User',
      url: () => routes.userSettings(),
    },
  ];

  const subNavItems: SettingsNavItem[] = radioSelection === 'User' ? [
    {
      label: 'About',
      url: () => routes.settings(),
    },
  ] : [
    {
      label: 'About',
      url: () => routes.generalOrganizationSettings(),
    },
    {
      label: 'Users',
      url: () => routes.usersOrganizationSettings(),
    },
    {
      label: 'Produce',
      url: () => routes.produceSettings(),
    },
  ];

  const subRadioSelection = handleSubItemSelect(url, subNavItems);

  return (
    <div className='overflow-x-auto'>
      <Menubar.Root className="flex gap-1 w-full items-center overflow-x-auto bg-white p-[3px] rounded-md">
        <Menubar.Menu>
          <Menubar.Trigger>
            <Link
              href={routes.default()}
              className="flex items-center text-xs font-normal text-gray-500 hover:text-gray-700"
            >
              <HomeIcon className="inline-block w-3 h-3 mr-2 shrink-0" />
              <span className='whitespace-nowrap'>
            Back to overview
              </span>
            </Link>
          </Menubar.Trigger>
        </Menubar.Menu>

        <span className='text-xs font-normal text-gray-500'><DotFilledIcon/></span>
        <Menubar.Menu>
          <Menubar.Trigger className="text-gray-500 hover:text-gray-700 outline-hidden select-none rounded flex items-center justify-between gap-[2px]">
            <span className='whitespace-nowrap text-xs font-normal'>{radioSelection}</span>
            <ChevronDownIcon className='inline-block w-3 h-3 ml-1' />
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content
              className="min-w-[100px] [animation-duration:_400ms] [animation-timing-function:_cubic-bezier(0.16,_1,_0.3,_1)] will-change-[transform,opacity]"
              align="start"
              collisionBoundary={[
                document.getElementById('settings-container'),
              ]}
              // style={{ width: 'var(--radix-menubar-content-available-width)' }}
              sideOffset={5}
              alignOffset={-3}
            >
              <Card className='rounded-xs'>
                <Menubar.RadioGroup value={radioSelection}>
                  {rootNavItems.map((item) => (
                    <Link key={item.label} href={item.url()}>
                      <Menubar.RadioItem
                        className={` ${radioSelection === item.label ? 'text-gray-700' : 'text-gray-500'} text-xs rounded flex items-center h-[25px] px-[10px] relative select-none pl-5 outline-hidden data-highlighted:bg-linear-to-br data-highlighted:from-indigo-500 data-highlighted:to-indigo-600 data-highlighted:text-indigo-50 data-disabled:text-mauve8 data-disabled:pointer-events-none`}
                        key={item.label}
                        value={item.label}
                      >
                        <Menubar.ItemIndicator className="absolute left-0 w-5 inline-flex items-center justify-center">
                          <DotFilledIcon />
                        </Menubar.ItemIndicator>
                        {item.label}
                      </Menubar.RadioItem>
                    </Link>

                  ))}
                </Menubar.RadioGroup>
              </Card>

            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
        <span className='text-xs font-normal text-gray-500'><DotFilledIcon/></span>

        <Menubar.Menu>
          <Menubar.Trigger disabled={!subNavItems || subNavItems.length <= 1} className="text-gray-500 hover:text-gray-700 outline-hidden select-none rounded flex items-center justify-between gap-[2px]">
            <span className='whitespace-nowrap text-xs font-normal'>
              {subRadioSelection}
            </span>
            {subNavItems.length > 1 && (
              <ChevronDownIcon className='inline-block w-3 h-3 ml-1' />
            )}
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content
              className="min-w-[100px] [animation-duration:_400ms] [animation-timing-function:_cubic-bezier(0.16,_1,_0.3,_1)] will-change-[transform,opacity]"
              align="start"
              collisionBoundary={[
                document.getElementById('settings-container'),
              ]}
              // style={{ width: 'var(--radix-menubar-content-available-width)' }}
              sideOffset={5}
              alignOffset={-3}
            >
              <Card className='rounded-xs'>
                <Menubar.RadioGroup value={subRadioSelection}>
                  {subNavItems.map((item) => (
                    <Link key={item.label} href={item.url()}>
                      <Menubar.RadioItem
                        className={` ${subRadioSelection === item.label ? 'text-gray-700' : 'text-gray-500'} text-xs rounded flex items-center h-[25px] px-[10px] relative select-none pl-5 outline-hidden data-highlighted:bg-linear-to-br data-highlighted:from-indigo-500 data-highlighted:to-indigo-600 data-highlighted:text-indigo-50 data-disabled:text-mauve8 data-disabled:pointer-events-none`}
                        key={item.label}
                        value={item.label}
                      >
                        <Menubar.ItemIndicator className="absolute left-0 w-5 inline-flex items-center justify-center">
                          <DotFilledIcon />
                        </Menubar.ItemIndicator>
                        {item.label}
                      </Menubar.RadioItem>
                    </Link>
                  ))}
                </Menubar.RadioGroup>
              </Card>
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>

      </Menubar.Root>
    </div>
  );
};
