import { BoxIcon, CheckIcon, FilterIcon, PlusIcon, SearchIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardGutter, CardHeaderContainer, CardPanels, SimpleCardHeader } from '@/Card';
import { ListDetail } from '@/Details';
import { PlaceholderBox } from '@/Fallback';
import {
  BuyerQualityIcon,
  PlaceholderIcon,
  ReportingAccuracyIcon,
  SimpleCardHeaderIcon,
  WarningIcon,
} from '@/Icon';
import { MeasurementFieldIcon, SellerIcon } from '@/Icon';
import { Label } from '@/Label';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { DescriptionText, Helper, MutedText, SimpleCardTitle, Strong } from '@/Text';
import { DistributionDisplay } from '~/Components/Analytics/DistributionDisplay';
import { Grouper } from '~/Components/Common/Grouper';
import Section from '~/Components/Common/Section';
import { AccuracyIndicator } from '~/Components/Indicators/AccuracyIndicator';
import { useQuery } from '~/hooks/useQuery';
import { AnalyticsConfigRequest, ReportingAccuracyResults } from '~/types/types';

import { useAnalytics } from '../../AnalyticsProvider';
import { formatPicker } from '~/utils/formatPicker';

interface ReportingAccuracyModuleProps {
  module?: {
    results: ReportingAccuracyResults;
  };
}

export const ReportingAccuracyModuleSection = ({ module }: ReportingAccuracyModuleProps) => {
  const { t } = useTranslation();
  const results = module?.results;
  const { filterOptions } = useAnalytics();

  const { isLoading, parameters, setParameter } = useQuery<AnalyticsConfigRequest>();

  const numberOfEntries = results?.qualityEntries || 0;
  const threshold = 60;
  const diff = 0;

  const hasSelectedItems = module.results.state !== 'select_items';

  return (
    <Section
      icon={ReportingAccuracyIcon}
      showCollapsed
      title={t('reporting_accuracy')}
      description={t('reporting_accuracy_description')}
      enableToggle>
      <div aria-label="card for reporting accuracy">
        {diff > 0 && (
          <PlaceholderBox
            icon={<PlaceholderIcon icon={BoxIcon}/>}
            title="Awaiting more inspection samples"
            description={`To activate reporting features, we require ${diff} additional measurements (currently ${numberOfEntries} of ${threshold} needed). This sampling threshold ensures our statistical analysis is reliable and that quality trends are based on sufficient data points across your inspections.`}
          />
        )}

        {diff <= 0 && results && (
          <Card>
            <SimpleCardHeader>
              <SimpleCardHeaderIcon icon={ReportingAccuracyIcon}/>
              <SimpleCardTitle>
                {t('reporting_accuracy')}
              </SimpleCardTitle>
            </SimpleCardHeader>

            <CardHeaderContainer>
              <Strong>
                {t('buyer_seller_accuracy_breakdown')}
              </Strong>
              <DescriptionText>
                {t('buyer_seller_accuracy_breakdown_description')}
              </DescriptionText>
            </CardHeaderContainer>

            <CardGutter align="end">
              <div className="flex space-x-4">
                <div>
                  <GenericPicker
                    showTitleOnSelected
                    icon={SellerIcon}
                    title="Seller"
                    options={formatPicker(filterOptions?.sellerOptions)}
                    emptyMessage=""
                    placeholder="Select a seller"
                    value={parameters.reporting_seller_id}
                    onChange={(value) => setParameter('reporting_seller_id', value)}
                  />
                </div>
              </div>
            </CardGutter>

            <CardGutter align="start" variant="darker">
              <div className="flex justify-between w-full">
                <div className="w-full grid grid-cols-[1fr_2fr]">
                  <div>
                    <Helper>
                      Field
                    </Helper>
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <Helper>
                      Organization
                    </Helper>

                    <Helper>
                      Breakdown
                    </Helper>
                  </div>
                </div>
              </div>
            </CardGutter>

            {!hasSelectedItems && (
              <PlaceholderBox
                title="No sellers selected"
                description="Select sellers to examine reporting accuracy"
                icon={<PlaceholderIcon icon={SearchIcon}/>}
              >
                <GenericPicker
                  showTitleOnSelected
                  icon={SellerIcon}
                  title="Seller"
                  options={formatPicker(filterOptions?.sellerOptions)}
                  emptyMessage=""
                  placeholder="Select a seller"
                  value={parameters.reporting_seller_id}
                  onChange={(value) => setParameter('reporting_seller_id', value)}
                />
              </PlaceholderBox>
            )}

            {hasSelectedItems && (
              <div>
                <div>
                  <Grouper
                    sticky
                    emptyPlaceholders={{
                      no_valid_data: {
                        text: 'No valid data',
                        description: 'There is not sufficient enough overlap between buyer and seller data',
                        icon: FilterIcon,
                      },
                      empty: {
                        text: 'No items available',
                        description: 'Add some items to get started',
                        icon: PlusIcon,
                      },
                    }}
                    emptyKey={results.state}
                    emptyPlaceholder={{
                      text: 'No items found',
                      description: 'Try adjusting your filters',
                      icon: SearchIcon,
                    }}
                    data={results.items}
                    groupBy={(item => [item.overallScore, item.variety?.name])}
                    config={{
                      'green': {
                        icon: CheckIcon,
                        labelKey: 'Field with no issues',
                        theme: 'emerald',
                      },
                      'yellow': {
                        icon: WarningIcon,
                        theme: 'amber',
                        labelKey: 'Requires attention',
                        priority: 2,
                      },
                    }}
                    spacing={false}
                  >
                    {(item, index) => (
                      <CardPanels columns="1_2" key={index}>
                        <CardBody bordered="all" variant="muted">
                          <div>
                            <Strong>
                              {item.field.name}
                            </Strong>

                            <div>
                              <ListDetail
                                value={item.averageAccuracyScore}
                                dtype='render'
                                label={t('accuracy')}
                                renderFn={(value) => (
                                  <div className="flex items-center gap-2">
                                    <div className="w-4 max-w-x5">
                                      <AccuracyIndicator score={item.averageAccuracyScore}/>
                                    </div>
                                    <MutedText>
                                      {value.toFixed(2)} %
                                    </MutedText>
                                  </div>
                                )}
                              />
                            </div>

                            {item.field.criticalLevel && (
                              <Label theme="red" className="ml-2">
                                {t(item.field.criticalLevel)}
                              </Label>
                            )}
                          </div>
                        </CardBody>
                        <div className="px-4 py-2 border-b divide-y divide-gray-100">
                          <DistributionDisplay
                            distribution={item.buyerDistribution}
                            title={module.results.buyerName}
                            icon={BuyerQualityIcon}
                            label={t('buyer')}
                          />

                          <DistributionDisplay
                            distribution={item.sellerDistribution}
                            title={module.results.sellerName}
                            icon={SellerIcon}
                            label={t('seller')}
                          />
                        </div>
                      </CardPanels>
                    )}
                  </Grouper>
                </div>
              </div>
            )}
          </Card>
        )}
      </div>
    </Section>
  );
};
