import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { cn } from '~/utils/cn';
import { Theme } from '~/utils/colors';

interface SegmentedCircleProgressProps {
  segments: {
    value: number;
    color: string;
    theme?: Theme;
    label?: string;
  }[];
  thickness?: 'thin' | 'normal' | 'thick';
  showLabels?: boolean;
  elevation?: 'none' | 'sm' | 'md' | 'lg';
  className?: string; // Added to allow custom classes from parent
  children?: ReactNode;
}

const thicknessVariants = {
  thin: 2,
  normal: 4,
  thick: 8,
};

const elevationVariants = {
  none: '',
  sm: 'shadow-[0_2px_8px_rgb(0,0,0,0.08)]',
  md: 'shadow-[0_4px_12px_rgb(0,0,0,0.12)]',
  lg: 'shadow-[0_8px_16px_rgb(0,0,0,0.16)]',
};

export const SegmentedCircleProgress = ({
  segments,
  thickness = 'normal',
  elevation = 'sm',
  showLabels = false,
  className,
  children,
}: SegmentedCircleProgressProps) => {
  // Using viewBox to create a scalable coordinate system
  const viewBoxSize = 100;
  const radius = viewBoxSize * 0.45; // 45% of viewBox size
  const circumference = 2 * Math.PI * radius;
  const totalValue = segments.reduce((sum, segment) => sum + segment.value, 0);
  let currentOffset = 0;

  return (
    <div className="flex flex-col items-center">
      <div
        className={cn(
          'relative rounded-full p-1 aspect-square', // aspect-square ensures perfect circle
          elevationVariants[elevation],
          className
        )}
      >
        <svg
          className="w-full h-full -rotate-90"
          viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
          preserveAspectRatio="xMidYMid meet"
        >
          {/* Background circle */}
          <circle
            cx={viewBoxSize / 2}
            cy={viewBoxSize / 2}
            r={radius}
            className="fill-none stroke-zinc-100 dark:stroke-zinc-800"
            strokeWidth={thicknessVariants[thickness]}
          />

          {/* Animated segments */}
          {segments.map((segment, index) => {
            const percentage = (segment.value / totalValue) * 100;
            const segmentLength = (circumference * percentage) / 100;
            const dashOffset = circumference - segmentLength;
            const startOffset = currentOffset;
            currentOffset += segmentLength;

            return (
              <motion.circle
                key={index}
                cx={viewBoxSize / 2}
                cy={viewBoxSize / 2}
                r={radius}
                className="fill-none"
                stroke={segment.color}
                strokeWidth={thicknessVariants[thickness] + 2}
                strokeDasharray={circumference}
                strokeLinecap="round"
                initial={{ strokeDashoffset: circumference }}
                animate={{
                  strokeDashoffset: dashOffset,
                  rotate: startOffset / circumference * 360,
                }}
                transition={{
                  duration: 1,
                  ease: 'easeInOut',
                }}
                style={{
                  transformOrigin: 'center',
                  transform: `rotate(${startOffset / circumference * 360}deg)`,
                }}
              />
            );
          })}
        </svg>

        {/* Center content */}
        {children && (
          <div className="absolute inset-0 flex items-center justify-center">
            {children}
          </div>
        )}
      </div>

      {/* Labels */}
      {showLabels && (
        <div className="mt-4 flex flex-wrap gap-4 justify-center">
          {segments.map((segment, index) => (
            <div key={index} className="flex items-center space-x-2">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: segment.color }}
              />
              <span className="text-sm text-zinc-600 dark:text-zinc-400">
                {segment.label || `Segment ${index + 1}`}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
