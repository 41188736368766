import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Card, CardBody, CardFooter, CardHeaderContainer } from '@/Card';
import { Tabby, Tabs } from '@/Tabbar';
import { CardSubtitle, CardTitle, MutedText, Text, TextLabel } from '@/Text';
import { colorMap } from '~/Components/Analytics/colorMap';
import { CountryName } from '~/Components/Locations/CountryName';
import { useAction } from '~/hooks/useAction';
import { Order, Produce } from '~/types/types';

export interface YearlyRankCardProps {
  produce?: Produce;
}

const generateData = () => {
  const data = [];
  const countries = ['PE', 'CL', 'ZA', 'ES', 'KE'];
  const baseValue = 100;

  for (let i = 0; i < 12; i++) {
    const monthData = {
      name: new Date(2024, i, 1).toLocaleString('default', { month: 'short' }),
    };
    const shuffledCountries = countries.sort(() => Math.random() - 0.5);

    shuffledCountries.forEach((country, index) => {
      monthData[country] = Math.round(
        (baseValue / countries.length) * (Math.random() + index)
      );
    });

    data.push(monthData);
  }

  return data;
};

type Payload = Order;
type TooltipBodyProps = TooltipProps<Payload>;

const TooltipBody = ({ payload, label }: TooltipBodyProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeaderContainer>
        <TextLabel>{label}</TextLabel>
      </CardHeaderContainer>
      <CardBody>
        <div>
          <TextLabel>{t('issues')}</TextLabel>
        </div>

        <hr />
        <div className="divide-y">
          {payload?.map((entry, index) => (
            <div
              key={`item-${index}`}
              className="flex items-center justify-between py-1 space-x-4"
              aria-label={`Issue for ${entry.dataKey.replace('_issues_count', '')} has ${entry.value} issues in ${label}`}
            >
              <div className="flex items-center">
                <div
                  className="w-3 h-3 mr-2 rounded-full"
                  style={{ backgroundColor: entry.fill }}
                />
                <Text>{t(`quality_report_labels.${entry.dataKey}`)}</Text>
              </div>
              <div className="flex items-center">
                <MutedText>{entry.value}</MutedText>
              </div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

const data = generateData();

/**
 * Component for the annual rank card.
 */
export const YearlyRankCard = ({ produce }: YearlyRankCardProps) => {
  const { t } = useTranslation();
  const { reload } = useAction();

  // Find the cumulative max for each month
  const yMax = data.reduce((acc, month) => {
    const monthMax = Object.keys(month)
      .filter((key) => key !== 'name')
      .reduce((acc, key) => {
        return acc + month[key];
      }, 0);

    return Math.max(acc, monthMax);
  }, 0);

  const names = Object.keys(data[0]).filter((key) => key !== 'name');

  return (
    <Card className="shadow-lg!">
      <CardHeaderContainer>
        <div className="flex items-center justify-between space-x-2">
          <div>
            <CardTitle>Annual action plan for Avocados</CardTitle>
            <CardSubtitle>
              Volume distribution by region for the year 2024
            </CardSubtitle>
          </div>
        </div>

        <Tabs inCardHeader>
          <Tabby tab={{ name: 'Hass', current: true, count: 2 }} />
        </Tabs>
      </CardHeaderContainer>

      <CardBody size="sm" className="h-[600px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            {/* Y axis */}
            <YAxis domain={[0, Math.round(yMax * 1.3)]} />
            <Tooltip />
            {names.map((key, index) => (
              <Bar
                key={index}
                dataKey={key}
                stackId="a"
                fill={colorMap[index]}
                radius={
                  index === 0
                    ? [0, 0, 10, 10]
                    : index === names.length - 1
                      ? [10, 10, 0, 0]
                      : 0
                }
                style={
                  {
                    // filter: `drop-shadow(0px 1px 1px ${colorMap[index]}`,
                  }
                }
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </CardBody>

      <CardFooter>
        <div className="flex space-x-4">
          {names.map((key, index) => (
            <div key={key} className="flex items-center">
              <div
                className="w-3 h-3 mr-2"
                style={{ backgroundColor: colorMap[index] }}
              />
              <TextLabel>
                <CountryName countryCode={key.toUpperCase()} />
              </TextLabel>
            </div>
          ))}
        </div>
      </CardFooter>
    </Card>
  );
};
