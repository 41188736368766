import * as SwitchPrimitive from '@radix-ui/react-switch';

import { cn } from '~/utils/cn';

interface SwitchProps extends SwitchPrimitive.SwitchProps {
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?: 'emerald' | 'blue' | 'indigo' | 'red';
}

const sizeVariants = {
  xs: 'w-[28px] h-[15px]',
  sm: 'w-[35px] h-[20px]',
  md: 'w-[42px] h-[25px]',
  lg: 'w-[50px] h-[30px]',
};

const thumbSizeVariants = {
  xs: 'w-[10px] h-[10px] translate-x-0.5 data-[state=checked]:translate-x-[16px]',
  sm: 'w-[17px] h-[17px] translate-x-0.5 data-[state=checked]:translate-x-[17px]',
  md: 'w-[21px] h-[21px] translate-x-0.5 data-[state=checked]:translate-x-[19px]',
  lg: 'w-[25px] h-[25px] translate-x-0.5 data-[state=checked]:translate-x-[20px]',
};

const colorVariants = {
  emerald: 'data-[state=checked]:bg-green-500',
  blue: 'data-[state=checked]:bg-blue-500',
  indigo: 'data-[state=checked]:bg-indigo-700',
  red: 'data-[state=checked]:bg-red-500',
};

const Switch = ({ onCheckedChange, checked, size = 'md', color = 'indigo', ...props }: SwitchProps) => (
  <SwitchPrimitive.Root
    {...props}
    checked={checked}
    role="switch"
    onCheckedChange={onCheckedChange}
    className={cn(
      'bg-gray-300 rounded-full relative shadow-md outline-hidden cursor-default',
      sizeVariants[size],
      colorVariants[color]
    )}
    style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
  >
    <SwitchPrimitive.Thumb
      className={cn(
        'block bg-white rounded-full transition-transform duration-100 will-change-transform',
        thumbSizeVariants[size]
      )}
    />
  </SwitchPrimitive.Root>
);

export default Switch;
