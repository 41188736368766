import { Link } from '@inertiajs/react';
import { ReactNode } from 'react';

import { Container } from '@/Container';
import { UserMenu, UserMenuAvatar } from '@/Nav/UserMenu';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';
import { AppProvider } from '~/Providers/AppProvider';

interface AdminLayoutProps {
  children: ReactNode;
}

export const AdminLayout = ({ children }: AdminLayoutProps) => {
  const routes = useRoutes();
  const { user } = usePageProps();

  return (
    <AppProvider>

      <div className="min-h-screen flex flex-col bg-gray-50 *:w-full">
        <div className="bg-gray-100 border-b border-gray-200 shadow-xs">
          <Container>
            <div className="flex items-center justify-between py-4">
              <Link
                href={'/b/admin'}
              >
                <img className="w-12 h-12" src="/assets/logo.svg" alt="AgStacked"/>
              </Link>
              <div>
                <UserMenu logoutRoute={routes.logout()}>
                  <UserMenuAvatar user={user}/>
                </UserMenu>
              </div>
            </div>
          </Container>
        </div>
        <div>
          {children}
        </div>
      </div>
    </AppProvider>
  );
};
