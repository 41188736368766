import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { router } from '@inertiajs/react';
import { ReactNode } from 'react';

import { AdminOnly } from '@/AdminOnly';
import { Alert, AlertDescription, AlertTitle } from '@/Alert';
import { Container } from '@/Container';
import { WithGenericFallback } from '@/Fallback';
import { PredictionCard } from '~/Components/ML/Predictions/PredictionCard';
import { GenericDetailsPane } from '~/Components/Panes/Details/GenericDetailsPane';
import { ViewSpecModal } from '~/Components/Spec/ViewSpecModal';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { useToast } from '~/hooks/useToast';
import { PageProps, Spec, VerifySpecFileOutput } from '~/types/types';
import { cn } from '~/utils/cn';

import { DashboardLayout } from '../DashboardLayout';
import { HarvestSection } from './Harvest/HarvestSection';
import InspectUnitModal from './Inspections/InspectUnitModal';
import { OrderInspectionsSection } from './Inspections/OrderInspectionsSection';
import { OrderLogisticsSection } from './Logistics/OrderLogisticsSection';
import { OrderDetailsSection } from './OrderDetails/OrderDetailsSection';
import { OrderEventsLog } from './OrderEventsLog';
import { OrderHeader } from './OrderHeader';
import { OrderProvider } from './OrderProvider';
import { OrderPayload } from './types';

export interface OrderParameters {
  activeUnitId?: string;
  activeFieldId?: string;
  activeStepId?: string;
}

export interface OrderPageProps extends PageProps {
  data: OrderPayload;
  isAdmin: boolean;
  active: {
    spec: Spec;
  };
  all_issues: any;
  activeFieldSummary?: string;
  parameters: {
    activeUnitId?: string;
    activeFieldId?: string;
    activeStepId?: string;
  };
  flash: {
    spec: {
      verifyTempSpecResults: VerifySpecFileOutput;
    };
  };
}

const OrderPage = ({ data, active, all_issues, parameters }: OrderPageProps) => {
  const order = data.order;
  const activeUnit = data.active_unit;
  const comments = data.groupedComments;

  const { features } = usePageProps();

  const { loading, resetCurrentPage: reloadIssues } = useAction('', {
    preserveScroll: true,
    preserveState: true,
    only: ['all_issues'],
  });

  const handleCloseActiveUnit = () => {
    router.reload({ data: { activeUnitId: null } });
  };

  const handleCloseActiveSpec = () => {
    router.reload({ data: { activeSpecId: null }, only: ['active'] });
  };

  const events = order.events ?? [];

  const allIssues = all_issues ?? [];

  const info = {
    issues: { allIssues: allIssues },
    issuesCount: allIssues.length,
  };

  return (
    <OrderProvider orderData={data}>
      {active.spec && (
        <ViewSpecModal
          spec={active.spec}
          isOpen={!!active.spec}
          onClose={handleCloseActiveSpec}
        />
      )}
      {activeUnit && (
        <InspectUnitModal
          unit={activeUnit}
          isOpen={!!activeUnit}
          onClose={handleCloseActiveUnit}
        />
      )}
      <div>
        <OrderHeader
          integration={data.integration}
          dataStreams={data.dataStreams}
          order={order}
          groupedComments={comments}
        />

        <Container>
          {order.cancelled && (
            <div className="py-8">
              <Alert variant="warning">
                <AlertTitle className="text-sm">
                  Order has been cancelled
                </AlertTitle>

                <AlertDescription className="text-xs">
                  This order has been cancelled and is no longer active.
                </AlertDescription>
              </Alert>
            </div>
          )}
        </Container>

        <div className={cn('py-4', order.cancelled && 'opacity-40')}>
          <Container>
            <div>
              <AdminOnly>
                <WithGenericFallback>
                  <GenericDetailsPane
                    object={info}
                    details={[
                      { title: 'Number issues', dtype: 'number', key: 'issuesCount' },
                      { title: 'issues', dtype: 'json', key: 'issues' },
                    ]}
                    container={document.body}
                    loading={loading}
                    fetchData={() => reloadIssues()}
                  >
                    <div className="fixed left-4 bottom-24">
                      <button
                        type="button"
                        className="flex items-center justify-center w-12 h-12 text-white bg-red-500 rounded-full focus:outline-hidden"
                      >
                        <ExclamationCircleIcon className="h-7 w-7"/>
                      </button>
                    </div>
                  </GenericDetailsPane>
                </WithGenericFallback>
              </AdminOnly>
              <div className="divide-y divide-gray-200">
                <div>
                  {features['access-dashboard']?.[0] && data.metaData && data.riskPrediction && (
                    <div>
                      <PredictionCard prediction={data.riskPrediction} supportedFields={[]}/>
                    </div>
                  )}
                </div>
                <OrderDetailsSection
                  activeStep={data.step}
                  integration={data.integration}
                  order={order}
                />
                <OrderInspectionsSection
                  activeFieldId={parameters?.activeFieldId}
                  attachments={data.attachments}
                  orderFieldSummaries={data.orderFieldSummaries}
                  activeSpec={data.active_spec}
                />
                <OrderLogisticsSection dataStreams={data.dataStreams}/>
                <HarvestSection dataStreams={data.dataStreams}/>

                <AdminOnly>
                  <OrderEventsLog
                    events={events}
                    order={order}
                    buyer={order.buyer as any ?? undefined}
                    seller={order.seller as any ?? undefined}
                  />
                </AdminOnly>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </OrderProvider>
  );
};

OrderPage.layout = (page: ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default OrderPage;
