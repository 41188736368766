import { ErrorBoundary } from '@sentry/react';
import { CircleAlert } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import { ReactNode, useEffect } from 'react';

import { SimpleIcon } from '@/Icon';
import { makeSellerTopLinks } from '@/Nav/nav-items';
import { MainNavItem, Navbar } from '@/Nav/Navbar';
import { Helper } from '@/Text';
import { DebugProvider, LocalDebugModal } from '~/Components/Common/LocalDebugModal';
import { FAB } from '~/Components/FAB';
import { ServerErrorProvider } from '~/hooks/ServerErrorProvider';
import { usePageProps } from '~/hooks/usePageProps';
import { Toaster } from '~/hooks/useToast';
import { AppProvider } from '~/Providers/AppProvider.js';
import { OrganizationStatus } from '~/types/types';

import { GeneratingModal } from '../Dashboard/GeneratingModal.tsx';

interface SellerLayoutProps {
  children: ReactNode;
}

export const SellerLayout = ({ children }: SellerLayoutProps) => {
  const posthog = usePostHog();
  const { organization, user, ...props } = usePageProps();

  const topLinks = makeSellerTopLinks(organization.id);

  const organizationMapsTo = organization?.maps_to?.[0] ?? null;

  useEffect(() => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }
    posthog.identify(user.id);
  }, [posthog]);

  return (
    <AppProvider>
      <div className="min-h-screen flex flex-col bg-gray-50 *:w-full">
        <DebugProvider pageProps={props}>
          <ServerErrorProvider>
            <LocalDebugModal/>

            <Navbar
              user={user}
              organization={organization}
              renderNavLinks={(
                <div className="flex gap-3">
                  {topLinks.map((link) => (
                    <MainNavItem key={link.name} link={link}/>
                  ))}
                </div>
              )}
            />
            <Toaster/>
            <FAB/>
            {/* <OnboardingModal user={user} organization={organization} show={show_onboard_modal ?? false} /> */}
            {organization.status === OrganizationStatus.GENERATING && (
              <GeneratingModal/>
            )}

            {organizationMapsTo && user.superadmin && (
              <div className="fixed bottom-0 left-0 right-0 z-20 w-full h-10 px-2 py-2 bg-gray-800 border-t">
                <SimpleIcon icon={CircleAlert} className="mr-2"/>

                <Helper className="text-gray-100!">
                  This organization is mapped to "{organizationMapsTo.toable.title}". All PO data will be sent there
                  instead.
                </Helper>
              </div>
            )}

            {organization.status === OrganizationStatus.GENERATING ? (
              <ErrorBoundary onError={() => {
                console.log('ignore');
              }} fallback={(
                <div>
                  Generating new data
                </div>
              )}>
                {children}
              </ErrorBoundary>
            ) : (
              <>
                {children}
              </>
            )}
          </ServerErrorProvider>
        </DebugProvider>
      </div>
    </AppProvider>
  );
};
