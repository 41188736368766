import React, { useState } from 'react';

import { Alert, AlertDescription, AlertTitle, WarningBadge } from '@/Alert';
import { IconButton } from '@/Button';
import { CloseIcon, ConstraintIcon, Icon, IconButtonIcon } from '@/Icon';
import { CenterAddon, Input, InputGroup, RightAddon } from '@/Input';
import { FieldType, Range } from '~/types/types';
import { cn } from '~/utils/cn';

// 1. RangeInput component
interface RangeInputProps {
  value: number | null;
  onChange: (value: string) => void;
  onFocus: () => void;
  placeholder: string;
  isPercentage: boolean;
  rounded?: 'left' | 'right' | 'both';
}

export const RangeInput: React.FC<RangeInputProps> = ({ value, onChange, onFocus, placeholder, rounded }) => {
  return (
    <InputGroup>
      <Input
        type="number"
        value={value as any}
        onChange={(e: any) => onChange(e.target.value)}
        onFocus={onFocus}
        placeholder={placeholder}
        rounded={rounded as any}
        className={cn(
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
          'w-20! pr-6',
          'sm:text-sm sm:leading-6 bg-transparent py-1.5 text-gray-600',
          'border-1 border-gray-100',
          'relative'
        )}
      />
    </InputGroup>
  );
};

// 2. RangeRow component
interface RangeRowProps {
  range: Range;
  isRAG: boolean;
  isPercentage: boolean;
  unit?: string;
  onChangeMin: (value: string) => void;
  onChangeMax: (value: string) => void;
  onFocusMin: () => void;
  onFocusMax: () => void;
  isProblematic: boolean;
  onRemove?: () => void;
  type?: 'rag' | 'range' | 'ratio';
  numeratorLabel?: string;
  denominatorLabel?: string;
}

export const formatRangeLabel = (range: Range, isPercentage: boolean) => {
  const min = range.min ?? 0;
  const max = range.max ?? 0;

  const type = isPercentage ? 'rag' : 'measurement';

  if (isPercentage && range.score) {
    return `label for ${type} and score ${range.score} between ${min} and ${max}`;
  }

  return `label for ${type} between ${min} and ${max}`;
};

export const RangeRow = ({
  range,
  isRAG,
  isPercentage,
  unit,
  onChangeMin,
  onChangeMax,
  onFocusMin,
  onFocusMax,
  isProblematic,
  onRemove,
  type = 'range',
  numeratorLabel = '',
  denominatorLabel = '',
}: RangeRowProps) => {
  if (type === 'ratio') {
    return (
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500">Target Ratio:</span>
          <div className="flex items-center" aria-label={formatRangeLabel(range, false)}>
            <RangeInput
              rounded="left"
              value={range.min}
              onChange={onChangeMin}
              onFocus={onFocusMin}
              placeholder="Min"
              isPercentage={false}
            />
            <RightAddon className="px-2! text-sm text-gray-500">
              {numeratorLabel}
            </RightAddon>
            <CenterAddon>
              <span className="text-sm font-medium">:</span>
            </CenterAddon>
            <RangeInput
              value={range.max}
              onChange={onChangeMax}
              rounded="right"
              onFocus={onFocusMax}
              placeholder="Max"
              isPercentage={false}
            />
            <RightAddon className="px-2! text-sm text-gray-500">
              {denominatorLabel}
            </RightAddon>
            {isRAG && onRemove && (
              <IconButton label="Remove range" onClick={onRemove} className="ml-2">
                <IconButtonIcon icon={CloseIcon}/>
              </IconButton>
            )}
          </div>
        </div>
        <div className="pl-4 text-sm text-gray-500">
          Acceptable range: {range.min}:{range.max} ± 0.2
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center" aria-label={formatRangeLabel(range, isPercentage)}>
      {isRAG && (
        <div className="min-w-0 w-16 flex items-center justify-center">
          <div className={`w-4 h-4 rounded-full bg-${range.score}-400`}></div>
        </div>
      )}
      <RangeInput
        rounded="left"
        value={range.min}
        onChange={onChangeMin}
        onFocus={onFocusMin}
        placeholder="Min"
        isPercentage={isPercentage}
      />
      <CenterAddon>
        <Icon>
          <ConstraintIcon/>
        </Icon>
      </CenterAddon>
      <RangeInput
        value={range.max}
        onChange={onChangeMax}
        rounded={unit ? 'both' : 'right'}
        onFocus={onFocusMax}
        placeholder="Max"
        isPercentage={isPercentage}
      />
      {unit && !isPercentage && (
        <RightAddon>
          {unit}
        </RightAddon>
      )}
      {isPercentage && (
        <RightAddon>
          %
        </RightAddon>
      )}
      {isProblematic && (
        <div className="ml-2">
          <WarningBadge/>
        </div>
      )}
      {isRAG && onRemove && (
        <IconButton label="Remove range" onClick={onRemove} className="ml-2">
          <IconButtonIcon icon={CloseIcon}/>
        </IconButton>
      )}
    </div>
  );
};

/**
 * Validate ranges and the relevant errors.
 *
 * How it works:
 * 1. The `useRangeValidation` has an `errors` state that stores the error messages based on the last time that `validateRanges` was called.
 * 2. Use `validateRanges` to validate the ranges. It will check for overlapping ranges and ranges where the maximum value is lower than the minimum value. It will update the `errors` state with the new errors.
 * 3. Use `errors` to display the error messages.
 * 4. Use `problematicRanges` to highlight the problematic ranges (which is a set of range IDs).
 *
 * What it checks:
 * 1. Overlapping ranges (e.g. range 1: 0-10, range 2: 5-15) but no ranges are allowed to overlap (e.g. range 1: 0-10, range 2: 9-15).
 * 2. Maximum value is lower than the minimum value (e.g. range 1: 10-5).
 */
export const useRangeValidation = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [problematicRanges, setProblematicRanges] = useState<Set<string>>(new Set());

  const validateRanges = (rangesToValidate: Range[]) => {
    const newErrors: string[] = [];
    const newProblematicRanges = new Set<string>();

    const sortedRanges = [...rangesToValidate].sort((a, b) => (a.min ?? 0) - (b.min ?? 0));
    for (let i = 0; i < sortedRanges.length - 1; i++) {
      if ((sortedRanges[i].max ?? 1) > (sortedRanges[i + 1].min ?? 0)) {
        newErrors.push('Warning: Some ranges are overlapping. This may cause unexpected behavior.');
        newProblematicRanges.add(sortedRanges[i].id);
        newProblematicRanges.add(sortedRanges[i + 1].id);
      }
    }

    rangesToValidate.forEach((range) => {
      if ((range.max ?? 1) < (range.min ?? 0)) {
        newErrors.push('Warning: In a range, the maximum value is lower than the minimum value.');
        newProblematicRanges.add(range.id);
      }
    });

    setErrors(newErrors);
    setProblematicRanges(newProblematicRanges);
  };

  return { errors, problematicRanges, validateRanges };
};

interface ErrorDisplayProps {
  errors: string[];
}

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ errors }) => {
  if (errors.length === 0) return null;

  return (
    <Alert variant="warning">
      <AlertTitle>Some constraints are invalid</AlertTitle>
      {errors.map((error, index) => (
        <AlertDescription key={index}>{error}</AlertDescription>
      ))}
    </Alert>
  );
};
