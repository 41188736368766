import { ClipboardIcon } from '@radix-ui/react-icons';
import { JsonEditor } from 'json-edit-react';
import { createContext, useCallback, useContext, useState } from 'react';

import { ActionbarItem, ActionbarRoot } from '@/Actionbar';
import { ActionbarIcon } from '@/Icon';
import { AnimatedFinishedLabel } from '@/Label';
import { Modal, ModalBody, ModalContent } from '@/Modal';

interface JSONDebugContextType {
  showDebug: (data: any, title?: string) => void;
}

const JSONDebugContext = createContext<JSONDebugContextType | undefined>(undefined);

interface JSONDebugProviderProps {
  children: React.ReactNode;
}

const countNodes = (obj: any): number => {
  if (Array.isArray(obj)) {
    return obj.reduce((sum, item) => sum + countNodes(item), 0);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).reduce((sum, value) => sum + countNodes(value), 0 as number) ?? 0;
  }
  return 1;
};

const DebugJSON = ({ json }: { json: any }) => {
  return (
    <div className="p-4 bg-gray-100 rounded-lg">
      <pre className="text-xs">{JSON.stringify(json, null, 2)}</pre>
    </div>
  );
};

export const JSONDebugProvider = ({ children }: JSONDebugProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [debugData, setDebugData] = useState<any>(null);
  const [debugTitle, setDebugTitle] = useState<string>('Debug Data');
  const [showCopiedLabel, setShowCopiedLabel] = useState(false);

  const showDebug = useCallback((data: any, title?: string) => {
    setDebugData(data);
    if (title) setDebugTitle(title);
    setIsOpen(true);
  }, []);

  const handleCopy = useCallback((json: any) => {
    navigator.clipboard.writeText(JSON.stringify(json, null, 2))
      .then(() => {
        setShowCopiedLabel(true);
        setTimeout(() => setShowCopiedLabel(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy JSON:', err);
      });
  }, []);

  const renderJSON = (json: any) => {
    const totalNodes = countNodes(json);
    const useJsonEditor = totalNodes <= 1000;

    return useJsonEditor ? (
      <JsonEditor data={json}/>
    ) : (
      <DebugJSON json={json}/>
    );
  };

  return (
    <JSONDebugContext.Provider value={{ showDebug }}>
      {children}
      <Modal open={isOpen} onOpenChange={setIsOpen}>
        <ModalContent className="max-w-3xl h-[70vh] flex flex-col">
          <div className="absolute bottom-0 max-w-lg right-0 z-10">
            <ActionbarRoot show={isOpen}>
              <AnimatedFinishedLabel show={showCopiedLabel}/>
              <ActionbarItem
                className="w-full"
                onClick={() => handleCopy(debugData)}
                shortcut="⌘C"
              >
                <ActionbarIcon icon={ClipboardIcon}/>
                Copy Debug Data
              </ActionbarItem>
            </ActionbarRoot>
          </div>
          <ModalBody className="grow overflow-auto">
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">{debugTitle}</h2>
              {debugData && renderJSON(debugData)}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </JSONDebugContext.Provider>
  );
};

export const useJSONDebug = () => {
  const context = useContext(JSONDebugContext);
  if (!context) {
    throw new Error('useJSONDebug must be used within a JSONDebugProvider');
  }
  return context.showDebug;
};
