import React, { ComponentType, ReactNode } from 'react';

import { CardGutter } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import { GrouperIcon } from '@/Icon';
import { MutedStrong, MutedText } from '@/Text';
import { cn } from '~/utils/cn';
import { textVariants, Theme } from '~/utils/colors';

interface GroupConfig {
  /** Icon component to display next to the group label */
  icon?: React.ComponentType;
  /** Translation key or display text for the group */
  labelKey?: string;
  /** Priority for sorting groups (higher numbers appear first) */
  priority?: number;
  /** Color theme for the group header */
  theme?: Theme;
}

interface EmptyPlaceholder {
  text: string;
  description?: string;
  icon?: ComponentType;
}

export interface GroupData<T> {
  key: string;
  label: string;
  items: T[];
  priority: number;
}

interface MultiGrouperProps<T> {
  /** Array of items to be grouped */
  data: T[];
  /** Function or key to group items by. If function, should return a string identifier for the group */
  groupBy: keyof T | ((item: T) => string);
  /** Configuration object for each group type */
  config?: Record<string, GroupConfig>;
  /** Render function for groups, receives the entire group data */
  children: (groupData: GroupData<T>) => ReactNode;
  /** Additional className for the root element */
  className?: string;
  /** Whether to add spacing between groups */
  spacing?: boolean;
  /** Whether to make group headers sticky while scrolling */
  sticky?: boolean;
  /** Offset from top for sticky headers (in pixels) */
  stickyOffset?: number;
  /** Single placeholder for empty state */
  emptyPlaceholder?: EmptyPlaceholder;
  /** Layout style: rows or grid */
  layout?: 'rows' | 'grid';
  /** Number of columns when using grid layout */
  gridCols?: 1 | 2 | 3 | 4;
}

/**
 * MultiGrouper component that groups data and returns entire groups to the render function
 * Unlike Grouper which returns individual items, MultiGrouper returns all items for a specific group
 */
export function MultiGrouper<T>({
  data,
  groupBy,
  config = {},
  children,
  className,
  spacing = true,
  sticky = false,
  stickyOffset = 0,
  emptyPlaceholder,
  layout = 'rows',
  gridCols = 1,
}: MultiGrouperProps<T>) {
  // Early return with placeholder if data is empty
  if (data.length === 0 && emptyPlaceholder) {
    return (
      <PlaceholderBox
        title={emptyPlaceholder.text}
        description={emptyPlaceholder.description}
        icon={emptyPlaceholder.icon ? React.createElement(emptyPlaceholder.icon) : undefined}
      />
    );
  }

  // Group the data
  const groupedData = React.useMemo(() => {
    const groups: Record<string, T[]> = {};

    data.forEach((item) => {
      const groupKey = typeof groupBy === 'function'
        ? groupBy(item)
        : String(item[groupBy]);

      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }

      groups[groupKey].push(item);
    });

    return groups;
  }, [data, groupBy]);

  // Convert grouped data to GroupData array and sort by priority
  const groupDataArray = React.useMemo(() => {
    return Object.entries(groupedData)
      .map(([key, items]) => {
        const groupConfig = config[key] ?? {};
        return {
          key,
          label: groupConfig.labelKey ?? key,
          items,
          priority: groupConfig.priority ?? 1,
        };
      })
      .sort((a, b) => (b.priority || 0) - (a.priority || 0));
  }, [groupedData, config]);

  return (
    <div className={className}>
      {groupDataArray.map((groupData) => {
        const groupConfig = config[groupData.key] ?? {};
        const theme = groupConfig.theme ?? 'gray';

        if (groupData.items.length === 0) return null;

        return (
          <div key={groupData.key} className={spacing ? 'space-y-4 mb-6' : 'mb-6'}>
            <CardGutter
              style={sticky ? { top: `${stickyOffset}px` } : undefined}
              className={cn(sticky && 'sticky z-10')}
            >
              <div className="flex items-center gap-4">
                <div className="flex items-center flex-1 gap-4 py-1">
                  <MutedStrong className="leading-none">
                    {groupConfig.icon && (
                      <GrouperIcon
                        icon={groupConfig.icon}
                        className={cn(textVariants[theme])}
                      />
                    )}
                    {groupConfig.labelKey ?? groupData.label}
                  </MutedStrong>

                  <MutedText className="leading-none">{groupData.items.length}</MutedText>
                </div>
              </div>
            </CardGutter>

            <div className={cn(
              spacing && 'space-y-4',
              layout === 'grid' && `grid grid-cols-1 md:grid-cols-${gridCols} gap-4`
            )}>
              {children(groupData)}
            </div>
          </div>
        );
      })}
    </div>
  );
}
