import { z } from 'zod';

import { Field, Inspection, Order, OrderProduce, Organization, QualityEntry } from '~/types/types';

export type AddQualityInput = {
  palletId?: string;
  orderId: string;
  produceId: string;
  inputOrganizationId: string;
  produceVarietyId: string;
  willContinueSubmission: boolean;
  fields: {
    fieldId: string;
    value: string;
    samples: number;
    samples_with_defects: number;
    note: string;
    input_unit: string;
    attachments?: File[];
  }[];
}

export type EditQualityInput = {
  palletId?: string;
  inputOrganizationId: string;
  orderId: string;
  produceId: string;
  produceVarietyId: string;
  inspectionId: string;
  fields: {
    id: string;
    fieldId: string;
    value: string;
    samples: number;
    samples_with_defects: number;
    input_unit: string;
    attachments?: File[];
  }[];
}

/**
 * Define the schema for the quality input form, along with the associated types.
 */
export const qualityInputSchema = z.object({
  palletId: z.string().optional(),
  fields: z
    .array(
      z.object({
        /**
         * The ID of the quality entry. This is used to identify the quality entry in the backend if this is an edit.
         */
        id: z.string().optional(),

        /**
         * The ID of the `field-definition` associated with this quality entry.
         */
        fieldId: z.string(),

        /**
         * A measurement, dictating an average measurement. Consider this for fields that are of type `measurement`
         */
        measurement: z.any().optional(),

        /**
         * A user might want to type the units (in case the current unit is incorrect).
         */
        unit: z.string().optional(),

        /**
         * A boolean flag, dictating that there is a defect present. Consider this to be the "minimal" engagement
         * with the buyer.
         */
        has_defect: z.boolean().optional(),

        /**
         * A number assigned to a percentage. This is the case if the user wants to inform "how many percentage" of
         * inspected items have a defect.
         */
        percentage: z.any().optional(),

        /**
         * This is considered as `all` samples potentially uploaded.
         */
        samples: z.any().optional(),

        /**
         * This might be considered if we are dealing with the question of "how many berries".
         */
        samples_with_defect: z.any().optional(),

        /**
         * This might be the case if the user wants to upload a `note` to the buyer.,
         */
        note: z.string().optional(),

        /**
         * Attachments for the field input, typically images for defects
         */
        attachments: z.any().optional(),
      })
    )
    .min(1, 'Please add at least one field'),
});
export type FormQualityInput = z.infer<typeof qualityInputSchema>;
export type FieldEntry = FormQualityInput['fields'][0];

export const QualityFormFactory = {
  /**
   * Make default values for the quality input form.
   */
  makeDefaultValues: {
    /**
     * Make default values for the quality input form. Either creates a new one or edits an existing one.
     * @param orderProduce
     * @param inspection
     * @param isEdit
     */
    new: (orderProduce: OrderProduce | null, inspection: Inspection | null, isEdit: boolean): FormQualityInput | null => {
      if (isEdit && inspection) {
        return QualityFormFactory.makeDefaultValues.forEdit(inspection);
      }

      return QualityFormFactory.makeDefaultValues.forCreate(orderProduce);
    },

    /**
     * If we are not editing, we need to create a new form.
     * @param orderProduce
     */
    forCreate: (orderProduce: OrderProduce | null): FormQualityInput | null => {
      if (orderProduce === null) {
        return null;
      }

      const spec = orderProduce?.spec;

      let fieldEntries: FieldEntry[] = [];
      if (spec?.linked_fields) {
        fieldEntries = spec.linked_fields.map(fieldDefinition => QualityFormFactory.createNewFieldEntry(fieldDefinition as Field));
      }

      return {
        palletId: '',
        fields: fieldEntries,
      };
    },

    /**
     * If we are editing, we need to create a new form with the default values, using existing inspection.
     * @param inspection
     */
    forEdit: (inspection: Inspection | null): FormQualityInput | null => {
      if (!inspection) {
        throw new Error('Inspection is required for edit default values');
      }

      const firstEntry = inspection.quality_entries?.[0];
      if (!firstEntry) {
        throw new Error('No quality entries found in inspection');
      }

      if (!inspection.quality_entries || inspection.quality_entries.length === 0) {
        throw new Error('No quality entries found in inspection');
      }

      return {
        fields: inspection?.quality_entries?.map(entry =>
          QualityInputMapper.qualityEntryToFormField(entry)
        ),
      };
    },
  },

  /**
   * Create a new field entry based on the field definition.
   * @param fieldDefinition The field definition to create a new field entry for. (This is the Field)
   */
  createNewFieldEntry: (fieldDefinition: Field): FieldEntry => {
    return {
      id: '',
      fieldId: fieldDefinition.id,
      measurement: undefined,
      unit: fieldDefinition.unit ?? undefined,
      has_defect: false,
      percentage: undefined,
      samples: undefined,
      samples_with_defect: undefined,
      note: '',
      attachments: [],
    };
  },
};

/**
 * Define a mapper which helps the mapping and converting the form fields to the model fields.
 */
export const QualityInputMapper = {
  createAPI: (
    formValues: FormQualityInput,
    inputOrganization: Organization,
    orderProduce: OrderProduce,
    order: Order,
    willContinueSubmission: boolean
  ): AddQualityInput => {
    return {
      palletId: '',
      inputOrganizationId: inputOrganization.id,
      willContinueSubmission,
      fields: formValues.fields.map(field => {
        let value = undefined;

        if (field.samples_with_defect !== undefined && field.samples !== undefined) {
          if (field.samples === 0) {
            value = 0;
          } else {
            value = field.samples_with_defect / field.samples;
          }
        }

        if (field.percentage !== undefined) {
          value = parseFloat(field.percentage);
        }

        if (field.measurement !== undefined) {
          value = parseFloat(field.measurement);
        }

        return {
          fieldId: field.fieldId,
          percentage: field.percentage,
          measurement: field.measurement,
          defect_present: field.has_defect,
          value: value as any,
          samples: field.samples,
          samples_with_defects: field.samples_with_defect,
          note: field.note ?? '',
          input_unit: field.unit ?? '',
          attachments: field.attachments ?? [],
        };
      }),
      orderId: order.id,
      produceId: orderProduce.produce_id,
      produceVarietyId: orderProduce.produce_variety_id,
    };
  },
  createEditAPI: (
    formValues: FormQualityInput,
    inputOrganization: Organization,
    order: Order,
    orderProduce: OrderProduce,
    inspection: Inspection
  ): EditQualityInput => {
    return {
      palletId: '',
      inputOrganizationId: inputOrganization.id,
      orderId: order.id,
      produceId: orderProduce.produce_id,
      produceVarietyId: orderProduce.produce_variety_id,
      inspectionId: inspection.id,
      fields: formValues.fields.map(field => {
        let value = undefined;

        if (field.samples_with_defect !== undefined && field.samples !== undefined) {
          if (field.samples === 0) {
            value = 0;
          } else {
            value = field.samples_with_defect / field.samples;
          }
        }

        if (field.percentage !== undefined) {
          value = parseFloat(field.percentage);
        }

        if (field.measurement !== undefined) {
          value = parseFloat(field.measurement);
        }

        return {
          id: field.id ?? '',
          fieldId: field.fieldId,
          value: value as any,
          samples: field.samples,
          samples_with_defects: field.samples_with_defect,
          note: field.note,
          input_unit: field.unit ?? '',
          attachments: field.attachments ?? [],
        };
      }),
    };
  },
  qualityEntryToFormField: (entry: QualityEntry): FieldEntry => {
    let percentage = null;
    let measurement = null;

    if (entry.data_type === 'measurement') {
      measurement = entry.number;
    }

    if (entry.data_type === 'percentage') {
      percentage = entry.number;
    }

    return {
      id: entry.id,
      fieldId: entry.field_id as string,
      percentage,
      measurement,
      samples: entry.samples_qty,
      samples_with_defect: entry.individual_counts,
      attachments: [],
    };
  },
};
