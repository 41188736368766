import { Link } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

import { Label } from '@/Label';
import { EmptyTableContainer, TableFooter } from '@/Table';
import { DeprecatedSortButton } from '@/Table/DeprecatedSortButton';
import { ReponsiveTableContainer } from '@/Table/TableContainer';
import { SortKey } from '@/Table/types';
import { Helper, IdentifierText, Strong, Text } from '@/Text';
import { CountryFlag } from '~/Components/Locations/CountryFlag';
import { CountryName } from '~/Components/Locations/CountryName';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';

import {
  OrderProduce,
  Organization,
  PaginatedItems,
} from '../../../Shared/types/types';
import { Card, CardFooter, CardHeaderContainer } from '../../../Shared/UI/Card';
import { PerformanceScoreBar } from './Components/PerformanceScoreBar';

interface SupplierRowProps {
  supplier: Organization;
  route: string;
}

const SupplierRow = ({ supplier, route }: SupplierRowProps) => {
  return (
    <Link
      href={route}
      className="grid grid-cols-[1fr_1fr_60px_1fr_1fr] gap-4 md:gap-8 items-center justify-between py-4 px-3 sm:px-8 hover:bg-gray-50 hover:cursor-pointer"
    >
      <div className="flex items-center">
        <div>
          <Strong>{supplier.title}</Strong>
          <div>
            <IdentifierText>{supplier.ggn}</IdentifierText>
          </div>
        </div>
      </div>

      <div>
        {!!supplier.sell_produces && supplier.sell_produces?.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {supplier.sell_produces?.map((produce) => (
              <Label key={produce.id}>{produce?.name}</Label>
            ))}
          </div>
        )}
      </div>
      <div>
        <Text>{supplier.sell_orders_count}</Text>
      </div>
      <div>
        <div>
          {supplier.country && (
            <span className="flex items-center">
              <span className="hidden sm:revert mx-2">
                <CountryFlag countryCode={supplier.country}/>
              </span>
              <Text>
                <CountryName countryCode={supplier.country.toUpperCase()}/>
              </Text>
            </span>
          )}
        </div>
      </div>
      {supplier.latest_sell_summary && (
        <div className="flex justify-end">
          <PerformanceScoreBar sellerSummary={supplier.latest_sell_summary}/>
        </div>
      )}
    </Link>
  );
};

export interface SuppliersTableFilters {
  region: string;
  produce: OrderProduce;
  search: string;
}

interface SuppliersTableProps {
  suppliersConnection: PaginatedItems<Organization>;
  sortKey: SortKey;
  filters: SuppliersTableFilters;
  availableRegions: string[];
  availableProduces: string[];
}

export const SuppliersTable = ({
  suppliersConnection,
}: SuppliersTableProps) => {
  const suppliers = suppliersConnection.data;
  const { supplier: generateSupplierRoute } = useRoutes<BuyerRoutes>();
  const { t } = useTranslation();

  return (
    <Card>
      <ReponsiveTableContainer>
        <CardHeaderContainer className="py-2!">
          {' '}
          {/* className='max-w-xl' if we want to further constrain */}
          <div className="grid grid-cols-[1fr_1fr_60px_1fr_1fr] items-center gap-4 md:gap-8">
            <div>
              <Helper>{t('supplier')}</Helper>
            </div>
            <div>
              <Helper>{t('produce')}</Helper>
            </div>
            <div>
              <Helper>{t('orders')}</Helper>
            </div>
            <div>
              <Helper>{t('region')}</Helper>
            </div>
            <div className="flex items-center ml-auto">
              <DeprecatedSortButton sortKey="performance_score">
                {t('performance')}
              </DeprecatedSortButton>
            </div>
          </div>
        </CardHeaderContainer>
        <hr/>
        <div>
          <div>
            <div className="divide-y divide-gray-200">
              {suppliers.length > 0 ? (
                suppliers.map((supplier) => (
                  <SupplierRow
                    key={supplier.id}
                    supplier={supplier}
                    route={generateSupplierRoute(supplier.id)}
                  />
                ))
              ) : (
                <EmptyTableContainer
                  placeholder={t('not_enough_supplier_data_found')}
                />
              )}
            </div>
          </div>
        </div>
      </ReponsiveTableContainer>
      <CardFooter>
        <TableFooter paginatedItems={suppliersConnection}/>
      </CardFooter>
    </Card>
  );
};
