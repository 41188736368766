import { BuildingOffice2Icon, CubeIcon, IdentificationIcon, MapPinIcon, TruckIcon } from '@heroicons/react/16/solid';
import { router } from '@inertiajs/react';
import { GlobeIcon } from '@radix-ui/react-icons';
import React from 'react';

import { StandoutBorder } from '@/Borders';
import { Card, CardBody, CardFooter } from '@/Card';
import { WithGenericFallback } from '@/Fallback';
import { Gutter } from '@/Gutter';
import { CancelledIcon, IconedField, LabelIcon, QualityIcon, RetailerIcon } from '@/Icon';
import { Label, MiniLabel } from '@/Label';
import { getStatusFromMap, StatusBadge } from '@/Labels/Status';
import { StatusLabel } from '@/StatusLabel';
import { MutedText, Strong } from '@/Text';
import { CountryName } from '~/Components/Locations/CountryName';
import { EngagementStatus, orderEngagementStatusMap } from '~/Components/Orders/OrderEngagementStatusMap';
import { orderPredictionStatusMap } from '~/Components/Orders/Representations/OrderPredictionStatusMap';
import { DateFormat, useDate } from '~/hooks/useDate';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { Order } from '~/types/types';
import { cn } from '~/utils/cn';
import { FormatStringList } from '~/utils/FormatStringList';

import { QualityBreakdownSummary } from '../QualityBreakdownSummary';
import { QualityLabel } from '../QualityLabel';

interface OrderCardDetails {
  orderRef?: string;
  engagementStatus?: EngagementStatus;
  sellerName?: string;
  sellerCountryCode?: string;
  buyerName?: string;
  containerReferences?: string[];
}

export const detailsFromBuyer = (order: Order): OrderCardDetails => {
  const containerReferences = Array.from(new Set(order.loads?.filter(
    (load) => load.container_ext_ref)
    .map((load) => load.container_ext_ref) ?? [])) as string[];

  return {
    engagementStatus: undefined,
    orderRef: order.public_ref,
    sellerName: order.seller?.title,
    sellerCountryCode: order.seller?.country?.toUpperCase(),
    buyerName: undefined,
    containerReferences: containerReferences,
  };
};

export const detailsFromSeller = (order: Order): OrderCardDetails => {
  const containerReferences = Array.from(new Set(order.loads?.filter(
    (load) => load.container_ext_ref)
    .map((load) => load.container_ext_ref) ?? [])) as string[];

  return {
    engagementStatus: order.requires_seller_input ? 'requires_seller_input' : undefined,
    orderRef: order.public_ref,
    sellerName: undefined,
    sellerCountryCode: undefined,
    buyerName: order.buyer?.title,
    containerReferences: containerReferences,
  };
};

interface OrderCardProps {
  order: Order;
  details: OrderCardDetails;
  isLoading?: boolean;
}

export const OrderCard = ({ order, isLoading, details }: OrderCardProps) => {
  const routes = useRoutes<BuyerRoutes>();
  const { parseOrFormat } = useDate();

  const columns = '2fr 2fr 2fr 1fr';

  const uniqueImages = order.order_produce
    ?.map((orderProduce) => orderProduce.produce?.image_url)
    .filter((v, i, a) => a.indexOf(v) === i);
  const uniqueProduces = order.order_produce
    ?.map((orderProduce) => orderProduce.produce?.name.toLocaleLowerCase())
    .filter((v, i, a) => a.indexOf(v) === i) as string[];
  const uniqueProduceVarieties = order.order_produce
    ?.map((orderProduce) => orderProduce.produce_variety?.name)
    .filter((v, i, a) => a.indexOf(v) === i) as string[];
  const hasStatus =
    (order.issues_count && order.issues_count > 0) || order.requiresAttention || order.predicted_score;

  return (
    <div className="relative overflow-visible">
      <Card isLoading={isLoading} hasHover className={cn(order.archived_at || order.cancelled && 'opacity-60')}>
        <CardBody>
          <div
            key={order.id}
            data-testid="order-row"
            role="row"
            onClick={() => router.get(routes.order(order.id))}
            style={{ gridTemplateColumns: columns }}
          >
            {/* Row: Main properties */}
            <div className="flex justify-between space-x-4">
              <div className="flex flex-col justify-start md:flex-row gap-x-4">
                <div className="flex -space-x-1 align-top isolate sm:mb-2 md:mb-0">
                  {uniqueImages?.map((image) => (
                    <StandoutBorder
                      key={image}
                      className="md:inline-block self-baseline"
                    >
                      {image && (
                        <img
                          src={image}
                          alt={image}
                          className="block object-cover rounded-full w-7 h-7"
                        />
                      )}
                    </StandoutBorder>
                  ))}
                </div>
                <div className="flex flex-col justify-start">
                  <div className="flex">
                    <Strong className="mb-2 leading-none capitalize">
                      <FormatStringList strings={uniqueProduces}/>
                    </Strong>
                  </div>
                  <div className="flex">
                    <MutedText className="text-xxs! leading-none text-gray-500!">
                      <FormatStringList strings={uniqueProduceVarieties}/>
                    </MutedText>
                  </div>
                </div>
                <div className="ml-4">
                </div>
              </div>

              {hasStatus && (
                <div className="flex space-x-2">
                  <div>
                    {order.predicted_score && (
                      <StatusBadge config={getStatusFromMap(order.predicted_score, orderPredictionStatusMap)}/>
                    )}
                  </div>
                  {!!order.issues_count && order.issues_count > 0 && (
                    <WithGenericFallback>
                      <div>
                        <QualityLabel
                          label={'issues'}
                          issuesCount={order.issues_count}
                        />
                      </div>
                    </WithGenericFallback>
                  )}
                  {!!order.comments_count && order.comments_count > 0 && (
                    <div>
                      <StatusLabel status="info">
                        {order.comments_count} comments
                      </StatusLabel>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Row 2: sub properties and issues breakdown */}
            <div className="flex items-center justify-between gap-2 mt-2 gap-x-4 wrap">
              <div className="flex flex-wrap items-center gap-2">
                {details.orderRef && (
                  <IconedField icon={IdentificationIcon}>
                    <MutedText>{details.orderRef}</MutedText>
                  </IconedField>
                )}
                {details.sellerName && (
                  <IconedField icon={BuildingOffice2Icon}>
                    <MutedText>{details.sellerName}</MutedText>
                  </IconedField>
                )}
                {details.sellerCountryCode && (
                  <IconedField icon={MapPinIcon}>
                    <MutedText>
                      <CountryName countryCode={details.sellerCountryCode}/>
                    </MutedText>
                  </IconedField>
                )}
                {details.buyerName && (
                  <IconedField icon={RetailerIcon}>
                    <MutedText>{details.buyerName}</MutedText>
                  </IconedField>
                )}
                {details.containerReferences && details.containerReferences?.length > 0 && (
                  <IconedField icon={CubeIcon}>
                    <MutedText>
                      <FormatStringList strings={details.containerReferences}/>
                    </MutedText>
                  </IconedField>
                )}
              </div>

              <div>
                {order.issues && order.issues?.length > 0 && (
                  <div>
                    <div className="flex justify-end">
                      <QualityBreakdownSummary issues={order.issues}/>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Row 3: Special status perhaps */}
            <div>
              {(order.has_buyer_intake || order.has_seller_intake || details.engagementStatus || order.cancelled) && (
                <div>
                  <div className="py-2">
                    <hr/>
                  </div>

                  <div className="inline-block">
                    <Gutter className="inline-block" theme="transparent">
                      {details.engagementStatus && (
                        <div>
                          <StatusBadge status={details.engagementStatus}
                            config={orderEngagementStatusMap[details.engagementStatus]}/>
                        </div>
                      )}
                      {order.has_buyer_intake && (
                        <div>
                          <MiniLabel>
                            <LabelIcon icon={QualityIcon}/>
                            Buyer intake
                          </MiniLabel>
                        </div>
                      )}

                      {order.has_seller_intake && (
                        <MiniLabel>
                          <LabelIcon icon={QualityIcon}/>
                          Seller intake
                        </MiniLabel>
                      )}

                      {order.cancelled && (
                        <Label>
                          <LabelIcon icon={CancelledIcon}/>
                          Cancelled
                        </Label>
                      )}
                    </Gutter>
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardBody>
        <hr/>
        <CardFooter>
          <div className="flex justify-between">
            <div>
              <MutedText>
                Last updated:{' '}
                {parseOrFormat(order.updated_at, DateFormat.HumanDateTimeNoYear)}
              </MutedText>
            </div>

            <div className="flex items-center gap-2">
              {order.ship_date && (
                <IconedField icon={TruckIcon}>
                  <MutedText>
                    {parseOrFormat(order.ship_date, DateFormat.DayFormat)}{' '}
                    shipping
                  </MutedText>
                </IconedField>
              )}
              {order.delivery_date && (
                <IconedField icon={GlobeIcon}>
                  {!!order.delay && (
                    <MutedText className="text-rose-500">
                      {parseOrFormat(order.expected_arrival_at, DateFormat.DayFormat)}{' '}
                      delayed arrival (+{order.delay.delayInPercentages.toFixed(0)}%) at {order.delivery_location}
                    </MutedText>
                  )}

                  {!order.delay && (
                    <MutedText>
                      {parseOrFormat(order.delivery_date, DateFormat.DayFormat)}{' '}
                      arrival at {order.delivery_location}
                    </MutedText>
                  )}
                </IconedField>
              )}
            </div>
          </div>
        </CardFooter>
      </Card>

      <div className="absolute top-0 max-w-sm -right-40">
        {/* TODO: Future pickup */}
        {/*{activeIssuesInfo && activeIssuesInfo.pivot.order_id === order.id && (*/}
        {/*  <div>*/}
        {/*    <Card>*/}
        {/*      <CardBody size="sm">*/}
        {/*        <MiniTemperatureSummaryCard dataStream={activeIssuesInfo.summarizable} data={[]} lowThresholdY={0}*/}
        {/*          upperThresholdY={0}/>*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};
