import { type VariantProps, cva } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

import { cn } from '~/utils/cn';

// ======= ProgressSteps Component =======
interface ProgressStepsProps {
  children: ReactNode;
  className?: string;
  orientation?: 'vertical' | 'horizontal';
}

/**
 * ProgressSteps is a component that displays a list of steps in a progress bar.
 * @param children
 * @param className
 * @param orientation
 * @constructor
 *
 * Here's how to use it:
 *
 * <ProgressSteps>
 *  <ProgressStep isCompleted title="Step 1" description="This is the first step" />
 *  <ProgressStep isActive title="Step 2" description="This is the second step" />
 *  <ProgressStep title="Step 3" description="This is the third step" />
 * </ProgressSteps>
 */
export const ProgressSteps = ({
  children,
  className,
  orientation = 'vertical',
}: ProgressStepsProps) => {
  return (
    <div className={cn(
      'flow-root',
      orientation === 'horizontal' && 'flex items-center justify-between',
      className
    )}>
      <ul
        role="list"
        className={cn(
          'relative',
          orientation === 'horizontal' && 'flex w-full'
        )}
      >
        {children}
      </ul>
    </div>
  );
};
// ======= ProgressStep Component =======
interface ProgressStepProps {
  title: string;
  description?: string;
  isCompleted?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  disabledText?: string;
  disabledDescription?: string;
  className?: string;
  showConnector?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  spacing?: 'sm' | 'md' | 'lg';
}

const stepIconVariants = cva(
  'flex items-center justify-center rounded-full w-10 h-10',
  {
    variants: {
      state: {
        completed: 'text-white',
        active: 'text-indigo-600 border-indigo-200 border border-solid',
        default: 'text-zinc-400 border-zinc-200 border border-solid',
        disabled: 'text-gray-400 border-gray-300 border border-solid',
      },
    },
    defaultVariants: {
      state: 'default',
    },
  }
);

// Animation variants for the background
const backgroundVariants = {
  completed: { backgroundColor: '#4f46e5' }, // indigo-600
  active: { backgroundColor: '#e0e7ff' }, // indigo-100
  default: { backgroundColor: '#f4f4f5' }, // zinc-100
  disabled: { backgroundColor: '#e5e7eb' }, // gray-200
};

// Animation variants for the checkmark
const checkVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1, transition: { delay: 0.1 } },
};

// Animation variants for the dot
const dotVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

export const ProgressStep = ({
  title,
  description,
  isCompleted = false,
  isActive = false,
  isDisabled = false,
  disabledText = 'Not available yet',
  disabledDescription,
  className,
  showConnector = true,
  icon,
  children,
  spacing,
  showSeparator = false,
}: ProgressStepProps & { showSeparator?: boolean }) => {
  // Determine the state for styling
  const state = isDisabled ? 'disabled' : isCompleted ? 'completed' : isActive ? 'active' : 'default';

  return (
    <div className={cn(showSeparator && 'border-b border-gray-200')}>
      <div className={cn(spacing === 'sm' && 'px-4', spacing === 'md' && 'px-6', spacing === 'lg' && 'px-8')}>
        <motion.li
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={cn('relative pb-8', className)}
        >
          {showConnector && (
            <motion.span
              aria-hidden="true"
              initial={{ backgroundColor: '#e5e7eb' }} // gray-200
              animate={{
                backgroundColor: isDisabled
                  ? '#e5e7eb' // gray-200
                  : isCompleted
                    ? '#4f46e5' // indigo-600
                    : '#e5e7eb', // gray-200
              }}
              transition={{ duration: 0.3 }}
              className="absolute left-5 top-5 -ml-px h-full w-0.5"
            />
          )}

          <div className="relative flex space-x-4">
            {/* Step icon/indicator with animation */}
            <div className="relative flex-shrink-0">
              <motion.div
                className={cn(stepIconVariants({ state }))}
                initial={state}
                animate={state}
                variants={backgroundVariants}
                transition={{ duration: 0.3 }}
              >
                <AnimatePresence mode="wait">
                  {icon ? (
                    <motion.div
                      key="custom-icon"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      {icon}
                    </motion.div>
                  ) : isCompleted && !isDisabled ? (
                    <motion.svg
                      key="checkmark"
                      className="w-5 h-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      variants={checkVariants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </motion.svg>
                  ) : (
                    <motion.span
                      key="dot"
                      className="h-2.5 w-2.5 rounded-full bg-current"
                      variants={dotVariants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                    />
                  )}
                </AnimatePresence>
              </motion.div>
            </div>

            {/* Step content */}
            <div className={cn('min-w-0 flex-1', isDisabled && 'opacity-60')}>
              <div className="pt-1">
                <motion.h3
                  initial={{ color: isDisabled ? '#9ca3af' : isActive || isCompleted ? '#111827' : '#6b7280' }}
                  animate={{ color: isDisabled ? '#9ca3af' : isActive || isCompleted ? '#111827' : '#6b7280' }}
                  transition={{ duration: 0.3 }}
                  className="font-medium text-md"
                >
                  {title}
                </motion.h3>
                {description && (
                  <motion.p
                    initial={{ color: isDisabled ? '#9ca3af' : '#6b7280' }}
                    animate={{ color: isDisabled ? '#9ca3af' : '#6b7280' }}
                    transition={{ duration: 0.3 }}
                    className="mt-1 text-sm"
                  >
                    {description}
                  </motion.p>
                )}
              </div>

              {/* Children content with optional disabled overlay */}
              {children && (
                <div className="relative mt-4">
                  <motion.div
                    animate={{ opacity: isDisabled ? 0.4 : 1 }}
                    transition={{ duration: 0.3 }}
                    className={cn(isDisabled && 'pointer-events-none')}
                  >
                    {children}
                  </motion.div>

                  {isDisabled && (disabledText || disabledDescription) && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="absolute inset-0 flex items-center justify-center"
                    >
                      <div className="bg-gray-50 bg-opacity-90 rounded-md p-4 text-center shadow-sm border border-gray-200 max-w-[90%]">
                        {disabledText && (
                          <h4 className="text-sm font-medium text-gray-600">
                            {disabledText}
                          </h4>
                        )}
                        {disabledDescription && (
                          <p className="mt-1 text-xs text-gray-500">
                            {disabledDescription}
                          </p>
                        )}
                      </div>
                    </motion.div>
                  )}
                </div>
              )}
            </div>
          </div>
        </motion.li>
      </div>
    </div>
  );
};