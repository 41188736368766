import { Link } from '@inertiajs/react';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import React, { HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { w } from 'windstitch';

import { cn } from '~/utils/cn';

import { IconBox, IconBoxIcon } from './Icon';
import { Spinner } from './Spinner';
import { CardSubtitle, CardTitle, Helper } from './Text';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  hasHover?: boolean;
  className?: string;
  isLoading?: boolean;
}

export const Card = ({ children, hasHover, className, isLoading, ...props }: CardProps) => {
  return (
    <div
      className={
        cn(
          'bg-white ring-1 ring-gray-900/5 h-full lg:h-revert rounded shadow-xs relative transition-all',
          className,
          hasHover ? 'hover:shadow-md cursor-pointer' : ''
        )}
      {...props}
    >
      <AnimatePresence>
        {isLoading && (
          <motion.div
            className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full rounded-md bg-gray-100/70 z-1000"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ backdropFilter: 'blur(1px)' }}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.1 }}
            >
              <Spinner />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </div>
  );
};

interface CardPanelProps extends HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'md' | 'lg';
}

/**
 * A container component that arranges multiple panels in a grid layout.
 * Commonly used to create responsive multi-column layouts for card content.
 *
 * @example
 * ```tsx
 * <CardPanels columns="2_1">
 *   <CardPanel>Main content (66%)</CardPanel>
 *   <CardSidePanel>Sidebar content (33%)</CardSidePanel>
 * </CardPanels>
 * ```
 *
 * @prop columns - Controls the grid column distribution
 *   - "1_1": Equal 50-50 split
 *   - "2_1": 66-33 split (main content + sidebar)
 *   - "1_2": 33-66 split (sidebar + main content)
 *   - "1_3": 25-75 split
 *   - "1_4": 20-80 split
 *   - "1_2_2": Three columns with 20-40-40 split
 */
export const CardPanels = w.div('grid [&>*:last-child]:rounded-br', {
  variants: {
    columns: {
      '1_1': 'lg:grid-cols-[1fr_1fr] sm:grid-cols-1',
      '1_2': 'lg:grid-cols-[1fr_2fr] sm:grid-cols-1',
      '2_1': 'lg:grid-cols-[2fr_1fr] sm:grid-cols-2',
      '1_3': 'md:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_3fr] sm:grid-cols-1',
      '1_4': 'grid-cols-1_4 sm:grid-cols-4',
      '1_2_2': 'lg:grid-cols-[1fr_2fr_2fr] sm:grid-cols-2',
    },
  },
  defaultVariants: {
    columns: '1_1',
  },
});

/**
 * A standard content panel that fills available space within a CardPanels layout.
 * Used for the main content areas of a card.
 *
 * @example
 * ```tsx
 * <CardPanel size="md">
 *   <div>Main content goes here</div>
 * </CardPanel>
 * ```
 *
 * @prop size - Controls the padding of the panel
 *   - "sm": Compact padding
 *   - "md": Standard padding (default)
 *   - "lg": Spacious padding
 */
export const CardPanel = ({ children, className, size }: CardPanelProps) => {
  return (
    <Card className={cn('h-full! rounded-none shadow-none! relative', className)}>
      <CardBody size={size}>
        {children}
      </CardBody>
    </Card>
  );
};

interface NavCardProps {
  children: ReactNode;
  href: string;
  className?: string;
}

export const NavCard = ({ children, href, className }: NavCardProps) => {
  return (
    <Card className={`relative ${className}`} hasHover>
      <Link href={href} className="absolute inset-0"/>
      {children}
    </Card>
  );
};

export const CardBody = w.div('', {
  variants: {
    size: {
      xs: 'px-2.5 py-1.5',
      sm: 'px-3 py-4',
      md: 'px-3 py-4 sm:py-4 sm:px-8',
      lg: 'pya-6 px-12',
    },
    variant: {
      default: 'bg-none',
      muted: 'bg-gray-50 border-gray-100',
      dashed: 'border-dashed border-gray-100 bg-gray-50 border-style-dashed',
    },
    bordered: {
      default: 'border-none',
      y: 'border-y',
      all: 'border',
      left: 'border-l',
      right: 'border-r',
      top: 'border-t',
      bottom: 'border-b',
    },
  },
  defaultVariants: {
    bordered: 'default',
    variant: 'default',
    size: 'md',
  },
});

export const SimpleCardHeader = w.div('flex items-center gap-2 py-1 px-4 border-b rounded-t', {
  variants: {
    variant: {
      white: 'bg-white text-gray-500',
      red: 'bg-rose-600 text-rose-50',
      green: 'bg-emerald-600 text-emerald-50',
    },
  },
  defaultVariants: {
    variant: 'white',
  },
});

export const CardHeaderContainer = w.div(
  'bg-gray-white rounded-t border-b border-gray-100',
  {
    variants: {
      size: {
        sm: 'py-1 px-4',
        md: 'py-2 px-3 sm:px-8',
        lg: 'py-6 px-12',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

export const CardContent = w.div(
  '',
  {
    variants: {
      size: {
        sm: 'px-4',
        md: 'px-3 sm:px-8',
        lg: 'px-12',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

interface CardHeaderProps {
  children?: ReactNode;
  /** @deprecated Use `icon` instead */
  renderIcon?: ReactNode;
  icon?: any;
  title?: string | null;
  description?: string | null;
}

export const CardGutter = w.div('border-y px-8 py-2 flex',
  {
    variants: {
      align: {
        start: '',
        end: 'flex justify-end',
      },
      rounded: {
        none: '',
        md: 'rounded-md',
        full: 'rounded-full',
      },
      variant: {
        lighter: 'bg-gray-50',
        default: 'bg-gray-100',
        darker: 'bg-gray-200 border-gray-300',
      },
    },
    defaultVariants: {
      variant: 'default',
      rounded: 'none',
      align: 'start',
    },
  }
);

/**
 * CardHeader Component
 *
 * A component for creating a header section within a Card.
 *
 * @example
 * // Basic usage
 * <CardHeader title="Card Title" description="Card description text" />
 *
 * @example
 * // With icon
 * <CardHeader
 *   title="Card Title"
 *   description="Card description text"
 *   icon={IconComponent}
 * />
 *
 * @example
 * // With custom content
 * <CardHeader title="Card Title">
 *   <div>Custom content goes here</div>
 * </CardHeader>
 *
 * @props
 * - title: The main title text of the card
 * - description: Optional description text displayed below the title
 * - icon: Icon component to display (preferred over renderIcon)
 * - renderIcon: Legacy way to provide an icon (deprecated)
 * - children: Optional custom content to render in the header
 */

export const CardHeader = ({
  renderIcon,
  children,
  title,
  description,
  icon,
}: CardHeaderProps) => {
  return (
    <CardHeaderContainer className="py-6">
      <div className="flex gap-x-4">
        {renderIcon && (
          <div className="hidden sm:revert">
            <IconBox>{renderIcon}</IconBox>
          </div>
        )}

        {icon && (
          <div className="hidden sm:revert">
            <CardIconBox icon={icon} />
          </div>
        )}

        <div className="grow">
          {title && (
            <CardTitle className="flex items-center mb-1 leading-none">
              {title}
            </CardTitle>
          )}
          {description && (
            <div>
              <CardSubtitle>{description}</CardSubtitle>
            </div>
          )}
          {children}
        </div>
      </div>
    </CardHeaderContainer>
  );
};

// Has inline box shadow from above
export const CardFooter = w.div(
  'rounded-b border-t ',
  {
    variants: {
      size: {
        sm: 'py-1 px-4',
        md: 'py-2 px-3 sm:px-8',
        lg: 'py-6 px-12',
      },
      variant: {
        default: 'shadow-none bg-gray-50 border-gray-200 text-gray-900',
        shadowed: 'shadow-inner',
        darker: 'bg-gray-100 border-gray-200 border',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  }
);

interface PromptCardProps {
  variant: 'gray' | 'emerald' | 'red' | 'blue' | 'yellow';
  renderIcon?: ReactNode;
  children: ReactNode;
}

const promptCardBodyVariants = cva('sm:py-8! rounded bg-linear-to-b border', {
  variants: {
    variant: {
      gray: 'from-gray-50 to-slate-50 border-slate-100',
      emerald: 'from-emerald-50 to-slate-50 border-slate-100',
      red: 'from-red-50 to-slate-50 border-slate-100',
      blue: 'from-blue-50 to-slate-50 border-slate-100',
      yellow: 'from-yellow-50 to-slate-50 border-slate-100',
    },
  },
  defaultVariants: {
    variant: 'emerald',
  },
});

export const promptCardIconVariants = cva(
  'inline-block p-1 mt-1 border-gray-100 rounded shadow-xs ring-1 ',
  {
    variants: {
      variant: {
        gray: 'from-gray-50 to-slate-50 ring-gray-200 border-red-100!',
        emerald:
          'text-emerald-500 bg-emerald-50 ring-emerald-200 border-gray-100',
        red: 'text-red-500 bg-red-50 ring-red-200 border-gray-100',
        blue: 'text-blue-500 bg-blue-50 ring-blue-200 border-gray-100',
        yellow: 'text-yellow-500 bg-yellow-50 ring-yellow-200 border-gray-100',
      },
    },
    defaultVariants: {
      variant: 'emerald',
    },
  }
);

/**
 * A card that is used to prompt the user to take action.
 * It has a colored border and a gradient background.
 *
 * @param variant - The color of the border and background.
 * @param renderIcon - An icon to render on the left side of the card.
 * @param children - The content of the card.
 *
 * @example
 * <PromptCard variant="emerald" renderIcon={<Icon />} >
 *   <CardTitle>Card Title</CardTitle>
 *   <CardSubtitle>Card Subtitle</CardSubtitle>
 *   <p>Card Content</p>
 * </PromptCard>
 */
export const PromptCard = ({
  variant,
  renderIcon,
  children,
}: PromptCardProps) => {
  return (
    <Card className="p-3 bg-white">
      <CardBody className={promptCardBodyVariants({ variant })}>
        <div className="flex gap-4">
          <div className="hidden sm:revert">
            {!!renderIcon && (
              <div
                className={promptCardIconVariants({
                  variant,
                })}
              >
                {renderIcon}
              </div>
            )}
          </div>
          <div>{children}</div>
        </div>
      </CardBody>
    </Card>
  );
};

export const CardSection = w.div('py-1 bg-gray-50 px-4');
export const CardSectionTitle = Helper;

interface ContextAreaProps {
  children: ReactNode;
  titleKey?: string;
}

export const CardRows = w.div('grid *:border-b');

export const ContextArea = ({ children, titleKey = 'context' }: ContextAreaProps) => {
  const { t } = useTranslation();
  return (
    <div className="px-2 py-2 my-2 border-2 rounded bg-gray-50">
      <div className="mb-1">
        <Helper className="text-xs flex text-slate-800!">
          {t(titleKey)}
        </Helper>
      </div>

      <div>
        <small>
          {children}
        </small>
      </div>
    </div>
  );
};

/**
 * This icon is used to overlay on the left side of its parent.
 *
 * @precondition - The parent must have `relative` positioning.
 *              - The component must be used in the "right" sibling of the overlay.
 * @param icon
 * @constructor
 */
export const PanelOverlayIcon = ({ icon }: { icon: any }) => {
  return (
    <div className="absolute left-0 transform -translate-x-1/2 -translate-y-1/2 sm:top-0 lg:top-1/2">
      <IconBox className="flex items-center w-7 h-7">
        <IconBoxIcon icon={icon}/>
      </IconBox>
    </div>
  );
};

/**
 * A specialized panel designed for sidebar content with distinct styling.
 * Typically used within CardPanels for secondary information, filters, or summaries.
 * Comes with a muted background and border styling by default.
 *
 * @example
 * ```tsx
 * <CardSidePanel size="md">
 *   <div>Sidebar content</div>
 * </CardSidePanel>
 * ```
 *
 * @prop flat - When true, removes border radius and certain borders
 *   - true: No border radius, no bottom-right radius
 *   - false: Rounded top-left, no left border (default)
 * @prop size - Controls the padding of the panel
 *   - "xs": Minimal padding (2.5/1.5)
 *   - "sm": Small padding (3/4)
 *   - "md": Standard padding with responsive increase (default)
 *   - "lg": Large padding (6/12)
 */
export const CardSidePanel = w.div(
  'border border-gray-200 border-b-0 border-r bg-gray-100',
  {
    variants: {
      flat: {
        true: 'rounded-none! rounded-br-none!',
        false: 'rounded-tl border-l-none',
      },
      size: {
        xs: 'px-2.5 py-1.5',
        sm: 'px-3 py-4',
        md: 'px-3 py-4 sm:py-4 sm:px-8',
        lg: 'pya-6 px-12',
      },
    },
    defaultVariants: {
      flat: 'false',
      size: 'md',
    },
  }
);

export const CardIconBox = ({
  icon,
  theme,
  size = 'sm',
}: {
  icon: React.ComponentType<any>;
  theme?: string;
  size?: 'sm' | 'md' | 'lg';
}) => {
  return (
    <IconBox className={cn(
      'flex !text-gray-400 items-center justify-center border-0!',
      theme && `bg-${theme}-50`,
      size === 'sm' && '!w-8 !h-8',
      size === 'md' && '!w-12 !h-12',
      size === 'lg' && '!w-14 !h-14',
    )}>
      <IconBoxIcon className='w-10 h-10 !max-w-7' icon={icon} />
    </IconBox>
  );
};