import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { CheckIcon, EllipsisHorizontalIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import React from 'react';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardHeaderContainer } from '@/Card';
import { Floater } from '@/Floater';
import { Icon, IconBox } from '@/Icon';
import { LabeledField } from '@/LabeledField';
import { SideLabeledField } from '@/SideLabeledField';
import { MutedText, Strong, Text } from '@/Text';
import { AccuracyIndicator } from '~/Components/Indicators/AccuracyIndicator';
import { useFormat } from '~/hooks/useFormat';
import { Organization } from '~/types/types';

interface OrderFieldAccuracyBoxProps {
  problem: any;
  buyer: Organization;
  sellerInfo: any;
  orderAccuracyInfo: any;
}

export const OrderFieldAccuracyBox = ({
  problem,
  sellerInfo,
  orderAccuracyInfo,
  buyer,
}: OrderFieldAccuracyBoxProps) => {
  const { formatPercentage } = useFormat();

  const fieldType = problem.field.type;

  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    middleware: [offset(100)],
    whileElementsMounted: autoUpdate,
  });

  return (
    <div className="relative" ref={refs.setReference}>
      <Card className="border-l-8 border-red-500">
        {problem.trend && (
          <Floater refs={refs} floatingStyles={floatingStyles}>
            <Card>
              <CardHeaderContainer className='bg-gray-50 py-2! flex items-center gap-2'>
                <IconBox>
                  <ExclamationCircleIcon className='w-4 h-4 text-red-500'/>
                </IconBox>
                <Strong>
                  Trend detected for
                  "{problem.field.name}"
                </Strong>
              </CardHeaderContainer>
              <CardBody>
                <div className="flex justify-between items-center w-full">
                  <LabeledField label="What does this mean?" renderValue={(
                    <MutedText>
                      The trend detected is a "first" significant increase in the measurement discrepancy
                      of {problem.field.name} for the
                      past month.
                      <br/>
                      <br/>
                      We recommend you to investigate this issue, mark it as "resolved", or ignore it
                      for future reference.
                    </MutedText>
                  )}/>
                </div>
              </CardBody>

              <CardFooter>
                <div className="flex space-2">
                  <div>
                    <Button
                      variant="white" size="xs">
                      Resolve
                    </Button>
                  </div>
                  <div>
                    <Button variant="ghost" size="xs">
                      <Icon className="w-4 h-4">
                        <EllipsisHorizontalIcon className="w-4 h-4"/>
                      </Icon>
                      Ignore
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Floater>
        )}
        <CardBody
          aria-label={`Field problem for ${problem.field.name} in `}>
          <div
            className="flex py-2 gap-2 w-full justify-between items-center"
          >
            <Strong>
              <div>
                {problem.field.name}
              </div>
            </Strong>

            <div>
              <div className="mt-1">
                <MutedText className="flex gap-2 items-center">
                  <div className="w-4 h-4">
                    <AccuracyIndicator score={problem.accuracy}/>
                  </div>
                  {formatPercentage(problem.accuracy)}
                </MutedText>
              </div>
            </div>
          </div>

          <hr/>

          <div className="flex justify-between items-center w-full">
            <MutedText className="flex justify-between w-full">
              <span
                aria-label={`Field by ${sellerInfo.title} for ${problem.field.name} PO ${orderAccuracyInfo.order.public_ref}`}>
                {sellerInfo.title}
              </span>
              <span
                aria-label={`Value for ${sellerInfo.title} for ${problem.field.name} for PO ${orderAccuracyInfo.order.public_ref}`}>
                                                                ~
                <span
                  className="text-gray-900">{problem.target_value?.toFixed(2)} {problem.field?.unit}</span>
              </span>
            </MutedText>
          </div>

          <div className="flex justify-between items-center w-full">
            <MutedText className="flex justify-between w-full">
              <span>
                {buyer.title}
              </span>
              <span>
                                                                ~
                <span
                  className="text-gray-900">

                  {problem.reference_value?.toFixed(2)}

                  {fieldType === 'percentage' ? '%' : ' '}
                  {problem.field?.unit}
                </span>
              </span>
            </MutedText>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
