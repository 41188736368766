// resources/js/Shared/Components/Analytics/DataWidget.tsx
import { ReactElement } from 'react';

import { Card, CardBody, CardGutter, CardHeader } from '@/Card';
import { ListDetail } from '@/Details';
import { WithGenericFallback } from '@/Fallback';
import SparkChart from '~/Components/Analytics/Charts/SparkCharts/SparkCharts';
import { RelativeDifferenceLabel } from '~/Components/Analytics/RelativeDifferenceLabel';
import { DataWidget as DataWidgetType } from '~/types/types';

interface DataWidgetProps {
  dataWidget: DataWidgetType;
  className?: string;
  icon?: ReactElement;
}

export function DataWidget({
  dataWidget,
  icon,
}: DataWidgetProps) {
  const { title, description, sparkChart, relativeDifference, value, valueLabel } = dataWidget;

  return (
    <Card>
      <CardHeader
        icon={icon}
        title={title}
        description={description}
      />

      <CardGutter variant='lighter'>
        <div className="flex justify-between w-full gap-8">
          <ListDetail
            label={valueLabel}
            value={value}
          />
          <RelativeDifferenceLabel difference={relativeDifference} />
        </div>
      </CardGutter>

      <hr />
      <CardBody className="mt-4">
        <WithGenericFallback>
          <SparkChart
            height={150}
            data={sparkChart}
            showXAxis
          />
        </WithGenericFallback>
      </CardBody>
    </Card>
  );
}