import { router } from '@inertiajs/react';
import { parse } from 'qs';
import { useCallback, useMemo } from 'react';

import { deepMerge } from '~/hooks/Api/apiFormatter';
import { usePageProps } from '~/hooks/usePageProps';
import { useApp } from '~/Providers/AppProvider';

import { fetchOtherParameters, filterNullParameters } from './useQuery.helpers';

interface UseQueryOptions<D = any, Out = string> {
  withParams?: boolean;
  preserveState?: boolean;
  preserveScroll?: boolean;
  only?: (keyof Out | string)[];
}

export const useQuery = <D = any, Out = string>(
  initialParameters: Partial<D> = {},
  {
    withParams = true,
    preserveState = true,
    preserveScroll = true,
    only = [],
  }: UseQueryOptions<D, Out> = {}
) => {
  const {
    app: { path },
  } = usePageProps();

  const { loading, setLoading } = useApp();

  // Get current parameters from URL if withParams is true
  const parameters: D = useMemo(() => {
    if (!withParams) return initialParameters;

    const urlParams = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    return deepMerge(urlParams, initialParameters);
  }, [initialParameters, withParams, window.location.search]); // Added window.location.search as dependency

  /**
   * Returns all parameters that have non-empty, non-null, non-undefined values
   */
  const activeParameters = useMemo(() => {
    const result: Partial<D> = {};

    Object.entries(parameters).forEach(([key, value]) => {
      // Skip null or undefined values
      if (value === null || value === undefined || value === '') {
        return;
      }

      // Skip empty arrays
      if (Array.isArray(value) && value.length === 0) {
        return;
      }

      // Include all other values
      result[key as keyof D] = value;
    });

    return result;
  }, [parameters]);

  /**
   * Counts the number of active parameters.
   *
   * If paramNames is undefined, it will count all parameters (except for certain exceptions)
   * If paramNames is defined, it will count only the parameters that are in the paramNames array.
   */
  const countActiveParameters = (paramNames?: string[]) => {
    const exceptions = ['page', 'sort_by', 'sort_asc'];

    if (!paramNames) {
      return Object.keys(activeParameters).filter((key) => !exceptions.includes(key)).length;
    }

    return Object.keys(activeParameters).filter((key) => paramNames.includes(key)).length;
  };

  /**
   * Updates the query parameters and reloads the page
   */
  const query = useCallback((
    newParameters: Partial<D>,
    options?: {
      replace?: boolean;
      preserveParams?: boolean;
    }
  ) => {
    const { replace = false, preserveParams = withParams } = options ?? {};

    let mergedParams = newParameters;

    // Merge with existing URL parameters if preserveParams is true
    if (preserveParams) {
      const urlParams = parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      mergedParams = deepMerge(urlParams, newParameters);
    }

    setLoading(true);

    return router.get(
      `/${path}`,
      mergedParams as any,
      {
        preserveState,
        preserveScroll,
        replace,
        only: only as any,
        onStart: () => {
          setLoading(true);
        },
        onFinish: () => {
          setLoading(false);
        },
      }
    );
  }, [path, withParams, preserveState, preserveScroll, only, setLoading]);

  /**
   * Resets the query parameters to their initial state
   * @param excludeParams - Optional array of parameter keys to exclude from reset
   */
  const reset = useCallback((excludeParams?: Array<keyof D>) => {
    if (excludeParams && excludeParams.length > 0) {
      // Get current URL parameters if withParams is true
      const urlParams = withParams ? parse(window.location.search, { ignoreQueryPrefix: true }) : {};

      // Create a parameters object with only the excluded parameters preserved
      const preservedParams = Object.fromEntries(
        Object.entries(parameters).filter(([key]) =>
          excludeParams.includes(key as keyof D)
        )
      );

      // Merge initial parameters with preserved parameters, ensuring excluded params take precedence
      const resetParams = { ...initialParameters, ...preservedParams };

      // If withParams is true, also preserve any excluded URL parameters
      if (withParams) {
        const preservedUrlParams = Object.fromEntries(
          Object.entries(urlParams).filter(([key]) =>
            excludeParams.includes(key as keyof D)
          )
        );
        Object.assign(resetParams, preservedUrlParams);
      }

      return query(resetParams, { replace: true, preserveParams: false });
    }

    return query(initialParameters, { replace: true, preserveParams: false });
  }, [initialParameters, parameters, query, withParams]);

  const setParameters = useCallback((newParameters: Partial<D>) => {
    return query(newParameters, { replace: true, preserveParams: true });
  }, [query]);

  /**
   * Updates the query parameters and reloads the page
   */
  const setParametersOfScope = useCallback((scopeParameters: Partial<D>) => {
    const urlParams = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const otherParams = fetchOtherParameters(urlParams, scopeParameters);
    const cleanedUpParams = filterNullParameters(scopeParameters);

    const newParameters = { ...cleanedUpParams, ...otherParams };

    setLoading(true);

    return router.get(
      `/${path}`,
      newParameters as any,
      {
        preserveState,
        preserveScroll,
        replace: true,
        only: only as any,
        onStart: () => {
          setLoading(true);
        },
        onFinish: () => {
          setLoading(false);
        },
      }
    );

  }, [path, preserveState, preserveScroll, only, setLoading]);

  /**
   * Updates a single parameter
   */
  const setParameter = useCallback(<K extends keyof D>(
    key: K,
    value: D[K]
  ) => {
    return query({
      ...parameters,
      [key]: value,
    });
  }, [query, parameters]);

  return {
    // Main query function
    query,

    // Helper functions
    reset,
    setParameters,
    setParameter,
    setParametersOfScope,

    // State
    parameters,
    activeParameters,
    countActiveParameters,
    isLoading: loading,
  };
};
