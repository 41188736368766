import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';

import { SparkChartData } from '~/types/types';

interface SparkChartProps {
  data: SparkChartData;
  height?: number;
  width?: number;
  variant?: 'line' | 'bar';
  showDots?: boolean;
  showXAxis?: boolean;
  className?: string;
}

export const SparkChart = ({
  data,
  height = 50,
  width = 200,
  variant = 'line',
  showDots = true,
  showXAxis = false,
  className,
}: SparkChartProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(width);

  // Update container width on resize
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    // Initial measurement
    updateWidth();

    // Set up resize observer
    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Clean up
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  const normalizedValues = useMemo(() => {
    const values = data.values;
    const max = Math.max(...values);
    const min = Math.min(...values);
    const range = max - min;

    return values.map((value) =>
      range === 0 ? 0.5 : (value - min) / range
    );
  }, [data.values]);

  // Calculate chart height to accommodate x-axis if needed
  const chartHeight = showXAxis ? height - 25 : height;

  const renderLineChart = () => {
    const points = normalizedValues.map((value, i) =>
      `${(i / (normalizedValues.length - 1)) * containerWidth},${chartHeight - (value * chartHeight)}`
    ).join(' ');

    // Create area path by adding bottom corners
    const areaPath = `${points} ${containerWidth},${chartHeight} 0,${chartHeight}`;

    // Create unique gradient ID
    const gradientId = `gradient-${data.type}-${data.color}-${Math.random().toString(36).substring(2, 9)}`;

    return (
      <g>
        <defs>
          <linearGradient
            id={gradientId}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="0%"
              stopColor={data.color}
              stopOpacity="0.2"
            />
            <stop
              offset="100%"
              stopColor={data.color}
              stopOpacity="0"
            />
          </linearGradient>
        </defs>

        {/* Gradient area */}
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          d={`M ${areaPath} Z`}
          fill={`url(#${gradientId})`}
        />

        {/* Line */}
        <motion.polyline
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
          }}
          points={points}
          fill="none"
          stroke={data.color}
          strokeWidth={2}
        />

        {/* Dots */}
        {showDots && normalizedValues.map((value, i) => (
          <motion.circle
            key={`dot-${i}`}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              delay: i * 0.1,
              type: 'spring',
              stiffness: 300,
              damping: 20,
            }}
            cx={(i / (normalizedValues.length - 1)) * containerWidth}
            cy={chartHeight - (value * chartHeight)}
            r={3}
            fill={data.color}
          />
        ))}
      </g>
    );
  };

  const renderBarChart = () => {
    const barWidth = containerWidth / normalizedValues.length - 2;
    const gradientId = `gradient-${data.type}-${data.color}-${Math.random().toString(36).substring(2, 9)}`;

    return (
      <g>
        <defs>
          <linearGradient
            id={gradientId}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="0%"
              stopColor={data.color}
              stopOpacity="1"
            />
            <stop
              offset="100%"
              stopColor={data.color}
              stopOpacity="0.4"
            />
          </linearGradient>
        </defs>
        {normalizedValues.map((value, i) => (
          <motion.rect
            key={`bar-${i}`}
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            transition={{
              delay: i * 0.05,
              type: 'spring',
              stiffness: 300,
              damping: 20,
            }}
            x={i * (containerWidth / normalizedValues.length)}
            y={chartHeight - (value * chartHeight)}
            width={barWidth}
            height={value * chartHeight}
            fill={`url(#${gradientId})`}
            rx={2}
            style={{ transformOrigin: 'bottom' }}
          />
        ))}
      </g>
    );
  };

  const renderXAxis = () => {
    if (!showXAxis) return null;

    // Only show a subset of labels to prevent overcrowding
    const maxLabels = Math.min(5, data.values.length);
    const step = Math.max(1, Math.ceil(data.values.length / maxLabels));

    // Format numbers to be more readable
    const formatValue = (value: number): string => {
      if (value >= 1000000) {
        return `${(value / 1000000).toFixed(1)}M`;
      } else if (value >= 1000) {
        return `${(value / 1000).toFixed(1)}K`;
      }
      return value.toString();
    };

    return (
      <g>
        {/* X-axis line */}
        <line
          x1="0"
          y1={chartHeight}
          x2={containerWidth}
          y2={chartHeight}
          stroke="#e2e8f0"
          strokeWidth="1"
        />

        {/* X-axis ticks - only show a subset */}
        {data.values.map((value, i) => {
          // Only render every nth tick, plus always the first and last
          if (i % step === 0 || i === data.values.length - 1) {
            return (
              <g key={`tick-${i}`}>
                <line
                  x1={(i / (data.values.length - 1)) * containerWidth}
                  y1={chartHeight}
                  x2={(i / (data.values.length - 1)) * containerWidth}
                  y2={chartHeight + 5}
                  stroke="#94a3b8"
                  strokeWidth="1"
                />
                <text
                  x={(i / (data.values.length - 1)) * containerWidth}
                  y={chartHeight + 20}
                  textAnchor="middle"
                  fontSize="10"
                  fontFamily="sans-serif"
                  fill="#64748b"
                >
                  {formatValue(value)}
                </text>
              </g>
            );
          }
          return null;
        })}
      </g>
    );
  };

  return (
    <div ref={containerRef} className={className}>
      <svg
        height={height}
        width="100%"
        preserveAspectRatio="none"
        className="overflow-visible"
      >
        {variant === 'line' ? renderLineChart() : renderBarChart()}
      </svg>
    </div>
  );
};

export default SparkChart;
