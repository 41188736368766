import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { type VariantProps, cva } from 'class-variance-authority';
import { format, startOfDay, startOfHour, startOfMonth, startOfWeek } from 'date-fns';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { Gutter } from '@/Gutter';
import { MutedText, Strong, Text } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';
import { cn } from '~/utils/cn';

// Timeline Container
interface TimelineProps {
  children: ReactNode;
  className?: string;
}

/**
 * Timeline component is a container for TimelineItem components.
 * @param children
 * @param className
 * @constructor
 */
export const Timeline = ({ children, className }: TimelineProps) => {
  return (
    <div className={cn('flow-root', className)}>
      <ul role="list" className="relative">
        {children}
      </ul>
    </div>
  );
};

// Timeline Item
interface TimelineItemProps {
  children: ReactNode;
  className?: string;
  showConnector?: boolean;
}

/**
 * TimelineItem is a single item in the Timeline component.
 * @param children
 * @param className
 * @param showConnector
 * @constructor
 */
export const TimelineItem = ({ children, className, showConnector = true }: TimelineItemProps) => {
  return (
    <motion.li
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={cn('relative pb-8', className)}
    >
      {showConnector && (
        <span
          aria-hidden="true"
          className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
        />
      )}
      <div className="relative flex items-start space-x-3">
        {children}
      </div>
    </motion.li>
  );
};

// Timeline Avatar Variants
const avatarVariants = cva(
  'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white shadow-xs border-gray-500',
  {
    variants: {
      variant: {
        gray: 'bg-gray-100 text-gray-500 border-gray-200! border! border-1 border-solid',
        blue: 'bg-blue-100 text-blue-600 border-blue-200! border! border-1 border-solid',
        green: 'bg-emerald-100 text-emerald-500 border-emerald-200! border! border-1 border-solid',
        yellow: 'bg-yellow-100 text-yellow-500',
        red: 'bg-red-100 text-red-500 border-red-200! border! border-1 border-solid',
      },
      size: {
        sm: 'h-6 w-6',
        default: 'h-8 w-8',
        lg: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'gray',
      size: 'default',
    },
  }
);

interface TimelineAvatarProps extends VariantProps<typeof avatarVariants> {
  children: ReactNode;
  className?: string;
}

/**
 * TimelineAvatar is a component that displays an avatar in the TimelineItem component.
 * @param children
 * @param variant
 * @param size
 * @param className
 * @constructor
 */
export const TimelineAvatar = ({
  children,
  variant,
  size,
  className,
}: TimelineAvatarProps) => {
  return (
    <div className="relative">
      <div
        className="relative px-1">
        <div className={cn(avatarVariants({ variant, size }), className)}>
          {children}
        </div>
      </div>
    </div>
  );
};

// Timeline Content
interface TimelineContentProps {
  children: ReactNode;
  className?: string;
}

/**
 * TimelineContent is a component that displays the content of the TimelineItem component.
 * @param children
 * @param className
 * @constructor
 */
export const TimelineContent = ({ children, className }: TimelineContentProps) => {
  return (
    <div className={cn('min-w-0 flex-1', className)}>
      {children}
    </div>
  );
};

/**
 * TimelineHeader is a component that displays the header of the TimelineContent component.
 * @param children
 * @param date
 * @constructor
 */
export const TimelineHeader = ({ children, date }: { children: ReactNode, date?: Date }) => {
  const { format } = useDate();

  return (
    <div className="flex justify-between items-center">
      <div>
        <Strong>{children}</Strong>
      </div>

      {date && (
        <MutedText>{format(date, DateFormat.HumanDateTime)}</MutedText>
      )}
    </div>
  );
};
export const TimelineDescription = Text;

export const TimelineActions = ({ children }: { children: ReactNode }) => {
  return (
    <div className="inline-block mt-1">
      <Gutter theme="transparent">
        {children}
      </Gutter>
    </div>
  );
};
cva(
  'absolute left-5 top-0 -ml-px h-full w-0.5',
  {
    variants: {
      variant: {
        default: 'bg-gray-200',
        solid: 'bg-gray-300',
        dashed: 'border-l border-dashed border-gray-300',
        dotted: 'border-l border-dotted border-gray-300',
      },
      thickness: {
        thin: 'w-px',
        default: 'w-0.5',
        thick: 'w-1',
      },
    },
    defaultVariants: {
      variant: 'default',
      thickness: 'default',
    },
  }
);

type GroupBy = 'day' | 'hour' | 'week' | 'month';

interface WithTimelineDateGroupProps<T> {
  items: T[];
  dateKey: keyof T;
  groupBy?: GroupBy;
  defaultExpanded?: boolean;
  formatGroup?: (date: Date) => string;
  children: (item: T) => ReactNode;
  showGroupConnectors?: boolean;
}

const defaultFormatters: Record<GroupBy, (date: Date) => string> = {
  hour: (date) => format(date, 'MMM d, yyyy hh:00 a'),
  day: (date) => format(date, 'MMMM d, yyyy'),
  week: (date) => `Week of ${format(date, 'MMM d, yyyy')}`,
  month: (date) => format(date, 'MMMM yyyy'),
};

const groupByFunctions: Record<GroupBy, (date: Date) => Date> = {
  hour: startOfHour,
  day: startOfDay,
  week: startOfWeek,
  month: startOfMonth,
};

export function WithTimelineDateGroup<T>({
  items,
  dateKey,
  groupBy = 'day',
  defaultExpanded = true,
  formatGroup,
  children,
}: WithTimelineDateGroupProps<T>) {
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});

  // Group items by the specified interval
  const groupedItems = useMemo(() => {
    return items.reduce((groups, item) => {
      const date = new Date(item[dateKey] as unknown as string);
      const groupDate = groupByFunctions[groupBy](date);
      const groupKey = groupDate.toISOString();

      if (!groups[groupKey]) {
        groups[groupKey] = {
          date: groupDate,
          items: [],
        };
      }

      groups[groupKey].items.push(item);
      return groups;
    }, {} as Record<string, { date: Date; items: T[] }>);
  }, [items, dateKey, groupBy]);

  // Sort groups by date (newest first)
  const sortedGroups = useMemo(() => {
    return Object.entries(groupedItems)
      .sort(([aKey], [bKey]) => new Date(bKey).getTime() - new Date(aKey).getTime());
  }, [groupedItems]);

  // Handle group expansion
  const toggleGroup = useCallback((groupKey: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupKey]: !prev[groupKey],
    }));
  }, []);

  const isGroupExpanded = useCallback((groupKey: string) => {
    return expandedGroups[groupKey] ?? defaultExpanded;
  }, [expandedGroups, defaultExpanded]);

  const formatter = formatGroup || defaultFormatters[groupBy];

  return (
    <div className="relative">
      {sortedGroups.map(([groupKey, group], groupIndex) => (
        <div key={groupKey} className="relative">
          <div className="relative mb-8 last:mb-0">
            {/* Group header with dot */}
            <motion.button
              className="relative flex w-full items-center justify-between px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              onClick={() => toggleGroup(groupKey)}
              initial={false}
            >
              <Strong className="ml-12 py-4 text-sm font-semibold text-gray-900">
                {formatter(group.date)}
              </Strong>
              <motion.div
                animate={{ rotate: isGroupExpanded(groupKey) ? 180 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronDownIcon className="h-5 w-5"/>
              </motion.div>
            </motion.button>

            <AnimatePresence initial={false}>
              {isGroupExpanded(groupKey) && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >

                  <div className="mt-2">
                    {group.items.map((item, index) => (
                      <TimelineItem
                        key={`${groupKey}-${index}`}
                        showConnector={
                          index !== group.items.length - 1 || // Show between items
                          (groupIndex !== sortedGroups.length - 1 && index === group.items.length - 1) // Show after last item if not last group
                        }
                      >
                        {children(item)}
                      </TimelineItem>
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      ))}
    </div>
  );
}
