import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, ContextArea } from '@/Card';
import { RAGScoreLabel } from '@/Labels/RAGScoreLabel';
import { MutedStrong, MutedText, Strong } from '@/Text';
import { OrderImage } from '~/Components/Orders/Representations/OrderImage';
import { QualityEntry } from '~/types/types';
import { cn } from '~/utils/cn';

interface QualityEntryCardProps {
  qualityEntry: QualityEntry;
  editable?: boolean;
  onChange?: (qualityEntry: QualityEntry) => void;
  compact?: boolean;
}

export const FieldQualityEntryCard = ({ qualityEntry, compact }: QualityEntryCardProps) => {

  return (
    <div className="border rounded bg-gray-50 border-gray-300 shadow-xs">
      <Card className="shadow-xs">
        <CardBody size="md">
          {qualityEntry.order && (
            <div className="mb-4">

              <div className="flex items-center gap-2">
                <div className="inline-block">
                  <OrderImage order={qualityEntry.order} size="sm"/>
                </div>
                <MutedText>
                  {qualityEntry.order.public_ref}
                </MutedText>
              </div>
            </div>
          )}
          <div className="flex items-center justify-between">
            <div className="gap-2 flex">
              <Strong>
                {qualityEntry.field.name}
              </Strong>
              <div>
                {qualityEntry.score && (
                  <RAGScoreLabel score={qualityEntry.score}/>
                )}
              </div>
            </div>

            <div>
              <div>

              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="flex justify-end py-1 px-2">
        <CardBody size="md" className="py-1!">
          <MutedStrong>
            32.00 Bx
          </MutedStrong>
        </CardBody>
      </div>
    </div>
  );
};

export const CommentQualityEntryCard = ({ qualityEntry, position = 'left', compact }: QualityEntryCardProps) => {
  return (
    <Card>
      <CardBody>
        {qualityEntry.order && !compact && (
          <div className="mb-4">

            <div className="flex items-center gap-2">
              <div className="inline-block">
                <OrderImage order={qualityEntry.order} size="sm"/>
              </div>
              <MutedText>
                {qualityEntry.order.public_ref}
              </MutedText>
            </div>
          </div>
        )}
        <div
          className={cn(
            position === 'right' ? 'ml-auto' : 'mr-auto',
          )}
        >
          <div className={cn(
            'relative p-4',
            'bg-gray-50 shadow-xs',
            'border border-zinc-200',
            'dark:bg-zinc-900 dark:border-zinc-800',
            'rounded-2xl',
            position === 'left' ? 'rounded-bl-none' : 'rounded-br-none'
          )}>
            <div className="space-y-2">
              <div className="text-xs font-medium text-zinc-500 dark:text-zinc-400">
                COMMENT
              </div>
              <div className="text-sm text-zinc-900 dark:text-zinc-100">
                "{qualityEntry.text}"
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export const QualityEntryCard = ({ qualityEntry, editable, compact }: QualityEntryCardProps) => {
  const { t } = useTranslation();

  if ('text' in qualityEntry && qualityEntry.text) {
    return (
      <CommentQualityEntryCard qualityEntry={qualityEntry} editable={editable} compact={compact}/>
    );
  }

  if ('field' in qualityEntry) {
    return (
      <FieldQualityEntryCard qualityEntry={qualityEntry} editable={editable} compact={compact}/>
    );
  }

  return (
    <></>
  );
};
