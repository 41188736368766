import { Slot } from '@radix-ui/react-slot';
import { MoreHorizontalIcon } from 'lucide-react';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@/Button';
import { CardIconBox } from '@/Card';
import {
  DropdownContent,
  DropdownGroup,
  DropdownGroupHeader,
  DropdownItem,
  DropdownRoot,
  DropdownSeparator,
  DropdownTrigger,
} from '@/DropdownMenu';
import { IconButtonIcon, SimpleIcon } from '@/Icon';
import { MutedText } from '@/Text';
import { BaseAction } from '~/Actions/BaseAction';
import { useCommandAction } from '~/hooks/useCommandAction';

interface CommandActionConfig {
  action: typeof BaseAction;
  description?: string;
  disabled?: boolean;
  target?: any;
  params?: any;
  onExecute?: (commandInstance: InstanceType<typeof BaseAction>) => Promise<void>;
  group?: string;
}

interface CommandActionDropdownProps {
  actionItems: CommandActionConfig[];
  children?: ReactNode;
  align?: 'start' | 'center' | 'end';
  layout?: 'compact' | 'full';
}

interface MoreIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
}

export const MoreIconButton = forwardRef<HTMLButtonElement, MoreIconButtonProps>(
  ({ onClick, ...props }, ref) => (
    <IconButton label="Options" onClick={onClick} {...props} ref={ref}>
      <IconButtonIcon icon={MoreHorizontalIcon}/>
    </IconButton>
  )
);
MoreIconButton.displayName = 'MoreIconButton';

/**
 * CommandActionDropdown
 *
 * This component constructs a DropdownMenu which renders a number of actions as items, and executes the given action.
 *
 * @example
 * <CommandActionDropdown
 *       trigger={
 *         <Button variant="ghost" size="icon">
 *           <MoreVertical className="w-4 h-4" />
 *         </Button>
 *       }
 *       actions={[
 *         {
 *           action: EditAction,
 *           target: someTarget,
 *         },
 *         {
 *           action: ShareAction,
 *           target: someTarget,
 *           group: 'sharing',
 *           onExecute: async (commandInstance) => {
 *             // Custom execution logic
 *             await commandInstance.execute({ someCustomParam: true });
 *           },
 *         },
 *         {
 *           action: DeleteAction,
 *           target: someTarget,
 *           group: 'danger',
 *         },
 *       ]}
 *     />
 */
export const CommandActionDropdown = ({
  children,
  actionItems,
  layout = 'compact',
  align = 'end',
}: CommandActionDropdownProps) => {
  const { t } = useTranslation();

  // Group actions if they have a group property
  const groupedActions = actionItems.reduce((acc, actionConfig) => {
    const group = actionConfig.group || 'default';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(actionConfig);
    return acc;
  }, {} as Record<string, CommandActionConfig[]>);

  // Sort groups to ensure 'default' is always first
  const sortedGroups = Object.keys(groupedActions).sort((a, b) => {
    if (a === 'default') return -1;
    if (b === 'default') return 1;
    return a.localeCompare(b);
  });

  return (
    <DropdownRoot>
      <DropdownTrigger asChild>
        <Slot>
          {children ? children : <MoreIconButton/>}
        </Slot>
      </DropdownTrigger>
      <DropdownContent align={align}>
        {sortedGroups.map((group, groupIndex) => {
          const groupActions = groupedActions[group];

          return (
            <div key={group}>
              {group !== 'default' && (
                <DropdownGroupHeader>
                  {t(group)}
                </DropdownGroupHeader>
              )}

              <DropdownGroup>
                {groupActions.map((actionConfig, index) => {
                  const [commandInstance, { loading }] = useCommandAction(
                    actionConfig.action,
                    actionConfig.target
                  );

                  return (
                    <DropdownItem
                      className='block font-normal text-gray-700'
                      key={`${group}-${index}`}
                      disabled={actionConfig.disabled || commandInstance.disabled() || loading}
                      onSelect={async () => {
                        if (actionConfig.onExecute) {
                          await actionConfig.onExecute(commandInstance);
                        } else {
                          await commandInstance.execute(actionConfig.params ?? {});
                        }
                      }}
                    >
                      <div className="flex items-center w-full gap-2">
                        {commandInstance.getIcon() && (
                          <>
                            {layout === 'compact' && (
                              <SimpleIcon
                                icon={commandInstance.getIcon()}
                                className="mr-2 h-4 w-4 !text-gray-700"
                              />
                            )}

                            {layout === 'full' && (
                              <CardIconBox icon={commandInstance.getIcon() as any} size="sm"/>
                            )}
                          </>
                        )}
                        <div>
                          <div>
                            {t(commandInstance.nameKey())}
                          </div>
                          {actionConfig.description && (
                            <MutedText>{actionConfig.description}</MutedText>
                          )}
                        </div>
                      </div>
                    </DropdownItem>
                  );
                })}
              </DropdownGroup>

              {groupIndex < sortedGroups.length - 1 && <DropdownSeparator/>}
            </div>
          );
        })}
      </DropdownContent>
    </DropdownRoot>
  );
};
