import 'react-photo-view/dist/react-photo-view.css';

import React from 'react';
import { PhotoProvider } from 'react-photo-view';

import { Helper } from '@/Text';
import { MultiGrouper } from '~/Components/Common/MultiGrouper';
import { Attachment } from '~/types/types';

import { AttachmentItem } from './AttachmentItem';

// Extend the Attachment type to include the group property
interface AttachmentWithGroup extends Attachment {
  group?: string;
}

interface AttachmentsGalleryProps {
  attachments: AttachmentWithGroup[];
  title?: string;
  grid?: boolean;
  gridCols?: 1 | 2 | 3 | 4;
  showComments?: boolean;
  itemSize?: 'sm' | 'md' | 'lg';
  groupByField?: boolean;
  layout?: 'default' | 'grid';
  multi?: boolean;
}

/**
 * Renders a gallery of attachments with configurable grid layout
 * Can use Grouper for more flexible layouts when multi=true
 */
export const AttachmentsGallery: React.FC<AttachmentsGalleryProps> = ({
  attachments,
  title = 'Attachments',
  grid = true,
  gridCols = 3,
  showComments = true,
  itemSize = 'md',
  groupByField = false,
  multi = false,
}) => {
  // Grid column classes
  const gridColsClass = {
    1: 'grid-cols-1',
    2: 'grid-cols-1 md:grid-cols-2',
    3: 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3',
    4: 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4',
  };

  // Group attachments by field if requested
  const groupedAttachments = React.useMemo(() => {
    return attachments.reduce((acc, attachment) => {
      // Use the group property if available, otherwise use a default group
      const group = attachment.group || 'Other Attachments';

      if (!acc[group]) {
        acc[group] = [];
      }

      acc[group].push(attachment);
      return acc;
    }, {} as Record<string, AttachmentWithGroup[]>);
  }, [attachments, groupByField]);

  // If using multi mode with Grouper
  if (multi) {
    return (
      <div className="space-y-4">
        <PhotoProvider>
          <MultiGrouper
            data={attachments}
            groupBy={(attachment) => attachment.group || 'Other Attachments'}
            gridCols={gridCols}
          >
            {(groupData) => (
              <div className={grid ? `p-4 grid ${gridColsClass[gridCols]} gap-4` : 'flex flex-wrap gap-4 py-4'}>
                {groupData.items.map((attachment: AttachmentWithGroup) => (
                  <AttachmentItem
                    key={attachment.id}
                    attachment={attachment}
                    showComments={showComments}
                    size={itemSize}
                  />
                ))}
              </div>
            )}
          </MultiGrouper>
        </PhotoProvider>
      </div>
    );
  }

  // Default rendering without Grouper
  return (
    <div className="space-y-4">
      {Object.entries(groupedAttachments).map(([group, groupAttachments]) => (
        <div key={group} className="space-y-2">
          <PhotoProvider>
            <div className="mt-4">
              {grid ? (
                <div className={`grid ${gridColsClass[gridCols]} gap-4`}>
                  {groupAttachments.map((attachment) => (
                    <AttachmentItem
                      key={attachment.id}
                      attachment={attachment}
                      showComments={showComments}
                      size={itemSize}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex flex-wrap gap-4">
                  {groupAttachments.map((attachment) => (
                    <div key={attachment.id} className="flex-none">
                      <AttachmentItem
                        attachment={attachment}
                        showComments={showComments}
                        size={itemSize}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </PhotoProvider>
        </div>
      ))}
    </div>
  );
};
