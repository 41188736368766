import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { HoverCardPortal } from '@radix-ui/react-hover-card';
import { ComponentPropsWithoutRef, ElementRef, forwardRef, ReactNode } from 'react';

import { cn } from '~/utils/cn';

import { TextLabel } from './Text';

const HoverCard = HoverCardPrimitive.Root;

const HoverCardTrigger = HoverCardPrimitive.Trigger;

const HoverCardContent = forwardRef<
  ElementRef<typeof HoverCardPrimitive.Content>,
  ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content> & { flat?: boolean }
>(({ className, align = 'center', sideOffset = 4, flat, ...props }, ref) => (
  <HoverCardPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    className={cn(
      // Base classes for layout and animation
      'z-1000 outline-hidden',
      'data-[state=open]:animate-in data-[state=closed]:animate-out',
      'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
      'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2',
      'data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',

      // Card-like properties, only applied when flat is false
      !flat && 'rounded-md border bg-popover p-4 text-popover-foreground shadow-xs',
      className,
    )}
    {...props}
  />
));
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

interface WithHoverCardProps {
  children: ReactNode;
  className?: string;
  title?: string;
  align?: 'center' | 'start';
  sideOffset?: number;
  renderContent: ReactNode;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
  flat?: boolean;
}

export const WithHoverCard = ({
  children,
  renderContent,
  disabled,
  onOpenChange,
  title,
  ...props
}: WithHoverCardProps) => (
  <HoverCard openDelay={200} closeDelay={200} onOpenChange={onOpenChange} open={disabled ? false : undefined}>
    <HoverCardTrigger className={cn('cursor-pointer')}>
      {children}
    </HoverCardTrigger>
    <HoverCardPortal>
      <HoverCardContent {...props}>
        {!!title && (
          <div className="px-4 py-2 mb-2 -mx-4 -mt-4 bg-slate-50 rounded-t-md">
            <TextLabel>
              {title}
            </TextLabel>
          </div>
        )}
        {renderContent}
      </HoverCardContent>
    </HoverCardPortal>
  </HoverCard>
);

export { HoverCard, HoverCardContent, HoverCardTrigger };
