import React from 'react';
import { useWatch } from 'react-hook-form';

import { ActionbarBlock, ActionbarRoot, ActionbarSeparator } from '@/Actionbar';
import { ActionbarIcon, WarningIcon } from '@/Icon';
import { SegmentedCircleProgress } from '~/Components/Common/SegmentedProgressBar';
import { FieldEntry } from '~/Components/Orders/QualityInput/formatter';
import { useQualityInput } from '~/Components/Orders/QualityInput/QualityInputProvider';

/**
 * Quality Input Action-Bar is a component used in the QualityInput page.
 *
 * @precondition Has a QualityInputProvider above it.
 */
export const QualityInputActionbar = () => {
  const { form } = useQualityInput();
  const { getFieldsResults } = useQualityInput();
  const fieldEntries: FieldEntry[] = useWatch({ control: form.control, name: 'fields' }) ?? [];
  const fieldResults = getFieldsResults(fieldEntries);

  const totalFieldEntries = fieldEntries.length;
  const untouchedFields = fieldResults.filter((fieldEntry) => !fieldEntry.touched).length;
  const touchedRequiredFields = fieldResults.filter((fieldEntry) => fieldEntry.touched && fieldEntry.definition.required).length;
  const touchedOptionalFields = fieldResults.filter((fieldEntry) => fieldEntry.touched && !fieldEntry.definition.required).length;
  const touchedFieldEntries = fieldResults.filter((fieldEntry) => fieldEntry.touched).length;

  const errorFieldEntries = fieldResults.filter((fieldResult) => fieldResult.has_risk === true).length;

  return (
    <ActionbarRoot show>
      <ActionbarBlock>
        <div aria-label="progress">
          <div className="flex items-center gap-2">
            <div className="max-w-[30px]">
              <SegmentedCircleProgress
                thickness="thick"
                elevation="lg"
                segments={[
                  {
                    value: untouchedFields,
                    color: '#ddd',
                    label: 'test',
                  },
                  {
                    value: touchedRequiredFields,
                    color: '#22c55e',
                    label: 'Touched',
                  },
                  {
                    value: touchedOptionalFields,
                    color: '#2B6CB0',
                    label: 'Errors',
                  },
                ]}/>
            </div>
            {touchedFieldEntries} / {totalFieldEntries}

          </div>
        </div>

      </ActionbarBlock>

      {errorFieldEntries > 0 && (
        <>
          <ActionbarSeparator/>

          <ActionbarBlock theme="red">
            <ActionbarIcon icon={WarningIcon}/>
            {errorFieldEntries}
          </ActionbarBlock>
        </>
      )}
    </ActionbarRoot>
  );
};
