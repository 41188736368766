import { ReactNode } from 'react';

import { MutedText, Strong } from './Text';

export interface LabeledFieldProps {
  label: string;
  uniqueLabel?: string;
  subLabel?: string;
  value?: string | number;
  renderValue?: ReactNode;
}

/**
 * LabeledField
 * Used to display fields above a value (or perhaps some input)
 */
export const LabeledField = ({ label, uniqueLabel, subLabel, value, renderValue }: LabeledFieldProps) => {
  return (
    <div className="space-y-1">
      <div>
        {uniqueLabel ? (
          <label htmlFor={uniqueLabel}>
            <Strong>
              {label}
            </Strong>
          </label>
        ) : (
          <Strong>
            {label}
          </Strong>
        )}
      </div>
      {subLabel && (
        <div>
          <MutedText className="block text-sm!">
            {subLabel}
          </MutedText>
        </div>
      )}
      <div>
        {value && (
          <>
            <MutedText className="text-sm">
              {value}
            </MutedText>
          </>
        )}
        {renderValue && (
          <>
            {renderValue}
          </>
        )}
      </div>
    </div>
  );
};
