import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { TruckIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { BoxIcon, ContainerIcon, ShipIcon, WarehouseIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardGutter, CardHeaderContainer } from '@/Card';
import { InlineDetails, ListDetail } from '@/Details';
import { WithHoverCard } from '@/HoverCard';
import { IconBox, IconedField, TransportLocationIcon } from '@/Icon';
import { Label, MultiLabel } from '@/Label';
import { NumericIndicator, WithNumericIndicator } from '@/NumericIndicator';
import { StatusDot } from '@/StatusDot';
import { CardSubtitle, CardTitle, Helper, MutedText } from '@/Text';
import { TimeHorizon } from '~/Components/Analytics/Charts/TimeHorizon/TimeHorizon';
import { CommentsPane } from '~/Components/Panes/CommentsPane';
import { DateFormat, useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import {
  LocationDataPayload,
  OrderDataStream,
  OrderDataStreamType,
} from '~/types/types';
import { formatLocation } from '~/utils/formatLocation';

import { useOrder } from '../OrderProvider';
import { TransportMap } from './Embeds/TransportMap';
import { LogisticsRouteIssues } from './LogisticsRouteIssues';
import { TransportSummaryText } from './TransportSummaryText';

interface TransportSummaryCardProps {
  dataStream: OrderDataStream<LocationDataPayload>;
}

function formatDelayToTimeHorizon(delay: Delay): TimeGraph {
  const nodes: TimeHorizonNode[] = [
    {
      id: 'ship',
      date: new Date(delay?.startDate),
      icon: BoxIcon,
      label: 'Shipped',
    },
    {
      id: 'delivery',
      date: new Date(delay?.originalArrivalDate),
      icon: WarehouseIcon,
      label: 'Expected Delivery',
      disabled: delay?.totalDelayInHours > 0,
    },
    {
      id: 'current',
      date: new Date(delay?.updatedArrivalDate),
      icon: TransportLocationIcon,
      label: delay?.totalDelayInHours > 0
        ? 'Delayed arrival'
        : 'In Transit',
    },
  ];

  const edges: TimeHorizonEdge[] = [
    {
      from: 'ship',
      to: 'current',
      status: 'completed',
    },
    {
      from: 'current',
      to: 'extended',
      type: 'optional',
      status: 'problem',
    },
  ];

  // If there's a delay, add the extended delivery node and edge
  if (delay?.totalDelayInHours > 0) {
    // nodes.push({
    //   id: 'extended',
    //   date: new Date(delay.updatedArrivalDate),
    //   icon: ExclamationTriangleIcon,
    //   label: 'Update: Delay detected',
    // });

    edges.push({
      from: 'delivery',
      to: 'current',
      status: 'pending',
      type: 'optional',
      status: 'problem',
      label: `Delay duration: ${delay?.totalDelayInHours}h`,
    });
  }

  return { nodes, edges };
}

export const TransportRouteCard = ({
  dataStream,
}: TransportSummaryCardProps) => {
  const { order } = useOrder();
  const { organization } = usePageProps();
  const { t } = useTranslation();
  const { format, parse } = useDate();

  const data = dataStream.data;

  const validDataStream = typeof dataStream?.data === 'object' &&
    !!dataStream.data &&
    'meta' in dataStream.data &&
    'events' in dataStream.data;

  if (!validDataStream) return null;

  const latestEvent =
    data.events.length > 0 ? data.events[data.events.length - 1] : null;

  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    middleware: [offset(10)],
    whileElementsMounted: autoUpdate,
  });

  const issues =
    dataStream?.issues?.filter((issue) => issue.status !== 'read') || [];

  const dataStreamSource = dataStream.data.meta.source ?? 'Sensitech';

  const loads = order.loads ?? [];

  const firstLoad = loads[0];

  return (
    <div id={OrderDataStreamType.LOCATION} ref={refs.setReference}>
      <Card>
        <CardHeaderContainer className="py-6">
          <div className="flex align-top space-x-3">
            <div>
              <WithNumericIndicator value={issues.length ?? 0}>
                <IconBox>
                  <TruckIcon className="w-6 h-6" />
                </IconBox>
              </WithNumericIndicator>
            </div>
            <div>
              <CardTitle className="flex items-center mb-1 leading-none">
                {t('transport_route')}
              </CardTitle>
              <div>
                <CardSubtitle>{t('transport_route_description')}</CardSubtitle>
              </div>
            </div>
          </div>
        </CardHeaderContainer>
        <CardGutter>
          {firstLoad && (
            <InlineDetails>
              <ListDetail icon={ShipIcon} label="Vessel" value={firstLoad.vessel_ext_ref}/>
              <ListDetail icon={ContainerIcon} label="Container" value={firstLoad.container_ext_ref}/>
            </InlineDetails>
          )}
        </CardGutter>
        <div className="bg-gray-50!">
          <CardBody>
            <div className="space-y-4">
              <div className="space-y-2">
                <div>
                  <Helper>{t('summary')}</Helper>
                  <div className="flex items-center">
                    <TimeHorizon
                      compact
                      timeGraph={formatDelayToTimeHorizon(order.delay)}
                      showLabels={true}
                    />
                  </div>
                </div>
                {latestEvent && latestEvent.label && (
                  <div>
                    <Helper>{t('estimated_location')}</Helper>
                    <div className="flex items-center">
                      <MutedText>
                        <span className="inline-flex items-center">
                          <IconedField icon={TruckIcon}>
                            {formatLocation(latestEvent)}
                          </IconedField>
                        </span>
                      </MutedText>
                    </div>
                  </div>
                )}
                <div>
                  <Helper>{t('live_update')}</Helper>
                  <div className="flex items-center">
                    <div className="mr-1">
                      <WithHoverCard renderContent={<>div</>}>
                        <StatusDot success={true} />
                      </WithHoverCard>
                    </div>
                    <MutedText>
                      <span>
                        {t('transports_summaries.last_synced_at', {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          lastSyncedAt: format(
                            parse(latestEvent?.date),
                            DateFormat.HumanDateTime
                          ),
                          source: dataStreamSource,
                        })}
                      </span>
                    </MutedText>
                  </div>
                </div>
                <div className="-mx-3 sm:-mx-8">
                  <TransportMap data={data} />
                </div>
              </div>
            </div>
          </CardBody>
        </div>
        <CardFooter>
          <div className="flex space-x-2">
            <CommentsPane
              commentable={{
                id: dataStream.id,
                type: 'OrderDataStream',
              }}
              comments={dataStream.comments || []}
              path={`/b/${organization.id}/comments/add`}
              Trigger={
                <Button className="flex items-center" size="sm" variant="white">
                  <span>Comment</span>
                  {dataStream.comments && dataStream.comments.length > 0 && (
                    <span className="ml-1">
                      <NumericIndicator brand="red" size="xs">
                        {dataStream.comments?.length}
                      </NumericIndicator>
                    </span>
                  )}
                </Button>
              }
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
