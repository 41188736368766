// noinspection JSUnusedGlobalSymbols

import { CaretRightIcon } from '@radix-ui/react-icons';
import { BuildingIcon, ChartBarIcon } from 'lucide-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@/Button';
import { Card, CardBody, CardFooter, NavCard } from '@/Card';
import { Container } from '@/Container';
import { WithGenericFallback } from '@/Fallback';
import { SearchIcon, SellerIcon } from '@/Icon';
import { DebugJSON } from '@/JSON';
import { LayoutHeader } from '@/Layout';
import { ParamsSetter } from '@/Pickers/ParamsSetter';
import { SearchBar } from '@/Searchbar';
import { PageDescription, PageTitle } from '@/Text';
import { JoinOrganization } from '~/Actions/Organizations/JoinOrganization';
import Section from '~/Components/Common/Section';
import { useCommandAction } from '~/hooks/useCommandAction';
import { Organization } from '~/types/types';

import { AdminLayout } from '../AdminLayout';
import { CreateOrganizationModal } from './CreateOrganizationModal';
import { DataWidget } from './DataWidget';
import { AdminPageProps } from './types';

const SearchOrganizationCard = ({ organization }: { organization: Organization }) => {
  const [join, { loading }] = useCommandAction(JoinOrganization, organization);

  return (
    <Card isLoading={loading}>
      <CardBody>
        {organization.title}
      </CardBody>
      <CardFooter>
        <div className="flex items-center gap-2">
          <Button size="sm" variant="white" onClick={() => join.execute({})}>
            Join
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

export const AdminPage = ({ buyerOrganizations, sellerOrganizations, searchOrganizations, dataWidgets }: AdminPageProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <LayoutHeader>
        <div className="flex justify-between">
          <div>
            <PageTitle>
              Admin Dashboard
            </PageTitle>
            <PageDescription>
              Manage your organizations, users, and more.
            </PageDescription>
          </div>

          <div>
            <CreateOrganizationModal>
              <Button>
                {t('create_workspace')}
              </Button>
            </CreateOrganizationModal>
          </div>
        </div>
      </LayoutHeader>

      <Container>
        <div className="py-16 space-y-12">
          <Section title="Your buyer organizations" icon={BuildingIcon} enableToggle showCollapsed>
            <div className="grid gap-4 mt-4 md:grid-cols-3">
              {buyerOrganizations.map((organization) => (
                <div key={organization.id} id={`organization::${organization.id}`}
                  aria-label={`Go to ${organization.title}`}>
                  <NavCard href={`/b/${organization.id}`}>
                    <CardBody>
                      <div className="flex items-center justify-between">
                        {organization.title}
                        <div>
                          <IconButton label="More">
                            <CaretRightIcon className="w-5 h-5 text-gray-500"/>
                          </IconButton>
                        </div>
                      </div>
                    </CardBody>
                  </NavCard>
                </div>
              ))}
            </div>
          </Section>

          <Section title="Your seller organizations" icon={SellerIcon} enableToggle showCollapsed>
            <div className="grid gap-4 mt-4 md:grid-cols-3">
              {sellerOrganizations.map((organization) => (
                <NavCard href={`/s/${organization.id}`} key={organization.id}>
                  <CardBody>
                    <div className="flex items-center justify-between">
                      {organization.title}
                      <div>
                        <IconButton label="More">
                          <CaretRightIcon className="w-5 h-5 text-gray-500"/>
                        </IconButton>
                      </div>
                    </div>
                  </CardBody>
                </NavCard>
              ))}
            </div>
          </Section>

          <Section title="Search other organizations" icon={SearchIcon} enableToggle showCollapsed>
            <div>
              <ParamsSetter dataKey="search" dataPrefix="params">
                {({ data, loading, onChange }) => (
                  <SearchBar value={data} onChange={onChange} loading={loading} placeholder="Search organizations"/>
                )}
              </ParamsSetter>
            </div>

            <div>
              {searchOrganizations && searchOrganizations.length > 0 && (
                <div className="grid gap-4 mt-4 md:grid-cols-3">
                  {searchOrganizations.map((organization) => (
                    <SearchOrganizationCard organization={organization} key={organization.id}/>
                  ))}
                </div>
              )}
            </div>
          </Section>

          <Section title="Data" icon={ChartBarIcon}>
            <WithGenericFallback>
              <div className="grid gap-4 mt-4 md:grid-cols-3">
                {Object.entries(dataWidgets).map(([key, dataWidget]) => (
                  <DataWidget key={key} dataWidget={dataWidget}/>
                ))}
              </div>
            </WithGenericFallback>
          </Section>
        </div>
      </Container>
    </div>
  );
};

AdminPage.layout = (page: ReactNode) => <AdminLayout>{page}</AdminLayout>;

export default AdminPage;
