import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  DelayIcon,
  Icon,
  LightThresholdIcon,
  MajorMinorDefectIcon,
  MeasurementFieldIcon,
  TransportTemperatureIcon,
} from '@/Icon';
import { Issue, IssueType } from '~/types/types';
import { filterActiveIssues } from '~/utils/issues';

interface QualityBreakdownSummaryProps {
  issues: Issue[];
}

interface GenericIssueCountProps {
  issueCount: number;
}

const MeasurementIssueCount = ({ issueCount }: GenericIssueCountProps) => {
  const { t } = useTranslation();
  return (
    <a
      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-normal text-gray-700 ring-1 ring-inset ring-gray-200"
    >
      <Icon className="w-3! h-3!">
        <MeasurementFieldIcon className="w-3 max-h-3 stroke-red-700"/>
      </Icon>
      {t('issue_breakdowns.measurement_issues', { count: issueCount })}
    </a>
  );
};

const MajorMinorIssuesDefectCount = ({ issueCount }: GenericIssueCountProps) => {
  const { t } = useTranslation();
  return (
    <a
      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-normal text-gray-700 ring-1 ring-inset ring-gray-200"
    >
      <Icon className="w-3! h-3!">
        <MajorMinorDefectIcon className="w-3 max-h-3 stroke-red-700"/>
      </Icon>
      {t('issue_breakdowns.minor_major_issues', { count: issueCount })}
    </a>
  );
};

const TransportTempThresholdCount = ({ issueCount }: GenericIssueCountProps) => {
  const { t } = useTranslation();

  return (
    <a
      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-normal text-gray-700 ring-1 ring-inset ring-gray-200"
    >
      <Icon className="w-3! h-3!">
        <TransportTemperatureIcon className="w-3 max-h-3 stroke-red-700"/>
      </Icon>
      {t('issue_breakdowns.temperature_issues', { count: issueCount })}
    </a>
  );
};

const TransportLightThresholdCount = ({ issueCount }: GenericIssueCountProps) => {
  const { t } = useTranslation();

  return (
    <a
      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-normal text-gray-700 ring-1 ring-inset ring-gray-200"
    >
      <Icon className="w-3! h-3!">
        <LightThresholdIcon className="w-3 max-h-3 stroke-red-700"/>
      </Icon>
      {t('issue_breakdowns.light_issues', { count: issueCount })}
    </a>
  );
};

const DelayThresholdCount = ({ issueCount }: GenericIssueCountProps) => {
  const { t } = useTranslation();

  return (
    <a
      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-normal text-gray-700 ring-1 ring-inset ring-gray-200"
    >
      <Icon className="w-3! h-3!">
        <DelayIcon className="w-3 max-h-3 stroke-red-700"/>
      </Icon>
      {t('issue_breakdowns.delay_issues', { count: issueCount })}
    </a>
  );
};

export const QualityBreakdownSummary = ({ issues }: QualityBreakdownSummaryProps) => {
  const activeIssues = filterActiveIssues(issues);

  const groupedIssues = groupBy(activeIssues, 'type') as Record<IssueType, Issue[]>;

  return (
    <div>
      <div className="space-x-2">
        {'out_of_measurement_spec' in groupedIssues && (
          <MeasurementIssueCount issueCount={groupedIssues.out_of_measurement_spec.length}/>
        )}

        {'transport_temp_threshold' in groupedIssues && (
          <TransportTempThresholdCount issueCount={groupedIssues.transport_temp_threshold.length}/>
        )}

        {'transport_light_threshold' in groupedIssues && (
          <TransportLightThresholdCount issueCount={groupedIssues.transport_light_threshold.length}/>
        )}

        {'minor_major_defects' in groupedIssues && (
          <MajorMinorIssuesDefectCount issueCount={groupedIssues.minor_major_defects.length}/>
        )}

        {'transport_delay' in groupedIssues && (
          <DelayThresholdCount issueCount={groupedIssues.transport_delay.length}/>
        )}
      </div>
    </div>
  );
};
