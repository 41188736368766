import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { Card, CardBody, CardFooter, CardHeaderContainer } from '@/Card';
import { ChartConfig, ChartContainer, ChartTooltipContent } from '@/Chart';
import { CardTitle, Strong, TextLabel } from '@/Text';
import { colorMap } from '~/Components/Analytics/colorMap';
import { DateFormat, useDate } from '~/hooks/useDate';
import { TimeSeries, TimeSeriesGroup } from '~/types/types';

// Update the TimeEvent interface to include isPadded
interface TimeEvent {
  date: string;
  value: number;
  isPadded: boolean | null;
  label?: string | null;
  additional?: any[] | null;
}

interface TimeseriesChartProps {
  /**
   * Array of TimeSeries objects to display
   */
  seriesGroup: TimeSeriesGroup;
  /**
   * Chart title
   */
  title?: string;
  /**
   * Height of the chart container
   */
  height?: number | string;
  /**
   * Width of the chart container
   */
  width?: number | string;
  /**
   * Unit to display on the Y-axis
   */
  unit?: string;
  /**
   * Whether to show dots on the lines
   */
  showDots?: boolean;
  /**
   * Whether to show the legend
   */
  showLegend?: boolean;
  /**
   * Custom class name for the container
   */
  className?: string;
    /**
   * Sync ID for synchronizing with other charts
   */
    syncId?: string;
}

interface LabelProps {
  x: number;
  y: number;
  stroke: string;
  value: string;
  opacity: number;
}

const LineEndLabel: React.FC<LabelProps> = ({ x, y, value, stroke, opacity }) => {
  return (
    <text
      x={x + 5}
      y={y - 10}
      fill={stroke}
      fontSize={11}
      textAnchor="start"
      fontWeight="500"
      opacity={opacity}
    >
      {value}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  const { parseOrFormat } = useDate();

  if (!active || !payload || !payload.length) return null;

  return (
    <ChartTooltipContent
      active={active}
      payload={payload}
      label={label ? parseOrFormat(label, DateFormat.HumanDateTime) : ''}
      indicator="line"
    />
  );
};

// Format floating point numbers consistently
const formatFloat = (value: number): string => {
  // Display with 1 decimal place
  return value.toFixed(1);
};

interface CustomLineProps {
  type: string;
  points: Array<{ x: number; y: number }>;
  baseLine?: number;
  stroke: string;
  strokeWidth: number;
  strokeOpacity: number;
  fill: string;
  fillOpacity: number;
  payload: any;
}

const CustomLine: React.FC<CustomLineProps> = ({
  type,
  points,
  stroke,
  strokeWidth,
  strokeOpacity,
  fill,
  fillOpacity,
  payload,
}) => {
  if (!points || points.length < 2) return null;

  // Create segments based on padded status
  const segments: Array<Array<{ x: number; y: number }>> = [];
  let currentSegment: Array<{ x: number; y: number }> = [];
  let currentIsPadded = payload[0]?.isPadded || false;

  points.forEach((point, index) => {
    const isPadded = payload[index]?.isPadded || false;

    if (isPadded !== currentIsPadded && currentSegment.length > 0) {
      segments.push(currentSegment);
      currentSegment = [];
      currentIsPadded = isPadded;
    }

    currentSegment.push(point);
  });

  if (currentSegment.length > 0) {
    segments.push(currentSegment);
  }

  return (
    <>
      {segments.map((segment, index) => (
        <Line
          key={index}
          type={type as any}
          points={segment}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeOpacity={strokeOpacity}
          strokeDasharray={currentIsPadded ? '5 5' : '0'}
          fill="none"
        />
      ))}
    </>
  );
};

export const TimeseriesChart = ({
  seriesGroup,
  title,
  height = 550,
  width = '100%',
  unit = '',
  showDots = true,
  showLegend = true,
  className = '',
  syncId,
}: TimeseriesChartProps) => {
  const { parseOrFormat } = useDate();
  const [hoveredSeries, setHoveredSeries] = useState<string | null>(null);

  // Process the data for Recharts - merge all series by date
  const chartData = useMemo(() => {
    if (!seriesGroup || !seriesGroup.timeSeries.length) return [];

    const dateMap = new Map<string, Record<string, any>>();

    // First, collect all unique dates
    seriesGroup.timeSeries.forEach((s) => {
      (s.events || []).forEach((event: TimeEvent) => {
        if (!dateMap.has(event.date)) {
          dateMap.set(event.date, { date: event.date });
        }
      });
    });

    // Then, populate values for each series at each date
    seriesGroup.timeSeries.forEach((s, seriesIndex) => {
      const seriesKey = s.label || `series-${seriesIndex}`;

      (s.events || []).forEach((event: TimeEvent) => {
        const entry = dateMap.get(event.date);
        if (entry) {
          entry[seriesKey] = event.value;
        }
      });
    });

    return Array.from(dateMap.values()).sort((a, b) =>
      new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }, [seriesGroup]);

  // Get series keys for the legend and lines
  const seriesKeys = useMemo(() => {
    return seriesGroup.timeSeries.map((s, index) => s.label || `series-${index}`);
  }, [seriesGroup]);

  // Calculate the domain for Y-axis, using seriesGroup bounds if available
  const yDomain = useMemo(() => {
    // First check if seriesGroup has predefined bounds
    if (seriesGroup.yMin !== undefined && seriesGroup.yMax !== undefined) {
      // Add some padding to the domain
      const range = seriesGroup.yMax - seriesGroup.yMin;
      const padding = range * 0.15;
      return [
        Math.max(0, seriesGroup.yMin - padding),
        seriesGroup.yMax + padding,
      ];
    }

    // Otherwise calculate from data
    if (!chartData.length) return [0, 100];

    let min = Infinity;
    let max = -Infinity;

    (chartData || []).forEach((dataPoint) => {
      (seriesKeys || []).forEach((key) => {
        if (dataPoint[key] !== undefined) {
          min = Math.min(min, dataPoint[key]);
          max = Math.max(max, dataPoint[key]);
        }
      });
    });

    // Add some padding to the domain (extra padding for labels)
    const padding = (max - min) * 0.15;
    return [Math.max(0, min - padding), max + padding];
  }, [chartData, seriesKeys, seriesGroup]);

  // Calculate the domain for X-axis, using seriesGroup bounds if available
  const xDomain = useMemo(() => {
    // First check if seriesGroup has predefined bounds
    if (seriesGroup.xMin !== undefined && seriesGroup.xMax !== undefined) {
      return [seriesGroup.xMin, seriesGroup.xMax];
    }

    // Otherwise use the first and last dates from chartData
    if (!chartData.length) return undefined; // Let recharts handle it

    const firstDate = new Date(chartData[0].date).getTime();
    const lastDate = new Date(chartData[chartData.length - 1].date).getTime();

    return [firstDate, lastDate];
  }, [chartData, seriesGroup]);

  // Find the last valid data point for each series
  const lastValidPoints = useMemo(() => {
    const result: Record<string, { value: number, dataIndex: number }> = {};

    seriesKeys.forEach(key => {
      // Go through the data in reverse to find the last valid point for each series
      for (let i = chartData.length - 1; i >= 0; i--) {
        if (chartData[i][key] !== undefined) {
          result[key] = {
            dataIndex: i,
            value: chartData[i][key],
          };
          break;
        }
      }
    });

    return result;
  }, [chartData, seriesKeys]);

  // Handle mouse enter for a series
  const handleMouseEnter = (seriesKey: string) => {
    setHoveredSeries(seriesKey);
  };

  // Handle mouse leave for a series
  const handleMouseLeave = () => {
    setHoveredSeries(null);
  };

  const chartConfig = {
    ...seriesGroup.timeSeries.reduce((acc, series, index) => {
      const color = series.color || colorMap[index % colorMap.length];
      return {
        ...acc,
        [series.label || `series-${index}`]: {
          label: series.label || `Series ${index + 1}`,
          color,
          formatValue: (value: number) => `${formatFloat(value)}${unit}`,
        },
      };
    }, {}),
  } satisfies ChartConfig;

  if (!seriesGroup || !seriesGroup.timeSeries.length) {
    return <div className="p-4 text-center">No data available</div>;
  }

  return (
    <div className={`flex flex-col ${className}`}>
      {title && <CardTitle className="mb-4">{title}</CardTitle>}

      <div>
        <ChartContainer config={chartConfig} className="w-full h-[500px]">
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 60, left: 20, bottom: 30 }}
            onMouseLeave={handleMouseLeave}
            syncId={syncId}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="date"
              tickFormatter={(date) => parseOrFormat(date, DateFormat.DayFormat)}
              tick={{ fontSize: 12 }}
              domain={xDomain}
              type="category"
            />
            <YAxis
              domain={yDomain}
              tickFormatter={(value) => `${formatFloat(value)}${unit}`}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={{ pointerEvents: 'none' }}
            />

            {seriesKeys.map((key, index) => {
              const color = seriesGroup.timeSeries[index].color || colorMap[index % colorMap.length];
              const lastPoint = lastValidPoints[key];
              const isHovered = hoveredSeries === key;
              const noHover = hoveredSeries === null;
              const opacity = noHover ? 1 : isHovered ? 1 : 0.04;
              const strokeWidth = noHover ? 2 : isHovered ? 3 : 1;

              return (
                <React.Fragment key={key}>
                  <Line
                    type={'monotone' as any}
                    dataKey={key}
                    name={key}
                    stroke={color}
                    fill={color}
                    strokeWidth={strokeWidth}
                    strokeOpacity={opacity}
                    dot={showDots ? {
                      r: 4,
                      strokeWidth: 2,
                      fill: 'white',
                      strokeOpacity: opacity,
                    } : false}
                    activeDot={{
                      r: 6,
                      strokeOpacity: 1,
                    }}
                    connectNulls={true}
                    isAnimationActive={true}
                    onMouseEnter={() => handleMouseEnter(key)}
                  />
                  {lastPoint && (
                    <ReferenceLine
                      x={chartData[lastPoint.dataIndex].date}
                      y={lastPoint.value}
                      stroke="none"
                      label={{
                        value: key,
                        position: 'right',
                        fill: color,
                        opacity: opacity,
                        fontSize: 11,
                        fontWeight: 500,
                      }}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </LineChart>

        </ChartContainer>
      </div>

      {showLegend && seriesKeys.length > 0 && (
        <CardFooter>
          <div className="flex flex-wrap gap-4">
            {seriesKeys.map((key, index) => {
              const color = seriesGroup.timeSeries[index].color || colorMap[index % colorMap.length];
              const isHovered = hoveredSeries === key;
              const noHover = hoveredSeries === null;
              const opacity = noHover ? 1 : isHovered ? 1 : 0.2;

              return (
                <div
                  key={key}
                  className="flex items-center cursor-pointer"
                  onMouseEnter={() => handleMouseEnter(key)}
                  onMouseLeave={handleMouseLeave}
                  style={{ opacity }}
                >
                  <div
                    className="w-3 h-3 mr-2 rounded-full"
                    style={{
                      backgroundColor: color,
                    }}
                  />
                  <TextLabel>{key}</TextLabel>
                </div>
              );
            })}
          </div>
        </CardFooter>
      )}
    </div>
  );
};
