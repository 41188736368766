import '../css/app.css';
import './config/i18n';

import { createInertiaApp } from '@inertiajs/react';
import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { JSONDebugProvider } from '~/Components/Common/Tools/JSONDebugProvider';
import { Toaster } from '~/hooks/useToast';
import { initSentry } from '~/utils/initSentry';

initSentry();

const options: Partial<PostHogConfig> = {
  api_host: 'https://eu.posthog.com',
  autocapture: true,
  on_xhr_error: (e) => {
    console.log('Something went wrong with PostHog', e);
  },
  capture_pageleave: false,
  capture_pageview: false,
  debug: true,
};

createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob('./pages/**/*Page.tsx', { eager: true });
    return pages[`./pages/${name}.tsx`];
  },
  setup({ el, App, props }) {
    createRoot(el).render(
      <React.StrictMode>
        <JSONDebugProvider>
          <PostHogProvider
            apiKey={import.meta.env.VITE_ENVIRONMENT !== 'local' && import.meta.env.VITE_ENVIRONMENT !== 'test' ? 'phc_9uYBq6HE5v6ZqLYifrUnDER3QtsJIylGuYxmgcRlifb' : undefined}
            options={options}
          >
            <App {...props} />
          </PostHogProvider>

          <Toaster/>
        </JSONDebugProvider>

      </React.StrictMode>
    );
  },
});
