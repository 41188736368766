import { AnimatePresence, motion } from 'framer-motion';
import { AlertCircleIcon, CheckIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';

import { Button } from '@/Button';
import { Card, CardBody, CardPanels } from '@/Card';
import { Checkbox } from '@/Checkbox';
import { FormField } from '@/Form';
import { Input, InputGroup, RightAddon } from '@/Input';
import { LabeledField } from '@/LabeledField';
import { ConstraintsLabel } from '@/Labels/ConstraintsLabel';
import Switch from '@/Switch';
import { Helper, MutedText, Strong, Text } from '@/Text';
import { AddFieldNote } from '~/Components/Orders/AddFieldNote';
import { FieldEntry as FormFieldType } from '~/Components/Orders/QualityInput/formatter';
import { getStatusFromField, useQualityInput } from '~/Components/Orders/QualityInput/QualityInputProvider';
import { calculateDomainFromRange, SpecBoxPlot } from '~/Components/Spec/SpecBoxPlot';
import { Field, FieldConstraint } from '~/types/types';
import { preprocessConstraint } from '~/utils/constraints';

import { MultiImageUpload } from './MultiImageUpload';

interface FieldInputCardProps {
  defaultFormEntry: FormFieldType;
  renderResults?: boolean;
  fieldDefinition: Field;
  index: number;
}

const springTransition = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
  mass: 1,
};

const FormFieldWithDebounce = ({ formField, id }: { formField: { onChange: any, value: any }, id: string }) => {
  const [localValue, setLocalValue] = useState(formField.value);
  const debouncedValue = useDebounce(localValue);

  // Update the form value when debounced value changes
  useEffect(() => {
    formField.onChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <Input
      value={localValue}
      onChange={(e: any) => setLocalValue(e.target.value)}
      placeholder="22"
      type="number"
      id={id}
      className="w-20 bg-white"
    />
  );
};

export const FieldInputCard = ({ fieldDefinition, renderResults, index }: FieldInputCardProps) => {
  const { t } = useTranslation();
  const { form, isEditing } = useQualityInput();
  const [usePercentage, setUsePercentage] = useState(true);

  /**
   * This is the `fresh` field entry from the form.
   * - The `defaultFormEntry` is the initial value of the field. But it is not updated when the user changes the value.
   */
  const fieldEntry = useWatch({ control: form.control, name: `fields.${index}` });

  // Initialize selectedFiles with any existing attachments from the form
  const [selectedFiles, setSelectedFiles] = useState<File[]>(fieldEntry?.attachments || []);

  // Update selectedFiles when fieldEntry.attachments changes
  useEffect(() => {
    if (fieldEntry?.attachments) {
      setSelectedFiles(fieldEntry.attachments);
    }
  }, [fieldEntry?.attachments]);

  const activeConstraint = fieldDefinition.constraints?.[0];

  /**
   * We render the defect field only if the field has a `percentage` or `defect` type.
   */
  const renderDefectCheckbox = fieldDefinition.type === 'percentage' || fieldDefinition.type === 'boolean';
  const status = getStatusFromField(fieldEntry, fieldDefinition);
  const hasDefect = status === 'risk';
  const renderPercentage = (fieldDefinition.type === 'percentage' || fieldDefinition.type === 'boolean') && usePercentage;

  /**
   * We might want to render a measurement box plot if the field is a measurement field and the user has entered a value.
   */
  const measurementRange = activeConstraint?.ranges?.[0];
  const requiresMeasurement = fieldDefinition.type === 'measurement';
  const measurementField = fieldEntry.measurement as number;
  const isMeasurementField = fieldDefinition.type === 'measurement';
  const requiresSamples = isMeasurementField;
  const hasMeasurementConstraint = isMeasurementField && !!measurementRange;
  const renderInputBoxPlot = hasMeasurementConstraint && fieldEntry.measurement !== undefined && fieldEntry.measurement !== null && fieldEntry.measurement !== '';

  /**
   * Handle file selection for attachments
   */
  const handleFileUpload = (files: File[]) => {
    console.log('Files uploaded:', files);

    // Update the form with the selected files
    const fieldPath = `fields.${index}` as const;

    form.setValue(`${fieldPath}.attachments`, files, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    // Also update the local state for UI display
    setSelectedFiles(files);

    console.log('Form updated with new attachments');
  };

  /**
   * TODO: Refactor (constraint is wrong)
   */

  return (
    <CardPanels columns="1_2" aria-label={`row for ${fieldDefinition.name}`}>
      <CardBody bordered="all" variant="muted" className="xs:rounded-t lg:rounded-none">
        <div className="py-2">
          <div>
            <Strong className="leading-none!">
              {fieldDefinition.name}
            </Strong>
          </div>

          <div className="mb-2">
            {activeConstraint && (
              <ConstraintsLabel
                constraint={preprocessConstraint(activeConstraint as FieldConstraint, fieldDefinition.type)}
                field={fieldDefinition}
              />
            )}
          </div>

          {!isEditing && (
            <FormField
              name={`fields.${index}.note`}
              render={({ field }) => (
                <AddFieldNote
                  ariaLabel={`note field for ${fieldDefinition?.name}`}
                  value={field.value || ''}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          )}

          {hasDefect && renderResults && (
            <AnimatePresence mode="sync">
              <motion.div
                initial={{ opacity: 0, height: 0, y: -20 }}
                animate={{
                  opacity: 1,
                  height: 'auto',
                  y: 0,
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                  y: -20,
                }}
                transition={springTransition}
                className="overflow-hidden"
              >
                <MutedText className="flex items-center gap-1">
                  <AlertCircleIcon className="w-4 h-4 text-red-500"/>
                  Issue detected
                </MutedText>
              </motion.div>
            </AnimatePresence>
          )}

          {!hasDefect && renderResults && (
            <AnimatePresence mode="sync">
              <motion.div
                initial={{ opacity: 0, height: 0, y: -20 }}
                animate={{
                  opacity: 1,
                  height: 'auto',
                  y: 0,
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                  y: -20,
                }}
                transition={springTransition}
                className="overflow-hidden"
              >
                <MutedText className="flex items-center gap-1">
                  <CheckIcon className="w-4 h-4 text-green-500"/>
                  Checks are passing
                </MutedText>
              </motion.div>
            </AnimatePresence>
          )}

        </div>
      </CardBody>

      <CardBody className="border-gray-200 border-solid lg:border-b">
        {renderDefectCheckbox && (
          <div className="flex">
            <div>
              <FormField
                name={`fields.${index}.has_defect`}
                render={({ field }) => (
                  <div className="flex gap-2">
                    <Text>
                      Defect present?
                    </Text>
                    <Checkbox
                      checked={field.value as boolean}
                      onCheckedChange={(val) => {
                        field.onChange(val);
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        )}

        {requiresMeasurement && (
          <div className="flex flex-wrap items-end justify-between gap-2">
            <div className="flex-grow">
              <FormField
                name={`fields.${index}.measurement`}
                render={({ field }) => (
                  <LabeledField
                    label={t('average_measurement')}
                    uniqueLabel={`average measurement for ${fieldDefinition?.name}`}
                    renderValue={
                      <div>
                        <InputGroup>
                          <FormFieldWithDebounce id={`average measurement for ${fieldDefinition?.name}`}
                            formField={field}/>
                          <RightAddon>
                            {fieldDefinition.unit}
                          </RightAddon>
                        </InputGroup>
                      </div>
                    }
                  />
                )}
              />
            </div>

            {renderInputBoxPlot && (
              <div className="flex flex-shrink-0">
                <div className="min-w-[150px] max-w-[150px] flex-grow-2">
                  <Card>
                    <CardBody size="xs" variant="muted" className="rounded">
                      <SpecBoxPlot
                        domain={calculateDomainFromRange(measurementRange, measurementField)}
                        range={measurementRange}
                        value={measurementField}
                        compact
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            )}
          </div>
        )}

        {requiresSamples && (
          <div className="mt-4">
            <FormField
              name={`fields.${index}.samples`}
              render={({ field }) => (
                <LabeledField
                  label={t('total_samples_inspected')}
                  subLabel={t('total_samples_inspected_description') as string}
                  uniqueLabel={`ratio samples for ${fieldDefinition?.name}`}
                  renderValue={
                    <InputGroup className="flex-1 md:flex-initial">
                      <Input
                        rounded="left"
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="22"
                        type="number"
                        className="w-20 bg-white"
                        id={`ratio samples for ${fieldDefinition?.name}`}
                      />
                      <RightAddon>units</RightAddon>
                    </InputGroup>
                  }
                />
              )}
            />
          </div>
        )}

        <AnimatePresence mode="sync">
          {hasDefect && !requiresSamples && (
            <motion.div
              initial={{ opacity: 0, height: 0, y: -20 }}
              animate={{
                opacity: 1,
                height: 'auto',
                y: 0,
              }}
              exit={{
                opacity: 0,
                height: 0,
                y: -20,
              }}
              transition={springTransition}
              className="overflow-hidden"
            >
              <div className="px-4 py-4 mt-4 border-gray-100 rounded bg-gray-50 border-1">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <div className="flex items-center justify-between gap-4">
                    <div>
                      <Helper>
                        Details
                      </Helper>
                    </div>

                    <div>
                      <label htmlFor={`switch-${index}`} className="flex items-center gap-2">
                        <Switch
                          id={`switch-${index}`}
                          size="xs"
                          onCheckedChange={(value) => {
                            setUsePercentage(value);
                          }}
                          checked={usePercentage}
                        />
                        <MutedText>{t('report_by_percentage')}</MutedText>
                      </label>
                    </div>
                  </div>

                  <hr/>

                  {renderPercentage ? (
                    <div>
                      <FormField
                        name={`fields.${index}.percentage`}
                        render={({ field }) => (
                          <LabeledField
                            label={t('percentage_defect')}
                            subLabel={t('percentage_defect_description') as string}
                            uniqueLabel={`percentage value field for ${fieldDefinition?.name}`}
                            renderValue={
                              <InputGroup className="flex-1 md:flex-initial">
                                <Input
                                  rounded="left"
                                  {...field}
                                  placeholder="22"
                                  type="number"
                                  className="w-20 bg-white"
                                  id={`percentage value field for ${fieldDefinition?.name}`}
                                />
                                <RightAddon>%</RightAddon>
                              </InputGroup>
                            }
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div className="pt-2 space-y-4">
                      <FormField
                        name={`fields.${index}.samples_with_defect`}
                        render={({ field }) => (
                          <LabeledField
                            label={t('count_defects')}
                            uniqueLabel={`samples with defect for ${fieldDefinition?.name}`}
                            subLabel={t('count_defects_description') as string}
                            renderValue={
                              <InputGroup className="flex-1 md:flex-initial">
                                <Input
                                  rounded="left"
                                  onChange={(e: any) => {
                                    field.onChange(e.target.value);
                                  }}
                                  value={field.value}
                                  defaultValue={field.value}
                                  placeholder="22"
                                  type="number"
                                  className="w-20 bg-white"
                                  id={`samples with defect for ${fieldDefinition?.name}`}
                                />
                                <RightAddon>units</RightAddon>
                              </InputGroup>
                            }
                          />
                        )}
                      />

                      <FormField
                        name={`fields.${index}.samples`}
                        render={({ field }) => (
                          <LabeledField
                            label={t('total_samples_inspected')}
                            subLabel={t('total_samples_inspected_description') as string}
                            uniqueLabel={`ratio samples for ${fieldDefinition?.name}`}
                            renderValue={
                              <InputGroup className="flex-1 md:flex-initial">
                                <Input
                                  rounded="left"
                                  value={field.value}
                                  onChange={field.onChange}
                                  placeholder="22"
                                  type="number"
                                  className="w-20 bg-white"
                                  id={`ratio samples for ${fieldDefinition?.name}`}
                                />
                                <RightAddon>units</RightAddon>
                              </InputGroup>
                            }
                          />
                        )}
                      />
                    </div>
                  )}

                  {/* File Upload Section - Only show for percentage fields when defect is checked */}
                  {fieldDefinition.type === 'percentage' && hasDefect && (
                    <div className="mt-4">
                      <LabeledField
                        label={t('defect_attachments')}
                        subLabel={t('defect_attachments_description') || 'Upload images of the defect (optional)'}
                        uniqueLabel={`defect attachments for ${fieldDefinition?.name}`}
                        renderValue={
                          <FormField
                            name={`fields.${index}.attachments`}
                            render={() => (
                              <MultiImageUpload
                                onUpload={handleFileUpload}
                                initialFiles={selectedFiles}
                              />
                            )}
                          />
                        }
                      />
                    </div>
                  )}
                </motion.div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </CardBody>
    </CardPanels>
  );
};
