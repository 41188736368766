import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { CubeIcon } from '@heroicons/react/24/outline';
import { router } from '@inertiajs/react';
import { CalendarFoldIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { AdminOnly } from '@/AdminOnly';
import { Button } from '@/Button';
import { CardBody, CardPanels } from '@/Card';
import { ListDetail } from '@/Details';
import { CommentIcon, ForecastAccuracyIcon, Icon, SimpleIcon, SpecFileIcon } from '@/Icon';
import { Label, MiniLabel } from '@/Label';
import { ConstraintsLabel } from '@/Labels/ConstraintsLabel';
import { Helper, HelperDescription, MutedText, Strong, Text } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { AttachmentsGallery } from '~/Components/Attachments/AttachmentsGallery';
import { LazyExpandableCard } from '~/Components/Common/Lazy/LazyExpandableCard';
import { AccuracyIndicator } from '~/Components/Indicators/AccuracyIndicator';
import { FormatCount } from '~/Components/Orders/FormatCount';
import { DateFormat, useDate } from '~/hooks/useDate';
import { useInspection } from '~/hooks/useInspection';
import { AccuracyScore, Field, FieldConstraint, FieldSummary, OrderFieldSummary, Pallet, Spec } from '~/types/types';
import { preprocessConstraint } from '~/utils/constraints';

import { OrderPageProps } from '../../../../pages/Dashboard/Order/OrderPage';
import { useOrder } from '../../../../pages/Dashboard/Order/OrderProvider';
import { FieldIcon } from '../../Fields/FieldIcon';
import { InspectionStatusIcon } from '../../Orders/InspectionStatusIcon';
import { QualityEntriesTable } from '../../Orders/QualityEntriesTable';
import { FormatSummarizable } from './FormatSummarizable';

interface FieldPreviewProps {
  field: Field;
  fieldSummaries: FieldSummary[];
  constraint?: FieldConstraint;
  isOpen: boolean;
  accuracyScore?: AccuracyScore;
}

export const FieldPreview = ({
  fieldSummaries,
  field,
  constraint,
  accuracyScore,
  isOpen,
}: FieldPreviewProps) => {
  const { t } = useTranslation();

  // TODO: This won't work in Shared folder (since it can only be used in OrderPage), but we'll fix it somehow..
  const { comments } = useOrder();

  const commentsTotal = comments.filter((comment) => {
    return comment.commentable?.field_id === field.id;
  }).length;

  const fieldName = t(field.translation_key_ref as string, {
    defaultValue: field.name,
  });

  const { format } = useDate();

  return (
    <CardPanels columns="1_2" className="xs:m-4 xs:rounded xs:border lg:m-0 lg:rounded-none lg:border-none">
      <CardBody bordered="all" variant="muted" className="xs:rounded-t lg:rounded-none">
        {/*  Field info */}
        <div className="justify-between py-2 xs:flex lg:block">
          <div>
            <div className="flex items-center space-x-1">
              <FieldIcon field={field}/>
              <Strong className="sm:hidden">Field: {fieldName}</Strong>
              <Strong className="hidden sm:inline">{fieldName}</Strong>
            </div>

            <div className="mt-2 space-y-2">
              <ListDetail
                label={t('spec')}
                value=""
                layout='space-between'
                dtype="render"
                icon={SpecFileIcon}
                renderFn={() => (
                  <>
                    {!constraint && <Label>{t('no_spec')}</Label>}
                    {constraint && (
                      <div className="space-y-1">
                        <ConstraintsLabel
                          compact
                          fractionToPercentage
                          constraint={preprocessConstraint(constraint, field.type)}
                          field={field}
                        />
                      </div>
                    )}
                  </>
                )}
              />

              <AdminOnly>
                {accuracyScore && (
                  <ListDetail
                    label={t('accuracy')}
                    value=""
                    icon={ForecastAccuracyIcon}
                    layout='space-between'
                    dtype="render"
                    renderFn={() => (
                      <div>
                        <div className="flex items-center gap-2">
                          <div className="max-w-4">
                            <AccuracyIndicator score={accuracyScore.score}/>
                          </div>

                          <MutedText>
                            {accuracyScore.score.toFixed(2)} %
                          </MutedText>
                        </div>
                      </div>
                    )}
                  />
                )}
              </AdminOnly>

              {commentsTotal > 0 && (
                <ListDetail
                  label={t('comments')}
                  layout='space-between'
                  value=""
                  icon={CommentIcon}
                  dtype="render"
                  renderFn={() => (
                    <div className="flex items-center gap-2">
                      <Label theme="blue">
                        {t('n_comments', { count: commentsTotal })}
                      </Label>
                    </div>
                  )}
                />
              )}
            </div>
          </div>

          {/* Details button */}
          <div className="mt-4">
            <Button
              variant="outline"
              size="xs"
              className="w-full sm:w-auto"
            >
              {isOpen ? (
                <ChevronUpIcon className="w-5 h-5"/>
              ) : (
                <ChevronDownIcon className="w-5 h-5"/>
              )}
              {t('details')}
            </Button>
          </div>
        </div>
      </CardBody>

      <div className="px-4 py-2 divide-y divide-gray-100">
        {fieldSummaries.map((fieldSummary) => (
          <div
            key={fieldSummary.id}
            className="flex items-center justify-between py-4"
            aria-label={`FieldSummary ${field.name} for ${
              fieldSummary.inspection?.order_step?.type
            } ${
              fieldSummary?.issues && fieldSummary.issues.length > 0
                ? 'with issues'
                : 'without issues'
            } and for variety ${fieldSummary?.produce_variety?.name}`}
          >
            <div className="flex justify-between w-full">
              {/* On the left side */}
              <div className="flex mt-1 space-x-1">
                <div className="inline-block leading-none">
                  <InspectionStatusIcon
                    hasIssue={!!fieldSummary?.issues && fieldSummary.issues.length > 0}
                  />
                </div>

                <div className="inline-block">
                  <div className="flex items-center gap-2">
                    <Strong className="block leading-none">
                      {fieldSummary.inspection?.organization?.title}
                    </Strong>
                    <MutedText className="leading-none text-gray-400!">
                      {fieldSummary?.produce_variety?.name}
                    </MutedText>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <MiniLabel>
                      {t(`step_alias_short.${fieldSummary.inspection?.order_step?.type}`)}
                    </MiniLabel>
                    <MutedText className="leading-none! text-gray-500! text-xxs">
                      <span className="flex items-center gap-1">
                        <SimpleIcon className="stroke-gray-300!" icon={CalendarFoldIcon}/>
                        {format(fieldSummary.created_at, DateFormat.HumanDateTime)}
                      </span>
                    </MutedText>
                  </div>
                </div>
              </div>

              {/* On the right side */}
              <div>
                <Text className="leading-none">
                  <FormatSummarizable
                    fieldSummary={fieldSummary}
                  />
                </Text>
                <MutedText className="flex justify-end">
                  <FormatCount count={fieldSummary.summarizable.count_samples}
                    unitType={fieldSummary.summarizable.unit_type}/>
                </MutedText>
                <div>
                  {fieldSummary.score && (
                    <div className="flex justify-end pt-2">
                      {fieldSummary.score === 'red' && (
                        <Tooltipped
                          label={`This quality entry has been scored according to the RAG score with ${fieldSummary.score}`}>
                          <Label theme="red">
                            {t('red')}
                          </Label>
                        </Tooltipped>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {fieldSummaries.length === 1 && (
          <div/>
        )}
      </div>
    </CardPanels>
  );
};

interface FieldBodyProps {
  orderFieldSummary: OrderFieldSummary;
  activeSpec: Spec | null;
}

const FieldBody = ({ orderFieldSummary, activeSpec }: FieldBodyProps) => {
  const { t } = useTranslation();
  const { getInspection } = useInspection();

  const fieldSummary = orderFieldSummary.fieldSummaries[0];
  const qualityEntries =
    fieldSummary.field?.quality_entries?.map((entry: any) => {
      return {
        ...entry,
        orderStepType: getInspection(entry.inspection_id)?.order_step?.type,
      };
    }) || [];

  const unitFieldSummaries =
    orderFieldSummary.fieldSummaries[0]?.field?.unit_field_summaries || [];

  // We derive the unit type from the data.
  // ASSUMPTION: All units are of the same type.
  const mainUnitType = unitFieldSummaries?.[0]?.unitable_type ?? null;

  const handleOpenActiveUnit = (unitable: Pallet) => {
    router.reload({
      data: { activeUnitId: unitable.id },
    });
  };

  // Get all attachments from quality entries
  const allAttachments = qualityEntries.flatMap((entry: any) => entry.attachments || []);

  return (
    <>
      <CardBody className="px-6 bg-gray-100 border-t border-gray-800 border-solid" size="sm">
        <div className="space-y-4">
          {/* Render attachments using the new AttachmentsGallery component */}
          {allAttachments.length > 0 && (
            <>
              <div>
                <div className="flex items-center gap-2">
                  <Helper>{t('attachments')}</Helper>
                </div>

                <hr className="mt-2"/>
              </div>

              <AttachmentsGallery
                attachments={allAttachments}
                title={t('attachments') || 'Attachments'}
                gridCols={3}
                showComments={true}
                itemSize="md"
                groupByField={true}
              />
            </>
          )}

          <div>
            <div className="flex items-center gap-2">
              <Helper>{t('history')}</Helper>
            </div>

            <hr className="mt-2"/>
          </div>

          {!!mainUnitType && (
            <div>
              <div>
                <Helper>{mainUnitType}</Helper>
              </div>
              <HelperDescription>
                These are the <strong>{unitFieldSummaries.length}</strong>{' '}
                pallets which have been checked for this measurement.
              </HelperDescription>
              <div className="px-4 mt-1 border divide-y rounded border-slate-200 divide-slate-200">
                {unitFieldSummaries.map((unitFieldSummary: any) => (
                  <div
                    key={unitFieldSummary.id}
                    className="flex items-center py-2 text-gray-200"
                  >
                    <Icon className="mr-2">
                      <CubeIcon/>
                    </Icon>
                    <Text>{unitFieldSummary.unitable.public_ref}</Text>

                    <div className="flex ml-auto mr-4 space-x-2">
                      <div>
                        <Label theme="red">
                          <FormatSummarizable fieldSummary={unitFieldSummary}/>
                        </Label>
                      </div>

                      <div>
                        <Label theme="gray">
                          {unitFieldSummary.summarizable.count_samples} cases
                        </Label>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <Button
                        size="sm"
                        variant="white"
                        onClick={() =>
                          handleOpenActiveUnit(unitFieldSummary.unitable)
                        }
                      >
                        Inspect
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="mt-2">
            <QualityEntriesTable
              activeSpec={activeSpec}
              qualityEntries={qualityEntries}
            />
          </div>
        </div>
      </CardBody>
    </>
  );
};

export interface GenericFieldSummaryProps {
  isOpen: boolean;
  orderFieldSummary: OrderFieldSummary;
  activeSpec: App.Models.Spec | null;
}

/**
 * The GenericFieldSummary component is used to display a summary of a field at a glance. Clicking on the preview will
 * open the full summary.
 * @param orderFieldSummary - An OrderFieldSummary contains multiple summaries (ie per inspection) related to one field per order.
 * @param isOpen - Whether the summary is open or not.
 * @param activeSpec - The active spec for the current field/produce.
 */
export const GenericFieldSummary = ({
  activeSpec,
  orderFieldSummary,
}: GenericFieldSummaryProps) => {
  const field = orderFieldSummary.field;
  const fieldSummaries = orderFieldSummary.fieldSummaries;
  const relevantConstraint = orderFieldSummary.relevantConstraint;

  const accuracyScore = orderFieldSummary?.accuracyScore;

  return (
    <LazyExpandableCard<OrderPageProps, { activeFieldId: string | null }>
      data={{ activeFieldId: field.id }}
      resultKey="activeFieldSummary"
      enableCache
      reloadKey="activeFieldSummary"
      renderPreview={({ open }) => (
        <FieldPreview
          constraint={relevantConstraint}
          field={field}
          accuracyScore={accuracyScore}
          fieldSummaries={fieldSummaries}
          isOpen={open ?? false}
        />
      )}
      renderContent={(result: OrderFieldSummary) => (
        <FieldBody
          activeSpec={activeSpec}
          orderFieldSummary={result}
        />
      )}
    />
  );
};
