import { type VariantProps, cva } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { cn } from '~/utils/cn';

const gutterVariants = cva(
  'relative overflow-hidden rounded-lg',
  {
    variants: {
      theme: {
        gray: 'bg-gray-50 border border-gray-100 ring-gray-200/10',
        white: 'bg-white border border-gray-100',
        transparent: 'pl-0! bg-transparent',
      },
      size: {
        sm: 'px-1.5',
        md: 'px-2 py-1',
        lg: 'p-3',
      },
      shadow: {
        true: 'shadow-xs',
        false: '',
      },
      width: {
        sm: 'max-w-sm',
        md: 'max-w-md',
        lg: 'max-w-lg',
        xl: 'max-w-xl',
        '2xl': 'max-w-2xl',
        full: 'w-full',
        fit: 'w-fit',
      },
    },
    defaultVariants: {
      theme: 'transparent',
      size: 'md',
      shadow: false,
      width: 'full',
    },
  }
);

const gapVariants = {
  none: 'gap-0',
  sm: 'gap-1',
  md: 'gap-2',
  lg: 'gap-3',
  xl: 'gap-4',
};

interface GutterProps extends VariantProps<typeof gutterVariants> {
  children: ReactNode;
  className?: string;
  gap?: 'none' | 'sm' | 'md' | 'lg';
  scrollAmount?: number;
}

export const Gutter = ({
  children,
  className,
  theme = 'gray',
  size,
  shadow,
  width,
  gap = 'sm',
}: GutterProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);

  const checkScroll = () => {
    const el = scrollRef.current;
    if (!el) return;

    setShowLeftShadow(el.scrollLeft > 0);
    setShowRightShadow(
      Math.floor(el.scrollLeft) < el.scrollWidth - el.clientWidth - 1
    );
  };

  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    checkScroll();
    el.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);

    return () => {
      el.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  return (
    <div className={cn(gutterVariants({ theme, size, shadow, width }), className)}>
      <AnimatePresence>
        {showLeftShadow && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute top-0 bottom-0 left-0 z-10 w-8 pointer-events-none bg-linear-to-r from-gray-50/90 to-transparent"
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showRightShadow && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute top-0 bottom-0 right-0 z-10 w-8 pointer-events-none bg-linear-to-l from-gray-50/90 to-transparent"
          />
        )}
      </AnimatePresence>

      <div
        ref={scrollRef}
        className={cn(
          'flex overflow-x-auto flex-wrap scrollbar-none relative',
          gapVariants[gap]
        )}
        style={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {children}
      </div>
    </div>
  );
};
