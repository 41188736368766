import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { WarehouseIcon } from 'lucide-react';

import { OrderIcon, TransportLocationIcon } from '@/Icon';
import { Tooltipped } from '@/Tooltip';
import { TimeEdge, TimeGraph, TimeNode } from '~/Components/Analytics/Charts/TimeHorizon/TimeHorizon.helpers';
import { useDate } from '~/hooks/useDate';
import { cn } from '~/utils/cn';

// Types for our component

interface TimeHorizonProps {
  timeGraph?: TimeGraph;
  className?: string;
  showLabels?: boolean;
  compact?: boolean;
}

const edgeColorMap = {
  active: {
    solid: 'bg-linear-to-r from-blue-500 to-blue-400',
    optional: 'bg-blue-500',
  },
  pending: {
    solid: 'bg-linear-to-r from-gray-300 to-gray-200',
    optional: 'bg-gray-300',
  },
  completed: {
    solid: 'bg-linear-to-r from-blue-500 to-blue-400',
    optional: 'bg-blue-500',
  },
  problem: {
    solid: 'bg-linear-to-r from-red-500 to-red-400',
    optional: 'bg-red-500',
  },
};
export const TimeHorizon = ({
  timeGraph,
  className,
  showLabels = true,
  compact = false,
}: TimeHorizonProps) => {
  const { nodes = [], edges = [] } = timeGraph || {};

  if (nodes.length < 2) {
    return null;
  }

  if (edges.length === 0) {
    return null;
  }

  const sortedNodes = [...nodes].sort((a, b) => a?.date?.getTime() - b?.date?.getTime());

  const segments = sortedNodes.slice(0, -1).map((node, index) => {
    const nextNode = sortedNodes[index + 1];
    const edge = edges.find(e => e.from === node.id && e.to === nextNode.id);

    return {
      fromNode: node,
      toNode: nextNode,
      edge,
    };
  });

  const { parseOrFormat } = useDate();

  return (
    <div
      className={cn(
        'w-full',
        compact ? 'py-4' : 'py-12', // Reduce vertical padding in compact mode
        className
      )}
    >
      <div className="relative flex flex-col">
        {/* Connecting lines between nodes */}
        <div className={cn(
          'flex h-2 items-center',
          compact ? 'mx-6' : 'mx-12' // Reduce horizontal padding in compact mode
        )}>
          {segments.map(({ fromNode, toNode, edge }, index) => (
            <motion.div
              key={`${fromNode.id}-${toNode.id}`}
              className={cn(
                'flex-1 relative h-full mt-2',
                compact ? 'mt-4' : 'mt-2' // Reduce margin in compact mode
              )}
            >
              {/* Edge label */}
              {edge?.label && (
                <div className="absolute w-full -top-6 text-center">
                  <span className={cn(
                    'text-xs',
                    edge.status === 'problem' ? 'text-red-500' : 'text-gray-500'
                  )}>
                    {edge.label}
                  </span>
                </div>
              )}

              {/* Base line */}
              <div className="absolute inset-0 bg-gray-200 rounded-full"/>

              {/* Animated progress line */}
              {edge && (
                <motion.div
                  className={cn(
                    'absolute inset-0 rounded-full',
                    edgeColorMap[edge.status][edge.type || 'solid'],
                    edge.type === 'optional' &&
                    '[mask-image:linear-gradient(to_right,#000_50%,transparent_50%)] [mask-size:8px_100%]',
                    edge.status === 'problem' && 'animate-pulse'
                  )}
                  initial={{ width: 0 }}
                  animate={{ width: '100%' }}
                  transition={{
                    type: 'spring',
                    bounce: 0.2,
                    duration: 0.6,
                    delay: index * 0.1,
                  }}
                />
              )}
            </motion.div>
          ))}
        </div>

        {/* Nodes - now with tooltips */}
        <div className={cn(
          'flex justify-between',
          compact ? '-mt-[14px] px-4' : '-mt-[18px] px-8' // Adjust spacing in compact mode
        )}>
          {sortedNodes.map((node, index) => {
            const Icon = node.icon;
            const hasProblems = edges.some(
              edge =>
                (edge.from === node.id || edge.to === node.id) &&
                edge.status === 'problem'
            );

            // Create tooltip content
            const tooltipContent = (
              <div className="flex flex-col items-center gap-1">
                <span className="font-medium">
                  {parseOrFormat(node.date, 'MMM d, yyyy')}
                </span>
                {node.label && (
                  <span className={cn(
                    'text-xs',
                    hasProblems ? 'text-red-200' : 'text-gray-200'
                  )}>
                    {node.label}
                  </span>
                )}
              </div>
            );

            return (
              <motion.div
                key={node.id}
                className={cn(
                  'flex flex-col items-center',
                  'px-4',
                  index === 0 && '-ml-4',
                  index === sortedNodes.length - 1 && '-mr-4',
                  node.disabled && 'opacity-40' // Add opacity when disabled
                )}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: node.disabled ? 0.4 : 1, y: 0 }} // Also handle in animation
                transition={{
                  type: 'spring',
                  bounce: 0.2,
                  duration: 0.6,
                  delay: index * 0.1,
                }}
              >
                <Tooltipped label={tooltipContent}>
                  <div className="relative cursor-pointer">
                    <div className="absolute inset-0 rounded-full bg-white"/>
                    {hasProblems && (
                      <div
                        className={cn(
                          'absolute inset-0 rounded-full blur-[2px]',
                          'bg-linear-to-b from-red-400/50 to-red-500/50',
                          'animate-pulse'
                        )}
                      />
                    )}
                    <div
                      className={cn(
                        'relative w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-white',
                        'border-2 flex items-center justify-center shadow-xs',
                        hasProblems ? 'border-red-500' : 'border-blue-500'
                      )}
                    >
                      <Icon
                        className={cn(
                          'w-4 h-4 sm:w-5 sm:h-5',
                          hasProblems ? 'text-red-500' : 'text-blue-500'
                        )}
                      />
                    </div>
                  </div>
                </Tooltipped>

                {/* Optional labels below nodes */}
                {showLabels && (
                  <div className="flex flex-col items-center mt-6">
                    <span className="text-xs sm:text-sm font-medium text-gray-700 whitespace-nowrap">
                      {parseOrFormat(node.date, 'MMM d')}
                    </span>
                    {node.label && (
                      <span
                        className={cn(
                          'text-xs sm:text-sm hidden sm:block mt-1 whitespace-nowrap',
                          hasProblems ? 'text-red-500' : 'text-gray-500'
                        )}
                      >
                        {node.label}
                      </span>
                    )}
                  </div>
                )}
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
