import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@radix-ui/react-icons';
import { useState } from 'react';

import Avatar from '@/Avatar';
import { IconButton } from '@/Button';
import { ColorDot } from '@/ColorDot';
import * as DropdownMenu from '@/DropdownMenu';
import { Text } from '@/Text';
import { TextEditor } from '@/TextEditor';
import { useAction } from '~/hooks/useAction';
import { useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { Comment } from '~/types/types';

interface ReplyProps {
  isRootReply: boolean;
  comment: Comment;
  onClose?: () => void;
}

export const ReplyComment = ({ isRootReply, comment, onClose }: ReplyProps) => {
  const { user, organization, order } = usePageProps();

  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { differenceToNow } = useDate();
  const { post: resolve } = useAction(
    `/b/${organization.id}/comments/${comment.id}/resolve`,
    {
      onSuccess: () => {
        onClose && onClose();
      },
    }
  );
  const { post: edit } = useAction(
    `/b/${organization.id}/comments/${comment.id}/edit`,
    {
      onSuccess: () => {
        onClose && onClose();
      },
    }
  );

  const { post: remove } = useAction(
    `/b/${organization.id}/comments/${comment.id}/delete`,
    {
      onSuccess: () => {
        onClose && onClose();
      },
    }
  );

  // TODO: Check if user is allowed to resolve comment (only if user is admin or user is the one who created the comment)
  const resolveComment = () => {
    resolve({});
    onClose && onClose();
  };

  const editComment = (text: string) => {
    edit({
      text,
    });
    setIsEditing(false);
  };

  const deleteComment = () => {
    remove();
  };

  const isAllowedToResolve = false; // comment?.user?.id === user.id;
  const isAllowedToEdit = comment?.user?.id === user.id;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-3">
        <div className="w-full">
          <div className="flex items-center gap-3 px-3 py-3">
            <div className="self-start">
              <Avatar imageSrc="" initials={comment.user?.name?.[0] || 'A'}/>
            </div>
            <div className="flex flex-col justify-start w-full">
              <div className="flex gap-2 ">
                <div className="w-full px-3 py-1 rounded bg-slate-50">
                  <div className="text-sm font-bold text-token-text">
                    {comment.user?.name}
                  </div>
                  {isEditing ? (
                    <TextEditor
                      onCancel={() => setIsEditing(false)}
                      onSubmit={({ value }) => editComment(value)}
                      value={comment.text}
                    />
                  ) : (
                    <Text className="text-token-text">{comment.text}</Text>
                  )}
                </div>
                <div className="flex gap-2 ml-auto">
                  {isRootReply && isAllowedToResolve ? (
                    <IconButton onClick={resolveComment} label="Resolve">
                      <CheckIcon
                        className="text-token-text"
                        strokeWidth={2}
                        width={20}
                        height={20}
                      />
                    </IconButton>
                  ) : null}
                  {isAllowedToEdit ? (
                    <DropdownMenu.DropdownRoot
                      open={isOpen}
                      onOpenChange={(open) => setIsOpen(open)}
                    >
                      <DropdownMenu.DropdownTrigger>
                        <IconButton label="Options">
                          <EllipsisVerticalIcon
                            className="text-token-text"
                            strokeWidth={2}
                            width={20}
                            height={20}
                          />
                        </IconButton>
                      </DropdownMenu.DropdownTrigger>
                      <DropdownMenu.DropdownContent
                        collisionPadding={20}
                        className="shadow-md!"
                      >
                        <DropdownMenu.DropdownLabel>Options</DropdownMenu.DropdownLabel>
                        <DropdownMenu.DropdownGroup>
                          <DropdownMenu.DropdownItem
                            onSelect={() => setIsEditing(true)}
                          >
                            Edit
                          </DropdownMenu.DropdownItem>
                          <DropdownMenu.DropdownItem onSelect={deleteComment}>
                            Delete
                          </DropdownMenu.DropdownItem>
                        </DropdownMenu.DropdownGroup>
                      </DropdownMenu.DropdownContent>
                    </DropdownMenu.DropdownRoot>
                  ) : null}
                </div>
              </div>
              <div className="flex items-center gap-2 mt-1">
                <span className="shrink-0 text-xs text-token-muted">
                  {differenceToNow(new Date(comment.created_at as string))}
                </span>
                <ColorDot brand="indigo" size="xs"/>
                <Text className="text-xs text-indigo-500">
                  {
                    comment.user?.organizations?.find(
                      (org) =>
                        order?.buyer_id === org.id ||
                        order?.seller_id === org.id
                    )?.title
                  }
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
