
import { ColorDot } from '@/ColorDot';
import { MutedText } from '@/Text';
import { useFormat } from '~/hooks/useFormat';
import { MeasurementDistribution, PercentageDistribution } from '~/types/types';

interface SummarizeDistributionProps {
  distribution?: MeasurementDistribution | PercentageDistribution | null;
}

export const SummarizeDistribution = ({ distribution }: SummarizeDistributionProps) => {
  const { formatFractionPercentage } = useFormat();
  if (distribution && 'median' in distribution) {
    return (
      <div className="flex justify-between w-full text-xxxs">
        <MutedText className="text-xxxs!">Min: {distribution.minDomainValue?.toFixed(2)}</MutedText>
        <MutedText className="text-xxxs!">Mean: {distribution.median.toFixed(2)}</MutedText>
        <MutedText className="text-xxxs!">Max: {distribution.maxDomainValue?.toFixed(2)}</MutedText>
      </div>
    );
  }

  if (distribution && 'countTrue' in distribution) {
    return (
      <div className="flex justify-between">
        <div className="flex gap-1">
          <div className="mt-2.5">
            <ColorDot brand="red" size="sm"/>
          </div>
          <div>
            <MutedText className="leading-none! text-xxs!">Defects</MutedText>
            <div className="leading-none">
              <MutedText className="leading-none! text-xxs!">
                {formatFractionPercentage(distribution.fractionTrue)}
              </MutedText>
            </div>
          </div>
        </div>

        <div className="flex gap-1">
          <div className="mt-2.5">
            <ColorDot brand="emerald" size="sm"/>
          </div>
          <div>
            <MutedText className="leading-none! text-xxs!">No defects</MutedText>
            <div className="leading-none!">
              <MutedText className="leading-none! text-xxs!">
                {formatFractionPercentage(distribution.fractionFalse)}
              </MutedText>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>

    </div>
  );
};
