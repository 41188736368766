import { Groups, OptionBaseType } from '@/Pickers/GenericPicker';

type PickerItems = Array<Record<string, any> | string>;

/**
 * Deduplicates an array of objects based on a specific key
 * @param items Array of objects to deduplicate
 * @param key Key to deduplicate on
 */
const deduplicateByKey = <T extends Record<string, any>>(items: T[], key: string): T[] => {
  return items.reduce((acc, current) => {
    const isDuplicate = acc.some(item => item[key] === current[key]);
    if (!isDuplicate) {
      acc.push(current);
    }
    return acc;
  }, [] as T[]);
};

/**
 * Format a list of items for use in a Picker component.
 *
 * @param items - The list of items to format. Must be an array of objects.
 * @param valueKey - The key to use for the value of the Picker item. This will be the underlying value of the Picker.
 * @param labelKey - The key to use for the label of the Picker item. This will be the visible label of the Picker.
 * @param groupKey - The key to use for the group of the Picker item. This will be the group label of the Picker.
 * @param countKey - The key to use for the count of the Picker item. This will be the count label of the Picker.
 * @param subLabel
 */
export const formatPicker = (
  items: PickerItems,
  valueKey = 'value',
  labelKey = 'label',
  groupKey = 'group',
  countKey = 'count',
  subLabel = 'subLabel',
  imageUrlKey = 'imageUrl'
): OptionBaseType[] => {
  if (!items.length) {
    return [];
  }

  const sampleItem = items[0];

  // Check if collection of objects
  if (typeof sampleItem === 'object' && sampleItem !== null) {
    const mappedItems = (items as Array<Record<string, any>>).map((item) => ({
      ...item,
      value: item[valueKey],
      label: item[labelKey],
      group: item[groupKey] ?? undefined,
      count: item[countKey] ?? undefined,
      disabled: item.disabled ?? false,
      subLabel: subLabel && subLabel in item ? item[subLabel] : undefined,
      imageUrl: item[imageUrlKey] ?? undefined,
    }));

    return deduplicateByKey(mappedItems, 'value');
  }

  // For string arrays, deduplicate before mapping
  const uniqueStrings = deduplicateByKey(
    (items as string[]).map(item => ({ value: item })),
    'value'
  );

  return uniqueStrings.map(({ value }) => ({
    value,
    label: value,
    disabled: false,
  }));
};

/**
 * Given a counter object (such as `test_count`), convert it to an array of items.
 * @param counter
 */
export const counterToItems = (counter: Record<string, number>) => {
  return Object.keys(counter).map((key) => {
    return {
      count: counter[key] ?? undefined,
      value: key,
      label: key,
    };
  });
};

const WAS_NOT_FOUND = -1;
const WAS_FOUND = 1;

export const searchPickerItems = (
  consideredItem: string,
  items: OptionBaseType[],
  search: string,
  searchableProperties: (keyof OptionBaseType)[] = ['label']
): number => {
  const matchingItem = items.find((item) => item.value.toLowerCase() === consideredItem.toLowerCase());

  if (matchingItem?.disabled) return WAS_NOT_FOUND;

  // Normalize the search
  const normalizedSearch = search.toLowerCase().trim();

  // If search is empty, consider it a match
  if (normalizedSearch === '') return WAS_FOUND;

  // Grab the searchable properties of the matching item and normalize them
  const searchableValues = searchableProperties.map((prop) => {
    const value = matchingItem?.[prop];
    return typeof value === 'string' ? value.toLowerCase() : '';
  });

  // Check if any of the searchable properties overlap with the search
  const isMatch = searchableValues.some((value) =>
    value.includes(normalizedSearch)
  );

  return isMatch ? WAS_FOUND : WAS_NOT_FOUND;
};

/**
 * Extract the unique groups
 * @param items
 * @param groupKey
 */
export const getGroups = (items: PickerItems, groupKey = 'group'): Groups => {
  return items.reduce<Groups>((acc, item: any) => {
    const groupValue = item[groupKey];

    if (typeof groupValue === 'string' && !acc[groupValue]) {
      acc[groupValue] = {
        label: groupValue,
      };
    }

    return acc;
  }, {});
};

export const toPickerItem = (item: any, valueKey = 'value', labelKey = 'label', groupKey = 'group', countKey = 'count', subLabel = 'subLabel'): OptionBaseType => {
  return {
    ...item,
    value: item[valueKey] ?? undefined,
    label: item[labelKey] ?? undefined,
    group: item[groupKey] ?? undefined,
    count: item[countKey] ?? undefined,
    disabled: item.disabled ?? false,
    subLabel: subLabel && subLabel in item ? item[subLabel] : undefined,
  };
};
