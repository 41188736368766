import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeader } from '@/Card';
import { CardTitle } from '@/Text';

import { QualityBreakdownOverTimeProps } from '../../../../../types/quality';
import { StackedAreaChart } from './StackedAreaChart';

/**
 * Component to visualize quality breakdowns over time using a stacked area chart.
 * This shows how different quality aspects contribute to overall quality metrics.
 */
export const QualityBreakdownOverTime = ({
  qualityOverTime,
  height = 300,
  width,
  className,
}: QualityBreakdownOverTimeProps) => {
  const { t } = useTranslation();

  // Only render if we have breakdown data
  if (!qualityOverTime.qualityBreakdown?.timeSeries?.length) {
    return null;
  }

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          {t('Quality Breakdown Over Time')}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <StackedAreaChart
          timeSeriesGroup={qualityOverTime.qualityBreakdown}
          height={height}
          width={width}
          unit=""
          showLegend={true}
          showTooltip={true}
          showDots={false}
        />
      </CardBody>
    </Card>
  );
};
