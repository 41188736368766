import { AnimatePresence, motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { ReactNode, useState } from 'react';

import { ChevronDownIcon, CollapsedIcon, SectionIcon } from '@/Icon';
import { SectionDescription, SectionTitle, Strong } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';

type SectionColumns = '1' | '2_3' | '1_3' | '3_1';

interface SectionProps {
  title?: string;
  description?: string | ReactNode;
  children: ReactNode;
  enableToggle?: boolean;
  defaultOpen?: boolean;
  showCollapsed?: boolean;
  renderPreview?: ReactNode;
  className?: string;
  nCollapsedItems?: number;
  icon?: any;
  columns?: SectionColumns;
  isLoading?: boolean;
}

const Section = ({
  title,
  description,
  children,
  enableToggle = false,
  defaultOpen = true,
  showCollapsed = false,
  renderPreview,
  className,
  nCollapsedItems = 1,
  icon,
  columns,
  isLoading = false,
}: SectionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleSection = () => {
    if (enableToggle) {
      setIsOpen(!isOpen);
    }
  };

  const headerContent = (
    <div className="grow">
      <div className="flex items-center justify-between">
        <SectionTitle>
          {title}
        </SectionTitle>
        {renderPreview && (
          <div className="ml-4">{renderPreview}</div>
        )}
      </div>
      {description && (
        <SectionDescription>
          {description}
        </SectionDescription>
      )}
    </div>
  );

  const mainContent = (!enableToggle || isOpen) ? (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ type: 'spring', bounce: 0.1, duration: 0.3 }}
    >
      <div className={cn('mt-4', columns && 'lg:mt-0')}>{children}</div>
    </motion.div>
  ) : showCollapsed ? (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ type: 'spring', bounce: 0.1, duration: 0.3 }}
    >
      <div
        className="flex mt-4 border rounded-lg border-zinc-200 bg-zinc-50 dark:border-zinc-700 dark:bg-zinc-800"
      >
        <Tooltipped label="Undo collapse">
          <button
            aria-label={`${title} toggle button`}
            type="button"
            onClick={toggleSection}
            className="p-4 transition-colors border-r rounded-l-lg bg-zinc-100 text-zinc-500 hover:bg-zinc-200"
          >
            <CollapsedIcon/>
          </button>
        </Tooltipped>
        <div className="p-4">
          <Strong className="text-zinc-500">
            +{nCollapsedItems} collapsed items
          </Strong>
        </div>
      </div>
    </motion.div>
  ) : null;

  return (
    <div className={cn('mb-16 relative', className)} aria-label={title}>
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-50/50 z-1000">
          <div className="flex items-center justify-center h-full">
            <Loader2 className="w-4 h-4 animate-spin" />
          </div>
        </div>
      )}
      {icon && (
        <div className="absolute top-0 hidden -left-8 xl:block">
          <SectionIcon icon={icon}/>
        </div>
      )}
      {columns ? (
        <div className={cn('grid grid-cols-1 gap-4',
          columns === '1' && 'lg:grid-cols-1 gap-4',
          columns === '2_3' && 'lg:grid-cols-[2fr_3fr] lg:gap-8',
          columns === '1_3' && 'lg:grid-cols-[1fr_3fr] lg:gap-56',
          columns === '3_1' && 'lg:grid-cols-[3fr_1fr] lg:gap-8',
        )}>
          <div className={cn(
            'flex items-start justify-between',
            enableToggle && 'cursor-pointer'
          )}
          onClick={toggleSection}>
            {headerContent}
            {enableToggle && (
              <motion.div
                animate={{ rotate: isOpen ? 180 : 0 }}
                transition={{ duration: 0.3 }}
                className="ml-4 shrink-0"
              >
                <ChevronDownIcon className="w-5 h-5 text-zinc-500 dark:text-zinc-400"/>
              </motion.div>
            )}
          </div>
          <AnimatePresence initial={false}>
            {mainContent}
          </AnimatePresence>
        </div>
      ) : (
        <>
          <div
            className={cn(
              'flex items-start justify-between',
              enableToggle && 'cursor-pointer'
            )}
            onClick={toggleSection}
          >
            {headerContent}
            {enableToggle && (
              <motion.div
                animate={{ rotate: isOpen ? 180 : 0 }}
                transition={{ duration: 0.3 }}
                className="ml-4 shrink-0"
              >
                <ChevronDownIcon className="w-5 h-5 text-zinc-500 dark:text-zinc-400"/>
              </motion.div>
            )}
          </div>
          <AnimatePresence initial={false}>
            {mainContent}
          </AnimatePresence>
        </>
      )}
    </div>
  );
};

type LayoutVariant = '1' | '2_3' | '1_3' | '3_1';

export const SectionLayout = ({
  variant = '1',
  children,
}: { variant: LayoutVariant, children: ReactNode }) => {
  return (
    <div className={cn('grid grid-cols-1 gap-4 *:min-w-0',
      variant === '1' && 'lg:grid-cols-1 gap-4',
      variant === '2_3' && 'lg:grid-cols-[2fr_3fr] gap-8 relative',
      variant === '1_3' && 'lg:grid-cols-[1fr_3fr] gap-14 relative',
      variant === '3_1' && 'lg:grid-cols-[3fr_1fr] gap-8 relative',
    )}>
      {children}
    </div>
  );
};

export const WithSectionIcon = ({
  icon,
  children,
}: { icon: any, children: ReactNode }) => {
  return (
    <div className="relative">
      <div className="absolute top-0 hidden -left-8 xl:block">
        <SectionIcon icon={icon}/>
      </div>

      {children}
    </div>
  );
};

export default Section;

export const Sections = ({ children }: { children: ReactNode }) => {
  return (
    <div className="space-y-12">
      {children}
    </div>
  );
};
