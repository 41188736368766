import { Link } from "@inertiajs/react";
import classnames from "classnames";
import { useEffect, useRef, useState } from "react";

import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import { PaginatedItems } from "../../types/types";
import { Button } from "../Button";
import { Input } from "../Input";
import { MutedText } from "../Text";

interface PaginationProps {
  pageIndex: number;
  maxPages: number;
  setPageIndex: (page: number) => void;
  isLoading: boolean;
}

export const Pagination = ({
  pageIndex,
  maxPages,
  setPageIndex,
  isLoading,
}: PaginationProps) => {
  const startedRef = useRef<boolean>(false);
  const [inputPageIndex, setInputPageIndex] = useState(1);

  useDebouncedEffect(
    () => {
      if (startedRef.current) {
        startedRef.current = false;
        setPageIndex(Math.max(1, Number(inputPageIndex)));
      }
    },
    500,
    [inputPageIndex]
  );

  useEffect(() => {
    if (!startedRef.current) {
      startedRef.current = false;
      setInputPageIndex(pageIndex);
    }
  }, [pageIndex, setInputPageIndex]);

  if (maxPages <= 1) return null;

  return (
    <div className="inline-block px-3 py-2 bg-white border border-gray-100 rounded-md shadow-xs">
      <div className="flex items-center">
        <div className="mr-2">
          <span className="text-gray-500">
            {pageIndex} / {maxPages}
          </span>
        </div>
        <Input
          style={{ width: "75px", height: "35px" }}
          type="number"
          value={inputPageIndex}
          onChange={(e: any) => {
            startedRef.current = true;
            setInputPageIndex(e.target.value);
          }}
        />
        {maxPages > 1 && (
          <>
            <div className="gap-2 inline-flex ml-2">
              {[...new Array(maxPages)].map((page, index) => (
                <Button
                  className={classnames()}
                  size="sm"
                  variant="outline"
                  key={index + 1}
                  disabled={isLoading}
                  onClick={() => setPageIndex(index + 1)}
                >
                  {index + 1}
                </Button>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

interface TableFooterProps {
  paginatedItems: PaginatedItems<any>;
}

export const TableFooter = ({ paginatedItems }: TableFooterProps) => {
  return (
    <div className="flex justify-between">
      <div>
        <MutedText>Total: {paginatedItems.total}</MutedText>
      </div>
      <div className="space-x-2">
        <Button
          variant="white"
          as={Link}
          preserveScroll
          href={paginatedItems.prev_page_url}
          disabled={!paginatedItems.prev_page_url as never}
        >
          Previous
        </Button>
        <Button
          variant="white"
          as={Link}
          preserveScroll
          href={paginatedItems.next_page_url}
          disabled={!paginatedItems.next_page_url as never}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
