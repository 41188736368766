import { PencilIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@radix-ui/react-icons';
import { cva } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { ChangeEvent, ExoticComponent, KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { w } from 'windstitch';

import { cn } from '~/utils/cn';
import { Theme } from '~/utils/colors';

import { LabelIcon, MiniLabelIcon, TrashIcon } from './Icon';

export const Label = w.div(`
  inline-flex items-center text-xs rounded-md ring-1 ring-inset font-medium
 `, {
  variants: {
    theme: {
      emerald: 'text-emerald-700 bg-emerald-50 ring-emerald-600/10',
      red: 'text-rose-500 bg-rose-50 ring-rose-600/10',
      gray: 'text-gray-600 bg-gray-50 ring-gray-500/10',
      orange: 'text-orange-600 bg-orange-50 ring-orange-500/10',
      blue: 'text-blue-600 bg-blue-50 ring-blue-500/10',
      purple: 'text-purple-600 bg-purple-50 ring-purple-500/10',
      tooltip: 'bg-transparent hover:bg-gray-200 cursor-pointer ring-0! px-1',
    },
    size: {
      xs: 'px-2 py-0.5',
      sm: 'px-3 py-1',
      md: 'px-4 py-2',
      lg: 'px-5 py-3',
    },
  },
  defaultVariants: {
    size: 'sm',
    theme: 'gray',
  },
});

interface MultiLabelProps {
  theme: Theme;
  children: ReactNode;
  label: string;
  icon?: ExoticComponent;
}

export const MultiLabel = ({ theme, children, label, icon }: MultiLabelProps) => {
  const themeClasses = {
    emerald: 'bg-emerald-500',
    gray: 'bg-gray-500',
    orange: 'bg-orange-500',
    red: 'bg-red-500',
    blue: 'bg-blue-500',
  };

  const labelClasses = cn(
    'rounded-r-none! text-xs text-white!',
    themeClasses[theme]
  );

  return (
    <span className="inline-flex">
      <Label theme={theme} className={labelClasses}>
        <span className="flex items-center">
          {icon && <LabelIcon icon={icon} className="w-3 h-3 mr-2 text-gray-300!"/>}
          {label}
        </span>
      </Label>
      <Label className="rounded-l-none" theme={theme}>
        {children}
      </Label>
    </span>
  );
};

interface MiniLabelProps {
  children?: ReactNode;
  variant?: 'default' | 'add' | 'muted';
  value?: string;
  onChange?: (newValue: string) => void;
  enableEdit?: boolean;
  isEdit?: boolean;
  onSave?: (newContent: string) => void;
  onRemove?: () => void;
  className?: string;
}

const fadeInOutVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const miniLabelVariants = cva(
  'inline-flex items-center gap-x-1.5 rounded-full px-2 py-0.5 text-xs font-normal border text-gray-700',
  {
    variants: {
      variant: {
        default: '',
        muted: '!bg-gray-50 !text-gray-400 !font-medium',
        add: 'border border-dashed border-gray-300 hover:bg-gray-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
export const MiniLabel = ({
  children,
  value,
  onChange,
  onSave,
  enableEdit,
  variant,
  className,
  onRemove,
}: MiniLabelProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [content, setContent] = useState(value || '');
  // const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value !== undefined) {
      setContent(value);
    } else if (typeof children === 'string') {
      setContent(children);
    }
  }, [value, children]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setContent(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleSave = () => {
    setIsEditing(false);
    if (onSave) {
      onSave(content);
    }
  };

  const handleRemove = (e: any) => {
    e.preventDefault();
    if (onRemove) {
      onRemove();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <span
      className={miniLabelVariants({ variant, className })}
      // onMouseEnter={() => setIsHovering(true)}
      // onMouseLeave={() => setIsHovering(false)}
    >
      {isEditing ? (
        <>
          <input
            ref={inputRef}
            type="text"
            value={content}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className="p-0 max-h-3 block border-0 bg-transparent outline-hidden text-xs"
          />
          <button type="button" onClick={handleSave} className="ml-1">
            <CheckIcon className="w-3 h-3 text-gray-500"/>
          </button>

          {onRemove && (
            <button type="button" onClick={handleRemove} className="ml-1">
              <MiniLabelIcon icon={TrashIcon}/>
            </button>
          )}
        </>
      ) : (
        <>
          {content || children}
          <AnimatePresence>
            {enableEdit && isHovering && !isEditing && (
              <motion.button
                type="button"
                onClick={handleEdit}
                className="ml-1"
                variants={fadeInOutVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.2 }}
              >
                <PencilIcon className="w-3 h-3 text-gray-400 hover:text-gray-600"/>
              </motion.button>
            )}
          </AnimatePresence>
        </>
      )}
    </span>
  );
};

const spinTransition = {
  repeat: Infinity,
  ease: 'linear',
  duration: 3,
};

interface LoadingLabelProps {
  text?: string;
  className?: string;
  spinnerColor?: string;
  textColor?: string;
  size?: 'sm' | 'md' | 'lg';
  children?: ReactNode;
  isLoading?: boolean;
}

const labelVariants = {
  hidden: { opacity: 0, y: 20, scale: 0.9 },
  visible: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: 20, scale: 0.9 },
};

export const LoadingLabel = ({ children, spinnerColor, isLoading }: LoadingLabelProps) => {
  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={labelVariants}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
          <Label theme="purple">
            <motion.svg
              animate={{ rotate: 360, y: 0 }}
              transition={spinTransition}
              viewBox="0 0 24 24"
              className={cn('h-4 w-4 mr-2', spinnerColor)}
            >
              <path
                fill="currentColor"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </motion.svg>
            {children}
          </Label>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const FinishedLabel = ({ children }: { children: ReactNode }) => {
  return (
    <Label theme="emerald">
      <CheckIcon className="w-4 h-4 mr-2"/>
      {children}
    </Label>
  );
};

export const AnimatedFinishedLabel = ({ show }: { show: boolean }) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ ease: 'linear' }}
          className="absolute bottom-16 right-4 z-20"
        >
          <FinishedLabel>Success</FinishedLabel>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
