import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import { InputDescription, InputLabel } from '@/Form';
import { ArrivalSiteIcon, IssueIcon, OrganizationIcon, ProduceIcon, QualityIcon, SellerIcon } from '@/Icon';
import { SidebarContent, SidebarDescription, SidebarFooter, SidebarGroup, SidebarHeader, SidebarSeparator, SidebarTitle, useSidebar } from '@/Nav/Sidebar';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { RegionPicker } from '@/Pickers/RegionPicker';
import { SearchBar } from '@/Searchbar';
import { useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { useQuery } from '~/hooks/useQuery';
import { OrdersPageProps } from '~/types/page-props';
import { counterToItems, formatPicker, getGroups } from '~/utils/formatPicker';

import { OrdersPageRequestProps } from './OrdersPage';

export const OrderSidebar = () => {
  const { t } = useTranslation();
  const { parse } = useDate();

  const { data, filterOptions } = usePageProps<OrdersPageProps>();
  const { parameters, isLoading, reset, setParametersOfScope, countActiveParameters } = useQuery<OrdersPageRequestProps>();

  const { setOpen } = useSidebar();

  const hasParams = countActiveParameters() > 0;

  /**
   * Open the sidebar when there are parameters
   */
  useEffect(() => {
    if (hasParams) {
      setOpen(true);
    }
  }, []);

  const setPaginationParam = (key: keyof OrdersPageRequestProps, value: any) => {
    setParametersOfScope({
      [key]: value,
      page: 1,
    });
  };

  const setPaginationParams = (filters: Partial<OrdersPageRequestProps>) => {
    setParametersOfScope({
      ...filters,
      page: 1,
    });
  };

  const includeGroupToIssueTypes = (pickerItems: Record<string, any>[]) => {
    return pickerItems.map((item) => {
      let group = undefined;

      if (
        item.value === 'minor_major_defects' || item.value === 'out_of_measurement_spec'
      ) {
        group = 'supplier';
      }

      if (
        item.value === 'transport_delay' ||
        item.value === 'transport_temp_threshold' ||
        item.value === 'transport_light_threshold'
      ) {
        group = 'logistics';
      }

      return {
        ...item,
        title: item.title,
        group,
      };
    });
  };

  const orderStatuses = includeGroupToIssueTypes(formatPicker(counterToItems(data.order_count_per_issue_type ?? {}).map(item => {
    return {
      ...item,
      title: t(`issue_status.${item.title}`),
      value: item.value,
    };
  })));

  const clearFilters = () => {
    reset(['singleStatusType']);
  };

  return (
    <>
      <SidebarHeader className="text-neutral-700">
        <SidebarTitle>
          Filter orders
        </SidebarTitle>
        <SidebarDescription>
          Filter the orders to display in the orders page.
        </SidebarDescription>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup
          collapsible
          count={countActiveParameters(['search', 'inspectionStatuses', 'issueStatuses'])}
          defaultCollapsed={countActiveParameters(['search', 'inspectionStatuses', 'issueStatuses']) === 0}
          label="Inspections"
          description="Filter orders by inspection status."
        >
          <div className="space-y-6">
            <div>
              <InputLabel className="mb-2">
            Search
              </InputLabel>
              <InputDescription className="mb-2">
            Search for orders by order number, buyer name, or seller name.
              </InputDescription>
              <SearchBar
                className="w-full"
                placeholder={t('orders_page_search') as string}
                loading={isLoading}
                value={parameters.search}
                onChange={(value) => {
                  setPaginationParam('search', value);
                }}
              />
            </div>
            <div>
              <InputLabel className="mb-2">
                Inspections
              </InputLabel>
              <InputDescription className="mb-2">
                Filter orders by inspection status.
              </InputDescription>
              <div>
                <GenericPicker
                  icon={QualityIcon}
                  showClear
                  options={[
                    {
                      label: 'Seller intake',
                      value: 'has_seller_input',
                      subLabel: 'Include orders with seller quality intake',
                      icon: SellerIcon,
                    },
                    {
                      icon: OrganizationIcon,
                      label: 'Buyer intake',
                      value: 'has_buyer_input',
                      subLabel: 'Include orders with buyer quality intake',
                    },
                  ]}
                  value={parameters.inspectionStatuses}
                  title="Inspections"
                  onChange={(value) => {
                    setPaginationParam('inspectionStatuses', value);
                  }}
                  placeholder="Select inspection"
                  emptyMessage="No inspections found"
                  isMulti
                />
              </div>
            </div>

            <div>
              <InputLabel className="mb-2">
                Issue statuses
              </InputLabel>
              <InputDescription className="mb-2">
                Filter orders by issue status.
              </InputDescription>
              <div>
                <GenericPicker
                  icon={IssueIcon}
                  groups={{
                    supplier: { label: 'Supplier input', icon: ProduceIcon },
                    logistics: { label: 'Logistics', icon: ProduceIcon },
                  }}
                  options={orderStatuses as any}
                  title="Issue status"
                  isMulti
                  placeholder="Select issue status"
                  emptyMessage="No issue statuses found"
                  value={parameters.issueStatuses}
                  onChange={(value) => {
                    setPaginationParam('issueStatuses', value);
                  }}
                />
              </div>
            </div>
          </div>
        </SidebarGroup>

        <SidebarSeparator />

        <SidebarGroup
          collapsible
          count={countActiveParameters(['produceIds', 'sellerIds', 'region_ids'])}
          defaultCollapsed={countActiveParameters(['produceIds', 'sellerIds', 'region_ids']) === 0}
          label="Produce, sellers, and regions"
          description="Filter orders by produce, seller, or region."
        >
          <div className="space-y-6">
            <div>
              <InputLabel className="mb-2">
                Produce
              </InputLabel>
              <InputDescription className="mb-2">
                Filter orders by produce.
              </InputDescription>
              <div>
                <GenericPicker
                  icon={ProduceIcon}
                  options={formatPicker(filterOptions.produces)}
                  isMulti
                  title="Produce"
                  showTitleOnSelected={false}
                  groups={getGroups(filterOptions.produces)}
                  placeholder="Search produce..."
                  emptyMessage="No produce found"
                  value={parameters.produceIds}
                  onChange={(value) => {
                    setPaginationParam('produceIds', value);
                  }}
                />
              </div>
            </div>

            <div>
              <InputLabel className="mb-2">
                Sellers
              </InputLabel>
              <InputDescription className="mb-2">
                Filter orders by seller.
              </InputDescription>
              <div>
                <GenericPicker
                  icon={SellerIcon}
                  options={formatPicker(filterOptions.suppliers, 'value', 'label')}
                  isMulti
                  title="Seller"
                  placeholder="Search sellers..."
                  emptyMessage="No sellers found"
                  value={parameters.sellerIds}
                  onChange={(value) => {
                    setPaginationParam('sellerIds', value);
                  }}
                />
              </div>
            </div>

            <div>
              <InputLabel className="mb-2">
                Regions
              </InputLabel>
              <InputDescription className="mb-2">
                Filter orders by region.
              </InputDescription>
              <div>
                <RegionPicker
                  value={parameters.region_ids}
                  isMulti
                  onChange={(value) => {
                    setPaginationParam('region_ids', value);
                  }}
                  regions={filterOptions.regions}
                  titleKey="Region"
                />
              </div>
            </div>
          </div>
        </SidebarGroup>

        <SidebarSeparator />

        <SidebarGroup
          label="Logistics and dates"
          description="Filter orders by shipping and arrival dates."
          collapsible
          count={countActiveParameters(['ship_date', 'arrival_date', 'destinationSites'])}
          defaultCollapsed={countActiveParameters(['ship_date', 'arrival_date', 'destinationSites']) === 0}
        >
          <div className="space-y-6">
            <div>
              <InputLabel className="mb-2">
                Arrival site
              </InputLabel>
              <InputDescription className="mb-2">
                Filter orders by arrival site.
              </InputDescription>
              <div>
                <GenericPicker
                  icon={ArrivalSiteIcon}
                  options={formatPicker(filterOptions.delivery_locations ?? [])}
                  isMulti
                  title="Arrival site"
                  placeholder="Search arrival sites..."
                  emptyMessage="No arrival sites found"
                  value={parameters.destinationSites}
                  onChange={(value) => {
                    setPaginationParam('destinationSites', value);
                  }}
                />
              </div>
            </div>
            <div>
              <InputLabel className="mb-2">
                Shipping date
              </InputLabel>
              <InputDescription className="mb-2">
                Filter orders by shipping date.
              </InputDescription>
              <div>
                <RangeDatePicker
                  value={{
                    from: parameters.ship_date?.from ? parse(parameters.ship_date.from) : undefined,
                    to: parameters.ship_date?.to ? parse(parameters.ship_date.to) : undefined,
                    period: parameters.ship_date?.period ?? undefined,
                  }}
                  onChange={(value) => {
                    setPaginationParams({
                      ship_date: {
                        from: value.from,
                        to: value.to,
                        period: value.period,
                      },
                    });
                  }}
                  dateNumbersLabel="Orders per ship date"
                  loading={isLoading}
                  enablePeriod
                />
              </div>
            </div>

            <div>
              <InputLabel className="mb-2">
              Arrival date
              </InputLabel>
              <InputDescription className="mb-2">
              Filter orders by arrival date.
              </InputDescription>
              <div>
                <RangeDatePicker
                  dateNumbersLabel="Orders per arrival date"
                  loading={isLoading}
                  enablePeriod
                  value={{
                    from: parameters.arrival_date?.from ? parse(parameters.arrival_date.from) : undefined,
                    to: parameters.arrival_date?.to ? parse(parameters.arrival_date.to) : undefined,
                    period: parameters.arrival_date?.period ?? undefined,
                  }}
                  onChange={(value) => {
                    setPaginationParams({
                      arrival_date: {
                        from: value.from,
                        to: value.to,
                        period: value.period,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </SidebarGroup>

        <SidebarSeparator />
      </SidebarContent>
      <SidebarSeparator/>
      <SidebarFooter>
        <div>
          <Button onClick={clearFilters} variant="white">
            {t('clear_filters')}
          </Button>
        </div>
      </SidebarFooter>
    </>
  );
};
