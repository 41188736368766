import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody } from '@/Card';
import Section, { SectionLayout } from '~/Components/Common/Section';

interface QualitySectionProps {
  children: ReactNode;
  titleKey: string;
  step: number;
  descriptionKey: string;
  progressPercentage?: number;
}

export const QualitySection = ({ children, step, titleKey, descriptionKey }: QualitySectionProps) => {
  const { t } = useTranslation();

  return (
    <Section title={`${step}. ${t(titleKey)}`} description={t(descriptionKey)}>
      <SectionLayout variant="1_3">
        <div>

        </div>
        <div>
          {children}
        </div>
      </SectionLayout>
    </Section>
  );
};
