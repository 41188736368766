import { AppProvider } from '~/Providers/AppProvider';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <AppProvider>
      <div className="min-h-screen bg-gray-50">
        {children}
      </div>
    </AppProvider>
  );
};
