import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';

import { Label } from '@/Label';
import { DescriptionText, Strong } from '@/Text';
import { cn } from '~/utils/cn';

// Types
interface TabOptionGroupProps {
  value: string;
  onChange: (value: string) => void;
  children: ReactNode;
  className?: string;
}

interface TabOptionItemProps {
  value: string;
  title: string;
  description: string;
  status?: 'active' | 'coming-soon' | 'unavailable';
  statusLabel?: string;
  disabled?: boolean;
  children?: ReactNode;
}

// Components
export const TabOptionGroup = ({
  value,
  onChange,
  children,
  className,
}: TabOptionGroupProps) => {
  return (
    <div
      role="tablist"
      className={cn(
        'grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50',
        'sm:grid-cols-3 sm:divide-x sm:divide-y-0',
        className
      )}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            isSelected: child.props.value === value,
            onSelect: () => onChange(child.props.value),
          });
        }
        return child;
      })}
    </div>
  );
};

export const TabOptionItem = ({
  title,
  description,
  status = 'active',
  statusLabel,
  isSelected,
  disabled,
  onSelect,
}: TabOptionItemProps & {
  isSelected?: boolean;
  onSelect?: () => void;
}) => {
  const getStatusLabel = () => {
    if (statusLabel) return statusLabel;
    return {
      'active': 'Last used',
      'coming-soon': 'Coming soon',
      'unavailable': 'Not available',
    }[status];
  };

  return (
    <motion.div
      role="tab"
      initial={false}
      animate={{
        opacity: disabled ? 0.4 : 1,
        scale: isSelected ? 1 : 0.98,
      }}
      whileHover={{
        scale: !disabled ? 1 : 0.98,
      }}
      transition={{
        type: 'spring',
        bounce: 0.1,
        duration: 0.3,
      }}
      aria-selected={isSelected}
      aria-disabled={disabled}
      tabIndex={0}
      onClick={() => !disabled && onSelect?.()}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          !disabled && onSelect?.();
        }
      }}
      className={cn(
        'flex flex-wrap items-center justify-between px-6 py-5',
        'text-center text-sm font-medium border-b-2',
        !disabled ? 'hover:bg-gray-50 cursor-pointer' : 'cursor-not-allowed',
        isSelected ? 'bg-white border-b-blue-700' : 'border-b-gray-200',
        disabled && 'border-b-gray-200'
      )}
    >
      <div className="text-left">
        <Strong>{title}</Strong>
        <DescriptionText>{description}</DescriptionText>
      </div>
      <div>
        <Label>{getStatusLabel()}</Label>
      </div>
    </motion.div>
  );
};
