import { ThumbsDownIcon } from 'lucide-react';

import { MeasurementFieldIcon, ProduceIcon, RegionIcon, ReportingAccuracyIcon, SellerIcon } from '@/Icon';
import { OptionBaseType } from '@/Pickers/GenericPicker';

export const comparisonMetrics: OptionBaseType[] = [
  {
    value: 'reds',
    label: 'Red',
    subLabel: 'Count of red issues',
    description: 'Compare the number of red (RAGs) over time on quality inspections',
    icon: ThumbsDownIcon,
  },
  {
    value: 'field_scores',
    label: 'Field scores',
    subLabel: 'Average field score',
    description: 'Compare the average measurements of a specific field (e.g. Weight, Diameter) over time based on buyer\'s inspection',
    icon: MeasurementFieldIcon,
  },
  {
    value: 'accuracy',
    label: 'Reporting accuracy over time',
    description: 'Compare the accuracy of quality inspections over time',
    subLabel: 'Accuracy of quality inspections over time',
    icon: ReportingAccuracyIcon,
  },
];

export const qualityEntityTypes: OptionBaseType[] = [
  {
    value: 'produce_variety',
    label: 'Produce varieties',
    subLabel: 'Compare quality between produce varieties',
    description: 'Compare quality between produce varieties',
    icon: ProduceIcon,
  },
  {
    value: 'region',
    label: 'Regions',
    subLabel: 'Compare quality between regions',
    description: 'Compare quality between regions',
    icon: RegionIcon,
  },
  {
    value: 'seller',
    label: 'Sellers',
    subLabel: 'Compare quality between sellers',
    description: 'Compare quality between sellers',
    icon: SellerIcon,
  },
];
