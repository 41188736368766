/**
 * Helper functions for extracting and analyzing issue regions in cold chain charts
 */
import { differenceInDays, differenceInHours, parseISO } from 'date-fns';

import { OrderDataStream } from '~/types/types';

export interface IssueData {
  startDate: string;
  endDate: string | null;
  anomalyCount: number;
  [key: string]: any;
}

export interface Issue {
  id: string;
  status: string;
  type?: string;
  issueable: {
    data: IssueData;
  };
  [key: string]: any;
}

export interface DataPoint {
  date: string;
  value: number;
  [key: string]: any;
}

export interface IssueRegion {
  issue: Issue;
  points: DataPoint[];
  startDate: number;
  endDate: number;
  durationHours: number;
  durationDays: number;
  pointCount: number;
}

export interface IssueRegionStats {
  totalRegions: number;
  totalPoints: number;
  totalDurationHours: number;
  totalDurationDays: number;
  averagePointsPerRegion: number;
  averageDurationHours: number;
  longestRegionDurationHours: number;
  shortestRegionDurationHours: number;
}

export interface EnrichedDataPoint extends DataPoint {
  inIssueRegion: boolean;
  anomalyValue?: number;
  status?: string;
  issueId?: string;
  issueRegionInfo?: {
    durationHours: number;
    durationDays: number;
    pointCount: number;
  };
}

/**
 * Extracts issue regions from a list of issues and data points
 *
 * @param issues - Array of issues with issueable.data containing startDate and endDate
 * @param dataPoints - Array of data points with a date property
 * @returns Array of issue regions with metadata
 */
export const extractIssueRegions = (
  issues: Issue[] | null | undefined,
  dataPoints: DataPoint[] | null | undefined
): IssueRegion[] => {
  if (!issues || !dataPoints || !issues.length || !dataPoints.length) {
    return [];
  }

  return issues.map(issue => {
    // Extract start and end dates from the issue
    const issueStartDate = new Date(issue.issueable.data.startDate).getTime();
    const issueEndDate = issue.issueable.data.endDate
      ? new Date(issue.issueable.data.endDate).getTime()
      : new Date().getTime(); // Use current time if ongoing

    // Find data points that fall within this issue's time range
    const pointsInRegion = dataPoints.filter(point => {
      const eventDate = new Date(point.date).getTime();
      return eventDate >= issueStartDate && eventDate <= issueEndDate;
    });

    // Calculate duration in hours and days
    const startDateObj = new Date(issueStartDate);
    const endDateObj = new Date(issueEndDate);
    const durationHours = differenceInHours(endDateObj, startDateObj);
    const durationDays = differenceInDays(endDateObj, startDateObj);

    return {
      issue,
      points: pointsInRegion,
      startDate: issueStartDate,
      endDate: issueEndDate,
      durationHours,
      durationDays,
      pointCount: pointsInRegion.length,
    };
  });
};

/**
 * Checks if a data point falls within any of the issue regions
 *
 * @param dataPoint - Data point with a date property
 * @param issueRegions - Array of issue regions
 * @returns The matching issue region or undefined if not in any region
 */
export const findIssueRegionForDataPoint = (
  dataPoint: DataPoint | null | undefined,
  issueRegions: IssueRegion[] | null | undefined
): IssueRegion | undefined => {
  if (!dataPoint || !issueRegions || !issueRegions.length) {
    return undefined;
  }

  const eventDate = new Date(dataPoint.date).getTime();

  return issueRegions.find(region =>
    eventDate >= region.startDate && eventDate <= region.endDate
  );
};

/**
 * Enriches data points with issue information
 *
 * @param dataPoints - Array of data points with a date property
 * @param issueRegions - Array of issue regions
 * @returns Array of data points with added issue information
 */
export const enrichDataPointsWithIssueInfo = (
  dataPoints: DataPoint[] | null | undefined,
  issueRegions: IssueRegion[] | null | undefined
): EnrichedDataPoint[] => {
  if (!dataPoints || !dataPoints.length) {
    return [];
  }

  if (!issueRegions || !issueRegions.length) {
    return dataPoints.map(dataPoint => ({
      ...dataPoint,
      inIssueRegion: false,
    }));
  }

  return dataPoints.map(dataPoint => {
    const matchingRegion = findIssueRegionForDataPoint(dataPoint, issueRegions);

    if (!matchingRegion) {
      return {
        ...dataPoint,
        inIssueRegion: false,
      };
    }

    return {
      ...dataPoint,
      anomalyValue: dataPoint.value,
      status: matchingRegion.issue.status,
      issueId: matchingRegion.issue.id,
      inIssueRegion: true,
      issueRegionInfo: {
        durationHours: matchingRegion.durationHours,
        durationDays: matchingRegion.durationDays,
        pointCount: matchingRegion.pointCount,
      },
    };
  });
};

/**
 * Gets statistics for all issue regions
 *
 * @param issueRegions - Array of issue regions
 * @returns Object with statistics about the issue regions
 */
export const getIssueRegionsStats = (
  issueRegions: IssueRegion[] | null | undefined
): IssueRegionStats => {
  if (!issueRegions || !issueRegions.length) {
    return {
      totalRegions: 0,
      totalPoints: 0,
      totalDurationHours: 0,
      totalDurationDays: 0,
      averagePointsPerRegion: 0,
      averageDurationHours: 0,
      longestRegionDurationHours: 0,
      shortestRegionDurationHours: 0,
    };
  }

  const totalPoints = issueRegions.reduce((sum, region) => sum + region.pointCount, 0);
  const totalDurationHours = issueRegions.reduce((sum, region) => sum + region.durationHours, 0);
  const totalDurationDays = issueRegions.reduce((sum, region) => sum + region.durationDays, 0);

  const durations = issueRegions.map(region => region.durationHours);
  const longestRegionDurationHours = Math.max(...durations);
  const shortestRegionDurationHours = Math.min(...durations);

  return {
    totalRegions: issueRegions.length,
    totalPoints,
    totalDurationHours,
    totalDurationDays,
    averagePointsPerRegion: totalPoints / issueRegions.length,
    averageDurationHours: totalDurationHours / issueRegions.length,
    longestRegionDurationHours,
    shortestRegionDurationHours,
  };
};
