import { useEffect } from 'react';

import { CheckboxList } from '@/Card/CheckboxCard';
import { InputDescription, InputLabel } from '@/Form';
import { ProduceIcon, SellerIcon } from '@/Icon';
import { SidebarContent, SidebarDescription, SidebarGroup, SidebarHeader, SidebarSeparator, SidebarTitle, useSidebar } from '@/Nav/Sidebar';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { DateFilterPicker } from '~/Components/Dates/DateFilterPicker';
import { useQuery } from '~/hooks/useQuery';
import { AnalyticsConfigRequest } from '~/types/types';
import { formatPicker } from '~/utils/formatPicker';

import { useAnalytics } from '../Dashboard/Reports/ReportsPage/AnalyticsProvider';
import { reportOptions } from '../Dashboard/Reports/ReportsPage/helpers';

export const AnalyticsSidebar = () => {
  const { filterOptions } = useAnalytics();
  const { parameters, setParameter, countActiveParameters } = useQuery<AnalyticsConfigRequest>();

  const { setOpen } = useSidebar();

  const hasActiveParameters = countActiveParameters() > 0;

  useEffect(() => {
    if (hasActiveParameters) {
      setOpen(true);
    }
  }, [hasActiveParameters, setOpen]);

  if (!hasActiveParameters) {
    return null;
  }

  return (
    <>
      <SidebarHeader className="border-b rounded-t bg-zinc-50">
        <SidebarTitle>
          Filters
        </SidebarTitle>
        <SidebarDescription>
          Filter the data to display in the analytics report.
        </SidebarDescription>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <div>
            <InputLabel className="mb-2">
              Produce
            </InputLabel>
            <InputDescription className="mb-2">
              Select the produce to filter your analytics report by.
            </InputDescription>

            <div>
              <GenericPicker
                value={parameters.produce_id ?? null}
                onChange={(value) => {
                  setParameter('produce_id', value as string);
                }}
                icon={ProduceIcon}
                showClear={false}
                title="Filter by produce"
                options={formatPicker(filterOptions.produceOptions)}
                placeholder="Select produce"
                emptyMessage="No produce found"
              />
            </div>
          </div>
        </SidebarGroup>

        <SidebarSeparator />

        <SidebarGroup
          collapsible
          label="Analytics types"
          description="Select the analytics types to display in the analytics report."
          count={countActiveParameters(['filter_types'])}
          defaultCollapsed={countActiveParameters(['filter_types']) === 0}
        >
          <div>
            <CheckboxList
              value={parameters.filter_types ?? []}
              options={formatPicker(reportOptions)}
              onChange={(value) => {
                setParameter('filter_types', value);
              }}
            />
          </div>
        </SidebarGroup>

        <SidebarSeparator />

        <SidebarGroup
          collapsible
          label="Additional filters"
          description="Filter the data to display in the analytics report."
          count={countActiveParameters(['date_filter', 'produce_variety_ids', 'region_ids', 'seller_ids'])}
          defaultCollapsed={countActiveParameters(['date_filter', 'produce_variety_ids', 'region_ids', 'seller_ids']) === 0}
        >
          <div className="space-y-6">
            <div>
              <InputLabel className="mb-2">
                Timeframe
              </InputLabel>

              <DateFilterPicker
                value={{
                  fromDate: parameters.date_filter?.from,
                  toDate: parameters.date_filter?.to,
                  granularity: parameters.date_filter?.granularity as any,
                  period: parameters.date_filter?.period as any,
                }}
                onChange={(value) => {
                  console.log(value);
                  setParameter('date_filter', {
                    from: value.inputFromDate,
                    to: value.inputToDate,
                    granularity: value.granularity as any,
                    period: value.period as any,
                  });
                }}
              />
            </div>

            <div>
              <InputLabel className="mb-2">
                Varieties
              </InputLabel>

              <div>
                <GenericPicker
                  value={parameters.produce_variety_ids ?? []}
                  onChange={(value) => {
                    setParameter('produce_variety_ids', value as string[]);
                  }}
                  isMulti
                  icon={ProduceIcon}
                  showTitleOnSelected={false}
                  title="Filter by varieties"
                  options={formatPicker(filterOptions.produceVarietyOptions ?? [])}
                  placeholder="Select varieties"
                  emptyMessage="No varieties found"
                />
              </div>
            </div>

            <div>
              <InputLabel className="mb-2">
              Sellers
              </InputLabel>

              <div>
                <GenericPicker
                  value={parameters.seller_ids ?? []}
                  title="Filter by sellers"
                  icon={SellerIcon}
                  placeholder="Select sellers"
                  options={formatPicker(filterOptions.sellerOptions ?? [])}
                  onChange={(value) => {
                    setParameter('seller_ids', value as string[]);
                  }}
                  isMulti
                  emptyMessage="No sellers found"
                />
              </div>
            </div>

            <div>
              <InputLabel className='mb-2'>
                Regions
              </InputLabel>

              <div>
                <GenericPicker
                  value={parameters.region_ids ?? []}
                  title="Filter by regions"
                  icon={SellerIcon}
                  placeholder="Select regions"
                  options={formatPicker(filterOptions.regionOptions ?? [])}
                  onChange={(value) => {
                    setParameter('region_ids', value as string[]);
                  }}
                  isMulti
                  emptyMessage="No regions found"
                />
              </div>
            </div>
          </div>
        </SidebarGroup>
      </SidebarContent>
    </>
  );
};
