
/**
 * Extract all parameters which are not considered as part of the scope (e.g. considered "other")
 */
export const fetchOtherParameters = (allUrlParams: Record<string, any>, newUrlParams: Record<string, any>) => {
  // If our current "allurlParams" are not an object or null, return an empty object
  if (typeof allUrlParams !== 'object' || allUrlParams === null) {
    return {};
  }

  // Return our new url params is not an object or null, return the original `allUrlParams` object
  if (typeof newUrlParams !== 'object' || newUrlParams === null) {
    return allUrlParams;
  }

  const result: any = {};

  // Get all keys from the newUrlparams
  const newKeysSet = new Set(Object.keys(newUrlParams));

  Object.keys(allUrlParams).forEach(param => {
    if (!newKeysSet.has(param)) {
      result[param] = allUrlParams[param];
    }
  });

  return result;
};

/**
 * Filter out parameters h
 * @param values
 */
export const filterNullParameters = (values: Record<string, any>): Record<string, any> => {
  const res = {};

  Object.keys(values).map(key => {
    if (!!values[key] && Array.isArray(values[key]) && values[key].length > 0) {
      res[key] = values[key];
    }

    if (!!values[key] && !Array.isArray(values[key])) {
      res[key] = values[key];
    }
  });

  return res;
};
