import { SideLabeledField } from '@/SideLabeledField';

interface BoxPlotProps {
  min: number;
  q1: number;
  median: number;
  q3: number;
  max: number;
  domainMin: number;
  domainMax: number;
  lowerThreshold?: number;
  upperThreshold?: number;
  isCompact?: boolean;
}

/**
 * A box-plot component that visualizes the distribution of a dataset (given pre-calculated distribution).
 * @param min
 * @param q1
 * @param median
 * @param q3
 * @param max
 * @param domainMin
 * @param domainMax
 * @param lowerThreshold
 * @param upperThreshold
 * @constructor
 */
export const BoxPlot = ({
  min,
  q1,
  median,
  q3,
  max,
  domainMin,
  domainMax,
  lowerThreshold,
  upperThreshold,
  isCompact = false,
}: BoxPlotProps) => {
  const mostMax = Math.max(max, domainMax);
  const mostMin = Math.min(min, domainMin);
  const range = mostMax - mostMin;

  const calculatePosition = (value: number) => {
    return ((value - domainMin) / range) * 100;
  };

  const minPos = calculatePosition(domainMin);
  const q1Pos = calculatePosition(q1);
  const medianPos = calculatePosition(median);
  const q3Pos = calculatePosition(q3);
  const maxPos = calculatePosition(Math.max(max, domainMax));

  const lowerThresholdPos = lowerThreshold !== undefined ? calculatePosition(lowerThreshold) : null;
  const upperThresholdPos = upperThreshold !== undefined ? calculatePosition(upperThreshold) : null;

  return (
    <div>
      <div className="relative h-3 w-full">
        {/* The line representing the full range from min to max */}
        <div className="absolute top-1/2 h-0.5 w-full bg-gray-300 transform -translate-y-1/2"></div>

        {/* Whiskers */}
        <div
          className="absolute top-1/2 h-3 w-0.5 rounded bg-gray-300 transform -translate-y-1/2"
          style={{ left: `${minPos}%` }}
        ></div>
        <div
          className="absolute top-1/2 h-3 w-0.5 rounded bg-gray-300 transform -translate-y-1/2"
          style={{ left: `${maxPos}%` }}
        ></div>

        {/* Box: from Q1 to Q3 with rounded corners and shadow */}
        <div
          className="absolute top-1/2 h-3 bg-green-200 rounded shadow-sm border-green-300 border-2 transform -translate-y-1/2"
          style={{ left: `${q1Pos}%`, width: `${q3Pos - q1Pos}%` }}
        ></div>

        {/* Median line */}
        <div
          className="absolute top-1/2 h-3 w-1 rounded bg-green-300 transform -translate-y-1/2"
          style={{ left: `${medianPos}%` }}
        ></div>

        {/* Optional Threshold Lines */}
        {lowerThresholdPos !== null && (
          <div
            className="absolute top-0 h-full w-[0.1px] border border-rose-500 border-dashed transform translate-y-0"
            style={{ left: `${lowerThresholdPos}%` }}
          ></div>
        )}
        {upperThresholdPos !== null && (
          <div
            className="absolute top-0 h-full w-[0.1px] border border-rose-500 border-dashed transform translate-y-0"
            style={{ left: `${upperThresholdPos}%` }}
          ></div>
        )}
      </div>
      {!isCompact && (
        <div className="mt-2">
          <SideLabeledField label="Min" value={min}/>
          <SideLabeledField label="Q1" value={q1}/>
          <SideLabeledField label="Median" value={median}/>
          <SideLabeledField label="Q3" value={q3}/>
          <SideLabeledField label="Max" value={max}/>
        </div>
      )}
    </div>
  );
};
