import { TrashIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { SellerRoutes } from '~/hooks/useRoutes';
import { Order } from '~/types/types';

export class DeleteQuality extends BaseAction {
  public static icon = TrashIcon;
  public static analyticsName = 'delete_inspection';
  public static type: ActionAppType = 'seller';
  public static toast = {
    titleKey: 'deleted_quality',
  };

  constructor(public context: ActionContext<Order>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'delete_inspection';
  }

  public disabled() {
    return false;
  }

  public async execute(data: any) {
    const routes = this.context.routes as SellerRoutes;
    const route = routes.deleteQualityInput;

    await this.post(route(this.context.target.id), data);
  }
}
