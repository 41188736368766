// noinspection JSUnusedGlobalSymbols

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@/Container';
import { PlaceholderBox } from '@/Fallback';
import { PlaceholderIcon, ReportIcon } from '@/Icon';
import { LayoutBody, LayoutHeader } from '@/Layout';
import { PageDescription, PageTitle } from '@/Text';
import Section from '~/Components/Common/Section';
import { useQuery } from '~/hooks/useQuery';

import { AnalyticsSidebar } from '../../Analytics/AnalyticsSidebar';
import { DashboardLayout } from '../DashboardLayout';
import { AnalyticsProvider } from '../Reports/ReportsPage/AnalyticsProvider';
import { reportOptions } from '../Reports/ReportsPage/helpers';
import { ModulesMap } from '../Reports/ReportsPage/ModulesMap';
import { ReportsPageProps } from '../Reports/ReportsPage/types';
import AnalyticsFlowBuilder from './AnalyticsFlowBuilder';

const AnalyticsPage = ({
  state,
  filter,
  costsModule,
  issuesModule,
  reportingAccuracyModule,
  filterOptions,
}: ReportsPageProps) => {
  const { t } = useTranslation();

  const modules = [
    costsModule, issuesModule, reportingAccuracyModule,
  ].filter((val) => !!val);
  const needsGranularity = !!costsModule || !!reportingAccuracyModule;

  return (
    <div>
      <LayoutHeader fullWidth>
        <Container>
          <div className="flex items-center justify-between">
            <div>
              <PageTitle>
                {t('analytics.title')}
              </PageTitle>
              <PageDescription>
                {t('reports.reports_description')}
              </PageDescription>
            </div>
          </div>
        </Container>
      </LayoutHeader>

      <LayoutBody>
        {state === 'requires_data' && (
          <Section>
            <AnalyticsFlowBuilder
              filterTypes={reportOptions}
              onSubmit={() => {}}
            />
          </Section>
        )}
        <div className="min-h-[calc(100vh-var(--header-height))]">
          <div>
            {filter?.isEmpty && (
              <PlaceholderBox
                title={t('reports.no_filter')}
                description={t('reports.no_filter_description')}
                icon={<PlaceholderIcon icon={ReportIcon}/>}
              />
            )}

            {!!modules && modules.length > 0 && (
              <div className="space-y-4 divide-y divide-gray-200">
                {modules.map((module, index) => (
                  <div className="py-4" key={index}>
                    <ModulesMap baseModule={module}/>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </LayoutBody>

    </div>
  );
};

AnalyticsPage.layout = (page: React.ReactNode) => (
  <AnalyticsProvider>
    <DashboardLayout sideBar={<AnalyticsSidebar />}>
      {page}
    </DashboardLayout>
  </AnalyticsProvider>
);
export default AnalyticsPage;
