import React from 'react';

import { LabeledField } from '@/LabeledField';
import { getStatusFromMap, StatusBadge } from '@/Labels/Status';
import { Tooltipped } from '@/Tooltip';
import SparkChart from '~/Components/Analytics/Charts/SparkCharts/SparkCharts';
import { TimeHorizon } from '~/Components/Analytics/Charts/TimeHorizon/TimeHorizon';
import { buildOrderGraph } from '~/Components/Analytics/Charts/TimeHorizon/TimeHorizon.helpers';
import { OrderImage } from '~/Components/Orders/Representations/OrderImage';
import { OrderTitle } from '~/Components/Orders/Representations/OrderTitle';
import { formatEntry } from '~/Components/Quality/QualityEntries/FormatQualityEntry';
import { qualityInspectionsStatusMap } from '~/Components/Quality/QualityEntries/Inspections/QualityInspectionsStatusMap';
import { useFormat } from '~/hooks/useFormat';
import { Order, OrderData } from '~/types/types';

interface OrderDetailsListProps {
  order: Order;
  details?: OrderData;
}

/**
 * Represents an order by a summary of its details in their most compat form.
 *
 * @preconditions - Rendered inside of a container element (such as a card, dropdown, etc).
 *                - The `details` are a meta-cache of the order's data and have been loaded and passed in.
 */
export const OrderDetailsList = ({ order, details }: OrderDetailsListProps) => {
  const { formatCurrency } = useFormat();
  const orderTimeHorizon = buildOrderGraph(order);

  /**
   * All spark charts
   */
  const unitsWithSpark = details?.unitSummaries?.filter((unit) => !!unit.transport_spark_chart);
  const randomTransportSparkCharts = unitsWithSpark?.[0]?.transport_spark_chart?.sparkCharts ?? [];
  const lightSparkChart = randomTransportSparkCharts.find((spark) => spark.type === 'light');
  const temperatureSparkChart = randomTransportSparkCharts.find((spark) => spark.type === 'temperature');

  const inspectionsData = details?.inspectionsData;

  console.log(details);

  return (
    <div className="space-y-2">
      <div className="flex gap-1 items-center">
        <OrderImage order={order}/>
        <OrderTitle order={order}/>
      </div>

      <hr/>

      {/*{details?.claimAmount !== null && details?.claimAmount !== undefined && (*/}
      {/*  <LabeledField*/}
      {/*    label="Claims"*/}
      {/*    renderValue={(*/}
      {/*      <Label theme="emerald">*/}
      {/*        {formatCurrency(details?.claimAmount)}*/}
      {/*      </Label>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*)}*/}

      <LabeledField
        label="Shipment timeline"
        renderValue={(
          <TimeHorizon compact timeGraph={orderTimeHorizon} showLabels={false}/>
        )}
      />

      {lightSparkChart && (
        <div className="py-2">
          <LabeledField
            label="Transport: Light data"
            renderValue={(
              <SparkChart data={lightSparkChart}/>
            )}
          />
        </div>
      )}

      {temperatureSparkChart && (
        <div className="py-2">
          <LabeledField
            label="Transport: Temperature data"
            renderValue={(
              <SparkChart data={temperatureSparkChart}/>
            )}
          />
        </div>
      )}

      {inspectionsData && (
        <div className="py-2">
          <LabeledField
            label="Inspection defects"
            renderValue={(
              <div className="flex flex-wrap gap-1">
                {inspectionsData.inspections?.[0].qualityItems?.map((inspection, index) => (
                  <Tooltipped
                    key={index}
                    label={formatEntry(inspection.averageValue, inspection.unit, inspection.fieldType)}
                  >
                    <div>
                      <StatusBadge
                        config={getStatusFromMap(inspection.defectLevel, qualityInspectionsStatusMap) ?? null}
                      >
                        {inspection.fieldName}
                      </StatusBadge>
                    </div>
                  </Tooltipped>
                ))}
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};
