/* eslint-disable @typescript-eslint/ban-ts-comment */
import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ActionbarBlock, ActionbarItem, ActionbarRoot, ActionbarSeparator } from '@/Actionbar';
import { Button } from '@/Button';
import { CardBody, CardGutter, CardSectionTitle } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import { Form, FormControl, FormField, FormGrid, FormItem, FormMessage } from '@/Form';
import {
  ActionbarIcon,
  AddIcon,
  ButtonIcon,
  ConstraintIcon, DeleteIcon, DiscardIcon, PlaceholderIcon, SaveIcon,
} from '@/Icon';
import { Input } from '@/Input';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { SideLabeledField } from '@/SideLabeledField';
import Switch from '@/Switch';
import { Helper } from '@/Text';
import { TextArea } from '@/TextArea';
import { SaveSpecField } from '~/Actions/Specs/SaveSpecField';
import { Field, Spec } from '~/types/types';
import { cn } from '~/utils/cn';
import { delimit } from '~/utils/delimitList';
import { formatPicker, getGroups, toPickerItem } from '~/utils/formatPicker';
import { makeRandomID } from '~/utils/random';

import { makeDefaultConstraint, makeDefaultField } from '../helpers/factories';
import { FieldFormatters } from '../helpers/formatters';
import { singleFieldFormSchema, SpecField } from '../helpers/schemas';
import { SpecConstraintCardSubForm } from './SpecConstraintCardSubForm';

interface EditSpecFieldCardFormProps {
  defaultField: Field;
  spec: Spec;
  knownFields: Field[];
  disabled: boolean;
  saveAction: SaveSpecField;
  onCancel: () => void;
  onRemove: () => void;
}

export const EditSpecFieldCardForm = ({
  defaultField,
  spec,
  knownFields,
  saveAction,
  disabled,
  onCancel,
  onRemove,
}: EditSpecFieldCardFormProps) => {
  const { t } = useTranslation();

  /**
   * Register the form for the field
   */
  const form = useForm<SpecField>({
    resolver: zodResolver(singleFieldFormSchema),
    defaultValues: FieldFormatters.modelToForm(defaultField),
  });

  /**
   * Extract the "field" from the form
   *
   * Note:
   * - The `defaultField` is the unedited form. Use this to reference the original field (prior to form editing).
   * - The `formField` is the edited form. Use this to reference the current field (after form editing), but before saving.
   */
  const formField = form.watch() as SpecField;

  /**
   * Register field constraints as an array operation
   */
  const {
    fields: defaultConstraints,
    append: appendConstraint,
    remove: removeConstraint,
  } = useFieldArray<SpecField, 'constraints'>({
    control: form.control,
    name: 'constraints',
  });

  /**
   * The field options we can support are:
   * + All known fields
   * + All known supported fields
   * + The local field that is being edited (in case it is a new field)
   */
  const supportedFieldOptions =
    formatPicker(
      [
        // The local field that is being edited
        toPickerItem(formField, 'id', 'name'),
        // All known supported fields
        ...knownFields.map((knownField) => ({
          ...knownField,
          subLabel: delimit([knownField.unit, knownField.defect_type, knownField.type]),
          metaLabel: knownField.assigned ? 'Assigned' : undefined,
        })),
      ],
      'name', 'name', 'category',
    );

  const groups = getGroups(supportedFieldOptions, 'category');

  /**
   * Add a new constraint to the field.
   */
  const handleAddConstraint = () => {
    appendConstraint(makeDefaultConstraint(formField));
  };

  /**
   * Whenever we change the field type, we also refresh the constraints of the field.
   *
   * Why do we need to refresh the constraints?
   * - Because the constraints' range type are linked to the field type. If any existing constraints exist, we need to update them.
   */
  const handleChangeFieldType = () => {
    form.setValue('constraints', []);
  };

  /**
   * If a field name is set, check if it's a known field.
   * - If so, we set the base_type, category, description, and metaLabel.
   * - If not, then we set the base_type to 'unknown' and the metaLabel to 'Custom'.
   * @param name
   */
  const handleSetFieldName = (name: string | null) => {
    const fieldId = formField.id;

    const supportedBaseField = supportedFieldOptions.find((field: any) => field.name === name);

    if (supportedBaseField) {
      form.reset(FieldFormatters.modelToForm({ ...supportedBaseField, id: fieldId ?? makeRandomID() } as any));
      return;
    }

    // If no supported base field, we create an unknown field
    form.reset({
      ...makeDefaultField(),
      ...formField,
      id: formField.id ?? makeRandomID(),
      name: name ?? '',
      base_type: 'unknown',
      metaLabel: 'Custom',
      constraints: [],
    } as any);
  };

  return (
    <Form {...form}>
      <div
        aria-label={`field-card for ${formField.name}`}
        className={cn('grid shadow-xs grid-cols-[1fr_3fr] rounded', disabled && 'opacity-30 pointer-events-none')}
      >
        <div className="px-4 py-4 border border-gray-200 border-r-0 rounded-l bg-gray-100">
          <div className="flex justify-between h-full flex-col">
            <div>
              <FormField
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <GenericPicker
                      value={field.value}
                      onChange={(value) => {
                        handleSetFieldName(value as string | null);
                      }}
                      onCreate={(newValue) => {
                        // Handle the creation of a new field
                        handleSetFieldName(newValue);
                      }}
                      groups={groups}
                      isMulti={false}
                      placeholder="Select or create field"
                      title="Field Type"
                      emptyMessage="No field type selected"
                      showClear={false}
                      options={supportedFieldOptions}
                    />
                  </FormItem>
                )}
              />
            </div>

            <div className="mt-12 w-full">
              <div>
                <Button onClick={onRemove} type="button" variant="gray" className="w-full justify-center flex">
                  <ButtonIcon icon={DeleteIcon}/>

                  {t('remove_field')}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-2 border rounded-r bg-white border-gray-200">
          <div>
            <div className="-mx-4 -mt-2 rounded-r">
              <CardGutter className="rounded-tr">
                <CardSectionTitle>
                  {t('settings')}
                </CardSectionTitle>
              </CardGutter>
            </div>
            <CardBody size="sm">
              <div>
                <FormGrid>
                  <SideLabeledField
                    label="Unit"
                    columns="wide"
                    uniqueLabel={`unit for ${formField.name}`}
                    subLabel="Define the unit of measurement for this field (e.g. kg, cm, etc.)"
                    renderValue={(
                      <FormField
                        name="unit"
                        render={({ field: controlledField }) => (
                          <FormItem>
                            <Input
                              {...controlledField}
                              placeholder={formField.type === 'percentage' ? '%' : 'kg'}
                              type="text"
                              disabled={formField.type === 'percentage'}
                              className="w-20 bg-white"
                              id={`unit for ${formField.name}`}
                            />
                          </FormItem>
                        )}
                      />
                    )}
                  />

                  <SideLabeledField
                    label="Description"
                    columns="wide"
                    uniqueLabel={`description for ${formField.name}`}
                    subLabel="Provide a description for this field. This will help users understand the purpose of this field."
                    renderValue={(
                      <FormField
                        name="description"
                        render={({ field: controlledField }) => (
                          <FormItem>
                            <FormControl {...controlledField}>
                              <TextArea
                                placeholder={'Optional description for this field'}
                                className="w-full bg-white"
                                id={`description for ${formField.name}`}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    )}
                  />

                  <SideLabeledField
                    label="Field Type"
                    columns="wide"
                    subLabel="Defined the type of data this field will store. Measurements are used for measurable tests (e.g. brix, weight, juice content, etc.), while percentages are used for defect measurements."
                    renderValue={(
                      <div>
                        <FormField
                          name="type"
                          render={({ field: controlledField }) => (
                            <FormItem className="justify-end flex items-center flex-wrap">
                              <div>
                                <GenericPicker
                                  {...controlledField}
                                  onChange={(value) => {
                                    controlledField.onChange(value);
                                    handleChangeFieldType();
                                  }}
                                  isMulti={false}
                                  placeholder="Select field type"
                                  title="Field data type"
                                  emptyMessage="No field type selected"
                                  showClear={false}
                                  options={[
                                    {
                                      label: 'Measurement',
                                      subLabel: 'Values that can be measured (e.g. weight, length)',
                                      value: 'measurement',
                                    },
                                    {
                                      label: 'Percentage',
                                      subLabel: '(Defects only): Values that can be counted (e.g. bruising, blemishes)',
                                      value: 'percentage',
                                    },
                                    {
                                      label: 'Ratio',
                                      subLabel: 'For values that are ratios (e.g. sugar:acid) for example',
                                      value: 'ratio',
                                    },
                                  ]}
                                />
                              </div>

                              <div className="w-full grow ">
                                <FormMessage/>
                              </div>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}
                  />

                  <SideLabeledField
                    label="Severity"
                    subLabel="Define the type of defect that will be recorded for this field. This is used to categorize defects and determine their severity."
                    columns="wide"
                    renderValue={(
                      <FormField
                        name="defect_type"
                        render={({ field: controlledField }) => (
                          <FormItem className="justify-end flex items-center">
                            <GenericPicker
                              {...controlledField}
                              isMulti={false}
                              placeholder="Select defect type"
                              title="Defect Type"
                              emptyMessage="No defect type selected"
                              showClear={true}
                              options={[
                                {
                                  label: 'Minor',
                                  subLabel: 'A minor defect that does not significantly affect the quality of the produce',
                                  value: 'minor',
                                },
                                {
                                  label: 'Major',
                                  subLabel: 'A major defect that significantly affects the quality of the produce',
                                  value: 'major',
                                },
                                {
                                  label: 'Nil',
                                  subLabel: 'Zero tolerance for this field: any constraint violation will result in a failed inspection',
                                  value: 'nil',
                                },
                              ]}
                            />
                          </FormItem>
                        )}
                      />
                    )}
                  />

                  <SideLabeledField
                    label="Required"
                    columns="wide"
                    subLabel="Ensure this field is required during inspection. This will help ensure data quality and consistency."
                    uniqueLabel={`required for ${formField.name}`}
                    renderValue={(
                      <div className="flex justify-end">
                        <FormField
                          name="required"
                          render={({ field: controlledField }) => (
                            <FormItem>
                              <Switch
                                id={`required for ${formField.name}`}
                                checked={controlledField.value as boolean}
                                onCheckedChange={controlledField.onChange}
                              />
                              <FormMessage/>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}
                  />
                </FormGrid>
              </div>
            </CardBody>
          </div>

          <div>
            <div className="-mx-4">
              <CardGutter>
                <Helper>
                  Constraints
                </Helper>
              </CardGutter>
            </div>
          </div>

          {defaultConstraints.length === 0 && (
            <div className="py-4">
              <PlaceholderBox
                title="No Constraints Set"
                description="Add constraints to define acceptable ranges or values for this field. Constraints help ensure data quality and consistency."
                icon={<PlaceholderIcon icon={ConstraintIcon}/>}
              >
                <Button
                  size="xs"
                  variant="primary"
                  onClick={handleAddConstraint}
                >
                  <ButtonIcon icon={AddIcon}/>
                  Add a constraint
                </Button>
              </PlaceholderBox>
            </div>
          )}

          {defaultConstraints.length > 0 && (
            <div>
              <div className="space-y-4 py-4">
                {defaultConstraints.map((defaultConstraint, constraintIndex) => (
                  <div key={defaultConstraint.id}>
                    <SpecConstraintCardSubForm
                      defaultConstraint={defaultConstraint}
                      constraintIndex={constraintIndex}
                      formField={formField}
                      onRemove={() => removeConstraint(constraintIndex)}
                      spec={spec}
                    />
                  </div>
                ))}
              </div>

              <Button
                size="sm"
                variant="add"
                onClick={handleAddConstraint}
              >
                <ButtonIcon icon={AddIcon}/>
                Add another constraint
              </Button>
            </div>
          )}
        </div>
      </div>

      <ActionbarRoot show>
        <ActionbarBlock>
          Editing {formField?.name}
        </ActionbarBlock>

        <ActionbarSeparator/>

        <ActionbarItem
          onClick={() => saveAction.execute(FieldFormatters.formToApi(formField))}
        >
          <ActionbarIcon icon={SaveIcon}/>
          Save field
        </ActionbarItem>

        <ActionbarSeparator/>

        <ActionbarItem onClick={onCancel}>
          <ActionbarIcon icon={DiscardIcon}/>
          Discard changes
        </ActionbarItem>
      </ActionbarRoot>
    </Form>
  );
};
