import { MutedText } from '@/Text';
import { useFormat } from '~/hooks/useFormat';

interface BooleanBreakdownProps {
  truePercentage: number; // Percentage of true values
  falsePercentage: number; // Percentage of false values
  trueCount?: number; // Number of true values
  falseCount?: number; // Number of false values
  isCompact?: boolean; // Whether to display the component in a compact form
}

/**
 * A component that visually shows a breakdown of boolean values as two colored bars.
 * @param truePercentage - The percentage of true values.
 * @param falsePercentage - The percentage of false values.
 * @constructor
 */
export const BooleanBreakdown = ({
  truePercentage,
  falsePercentage,
  trueCount,
  falseCount,
  isCompact = false,
}: BooleanBreakdownProps) => {
  const { formatPercentage } = useFormat();
  // Ensure the percentages add up to 100%
  const adjustedTruePercentage = truePercentage <= 100 ? truePercentage : 100;
  const adjustedFalsePercentage = falsePercentage <= 100 ? falsePercentage : 100;

  return (
    <div>
      <div className="flex w-full h-3 bg-gray-100 rounded overflow-hidden shadow-xs">
        {/* Green bar representing true values */}
        <div
          className="h-full bg-rose-400"
          style={{ width: `${adjustedTruePercentage}%` }}
        ></div>
        {/* Red bar representing false values */}
        <div
          className="h-full bg-emerald-400"
          style={{ width: `${adjustedFalsePercentage}%`, float: 'right' }}
        ></div>
      </div>
      {!isCompact && (
        <div>
          <div>
            <MutedText>
              {formatPercentage(adjustedTruePercentage)} marked as "present" ({trueCount} times)
            </MutedText>
          </div>
          <MutedText>
            {formatPercentage(adjustedFalsePercentage)} marked as "absent" ({falseCount} times)
          </MutedText>
        </div>
      )}
    </div>
  );
};
