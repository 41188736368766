import { motion } from 'framer-motion';

import { Range } from '~/types/types';
import { cn } from '~/utils/cn';

interface SpecBoxPlotProps {
  range: {
    min: number | null;
    max: number | null;
    inclusive_min?: boolean;
    inclusive_max?: boolean;
  };
  value: number;
  domain: {
    min: number;
    max: number;
  };
  unit?: string;
  compact?: boolean;
}

const formatUnit = (unit: string) => {
  if (unit.length > 4) {
    return unit.slice(0, 4) + '...';
  }
  return unit;
};

const springTransition = {
  type: 'spring',
  bounce: 0.2,
  duration: 0.6,
};

export const SpecBoxPlot = ({
  range,
  value,
  domain,
  unit = '',
  compact,
}: SpecBoxPlotProps) => {
  const formattedUnit = formatUnit(unit);

  const calculatePosition = (val: number) => {
    return ((val - domain.min) / (domain.max - domain.min)) * 100;
  };

  const valuePos = calculatePosition(value);

  // Determine if value is within bounds
  const isWithinRange = (
    (range.min === null || value >= range.min) &&
    (range.max === null || value <= range.max)
  );

  // Helper to render the range visualization
  const getRangeStyle = () => {
    if (range.min !== null && range.max !== null) {
      // Both min and max defined
      return {
        left: `${calculatePosition(range.min)}%`,
        width: `${calculatePosition(range.max) - calculatePosition(range.min)}%`,
      };
    } else if (range.min !== null) {
      // Only min defined - extend to end
      return {
        left: `${calculatePosition(range.min)}%`,
        width: `${100 - calculatePosition(range.min)}%`,
      };
    } else if (range.max !== null) {
      // Only max defined - extend from start
      return {
        left: '0%',
        width: `${calculatePosition(range.max)}%`,
      };
    }
    return {};
  };

  return (
    <div className="space-y-1">
      {/* Main plot */}
      <div className="relative h-6">
        {/* Base line */}
        <div className="absolute top-1/2 h-0.5 w-full bg-gray-100 transform -translate-y-1/2"/>

        {/* Range area - Always green, represents the spec */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={springTransition}
          className={cn(
            'absolute top-1/2 h-4 rounded shadow-sm transform -translate-y-1/2',
            'bg-emerald-100 border-2 border-emerald-500'
          )}
          style={getRangeStyle()}
        />

        {/* Value indicator */}
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={springTransition}
          className="absolute top-0 transform -translate-x-1/2 flex flex-col items-center"
          style={{ left: `${valuePos}%` }}
        >
          <motion.div
            className={cn(
              'h-6 w-0.5',
              isWithinRange ? 'bg-emerald-600' : 'bg-rose-500'
            )}
            animate={{
              height: [0, 24],
              transition: { delay: 0.2, duration: 0.3 },
            }}
          />
        </motion.div>
      </div>

      {/* Scale indicators */}
      {!compact && (
        <div className="relative h-4 text-xs">
          {/* Domain min */}
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="absolute left-0 -translate-x-1/2 text-gray-500"
          >
            {domain.min}{formattedUnit}
          </motion.div>

          {/* Range min (if exists) */}
          {range.min !== null && (
            <motion.div
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="absolute -translate-x-1/2 font-medium text-emerald-700"
              style={{ left: `${calculatePosition(range.min)}%` }}
            >
              {range.min}{formattedUnit}
            </motion.div>
          )}

          {/* Value */}
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className={cn(
              'absolute -translate-x-1/2 font-medium',
              isWithinRange ? 'text-emerald-700' : 'text-rose-600'
            )}
            style={{ left: `${valuePos}%` }}
          >
            {value}{formattedUnit}
          </motion.div>

          {/* Range max (if exists) */}
          {range.max !== null && (
            <motion.div
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="absolute -translate-x-1/2 font-medium text-emerald-700"
              style={{ left: `${calculatePosition(range.max)}%` }}
            >
              {range.max}{formattedUnit}
            </motion.div>
          )}

          {/* Domain max */}
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="absolute right-0 translate-x-1/2 text-gray-500"
          >
            {domain.max}{formattedUnit}
          </motion.div>
        </div>
      )}
    </div>
  );
};

export const calculateDomainFromRange = (specRange: Range, value: number) => {
  // Start with 0 as the minimum domain value
  const domainMin = 0;

  // Find the highest value between:
  // - The spec's max (if it exists)
  // - The spec's min (if it exists)
  // - The current value
  const relevantMax = Math.max(
    specRange.max ?? -Infinity,
    specRange.min ?? -Infinity,
    value
  );

  // Add 30% padding to the right
  const padding = relevantMax * 0.3;
  const domainMax = Math.ceil(relevantMax + padding);

  return {
    min: domainMin,
    max: domainMax,
  };
};
