import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardHeader } from '@/Card';
import { Form, FormField, FormGrid, FormItem, FormMessage } from '@/Form';
import { NumberInput } from '@/Form/NumberInput';
import { SideLabeledField } from '@/SideLabeledField';
import { Slider } from '@/Slider';
import { CardTitle } from '@/Text';
import { SaveProduceThresholds } from '~/Actions/Specs/SaveProduceThresholds';
import { useCommandAction } from '~/hooks/useCommandAction';
import { Produce } from '~/types/types';

import { Formatters, FormSaveTempValues, saveTempSchema } from './WeatherProduceTempForm.helpers';

interface WeatherTempFormProps {
  produce: Produce;
}

export const WeatherProduceTempForm = ({ produce }: WeatherTempFormProps) => {
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: Formatters.modelToForm(produce),
    resolver: zodResolver(saveTempSchema),
  });

  const [save, { loading }] = useCommandAction(SaveProduceThresholds, produce);

  const onSubmit = async (values: FormSaveTempValues) => {
    await save.execute(Formatters.formToApi(values));
  };

  const temp = form.watch('temp');
  const lowTemp = temp.low;
  const highTemp = temp.high;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div>
          <Card>
            <CardHeader>
              <CardTitle>
                {t('Temperature')}
              </CardTitle>
            </CardHeader>
            <CardBody>

              <FormGrid>
                <FormField
                  name="temp"
                  render={({ field: controlledField }) => (
                    <FormItem>
                      <SideLabeledField
                        label="Temperature threshold"
                        subLabel={`The temperature this produce should be grown at is between ${lowTemp}°C and ${highTemp}°C`}
                        renderValue={(
                          <Slider
                            {...controlledField}
                            showThumbValue
                            unit="°C"
                            direction="ltr"
                            color="blue"
                            type="range"
                            value={[controlledField.value.low, controlledField.value.high]}
                            onChange={([low, high]) => controlledField.onChange({ low, high })}
                            min={-10}
                            max={60}
                            step={0.5}
                          />
                        )}/>
                      <FormMessage/>
                    </FormItem>
                  )}
                />
                <FormField
                  name="minLengthInHours"
                  render={({ field: controlledField }) => (
                    <FormItem>
                      <SideLabeledField
                        label={t('temp_threshold_hours_title')}
                        subLabel={t('weather_temp_threshold_hours_description')}
                        renderValue={(
                          <div className="inline-block w-24">
                            <NumberInput
                              {...controlledField}
                              type="number"
                              min={0}
                              step={1}
                              className="w-12"
                            />
                          </div>
                        )}/>
                      <FormMessage/>
                    </FormItem>
                  )}
                />
              </FormGrid>
            </CardBody>
            <CardFooter>
              <Button disabled={save.disabled() || loading || !form.formState.isValid || !form.formState.isDirty as any}
                type="submit"
                variant="primary"
              >
                {t('Save')}
              </Button>
            </CardFooter>
          </Card>
        </div>
      </form>
    </Form>
  );
};
