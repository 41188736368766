import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Modal, ModalContent, ModalTrigger } from '@/Modal';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { Issue } from '~/types/types';

interface ResolveIssueData {
  status: string;
  comment: string;
  informSeller: boolean;
}

interface ResolveIssueModalProps {
  children: ReactNode;
  issue: Issue;
  initialIssueState: string;
  onChange?: (data: ResolveIssueData) => void;
}

export const ResolveIssueModal = ({ children, initialIssueState, onChange }: ResolveIssueModalProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(initialIssueState);
  const [comment, setComment] = useState('');
  const [informSeller, setInformSeller] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (initialIssueState) {
      setStatus(initialIssueState);
    }
  }, [initialIssueState]);

  const handleStatusChange = (newStatus: string) => {
    setStatus(newStatus);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onChange) {
      onChange({ status, comment, informSeller });
    }

    setOpen(false);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setComment(textarea.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInformSeller(event.target.checked);
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        {children}
      </ModalTrigger>
      <ModalContent className="min-w-[800px]">
        <form onSubmit={handleSubmit} className="relative">
          <div className="overflow-hidden rounded-lg border border-gray-300 shadow-xs">
            <div className="text-sm px-3 border-bottom border-gray-200 border-bottom-1 border-b py-2.5 font-normal"
              style={{ color: 'rgb(113, 113, 122)' }}>
              {t('resolve_issue')}
            </div>
            <div className="">
              <label htmlFor="description" className="sr-only">
                {t('description')}
              </label>
              <textarea
                id="description"
                name="description"
                rows={2}
                placeholder={t('add_comment') as any}
                className="block w-full resize-none border-0 py-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                defaultValue={''}
                onInput={handleInput}
              />

              {/* Spacer element to match the height of the toolbar */}
              <div aria-hidden="true">
                <div className="py-2">
                  <div className="h-9"/>
                </div>
                <div className="h-px"/>
                <div className="py-2">
                  <div className="py-px">
                    <div className="h-9"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute inset-x-px bottom-0">
            {/* Actions */}
            <div className="flex flex-nowrap justify-end space-x-2 px-2 py-2 sm:px-3">
              <div className="max-w-[200px]">
                <GenericPicker
                  title={t('status')}
                  value={status}
                  selected={status}
                  options={[
                    { label: t('escalating'), value: 'escalated' },
                    { label: t('accepted'), value: 'accepted' },
                    { label: t('read'), value: 'read' },
                  ]}
                  emptyMessage=""
                  placeholder=""
                  onChange={(status) => handleStatusChange(status ?? 'read')}
                />
              </div>
            </div>
            <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
              <div className="flex">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="comments"
                      name="comments"
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      aria-describedby="comments-description"
                      className="peer h-3 w-3 shrink-0 rounded-xs border border-primary ring-offset-background focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
                    />
                  </div>
                  <div className="ml-3 text-xs leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900">
                      {t('share_with_seller')}
                    </label>
                    <span id="comments-description" className="text-gray-500">
                      <span className="sr-only">{t('save_settings')}</span>{t('share_with_seller_description')}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <Button>
                  {t('submit')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
};
