/**
 * React custom hook which returns the page props
 */

import { usePage } from '@inertiajs/react';
import { createContext, useContext, useState } from 'react';

import { PageProps } from '~/types/types';

interface PagePropsWithUrl extends PageProps {
  url: string;
  features: Record<string, [0]>;
}

interface LoadingState {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

const LoadingContext = createContext<LoadingState>({
  isLoading: false,
  setIsLoading: () => {},
});

export const useGlobalLoading = () => useContext(LoadingContext);

export const usePageProps = <T extends PageProps>(props?: T) => {
  const { url, props: hookProps = {} } = usePage<T>();
  const [isLoading, setIsLoading] = useState(false);

  return {
    url: url,
    ...hookProps,
    ...props,
    loading: {
      isLoading,
      setIsLoading,
    },
  } as T & PagePropsWithUrl & { loading: LoadingState };
};
