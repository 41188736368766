import { type VariantProps, cva } from 'class-variance-authority';
import { motion } from 'framer-motion';

import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';
import { Theme, themeClasses } from '~/utils/colors';

interface DataPoint {
  value: number;
  color?: string;
  label?: string;
  theme?: Theme;
}

const databarsVariants = cva(
  'flex w-full rounded-full overflow-hidden h-2', // base styles
  {
    variants: {
      size: {
        sm: 'h-1',
        md: 'h-2',
        lg: 'h-3',
        xl: 'h-4',
      },
      variant: {
        default: 'gap-0.5',
        connected: 'gap-0',
        segments: 'gap-1',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  }
);

interface DatabarsProps extends VariantProps<typeof databarsVariants> {
  data: DataPoint[];
  className?: string;
  animate?: boolean;
}

/**
 * Databars is a component that displays data as a series of horizontal bars.
 * It supports themes, custom colors, different sizes, and variants.
 *
 * @component
 * @example
 * ```tsx
 * <Databars
 *   data={[
 *     { value: 30, theme: 'green', label: 'Completed' },
 *     { value: 50, theme: 'orange', label: 'In Progress' },
 *     { value: 20, theme: 'red', label: 'Failed' },
 *   ]}
 *   size="md"
 *   variant="default"
 * />
 * ```
 */
export const Databars = ({
  data,
  className,
  size,
  variant,
  animate = true,
}: DatabarsProps) => {
  // Filter out null values and get valid data points
  const validData = data.filter((point): point is DataPoint & { value: number } =>
    point.value !== null && point.value !== undefined && point.value !== 0
  );

  // Calculate total for percentages (only from valid values)
  const total = validData.reduce((sum, point) => sum + point.value, 0);

  // Check if we have any valid data points
  const hasValidData = validData.length > 0;

  // If we have no valid data points, show a single empty bar
  if (!hasValidData) {
    return (
      <div
        className={cn(
          databarsVariants({ size, variant }),
          className
        )}
      >
        <div className="w-full h-full bg-gray-200 rounded-full"/>
      </div>
    );
  }

  return (
    <div
      className={cn(
        databarsVariants({ size, variant }),
        className
      )}
    >
      {validData.map((point, index) => {
        // If this is the only valid point, use full width
        const width = validData.length === 1
          ? 100
          : (point.value / total) * 100;

        return (
          <Tooltipped key={index} label={point.label}>
            <motion.div
              key={index}
              className={cn(
                'h-full',
                // Only apply rounded corners if:
                // 1. It's the only valid point (fully rounded)
                // 2. Or apply regular rounding rules for multiple points
                validData.length === 1
                  ? 'rounded-full'
                  : cn(
                    'rounded-full',
                    index === 0 && 'rounded-r-none',
                    index === validData.length - 1 && 'rounded-l-none',
                    index !== 0 && index !== validData.length - 1 && 'rounded-none',
                    variant === 'connected' && 'rounded-none',
                    point.label && 'cursor-pointer hover:brightness-110',
                  ),
                // Use theme class if theme is provided, otherwise use color or default
                point.theme ? themeClasses[point.theme] : 'bg-primary'
              )}
              style={{
                width: `${width}%`,
                // Only set backgroundColor if color is provided and theme is not
                ...(point.color && !point.theme && { backgroundColor: point.color }),
              }}
              initial={animate ? { width: 0 } : undefined}
              animate={animate ? { width: `${width}%` } : undefined}
              transition={{
                duration: 0.5,
                ease: 'easeOut',
                delay: index * 0.1,
              }}
            />
          </Tooltipped>
        );
      })}
    </div>
  );
};
