import { createContext, useContext, useState, ReactNode } from 'react';

interface AppContextType {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  load: <T>(promise: Promise<T>) => Promise<T>;
}

const AppContext = createContext<AppContextType | null>(null);

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [loading, setLoading] = useState(false);

  const load = async <T,>(promise: Promise<T>): Promise<T> => {
    try {
      setLoading(true);
      return await promise;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppContext.Provider value={{ loading, setLoading, load }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};
