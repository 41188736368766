import { AnimatePresence, motion, MotionConfig } from 'framer-motion';
import { ReactNode } from 'react';

import { cn } from '~/utils/cn';
import { defaultSpring, fadeInOutUpVariants } from '~/utils/motion';

// ActionbarRoot
interface ActionbarRootProps {
  children: ReactNode;
  className?: string;
  show?: boolean;
}

const actionbarSpringConfig = defaultSpring;

export const ActionbarRoot = ({ children, className, show }: ActionbarRootProps) => {
  return (
    <MotionConfig transition={actionbarSpringConfig}>
      <AnimatePresence>
        {show && (
          <motion.div
            {...fadeInOutUpVariants}
            aria-label="action-bar"
            className={cn(
              'fixed bottom-4 left-1/2 transform -translate-x-1/2',
              'z-50 flex items-center rounded-lg bg-gray-900 px-2 shadow-lg shadow-black/30 dark:ring-1 dark:ring-white/10',
              className
            )}
            style={{
              translateX: '-50%',
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </MotionConfig>
  );
};

interface ActionbarBlockProps {
  theme?: 'default' | 'red' | 'blue' | 'green';
  className?: string;
  children?: ReactNode;
}

const themeVariants = {
  default: 'text-gray-300',
  red: 'text-red-500/80',
  blue: 'text-blue-300/80',
  green: 'text-green-300/80',
};

export const ActionbarBlock = ({
  children,
  className,
  theme = 'default',
}: ActionbarBlockProps) => {
  return (
    <div
      className={cn(
        'px-3 py-2.5 text-sm tabular-nums',
        themeVariants[theme],
        className
      )}
    >
      {children}
    </div>
  );
};

// ActionbarItem
interface ActionbarItemProps {
  children: ReactNode;
  onClick?: () => void;
  shortcut?: string;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const ActionbarItem = ({
  children,
  onClick,
  shortcut,
  className,
  disabled = false,
  type = 'button',
}: ActionbarItemProps) => {
  return (
    <span className={cn(
      'flex items-center gap-x-2 rounded-lg bg-gray-900 p-1',
      'text-base font-medium text-gray-50 outline-hidden transition focus:z-10 sm:text-sm sm:last-of-type:-mr-1',
      className
    )}>
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={cn(
          'flex items-center  gap-x-2 rounded-md px-1 py-1',
          'hover:bg-gray-800 focus-visible:bg-gray-800 focus-visible:hover:bg-gray-800',
          'disabled:text-gray-500',
          'outline outline-offset-2 outline-0 focus-visible:outline-2 outline-blue-500 dark:outline-blue-500',
          disabled && 'cursor-not-allowed opacity-50'
        )}
      >
        <span>{children}</span>
        {shortcut && (
          <span
            className="hidden h-6 select-none items-center justify-center rounded-md bg-gray-800 px-2 font-mono text-xs text-gray-400 ring-1 ring-inset ring-gray-700 transition sm:flex">
            {shortcut}
          </span>
        )}
      </button>
    </span>
  );
};

// ActionbarSeparator
export const ActionbarSeparator = () => {
  return <div className="h-4 w-px bg-gray-700"/>;
};
