import { useFormContext } from 'react-hook-form';

import { IconButton } from '@/Button';
import { Card, CardBody, CardHeaderContainer } from '@/Card';
import { FormField, FormItem, FormMessage } from '@/Form';
import {
  ConstraintIcon, IconBox, IconBoxIcon, IconButtonIcon, TrashIcon,
} from '@/Icon';
import { LabeledField } from '@/LabeledField';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { MutedStrong, MutedText, Strong } from '@/Text';
import { cn } from '~/utils/cn';
import { strongBorderColorByIndex, strongColorByIndex } from '~/utils/colors';
import { formatPicker } from '~/utils/formatPicker';

import { makeDefaultConstraint } from '../helpers/factories';
import { copyRanges } from '../helpers/helpers';
import { SpecField } from '../helpers/schemas';
import { SpecConstraintsEditorCard } from './SpecConstraintsEditorCard';

interface SpecConstraintCardProps {
  defaultConstraint: any;
  constraintIndex: number;
  formField: SpecField;
  onRemove: () => void;
  spec: any;
}

/**
 * SpecConstraintCardSubForm
 *
 * This component is used to render the constraint card in the spec editor.
 *
 * @precondition - This component must be rendered directly or indirectly under a form (such as `EditSpecFieldCardForm`).
 *
 * @param defaultConstraint
 * @param constraintIndex
 * @param formField
 * @param spec
 * @param onRemove
 * @constructor
 */
export const SpecConstraintCardSubForm = ({
  defaultConstraint,
  constraintIndex,
  formField,
  spec,
  onRemove,
}: SpecConstraintCardProps) => {
  const { setValue, watch } = useFormContext();

  const constraint = watch(`constraints.${constraintIndex}`);

  const handleSetTemporarySpec = () => {
    setValue(`constraints.${constraintIndex}.nested_constraints`, [{
      ...makeDefaultConstraint(formField),
      type: defaultConstraint.type,
      tempStartDate: undefined,
      tempEndDate: undefined,
      ranges: copyRanges(defaultConstraint),
    }]);
  };

  return (
    <Card aria-label="constraint container" className={cn('border-l-4', strongBorderColorByIndex(constraintIndex))}>
      <CardHeaderContainer className="py-4!">
        <div className="flex justify-between items-center">
          <div aria-label={`icon and label for ${constraintIndex}`} className="flex items-center gap-3">
            <IconBox
              className={cn(
                'min-w-10! h-10! flex items-center justify-center border-0!',
                strongColorByIndex(constraintIndex),
              )}
            >
              <IconBoxIcon icon={ConstraintIcon}/>
            </IconBox>

            <div className="flex flex-col">
              <Strong className="leading-none">
                Field Constraint {constraintIndex + 1}
              </Strong>

              <div className="leading-none">
                <MutedText>
                  Apply rules to your produce
                </MutedText>
              </div>
            </div>
          </div>

          <div aria-label={`remove ${constraintIndex}`}>
            <IconButton label={`Remove constraint ${constraintIndex + 1}`} onClick={onRemove}>
              <IconButtonIcon icon={TrashIcon}/>
            </IconButton>
          </div>
        </div>
      </CardHeaderContainer>

      <CardBody>
        <div className="space-y-12">
          <div>
            <LabeledField
              label="Step 1: Define the allowed ranges"
              subLabel="Set the acceptable range for this field. Measurements outside this range will be flagged as potential issues during inspections."
              renderValue={(
                <div>
                  <FormField
                    name={`constraints.${constraintIndex}`}
                    render={({ field: controlledField }) => (
                      <FormItem>
                        <SpecConstraintsEditorCard
                          field={formField}
                          constraint={constraint}
                          onUpdate={controlledField.onChange}
                          onAddTempConstraint={handleSetTemporarySpec}
                        />

                        <FormMessage/>
                      </FormItem>
                    )}
                  />
                </div>
              )}/>
          </div>

          <div className="flex items-center">
            <LabeledField
              label="Step 2: Define the scope of this constraint"
              subLabel="Tailor your spec by choosing exactly where it applies to. Pick the specific produce, regions, sellers, and store intention it affects. This ensures your spec is used precisely where you need it"
              renderValue={(
                <div className="mt-1">
                  <Card>
                    <CardBody variant="muted" className="rounded">
                      <FormField
                        name={`constraints.${constraintIndex}.produce_varieties`}
                        render={({ field: controlledField }) => (
                          <FormItem>
                            <div>
                              <MutedStrong>
                                Produce Varieties
                              </MutedStrong>
                            </div>
                            <div className="mb-2">
                              <MutedText>
                                Select the produce varieties this constraint applies to
                              </MutedText>
                            </div>
                            <GenericPicker
                              value={controlledField.value}
                              onChange={controlledField.onChange}
                              isMulti={true}
                              placeholder="Select produce varieties"
                              title="All produce varieties"
                              showTitleOnSelected={false}
                              emptyMessage="No produce varieties selected"
                              showClear={true}
                              options={formatPicker(spec.linked_varieties ?? [], 'public_name', 'public_name')}
                            />

                            <FormMessage/>
                          </FormItem>
                        )}
                      />
                    </CardBody>
                  </Card>
                </div>
              )}/>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
