import { type VariantProps, cva } from 'class-variance-authority';
import { format } from 'date-fns';
import { CheckIcon } from 'lucide-react';
import React from 'react';

import { Avatar } from '@/Avatar';
import { Card, CardBody, CardFooter, CardGutter } from '@/Card';
import { ListDetail } from '@/Details';
import { Gutter } from '@/Gutter';
import { MiniLabel } from '@/Label';
import { RAGScoreLabel } from '@/Labels/RAGScoreLabel';
import { MutedStrong, MutedText, Strong } from '@/Text';
import { RAGScoreType } from '~/types/types';
import { cn } from '~/utils/cn';

interface QuoteProps {
  children: React.ReactNode;
  className?: string;
}

export const Quote = ({ children, className }: QuoteProps) => {
  return (
    <div className={cn('text-sm flex items-start gap-2 my-2', className)}>
      <p className="italic text-gray-500">
        <span className="inline-block text-gray-400">&ldquo;</span>
        {children}
        <span className="inline-block text-gray-400">&rdquo;</span>
      </p>
    </div>
  );
};

const chatBoxVariants = cva(
  'rounded-tr-2xl rounded-br-2xl rounded-bl-2xl p-4 max-w-[85%] text-sm bg-gray-50 border border-gray-200 shadow-xs',
  {
    variants: {
      variant: {
        sender: 'ml-12', // Offset for avatar
        receiver: 'bg-gray-100 mr-12 ml-auto', // Right-aligned
      },
    },
    defaultVariants: {
      variant: 'sender',
    },
  }
);

interface ChatBoxProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof chatBoxVariants> {
  author: {
    name: string;
    avatar?: string;
  };
  date: Date;
  message: string;
  isReceiver?: boolean;
  isRead?: boolean;
  score?: RAGScoreType;
  produceVarietyName?: string;
}

export const ChatBox = React.forwardRef<HTMLDivElement, ChatBoxProps>(
  ({ author, date, message, isReceiver = false, isRead = false, score, className, produceVarietyName, ...props }, ref) => {
    const initials = author.name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase();

    return (
      <div ref={ref} {...props}>
        {/* Message container */}
        <div className="relative">
          {/* Avatar - only show for sender */}
          <div className='flex gap-4'>
            <div>
              <Avatar
                size="md"
                theme="gray"
                imageSrc={author.avatar}
                initials={initials}
                alt={author.name}
              />
            </div>

            {/* Header with name and time */}
            <div className="w-full">
              <div>
                <MutedStrong>
                  {author.name}
                </MutedStrong>
                <span className="ml-2 text-gray-500">
                  {/* {format(date, 'h:mmaaa')} */}
                  {isReceiver && isRead && (
                    <CheckIcon className="inline w-4 h-4 ml-1 text-gray-400" />
                  )}
                </span>
              </div>

              <Card
                className='w-full mt-2 rounded-tl-none rounded-tr-2xl rounded-br-2xl rounded-bl-2xl'
              >
                <CardBody
                  className="rounded-tr-2xl" variant='muted'>
                  <div>
                    <Quote>
                      {message}
                    </Quote>
                  </div>

                </CardBody>

                <CardFooter
                  size='sm'
                  variant='darker'
                  className="rounded-bl-2xl rounded-br-2xl"
                >
                  <Gutter size="sm" gap='xl' theme="transparent">
                    <ListDetail
                      dtype='render'
                      renderFn={() => (
                        <div className="flex items-center gap-2">
                          <RAGScoreLabel score={score} />
                        </div>
                      )}
                      label="Score"
                      value={score}
                    />

                    <ListDetail
                      dtype="text"
                      label="Variety"
                      value={produceVarietyName}
                    />
                  </Gutter>
                </CardFooter>
              </Card>
            </div>
          </div>

          {/* Message bubble */}
        </div>
      </div>
    );
  }
);

ChatBox.displayName = 'ChatBox';