export type Theme = 'emerald' | 'red' | 'gray' | 'orange' | 'blue' | 'amber';

export const fillThemeClasses: Record<Theme, string> = {
  emerald: 'fill-emerald-500 hover:stroke-emerald-600',
  red: 'fill-rose-500 hover:stroke-rose-800',
  gray: 'fill-gray-500 hover:stroke-gray-600',
  orange: 'fill-amber-500 hover:stroke-amber-600',
  blue: 'fill-blue-500 hover:stroke-blue-600',
  amber: 'fill-amber-500 hover:stroke-amber-600',
};

export const themeClasses: Record<Theme, string> = {
  red: 'bg-rose-500',
  emerald: 'bg-emerald-500',
  orange: 'bg-amber-500',
  blue: 'bg-blue-500',
  gray: 'bg-gray-500',
  amber: 'bg-amber-500',
};

export const textVariants: Record<Theme, string> = {
  blue: 'text-blue-500',
  emerald: 'text-emerald-500',
  slate: 'text-slate-500',
  gray: 'text-gray-500',
  indigo: 'text-indigo-500',
  purple: 'text-purple-500',
  orange: 'text-orange-500',
  red: 'text-rose-700',
  pink: 'text-pink-500',
  cyan: 'text-cyan-500',
  teal: 'text-teal-500',
  lime: 'text-lime-500',
  amber: 'text-amber-500',
  green: 'text-green-500',
  fuchsia: 'text-fuchsia-500',
};

export const themeTokens = {
  red: '#F43F5E', // rose-500,
  green: '#10B981', // green-500,
  orange: '#F59E0B', // amber-500,
  blue: '#2563EB', // blue-500,
};

export const themes = [
  'blue',
  'emerald',
  'yellow',
  'slate',
  'gray',
  'indigo',
  'purple',
  'orange',
  'red',
  'pink',
  'cyan',
  'teal',
  'lime',
  'amber',
  'green',
  'fuchsia',
];

/**
 * Return color by index.
 *
 * Order of colors is defined in the `themes` array.
 * @param index
 */
export const strongColorByIndex = (index: number) => {
  if (index === 0) {
    return 'bg-blue-500! text-blue-50!';
  }

  if (index === 1) {
    return 'bg-emerald-500! text-emerald-50!';
  }

  if (index === 2) {
    return 'bg-yellow-500! text-yellow-50!';
  }

  if (index === 3) {
    return 'bg-slate-500! text-slate-50!';
  }

  if (index === 4) {
    return 'bg-gray-500! text-gray-50!';
  }

  if (index === 5) {
    return 'bg-indigo-500! text-indigo-50!';
  }

  if (index === 6) {
    return 'bg-purple-500! text-purple-50!';
  }

  if (index === 7) {
    return 'bg-orange-500! text-orange-50!';
  }

  if (index === 8) {
    return 'bg-red-500! text-red-50!';
  }

  if (index === 9) {
    return 'bg-pink-500! text-pink-50!';
  }

  if (index === 10) {
    return 'bg-cyan-500! text-cyan-50!';
  }

  return 'bg-gray-500! text-gray-50!';
};

export const strongBorderColorByIndex = (index: number) => {
  if (index === 0) {
    return 'border-blue-500';
  }

  if (index === 1) {
    return 'border-emerald-500';
  }

  if (index === 2) {
    return 'border-yellow-500';
  }

  if (index === 3) {
    return 'border-slate-500';
  }

  if (index === 4) {
    return 'border-gray-500';
  }

  if (index === 5) {
    return 'border-indigo-500';
  }

  if (index === 6) {
    return 'border-purple-500';
  }

  if (index === 7) {
    return 'border-orange-500';
  }

  if (index === 8) {
    return 'border-red-500';
  }

  if (index === 9) {
    return 'border-pink-500';
  }

  if (index === 10) {
    return 'border-cyan-500';
  }

  return 'border-gray-500';
};

export type DefaultTheme = typeof themes[number];
