import { zodResolver } from '@hookform/resolvers/zod';
import { motion } from 'framer-motion';
import { ChartArea, ThumbsDown } from 'lucide-react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardHeader } from '@/Card';
import { IconCheckboxCard } from '@/Card/CheckboxCard';
import { Form, FormControl, FormField, FormItem, FormValidationStatus, InputDescription, InputLabel } from '@/Form';
import { MeasurementFieldIcon, ProduceIcon, RegionIcon, SellerIcon } from '@/Icon';
import { GenericPicker, OptionBaseType } from '@/Pickers/GenericPicker';
import { PickerFetcher } from '@/Pickers/PickerFetcher';
import { ProgressStep, ProgressSteps } from '@/ProgressSteps';
import { Spinner } from '@/Spinner';
import { DescriptionText, Helper } from '@/Text';
import Section from '~/Components/Common/Section';
import { DateFilterPicker } from '~/Components/Dates/DateFilterPicker';
import { useQuery } from '~/hooks/useQuery';
import { useApp } from '~/Providers/AppProvider';
import { AnalyticsConfigRequest } from '~/types/types';
import { formatPicker, getGroups } from '~/utils/formatPicker';

import { useAnalytics } from '../Reports/ReportsPage/AnalyticsProvider';
import { comparisonMetrics } from '../Reports/ReportsPage/Modules/QualityIssues/QualityOverTime.helpers';

interface AnalyticsFlowBuilderProps {
  filterTypes: OptionBaseType[];
  onSubmit: (data: any) => void;
}

const formSchema = z.object({
  analysisTypes: z.array(z.string()).min(1, { message: 'At least one analysis type is required' }),
  varieties: z.array(z.string()).optional().nullable(),
  sellers: z.array(z.string()).optional().nullable(),
  dateFilter: z.any().optional(),
  region_ids: z.array(z.string()).optional().nullable(),
  unit_type: z.enum(['reds', 'field_scores', 'accuracy']),
  field_id: z.string().optional(),
  quality_entity_type: z.string(),
  produce_id: z.string().min(1, { message: 'Produce selection is required' }),
});

type FormValues = z.infer<typeof formSchema>;

const QualityIssuesSettings = ({ control, params }: { control: Control<FormValues>, params: { produce_id: string; seller_ids: string[]; produce_variety_ids: string[] } }) => {
  const { t } = useTranslation();
  const { filterOptions } = useAnalytics();

  const unitType = useWatch({ control, name: 'unit_type' });

  return (
    <div id="quality-issues-settings">
      <Card>
        <CardBody variant="muted" className="rounded">
          <Helper>
              Quality issues settings
          </Helper>
          <DescriptionText className='!text-gray-400'>
            Further customize your quality issues report by selecting what you want to analyze. You can always change your settings later.
          </DescriptionText>

          <div className="mt-4 space-y-6">
            <FormField
              name="unit_type"
              render={({ field }) => (
                <FormItem>
                  <InputLabel>{t('Select unit of analysis')}</InputLabel>
                  <InputDescription>
                    Select what you would like to analyze.
                  </InputDescription>
                  <FormControl>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-3">
                      {comparisonMetrics.map((option) => (
                        <IconCheckboxCard
                          key={option.value}
                          size="sm"
                          icon={option.icon}
                          showCheckbox={false}
                          title={option.label}
                          description={option.subLabel}
                          checked={option.value === field.value}
                          onCheckedChange={() => field.onChange(option.value)}
                        />
                      ))}
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />

            {unitType === 'field_scores' && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
              >
                <FormField
                  name="field_id"
                  shouldUnregister={false}
                  render={({ field }) => (
                    <FormItem>
                      <InputLabel>{t('Select field')}</InputLabel>
                      <InputDescription>{t('Select the field you want to analyze')}</InputDescription>
                      <FormControl>
                        <PickerFetcher
                          reloadKey="filterOptions"
                          optionsKey="filterOptions.fieldOptions"
                          parseInitialOptionsConfig={{
                            key: 'field_id',
                            labelKey: 'name',
                            valueKey: 'id',
                          }}
                          params={params}
                        >
                          {(props) => (
                            <GenericPicker
                              {...props}
                              title={t('field')}
                              onChange={field.onChange}
                              value={field.value}
                              placeholder={t('Search fields')}
                              groups={getGroups(filterOptions.fieldOptions)}
                              emptyMessage={t('No fields found')}
                            />
                          )}
                        </PickerFetcher>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </motion.div>
            )}

            <FormField
              name="quality_entity_type"
              render={({ field }) => (
                <FormItem>
                  <InputLabel>{t('Select comparison type')}</InputLabel>
                  <InputDescription>
                    Select the comparison type you want to analyze.
                  </InputDescription>
                  <FormControl>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-1">
                      {[
                        {
                          value: 'produce_variety',
                          icon: ProduceIcon,
                          label: 'Produce varieties',
                          subLabel: 'Compare quality of fields between produce varieties',
                          disabled: false,
                        },
                        {
                          value: 'region',
                          icon: RegionIcon,
                          label: 'Regions',
                          subLabel: 'Compare quality of fields between regions',
                          disabled: false,
                        },
                        {
                          value: 'seller',
                          icon: SellerIcon,
                          label: 'Sellers',
                          subLabel: 'Compare quality of fields between sellers',
                          disabled: true,
                        },
                      ].map((option) => (
                        <>
                          <IconCheckboxCard
                            size="sm"
                            icon={option.icon}
                            title={option.label}
                            onCheckedChange={() => field.onChange(option.value)}
                            description={option.subLabel}
                            checked={field.value === option.value}
                            showCheckbox={false}
                          />
                        </>
                      ))}
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export const AnalyticsFlowBuilder = ({
  filterTypes,
}: AnalyticsFlowBuilderProps) => {
  const { t } = useTranslation();
  const { filterOptions } = useAnalytics();
  const { setParametersOfScope, isLoading } = useQuery<AnalyticsConfigRequest>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      analysisTypes: [],
      varieties: [],
      sellers: [],
      region_ids: [],
      unit_type: 'reds',
      field_id: '',
      quality_entity_type: 'produce_variety',
      produce_id: '',
    },
  });

  const analysisTypeItems = filterTypes.map(type => ({
    value: type.value,
    label: type.label,
    description: type.subLabel,
    icon: type.icon,
  }));

  const selectedSellers = form.watch('sellers');
  const selectedProduce = form.watch('produce_id');
  const selectedVarieties = form.watch('varieties');
  const selectedRegions = form.watch('region_ids');
  const isFormValid = form.formState.isValid;

  const params = {
    produce_id: selectedProduce,
  };

  const handleReload = (values: FormValues) => {
    setParametersOfScope({
      produce_id: values.produce_id,
      filter_types: values.analysisTypes,
      region_ids: values.region_ids,
      seller_ids: values.sellers,
      produce_variety_ids: values.varieties,
      unit_type: values.unit_type,
      field_id: values.field_id,
      quality_entity_type: values.quality_entity_type,
      date_filter: {
        from: values.dateFilter?.inputFromDate,
        to: values.dateFilter?.inputToDate,
        granularity: values.dateFilter?.granularity,
        period: values.dateFilter?.period,
      },
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleReload)}>

        {/* Filter Selection */}
        <Section>
          <Card isLoading={isLoading}>
            <CardHeader
              title="Build your report"
              description="Follow the steps below to generate your analytics report. Select your analysis type, choose the produce you want to analyze, apply any additional filters, and set your date range."
              icon={ChartArea}
            />
            <div className="py-4">
              <ProgressSteps>

                <ProgressStep
                  title="Produce"
                  description="Select specific produce to include in your analysis"
                  isActive={!selectedProduce}
                  spacing="lg"
                  isCompleted={!!selectedProduce}
                >
                  <FormField
                    name="produce_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <PickerFetcher
                            reloadKey="filterOptions"
                            optionsKey="filterOptions.produceOptions"
                            parseInitialOptionsConfig={{
                              key: 'produce_id',
                              labelKey: 'name',
                              valueKey: 'id',
                            }}
                            params={params}
                          >
                            {(props) => (
                              <GenericPicker
                                {...props}
                                title={t('filters.produce')}
                                onChange={field.onChange}
                                value={field.value}
                                placeholder={t('filters.search_produce')}
                                emptyMessage={t('filters.no_produce')}
                                groups={getGroups(filterOptions.produceOptions)}
                              />
                            )}
                          </PickerFetcher>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </ProgressStep>
                <ProgressStep
                  title="Analysis type"
                  description="Select the analysis type you want to perform"
                  isActive={!selectedProduce}
                  isDisabled={!selectedProduce}
                  isCompleted={!!selectedProduce}
                  spacing="lg"
                  disabledText='Select produce first'
                  disabledDescription='Select the produce you want to analyze'
                >
                  <FormField
                    name="analysisTypes"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="grid grid-cols-1 space-y-3">
                            {analysisTypeItems.map((type) => {
                              const isChecked = Array.isArray(field.value) && field.value.includes(type.value);

                              console.log(isChecked);

                              return (
                                <div key={type.value}>
                                  <IconCheckboxCard
                                    size="md"
                                    key={type.value}
                                    icon={type.icon}
                                    showCheckbox={false}
                                    title={type.label}
                                    description={type.description}
                                    checked={isChecked}
                                    onCheckedChange={(checked: boolean) => {
                                      console.log({ checked });
                                      if (checked) {
                                        field.onChange([...(field.value || []), type.value]);
                                      } else {
                                        field.onChange((field.value || []).filter((v: string) => v !== type.value));
                                      }
                                    }}
                                  />
                                  {type.value === 'quality_issues' && isChecked && (
                                    <QualityIssuesSettings params={params} />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </ProgressStep>

                <ProgressStep
                  title="Additional filters"
                  description="Add additional filters to your analysis"
                  isDisabled={!selectedProduce}
                  disabledText='Select produce first'
                  disabledDescription='Select the produce you want to analyze'
                  isActive={!!selectedProduce}
                  spacing="lg"
                >
                  <div className="py-2 space-y-4">
                    <FormField
                      name="varieties"
                      render={({ field }) => (
                        <FormItem>
                          <InputLabel>{t('filters.varieties')}</InputLabel>
                          <InputDescription>{t('filters.varieties_description')}</InputDescription>
                          <FormControl>
                            <PickerFetcher
                              reloadKey="filterOptions"
                              optionsKey="filterOptions.produceVarietyOptions"
                              parseInitialOptionsConfig={{
                                key: 'produce_variety_ids',
                                labelKey: 'name',
                                valueKey: 'id',
                              }}
                              params={params}
                            >
                              {(props) => (
                                <GenericPicker
                                  {...props}
                                  options={formatPicker(filterOptions.produceVarietyOptions)}
                                  title={t('filters.varieties')}
                                  onChange={field.onChange}
                                  value={field.value}
                                  isMulti
                                  placeholder={t('filters.search_varieties')}
                                  emptyMessage={t('filters.no_varieties')}
                                  // groups={getGroups(filterOptions.produceVarietyOptions)}
                                />
                              )}
                            </PickerFetcher>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="sellers"
                      render={({ field }) => (
                        <FormItem>
                          <InputLabel>{t('filters.sellers')}</InputLabel>
                          <InputDescription>{t('filters.sellers_description')}</InputDescription>
                          <FormControl>
                            <PickerFetcher
                              reloadKey="filterOptions"
                              optionsKey="filterOptions.sellerOptions"
                              parseInitialOptionsConfig={{
                                key: 'seller_ids',
                                labelKey: 'name',
                                valueKey: 'id',
                              }}
                              params={params}
                            >
                              {(props) => (
                                <GenericPicker
                                  {...props}
                                  title={t('filters.sellers')}
                                  onChange={field.onChange}
                                  value={field.value}
                                  isMulti
                                  placeholder={t('filters.search_sellers')}
                                  emptyMessage={t('filters.no_sellers')}
                                />
                              )}
                            </PickerFetcher>
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      name="region_ids"
                      render={({ field }) => (
                        <FormItem>
                          <InputLabel>{t('filters.regions')}</InputLabel>
                          <InputDescription>{t('filters.regions_description')}</InputDescription>
                          <FormControl>
                            <PickerFetcher
                              reloadKey="filterOptions"
                              optionsKey="filterOptions.regionOptions"
                              parseInitialOptionsConfig={{
                                key: 'region_ids',
                                labelKey: 'name',
                                valueKey: 'id',
                              }}
                              params={params}
                            >
                              {(props) => (
                                <GenericPicker
                                  {...props}
                                  isMulti
                                  title={t('filters.regions')}
                                  onChange={field.onChange}
                                  value={field.value}
                                  placeholder={t('filters.search_regions')}
                                  emptyMessage={t('filters.no_regions')}
                                />
                              )}
                            </PickerFetcher>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </ProgressStep>

                <ProgressStep
                  title="Date range"
                  spacing="lg"
                  description="Select the date range for your analysis"
                  isDisabled={!selectedProduce}
                  isActive={!!selectedProduce}
                  disabledText='Select produce first'
                  disabledDescription='Select the produce you want to analyze'
                  showConnector={false}
                >
                  <FormField
                    name="dateFilter"
                    render={({ field }) => (
                      <FormItem>
                        <DateFilterPicker
                          value={field.value}
                          onChange={field.onChange}
                          enableGranularity
                        />
                      </FormItem>
                    )}
                  />
                </ProgressStep>
              </ProgressSteps>
            </div>
            <CardFooter>
              <div className="flex items-center justify-end">
                <FormValidationStatus control={form.control} label="Unfinished" />
                <Button type="submit" size="lg" disabled={!isFormValid || isLoading}>
                  {isLoading && (
                    <span className='mr-2'>
                      <Spinner />
                    </span>
                  )}
                  {t('analytics.generate_report')}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Section>
      </form>
    </Form>
  );
};

export default AnalyticsFlowBuilder;
