'use client';

import { useTranslation } from 'react-i18next';

import { RegionIcon } from '@/Icon';
import { CountryFlag } from '~/Components/Locations/CountryFlag';
import { PickerItem } from '~/types/types';

import { GenericPicker, OptionBaseType } from './GenericPicker';

interface RegionPickerProps {
  onChange: (region: string | null) => void;
  loading?: boolean;
  selectedRegion?: string | null;
  value?: string | null;
  regions?: PickerItem[];
  titleKey?: string;
  isMulti?: boolean;
}

interface RegionOption extends OptionBaseType {
  countryCode: string;
}

/**
 * The RegionPicker is used to select a region (currently one region).
 *
 * - It works by reloading the page with a `region` key in the query string `?region=PE`.
 * - Pass in the currently selected region as `selectedRegion` (should be uppercased country-code, EG 'PE').
 * - Pass in the available regions as `regions` (should be uppercased country-codes, EG 'PE').
 */
export const RegionPicker = ({
  onChange,
  value = null,
  selectedRegion = null,
  regions = [],
  titleKey = 'select_region',
  loading,
  isMulti = false,
}: RegionPickerProps) => {
  const { t } = useTranslation();

  // Transform regions into options format expected by GenericPicker
  const options: RegionOption[] = regions.map((region: PickerItem) => {
    const countryCode = region.label.toUpperCase();

    let countryName = countryCode;
    try {
      // Get the country name using Intl.DisplayNames
      countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode) || countryCode;
    } catch {
      // If the country name is not found, use the country code
      countryName = countryCode;
    }

    return {
      value: region.value,
      label: countryName,
      countryCode,
      icon: () => <CountryFlag countryCode={countryCode} />,
      subLabel: countryCode,
    };
  });

  return (
    <GenericPicker
      className='w-full'
      title={t(titleKey)}
      options={options}
      value={selectedRegion || value}
      onChange={onChange}
      loading={loading}
      icon={RegionIcon}
      placeholder={t('search_region')}
      isMulti={isMulti}
      emptyMessage={t('no_region_found')}
      showClear
    />
  );
};
