import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Circle } from 'lucide-react';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormLabel } from '@/Form';
import { cn } from '~/utils/cn';
import { makeRandomID } from '~/utils/random';

export const RadioGroup = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export const RadioGroupItem = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current text-current"/>
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

interface RadioBooleanSelectionProps {
  value: boolean;
  onChange: (value: boolean) => void;
  labels?: [string, string];
  subLabels?: [string, string];
  disabled?: boolean;
}

/**
 * RadioBooleanSelection
 *
 * This component is used to render a radio group with two options, Yes and No (or custom labels).
 *
 * How to use it:
 * - Assuming we work with a boolean Controlled property in the parent, we can use this component to render a radio group with two options, Yes and No.
 *
 * @param value
 * @param onChange
 * @param labels
 * @param subLabels
 * @constructor
 */
export const RadioBooleanSelection = ({
  value,
  onChange,
  labels,
  disabled,
}: RadioBooleanSelectionProps) => {
  const { t } = useTranslation();
  const id = makeRandomID();
  const [trueLabel, falseLabel] = labels ?? [t('yes'), t('no')];
  return (
    <div className="flex justify-end items-center gap-8">
      <div className="flex items-center space-x-2">
        <RadioGroup
          disabled={disabled}
          value={value ? 'true' : 'false'}
          onValueChange={value => onChange(value === 'true')}
          className="flex items-center space-x-8"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem className="text-emerald-700" value="true" id={`${id}_one`}/>
            <FormLabel className="font-normal text-gray-700" htmlFor={`${id}_one`}>{trueLabel}</FormLabel>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem className="text-emerald-700" value="false" id={`${id}_two`}/>
            <FormLabel className="font-normal text-gray-700" htmlFor={`${id}_two`}>{falseLabel}</FormLabel>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};
