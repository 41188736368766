import { Trash2Icon } from 'lucide-react';
import React from 'react';
import { PhotoView } from 'react-photo-view';

import { Button } from '@/Button';
import { Card, CardBody } from '@/Card';
import { NumericIndicator } from '@/NumericIndicator';
import { Tooltipped } from '@/Tooltip';
import { CommentsPane } from '~/Components/Panes/CommentsPane';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { Attachment } from '~/types/types';

interface AttachmentItemProps {
  attachment: Attachment;
  showComments?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

/**
 * Renders a single attachment with its file and potential comment
 */
export const AttachmentItem: React.FC<AttachmentItemProps> = ({
  attachment,
  showComments = true,
  size = 'md',
}) => {
  const { organization, order } = usePageProps();

  const { post: deleteAttachment } = useAction(
    `/b/${organization?.id}/orders/${order?.id}/attachments/${attachment.id}/delete`,
    {
      preserveScroll: true,
    }
  );

  // Size classes for the container
  const sizeClasses = {
    sm: 'aspect-square max-h-24',
    md: 'aspect-square max-h-36',
    lg: 'aspect-square max-h-48',
  };

  return (
    <Card key={attachment.id}>
      {attachment.file && (
        <div className="relative">
          <CardBody size="sm" className="p-2">
            <div className="relative">
              <div className="group">
                <PhotoView
                  src={attachment.file.url}
                >
                  <div
                    className={`block w-full overflow-hidden bg-gray-100 rounded-lg cursor-pointer group ${sizeClasses[size]} focus-within:ring-emerald-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100`}
                  >
                    <img
                      src={attachment.file.url}
                      alt={`${attachment.attachable_type} ${attachment.attachable_id}`}
                      className="object-cover w-full h-full pointer-events-none group-hover:opacity-75"
                    />

                    {/* Delete button that appears on hover */}
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteAttachment({});
                      }}
                      aria-label="Delete attachment"
                      data-testid="delete-attachment"
                      className="absolute top-2 right-2 p-1.5 bg-white bg-opacity-80 rounded-full shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:bg-red-50"
                    >
                      <Trash2Icon className="w-4 h-4 text-red-500" />
                    </button>
                  </div>
                </PhotoView>
              </div>
            </div>
          </CardBody>

          {showComments && (
            <CardBody className="bg-gray-50" size="sm">
              <div className="flex items-center justify-between">
                <Tooltipped
                  label={
                    attachment.file.filename.split('/')[
                      attachment.file.filename.split('/').length - 1
                    ]
                  }
                >
                  <p className="block text-sm font-medium text-gray-900 truncate cursor-default">
                    {attachment.file.filename.split('/').pop()}
                  </p>
                </Tooltipped>

                {organization && (
                  <div>
                    <CommentsPane
                      Trigger={
                        <Button
                          size="sm"
                          variant="white"
                          className="flex items-center"
                        >
                          <span>Comment</span>
                          {attachment.comments &&
                            attachment.comments.length > 0 && (
                            <span className="ml-1">
                              <NumericIndicator
                                brand="red"
                                size="xs"
                              >
                                {attachment.comments?.length}
                              </NumericIndicator>
                            </span>
                          )}
                        </Button>
                      }
                      comments={attachment.comments || []}
                      commentable={{
                        id: attachment.id,
                        type: 'Attachment',
                      }}
                      path={`/b/${organization.id}/comments/add`}
                      order={order}
                    />
                  </div>
                )}
              </div>
            </CardBody>
          )}
        </div>
      )}
    </Card>
  );
};
