import { useCallback, useEffect, useRef, useState } from 'react';

interface FileWithPreview {
  file: File;
  previewUrl: string;
}

interface UseMultiImageUploadProps {
  onUpload?: (files: File[]) => void;
  initialFiles?: File[];
}

export function useMultiImageUpload({ onUpload, initialFiles = [] }: UseMultiImageUploadProps = {}) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  // Initialize with any existing files
  useEffect(() => {
    if (initialFiles.length > 0) {
      const filesWithPreviews = initialFiles.map(file => ({
        file,
        previewUrl: URL.createObjectURL(file),
      }));
      setFiles(filesWithPreviews);
    }

    // Cleanup function to revoke object URLs
    return () => {
      files.forEach(file => {
        URL.revokeObjectURL(file.previewUrl);
      });
    };
  }, [initialFiles]);

  const handleThumbnailClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const newFiles = Array.from(event.target.files);

        // Create preview URLs for each file
        const newFilesWithPreviews = newFiles.map(file => ({
          file,
          previewUrl: URL.createObjectURL(file),
        }));

        setFiles(prev => [...prev, ...newFilesWithPreviews]);

        // Call the onUpload callback with all files (existing + new)
        if (onUpload) {
          const allFiles = [...files.map(f => f.file), ...newFiles];
          onUpload(allFiles);
        }
      }
    },
    [files, onUpload]
  );

  const handleRemove = useCallback((index: number) => {
    setFiles(prev => {
      // Revoke the object URL to prevent memory leaks
      URL.revokeObjectURL(prev[index].previewUrl);

      // Remove the file at the specified index
      const updatedFiles = prev.filter((_, i) => i !== index);

      // Call the onUpload callback with the updated files
      if (onUpload) {
        onUpload(updatedFiles.map(f => f.file));
      }

      return updatedFiles;
    });
  }, [onUpload]);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const droppedFiles = Array.from(e.dataTransfer.files).filter(
          file => file.type.startsWith('image/') || file.type === 'application/pdf'
        );

        if (droppedFiles.length > 0) {
          // Create preview URLs for each file
          const newFilesWithPreviews = droppedFiles.map(file => ({
            file,
            previewUrl: URL.createObjectURL(file),
          }));

          setFiles(prev => [...prev, ...newFilesWithPreviews]);

          // Call the onUpload callback with all files (existing + new)
          if (onUpload) {
            const allFiles = [...files.map(f => f.file), ...droppedFiles];
            onUpload(allFiles);
          }
        }
      }
    },
    [files, onUpload]
  );

  return {
    files,
    fileInputRef,
    handleThumbnailClick,
    handleFileChange,
    handleRemove,
    handleDrop,
  };
}
