import { Fragment, ReactNode } from 'react';

import { makeSettingsSubLinks, makeTopLinks } from '@/Nav/nav-items';
import { MainNavItem, Navbar, SubNavItem } from '@/Nav/Navbar';
import { DebugProvider, LocalDebugModal } from '~/Components/Common/LocalDebugModal';
import { usePageProps } from '~/hooks/usePageProps';
import { AppProvider } from '~/Providers/AppProvider';

interface DashboardLayoutProps {
  children: ReactNode;
}

export const SettingsLayout = ({ children }: DashboardLayoutProps) => {
  const { user, organization, features, ...props } = usePageProps();
  const mainTopLinks = makeTopLinks(organization.id);
  const subLinks = makeSettingsSubLinks(organization.id);

  return (
    <div className="min-h-screen bg-gray-50">
      <AppProvider>
        <DebugProvider pageProps={props}>
          <LocalDebugModal/>
          <Navbar
            user={user}
            organization={organization}
            renderNavLinks={(
              <div className="flex gap-3">
                {mainTopLinks.map((link) => (
                  <Fragment key={link.name}>
                    {link.requiresFeature && features && link.requiresFeature in features && features[link.requiresFeature][0] && (
                      <MainNavItem key={link.name} link={link}/>
                    )}

                    {!link.requiresFeature && (
                      <MainNavItem key={link.name} link={link}/>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
            renderSubNavLinks={(
              <div className="flex items-center justify-center space-x-4">
                {subLinks.map((link) => (
                  <SubNavItem key={link.name} link={link}/>
                ))}
              </div>
            )}
          />
          {children}
        </DebugProvider>
      </AppProvider>
    </div>
  );
};

export default SettingsLayout;
