import { QualityCostsIcon, QualityIssuesIcon, ReportingAccuracyIcon } from '@/Icon';
import { OptionBaseType } from '@/Pickers/GenericPicker';

export const reportOptions: OptionBaseType[] = [
  {
    label: 'Quality Costs',
    subLabel: 'Analyze quality-related costs over time, including breakdowns by seller. Track financial impact of quality issues and identify cost-saving opportunities.',
    group: 'costs',
    value: 'costs',
    icon: QualityCostsIcon,
    disabled: false,
  },
  {
    label: 'Quality issues',
    subLabel: 'Analyze quality issues over time, including breakdowns by seller. Understand the root causes of quality issues and how they trend with other metrics.',
    group: 'issues',
    value: 'quality_issues',
    icon: QualityIssuesIcon,
    disabled: false,
    metaLabel: 'Quality issues',
  },
  {
    label: 'Reporting accuracy',
    subLabel: 'Compare the accuracy of your sellers over time and how accurate they are compared to the industry average.',
    group: 'trust',
    value: 'reporting_accuracy',
    icon: ReportingAccuracyIcon,
    disabled: false,
  },
];
