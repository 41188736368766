import React from 'react';
import { useTranslation } from 'react-i18next';

import { WithAwaitingFallback } from '@/Fallback';
import { SellerIcon } from '@/Icon';
import Section, { SectionLayout } from '~/Components/Common/Section';
import { WeatherIssuesCard } from '~/Components/Issues/Weather/WeatherIssuesCard';
import { OrderDataStream, OrderDataStreamType, WeatherDataPayload } from '~/types/types';
import {usePageProps} from "~/hooks/usePageProps";

import {WeatherSummarySection} from "./WeatherSummaryCard";

export interface HarvestSectionProps {
  dataStreams: OrderDataStream<WeatherDataPayload>[];
}

export const HarvestSection = ({ dataStreams }: HarvestSectionProps) => {
  const { t } = useTranslation();
  const { data } = usePageProps();

  const relevantDataStreams = dataStreams.filter((dataStream) => {
    return dataStream.type === OrderDataStreamType.WEATHER && dataStream?.data?.events?.length > 0;
  });

  if (relevantDataStreams.length === 0) {
    return null;
  }

  const weatherIssue = data.weatherIssue;

  return (
    <div id={OrderDataStreamType.WEATHER} className="py-8">
      <Section title={t('weather')} icon={SellerIcon} showCollapsed enableToggle>
        <SectionLayout variant="3_1">
          <WithAwaitingFallback>
            <div className="space-y-4">
              <WeatherSummarySection dataStreams={relevantDataStreams}/>
            </div>
          </WithAwaitingFallback>
          <div>
            {weatherIssue && (
              <WeatherIssuesCard compoundIssue={weatherIssue}/>
            )}
          </div>
          {/* All placeholders..  */}
          {/*<div>*/}
          {/*  <Card>*/}
          {/*    <SimpleCardHeader className="bg-rose-600! rounded-t">*/}
          {/*      <SimpleCardHeaderIcon className="text-rose-50!" icon={IssueIcon}/>*/}
          {/*      <SimpleCardTitle className="text-rose-50!">*/}
          {/*        Weather issue*/}
          {/*      </SimpleCardTitle>*/}
          {/*    </SimpleCardHeader>*/}
          {/*    <CardBody>*/}
          {/*      <div className="mb-3">*/}
          {/*        <div className="mb-2">*/}
          {/*          <Helper>Explanation</Helper>*/}
          {/*        </div>*/}
          {/*        <div className="flex gap-4">*/}
          {/*          <IconBox className="bg-rose-50! text-rose-700! w-8 h-8 flex items-center justify-center border-0!">*/}
          {/*            <IconButtonIcon className="text-rose-700! max-w-6!" icon={UmbrellaIcon}/>*/}
          {/*          </IconBox>*/}

          {/*          <div>*/}
          {/*            <Text>*/}
          {/*              In the growing period, there was reduced rainfall.*/}
          {/*            </Text>*/}
          {/*            <div className="bg-gray-50 border-2 px-2 py-2 my-2 rounded">*/}
          {/*              <MutedText>*/}
          {/*                <Strong className="text-xs flex text-slate-800!">*/}
          {/*                  Context*/}
          {/*                </Strong>*/}

          {/*                <div>*/}
          {/*                  Historic trend data is associated with risk of <Strong className="text-xs! text-gray-500!">salt*/}
          {/*                  injury</Strong>.*/}
          {/*                </div>*/}
          {/*              </MutedText>*/}

          {/*              <div className="mt-1">*/}
          {/*                <Button variant="white" size="xs">*/}
          {/*                  Show more*/}
          {/*                </Button>*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*      </div>*/}

          {/*      <hr className="py-1"/>*/}

          {/*      <div>*/}
          {/*        <div className="mb-2">*/}
          {/*          <Helper>Predicted impact</Helper>*/}
          {/*        </div>*/}

          {/*        <div className="flex gap-4">*/}
          {/*          <IconBox className="bg-blue-50! text-blue-700! w-8 h-8 flex items-center justify-center border-0!">*/}
          {/*            <IconButtonIcon className="text-blue-700! max-w-6!" icon={AIIcon}/>*/}
          {/*          </IconBox>*/}

          {/*          <div>*/}
          {/*            <Text>*/}
          {/*              The forecasted yield for this produce is forecasted to be <Strong className="text-rose-700!">critically*/}
          {/*              low</Strong>.*/}
          {/*            </Text>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </CardBody>*/}
          {/*    <CardFooter>*/}
          {/*      <IssueActions*/}
          {/*        loading={false}*/}
          {/*        onClick={() => {*/}
          {/*        }}*/}
          {/*        issue={{ status: '', comment: '', informSeller: false }}*/}
          {/*        onChange={() => {*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </CardFooter>*/}
          {/*  </Card>*/}
          {/*</div>*/}
        </SectionLayout>
      </Section>
    </div>
  );
};
