import { ImagePlus, Trash2 } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';

import { useMultiImageUpload } from './useMultiImageUpload';

interface MultiImageUploadProps {
  onUpload: (files: File[]) => void;
  initialFiles?: File[];
  className?: string;
}

export function MultiImageUpload({ onUpload, initialFiles = [], className = '' }: MultiImageUploadProps) {
  const { t } = useTranslation();

  const {
    files,
    fileInputRef,
    handleThumbnailClick,
    handleFileChange,
    handleRemove,
    handleDrop,
  } = useMultiImageUpload({
    onUpload,
    initialFiles,
  });

  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDropWrapper = (e: React.DragEvent<HTMLDivElement>) => {
    handleDrop(e);
    setIsDragging(false);
  };

  return (
    <div className={`w-full space-y-4 ${className}`}>
      <input
        type="file"
        accept="image/*,.pdf"
        className="hidden"
        ref={fileInputRef}
        onChange={handleFileChange}
        multiple
      />

      {files.length === 0 ? (
        <div
          onClick={handleThumbnailClick}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDropWrapper}
          className={`flex h-40 cursor-pointer flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 transition-colors hover:bg-gray-100 ${
            isDragging ? 'border-blue-500 bg-blue-50' : ''
          }`}
        >
          <div className="p-3 bg-white rounded-full shadow-sm">
            <ImagePlus className="w-6 h-6 text-gray-500" />
          </div>
          <div className="text-center">
            <p className="text-sm font-medium">{t('click_to_select')}</p>
            <p className="text-xs text-gray-500">
              {t('drag_and_drop_files')}
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
            {files.map((file, index) => (
              <div key={index} className="relative group">
                <div className="relative h-32 overflow-hidden border rounded-lg">
                  {file.file.type.startsWith('image/') ? (
                    <img
                      src={file.previewUrl}
                      alt={t('preview_image', { index: index + 1 }) || `Preview ${index + 1}`}
                      className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-105"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full bg-gray-100">
                      <span className="text-sm text-gray-500">{t('pdf_document')}</span>
                    </div>
                  )}
                  <div className="absolute inset-0 transition-opacity opacity-0 bg-black/40 group-hover:opacity-100" />
                  <div className="absolute inset-0 flex items-center justify-center gap-2 transition-opacity opacity-0 group-hover:opacity-100">
                    <Button
                      size="sm"
                      variant="error"
                      onClick={() => handleRemove(index)}
                      className="w-8 h-8 p-0"
                      aria-label={t('remove_file')}
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
                <div className="flex items-center gap-2 mt-1 text-xs text-gray-500">
                  <span className="truncate max-w-[90%]">{file.file.name}</span>
                </div>
              </div>
            ))}

            {/* Add more button */}
            <div
              onClick={handleThumbnailClick}
              className="flex flex-col items-center justify-center h-32 gap-2 transition-colors border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
            >
              <ImagePlus className="w-6 h-6 text-gray-500" />
              <span className="text-xs text-gray-500">{t('add_more_files')}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
