import * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import React from 'react';
import {
  Control,
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { w } from 'windstitch';

import { MutedText, Text } from '@/Text';

import { cn } from '../utils/cn';

const labelVariants = cva(
  'text-sm font-medium text-gray-700 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
);

/**
 * Root form component.
 *
 * Structure:
 * - Form (give it the form context: with the form.useForm() context spread in)
 * - form (with the onSubmit handler) (html form element)
 * - FormGrid (div, to space out items)
 * - (If controlled inputs) FormField (Controller, with the name prop, and render prop); this makes Controlled inputs
 * - FormItem (input-group)
 * - (If controlled inputs) FormControl (accessible wrapper around the input, requires FormField)
 *
 * How to use Form items
 * @example
 * ```tsx
 * <Form {...form}>
 *   <form onSubmit={form.handleSubmit(handleSubmit)}>
 *     <FormGrid>
 *       <FormField
 *         name="type"
 *         render={({ field }) => {
 *           return (
 *             <FormItem>
 *               <InputLabel>{t('report_type')}</InputLabel>
 *               <InputDescription>
 *                 {t('report_type_description')}
 *               </InputDescription>
 *               <FormControl>
 *                 <RadioGroup
 *                   disabled={isEditing}
 *                   onValueChange={field.onChange}
 *                   value={field.value}
 *                   className="space-y-2"
 *                 >
 *                   <RadioButtonCard
 *                     id="FORECAST_ACCURACY"
 *                     value="FORECAST_ACCURACY"
 *                     label={t('reports.type_FORECAST_ACCURACY') as string}
 *                     subLabel={t('reports.type_FORECAST_ACCURACY_description') as string}
 *                   />
 *                   <RadioButtonCard
 *                     id="QUALITY"
 *                     value="QUALITY"
 *                     label={t('reports.type_QUALITY') as string}
 *                     subLabel={t('quality_report_description') as string}
 *                   />
 *                   <RadioButtonCard
 *                     id="QUALITY_COSTS"
 *                     value="QUALITY_COSTS"
 *                     label={t('reports.type_QUALITY_COSTS') as string}
 *                     subLabel={t('reports.type_QUALITY_COSTS_description') as string}
 *                   />
 *                 </RadioGroup>
 *               </FormControl>
 *             </FormItem>
 *           );
 *         }}
 *       />
 *     </FormGrid>
 *   </form>
 * </Form>
 * ```
 */
export const Form = FormProvider;

/**
 * Label component.
 *
 * Used for the label of an input.
 */
export const InputLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
  VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants(), className)}
    {...props}
  />
));
InputLabel.displayName = LabelPrimitive.Root.displayName;

export const InputDescription = w.div('text-sm text-gray-500');

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName
}

type ItemContextValue = {
  id: string
}

const ItemContext = React.createContext<ItemContextValue>(
  {} as ItemContextValue
);

/**
 * A FormItem is an individual input group, containing a unique `id`.
 *
 * @precondition Must be used within a Form
 */
export const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
// eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <ItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn('space-y-1', className)} {...props} />
    </ItemContext.Provider>
  );
});
FormItem.displayName = 'Item';

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
);

/**
 * A FormField is used for Controller form inputs (like switches).
 *
 * Note: has the same API as Controller.
 *
 * @precondition Must be used within a Form
 */
export const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    ...props
  }: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(ItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

/**
 * A FormControl is used for controlled form inputs to give them accessible labels.
 * - It passes these accessibility to the direct child.
 * - It also passes all props to the direct child.
 *
 * @precondition Must be used within a FormField
 */
export const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

export const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <Text
      ref={ref}
      id={formDescriptionId}
      className={cn('text-sm text-muted-foreground', className)}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

export const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <Text
      ref={ref}
      id={formMessageId}
      className={cn('text-sm font-medium text-red-500', className)}
      {...props}
    >
      {body}
    </Text>
  );
});
FormMessage.displayName = 'FormMessage';

export const FormGrid = w.div('space-y-8');

export const InputRoot = w.div('');

export const Input = w.input(`
  rounded flex h-9 w-full border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 placeholder-gray-500 placeholder-opacity-75
`);

export const FormSeparator = w.hr('h-px bg-gray-200');

export const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
  VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants(), className)}
    {...props}
  />
));
FormLabel.displayName = LabelPrimitive.Root.displayName;

interface FormValidationStatusProps<T extends Record<string, any>> {
  control: Control<T>;
  label: string;
}

/**
 * FormValidationStatus component
 *
 * Displays a validation status message for a form.
 * The component shows a message when the form is either invalid or not dirty.
 * When the form becomes valid and has been modified, the component returns null.
 *
 * @param control - The form control from react-hook-form
 * @param label - The text to display as the validation message (defaults to 'Complete the form')
 * @returns A status indicator with the provided label or null if the form is valid and dirty
 */

export const FormValidationStatus = <T extends Record<string, any>>({
  control,
  label = 'Complete the form',
}: FormValidationStatusProps<T>) => {
  const { t } = useTranslation();
  const { isValid, isDirty } = useFormState({ control });

  // If form is valid, don't show anything
  if (isValid && isDirty) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex items-center mr-4">
        <span className="w-2 h-2 mr-2 bg-gray-400 rounded-full"></span>
        <MutedText className="font-medium">
          {t(label)}
        </MutedText>
      </div>
    </motion.div>
  );
};