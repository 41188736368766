// AnalyticsProvider.tsx

import { createContext, ReactNode, useContext } from 'react';

import { usePageProps } from '~/hooks/usePageProps';

export type AnalyticsFilterOptions = App.Http.Controllers.Reports.AnalyticsFilterOptions & {
  produceOptions: App.Domain.Common.PickerOption[];
}

interface AnalyticsContextValue {
  filterOptions: AnalyticsFilterOptions;
}

interface AnalyticsProviderProps {
  children: ReactNode;
}

const AnalyticsContext = createContext<AnalyticsContextValue | undefined>(undefined);

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const { filterOptions } = usePageProps();

  const value = {
    filterOptions,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }

  return context;
};
