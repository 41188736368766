import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeader } from '@/Card';
import { CardTitle } from '@/Text';
import { useQuery } from '~/hooks/useQuery';
import { useQueryParam } from '~/hooks/useQueryParam';
import { AnalyticsConfigRequest, QualityOverTime } from '~/types/types';

import { DensityAreaChart } from './DensityArea';
import { TimeseriesChart } from './TimeseriesChart';
import {Tooltipped} from "@/Tooltip";
import { InfoIcon } from 'lucide-react';
import {TooltipIcon} from "@/Icon";

interface QualityOverTimeChartProps {
  /**
   * QualityOverTime data to display
   */
  qualityOverTime: QualityOverTime;
  /**
   * Height of the chart
   */
  height?: number | string;
  /**
   * Width of the chart
   */
  width?: number | string;
  /**
   * Custom class name for the container
   */
  className?: string;
}

/**
 * Component to visualize QualityOverTime data using a line chart.
 */
export const QualityOverTimeChart = ({
  qualityOverTime,
  height = 550,
  width = '100%',
  className = '',
}: QualityOverTimeChartProps) => {
  const { t } = useTranslation();
  const { parameters } = useQuery<AnalyticsConfigRequest>();
  const { field } = qualityOverTime;

  // Use query parameters for date range filtering with a single parameter
  const { value: dateRange } = useQueryParam<any, 'dateRange'>('dateRange', null);

  // Determine the unit based on field type
  const getUnit = () => {
    if (field?.type === 'percentage') return '%';
    if (field?.unit) return field.unit;

    return '';
  };

  const syncId = `sync-${field?.id}-${qualityOverTime.densityChart?.id || 'density'}`;

  // Prepare data for the scrubber
  const scrubberData = useMemo(() => {
    // Combine all time series data for the scrubber
    const allDates = new Set<string>();
    const dateValueMap = new Map<string, number>();

    // Add dates from time series
    qualityOverTime.timeSeriesGroup.timeSeries.forEach(series => {
      series.events.forEach(event => {
        allDates.add(event.date);
        // Sum values for the same date (for volume representation)
        const currentValue = dateValueMap.get(event.date) || 0;
        dateValueMap.set(event.date, currentValue + (event.value || 0));
      });
    });

    // Add dates from density chart if available
    if (qualityOverTime.densityChart?.events) {
      qualityOverTime.densityChart.events.forEach(event => {
        allDates.add(event.date);
        dateValueMap.set(event.date, event.value);
      });
    }

    // Convert to array and sort by date
    return Array.from(allDates)
      .map(date => ({
        date,
        value: dateValueMap.get(date) || 0,
      }))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [qualityOverTime]);

  // Set default date range if not provided
  const defaultDateRange = useMemo(() => {
    if (scrubberData.length === 0) return null;

    // Default to showing all data
    return {
      from: scrubberData[0].date,
      to: scrubberData[scrubberData.length - 1].date,
    };
  }, [scrubberData]);

  // Use either the URL date range or the default
  const effectiveDateRange = dateRange || defaultDateRange;

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          {qualityOverTime.title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <TimeseriesChart
          seriesGroup={qualityOverTime.timeSeriesGroup}
          height={height}
          width={width}
          unit={getUnit()}
          showDots={false}
          showLegend={true}
          syncId={syncId}
          // Pass date range to TimeseriesChart if needed
          dateRange={effectiveDateRange}
        />

        {qualityOverTime.weatherDensityChart && qualityOverTime.weatherDensityChart?.events?.length > 0 && (
          <div className="pt-2 mt-2 border-t">
            <div className="flex items-center justify-between mb-1">
              <span className="text-xs font-medium text-gray-500">
                {qualityOverTime.weatherDensityChart.label} <Tooltipped label={'The analysis period has been automatically set to include 60 days prior to your selected date period (if data available)'}>
                <span><TooltipIcon icon={InfoIcon} className={'text-slate-500' } /></span>
              </Tooltipped>
              </span>
            </div>
            <DensityAreaChart
              timeSeries={qualityOverTime.weatherDensityChart}
              height={80}
              showYAxis={false}
              gradientColors={{start: '#1662f9', end: '#1662f9'}}
              gradientId="rainfall-density"
              syncId={syncId}
              // Pass date range to DensityAreaChart if needed
              dateRange={effectiveDateRange}
              tooltipValueFractionDigits={3}
            />
          </div>
        )}

        {qualityOverTime.delayDensityChart?.events?.length > 0 && (
          <div className="pt-2 mt-2 border-t">
            <div className="flex items-center justify-between mb-1">
            <span className="text-xs font-medium text-gray-500">
              Total delays on day
            </span>
            </div>
            <DensityAreaChart
              timeSeries={qualityOverTime.delayDensityChart}
              height={80}
              showYAxis={false}
              gradientColors={{start: '#f97316', end: '#f97316'}}
              gradientId="volume-density"
              syncId={syncId}
              // Pass date range to DensityAreaChart if needed
              dateRange={effectiveDateRange}
            />
          </div>
        )}

        {qualityOverTime.issuesDensityChart?.events?.length > 0 && (
          <div className="pt-2 mt-2 border-t">
            <div className="flex items-center justify-between mb-1">
            <span className="text-xs font-medium text-gray-500">
              Total issues on day
            </span>
            </div>
            <DensityAreaChart
              timeSeries={qualityOverTime.issuesDensityChart}
              height={80}
              showYAxis={false}
              gradientColors={{start: '#ef4444', end: '#ef4444'}}
              gradientId="volume-density"
              syncId={syncId}
              // Pass date range to DensityAreaChart if needed
              dateRange={effectiveDateRange}
            />
          </div>
        )}

      </CardBody>
    </Card>
);
};
